import React, { useState} from 'react';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function ProfilSearchBox({username}) {
    const [searchValue, setSearchValue] = useState('');
    const [loginTime, setLoginTime] = useState(null);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const navigate = useNavigate();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (searchValue.toLowerCase() === 'home') {
                navigate('/main/homePage'); 
            } else if (searchValue.toLowerCase() === 'sales'){
                navigate('/salesIndex'); 
            } else if (searchValue.toLowerCase() === 'setting'){
                navigate('/settingIndex');
            } else if (searchValue.toLowerCase() === 'purchase'){
                navigate('/purchaseIndex'); 
            } else if (searchValue.toLowerCase() === 'finance'){
                navigate('/financeIndex'); 
            } else if (searchValue.toLowerCase() === 'warehouse'){
                navigate('/warehouseIndex'); 
            } else if (searchValue.toLowerCase() === 'hr'){
                navigate('/hrIndex'); 
            } else if (searchValue.toLowerCase() === 'analytics'){
                navigate('/analyticsIndex'); 
            } else if (searchValue.toLowerCase() === 'document'){
                navigate('/documentIndex'); 
            } else if (searchValue.toLowerCase() === 'logout'){
                localStorage.removeItem('loggedInTime');
                setLoginTime(null);
                navigate('/'); 
            }

        }
    };

    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };

    const handleChangeProfile = () =>{
        navigate('/main/changeProfile');
    }

    return (
        <div className="profilSearchBoxContainer">
            <FaSearch className="search-icon" onClick={toggleMenu} />
            <div className="search-box-container">
                <FaSearch />
                <input 
                    type='text' 
                    placeholder='Search...' 
                    className='search-box' 
                    value={searchValue} 
                    onChange={(event) => setSearchValue(event.target.value)} // Update searchValue on change
                    onKeyDown={handleKeyDown} // Listen for Enter key press
                />
            </div>
            <div className="profilContainer" onClick={handleChangeProfile}>{username}</div>
            {isMenuVisible && (
                <div>d</div>
            )}
        </div>
    );
}

export default ProfilSearchBox;
