import React, { useState, useEffect } from 'react';
import DashboardNonActive from '../../component/menu/DashboardNonActive';
import SalesNonActive from '../../component/menu/SalesNonActive';
import PurchaseNonActive from '../../component/menu/PurchaseNonActive';
import FinanceNonActive from '../../component/menu/FinanceNonActive';
import WarehouseNonActive from '../../component/menu/WarehouseNonActive';
import HRNonActive from '../../component/menu/HRNonActive';
import AnalyticsnonActive from '../../component/menu/AnalyticsNonActive';
import DocumentnonActive from '../../component/menu/DocumentNonActive';
import SettingActive from '../../component/menu/SettingActive';
import Logout from '../../component/menu/Logout';
import { useParams } from 'react-router-dom';
import ProfilSearchBox from '../../component/ProfilSearchBox';
import useAuthSession from '../../services/Session';
import Loader from '../../component/Loader';
import TextBox from '../../component/TextBox';
import TextArea from '../../component/TextArea';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../App';
import ErrorDialog from '../../component/dialog/ErrorDialog';

function DetailCustomer() {
    const navigate = useNavigate();
    let { id } = useParams();
    const { username } = useAuthSession();
    const [loading, setLoading] = useState(false);
    const [editedValues, setEditedValues] = useState({});
    const [showDialog, setShowDialog] = useState(false);  
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}master/customer/getdetailcustomer.php?company_id=${id}`);
                if (!response.ok) {
                    setErrorCode('404');
                    setErrorTitle('Failed to fetch purchase data');
                    setShowDialog(true);
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setEditedValues(data.Data[0]);
                } else {
                    setErrorCode('404');
                    setErrorTitle('Failed to load the data');
                    setShowDialog(true);
                }
            } catch (error) {
                setErrorCode('404');
                setErrorTitle(error.message);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]); 

    const handleInputChange = (key, value) => {
        setEditedValues(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleSubmit = async (e) => {
        setLoading(true);

        try{
            const apiUpdateCustomer = `${API_BASE_URL}master/customer/updatecustomer.php`;
            const response = await fetch(apiUpdateCustomer, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    company_id: id,
                    company_name: editedValues.company_name,
                    company_address: editedValues.company_address,
                    company_phone: editedValues.company_phone,
                    company_pic_name: editedValues.company_pic_name,
                    company_pic_contact: editedValues.company_pic_contact,
                    company_top: editedValues.company_top
                }),
            });

            if (!response.ok) {
                setErrorCode('404');
                setErrorTitle('Failed to reset update customer data');
                setShowDialog(true);
            }

            if (response.status === 500) {
                setErrorCode('404');
                setErrorTitle('Failed to update customer data !! Please check your field again !!');
                setShowDialog(true);
            }


            if(response.status === 200){
                navigate('/settingIndex');
            }
        } catch (error){
            setErrorCode('404');
            setErrorTitle('An error occurred during update. Please try again later.');
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    return (
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive />
                <SalesNonActive />
                <PurchaseNonActive />
                <FinanceNonActive />
                <WarehouseNonActive />
                <HRNonActive />
                <AnalyticsnonActive />
                <DocumentnonActive />
                <SettingActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? (<Loader />) : (
                    <div>
                        <div className='grid-container'>
                            <div className='MenuName'>Detail Customer</div>
                        </div>
                        <div className="card-for-grid">
                            <form onSubmit={handleSubmit}>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Company Name</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={editedValues.company_name} onChange={(e) => handleInputChange('company_name', e.target.value)} />
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Company Phone</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={editedValues.company_phone} onChange={(e) => handleInputChange('company_phone', e.target.value)} />
                                    </div>
                                </div>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Company Address</label>
                                        <div className='space-between-1vh'></div>
                                        <TextArea value={editedValues.company_address} onChange={(e) => handleInputChange('company_address', e.target.value)} />
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Company PIC Name</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={editedValues.company_pic_name} onChange={(e) => handleInputChange('company_pic_name', e.target.value)} />
                                    </div>
                                </div>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Company PIC Contact</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={editedValues.company_pic_contact} onChange={(e) => handleInputChange('company_pic_contact', e.target.value)} />
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Company TOP</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={editedValues.company_top} onChange={(e) => handleInputChange('company_top', e.target.value)}/>
                                    </div>
                                </div>
                                <div className='space-between'></div>
                                <div className='grid-container-form-submit'>
                                    <div className='verification-button' onClick={handleSubmit}>Update</div>
                                </div>
                            </form>
                        </div>
                        <div className='grid-container'>
                            <div className='MenuName'>Customer History</div>
                        </div>
                    </div>
                )}
                {/* <div>{id}</div> */}
            </div>
        </div>
    );
}

export default DetailCustomer;
