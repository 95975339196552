import React, { useState, useEffect } from 'react';
import FormLabel from "../FormLabel";
import { FaTrash } from 'react-icons/fa';
import TextBox from "../TextBox";
import { API_BASE_URL } from '../../../App';

function SalesOrderTemplate({ items, setItems, selectedTax }) {
    const [count, setCount] = useState(0);
    const [total, setTotal] = useState(0);
    const [totalDPP, setTotalDPP] = useState(0);
    const [totalPPN, setTotalPPN] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [kursOptions, setKursOptions] = useState([]);
    const [uomOptions, setUomOptions] = useState([]);
    const [kodeProduk, setKodeProduk] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    useEffect(() => {
        fetchKursOptions();
        fetchUomOptions();
    }, []);

    const fetchKursOptions = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}master/currency/getallcurrency.php`);
            const data = await response.json();
            setKursOptions(data.Data.map(currency => ({ key: currency.Id, value: currency.Currency })));
        } catch (error) {
            console.error('Error fetching kurs options:', error);
        }
    };

    const fetchUomOptions = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}master/uom/getalluom.php`);
            const data = await response.json();
            setUomOptions(data.Data.map(uom => ({ key: uom.uomID, value: uom.uomName })));
        } catch (error) {
            console.error('Error fetching UOM options:', error);
        }
    };

    const handleKursChange = (id, key) => {
        setItems(prevItems =>
            prevItems.map(item =>
                item.id === id ? { ...item, kurs: key } : item
            )
        );
    };

    const handleUOMChange = (id, value) => {
        setItems(prevItems =>
            prevItems.map(item =>
                item.id === id ? { ...item, sat: value } : item
            )
        );
    };

    useEffect(() => {
        calculateTotal();
    }, [items, selectedTax]);

    const handleDeleteItem = (id) => {
        const updatedItems = items.filter(item => item.id !== id);
        setItems(updatedItems);
    };

    const calculateTotal = () => {
        let sum = 0, dppSum = 0, ppnSum = 0, grandSum = 0;
        items.forEach(item => {
            let itemDPP = item.quantity * item.harga;
            let itemPPN = itemDPP * (selectedTax / 100);
            let itemTotal = itemDPP + itemPPN;
            sum += itemTotal;
            dppSum += itemDPP;
            ppnSum += itemPPN;
            grandSum += itemDPP + itemPPN;
        });
        setTotal(sum);
        setTotalDPP(dppSum);
        setTotalPPN(ppnSum);
        setGrandTotal(grandSum);
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(amount).replace('Rp', 'Rp. ');
    };

    const handleAddItem = () => {
        setCount(prevCount => prevCount + 1);

        const newItem = {
            id: count + 1,
            label: `Item #${count + 1}`,
            productname: '',
            quantity: 0, 
            sat: '', 
            currency: 0,
            harga: 0,
            total: 0,
            kurs: 0,
            dpp: 0,
            ppn: 0
        };

        setItems(prevItems => [...prevItems, newItem]);
    };

    const handlePONumber = (id, value) => {
        setItems(prevItems =>
            prevItems.map(item =>
                item.id === id ? { ...item, purchaseordernumber: value } : item
            )
        );
    };

    const handleProductNameChange = (id, value) => {
        setItems(prevItems =>
            prevItems.map(item =>
                item.id === id ? { ...item, productname: value } : item
            )
        );
    };

    const handleCurrencyChange = (id, value) => {
        setItems(prevItems =>
            prevItems.map(item =>
                item.id === id ? { ...item, currency: value } : item
            )
        );
    };

    const handleQuantityChange = (id, value) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setItems(prevItems =>
                prevItems.map(item =>
                    item.id === id ? { ...item, quantity: value } : item
                )
            );
        }
    };

    const handleHargaChange = (id, value) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setItems(prevItems =>
                prevItems.map(item =>
                    item.id === id ? { ...item, harga: value } : item
                )
            );
        }
    };

    const DPPCount = (harga, quantity) => {
        const DPPResult = harga * quantity;
        return formatCurrency(DPPResult);
    }

    const PPNCount = (harga, quantity) => {
        const PPNResult = (harga * quantity) * (selectedTax / 100);
        return formatCurrency(PPNResult);
    }

    const TotalCount = (harga, quantity) => {
        const Total = (harga * quantity) + ((harga * quantity) * (selectedTax / 100));
        return formatCurrency(Total);
    }

    const handleKodeProdukChange = async (e) => {
        const value = e.target.value;
        setKodeProduk(value);

        if (value.length > 0) {
            try {
                const response = await fetch(`${API_BASE_URL}warehouse/productquery.php?find=${value}`);
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setSuggestions(data.products);
                    setShowSuggestions(true);
                } else {
                    setSuggestions([]);
                    setShowSuggestions(false);
                }
            } catch (error) {
                console.error("Error fetching product suggestions:", error);
                setSuggestions([]);
                setShowSuggestions(false);
            }
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    };

    const handleSuggestionClick = (id, suggestion) => {
        setItems(prevItems =>
            prevItems.map(item =>
                item.id === id ? { ...item, productname: suggestion.productName } : item
            )
        );
        setKodeProduk(suggestion.skuID);
        setShowSuggestions(false);
    };

    return (  
        <div className="scrollable-horizontal" >
            {items.map(item => (
            <div key={item.id}>
                <div className='ItemNameTemplateForm'>
                    <FormLabel labelName={item.label}/>
                    <div onClick={() => handleDeleteItem(item.id)} className='DeleteTemplateForm'>
                        <FaTrash className="icon" />
                        Delete {item.label}
                    </div>
                </div>
                <div className='grid-container-scrollable'>
                    <div className="only-100-width">
                        <FormLabel labelName={"PO Number"}/>
                        <TextBox placeholder={"Insert purchase order number"}  value={item.purchaseordernumber} onChange={e => handlePONumber(item.id, e.target.value)} />
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"Product Name"}/>
                        <TextBox 
                            placeholder={"Insert product name"}  
                            value={item.productname} 
                            onChange={e => {
                                handleProductNameChange(item.id, e.target.value);
                                handleKodeProdukChange(e);
                            }} 
                        />
                        {showSuggestions && (
                            <ul className="suggestions-list">
                                {suggestions.map((suggestion, index) => (
                                    <li key={index} onClick={() => handleSuggestionClick(item.id, suggestion)}>
                                        {suggestion.skuID} - {suggestion.productName}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"Quantity"}/>
                        <TextBox placeholder={"Insert Quantity"} value={item.quantity} onChange={e => handleQuantityChange(item.id, e.target.value)} />
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"SAT"}/>
                        <select value={item.sat} onChange={e => handleUOMChange(item.id, e.target.value)}>
                            <option value="">Select SAT</option>
                                {uomOptions.map(uom => (
                                    <option key={uom.key} value={uom.key}>{uom.value}</option>
                                ))}
                        </select>
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"Harga @"} />
                        <TextBox value={item.harga.toLocaleString()} onChange={e => handleHargaChange(item.id, e.target.value)} placeholder={"Insert your price"}/>
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"Total"}/>
                        <TextBox placeholder={"Insert total value"} value={TotalCount(item.harga, item.quantity)}/>
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"DPP"}/>
                        <TextBox placeholder={"Insert DPP value"} value={DPPCount(item.harga, item.quantity)}/>
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"PPN"}/>
                        <TextBox placeholder={"Insert PPN value"} value={PPNCount(item.harga, item.quantity)}/>
                    </div>
                    <div >
                    </div> 
                </div>
            </div>
            ))}
            <div className='grid-container-scrollable'>
                    <div className="only-100-width">
                        
                    </div>
                    <div className="only-100-width">
                        
                    </div>
                    <div className="only-100-width">
                        
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"Total"}/>
                    </div>
                    <div className="only-100-width">
                        <TextBox value={formatCurrency(total)}/>
                    </div>
                    <div className="only-100-width">
                    
                    </div> 
                    <div className="only-100-width">
                        <TextBox value={formatCurrency(totalDPP)}/>
                    </div>  
                    <div className="only-100-width">
                    <TextBox value={formatCurrency(totalPPN)}/>
                    </div>
                    <div>
                    
                    </div>                 
                </div>
            <div className='AddItemTemplate' onClick={handleAddItem} style={{ cursor: 'pointer' }} >Click Here To Add Item</div>
        </div>
    );
}

export default SalesOrderTemplate;
