import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NewSalesDialog = ({ onCloseDialog }) => {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('newSales'); 
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        setShowDialog(true); 
    }, []);

    const handleCloseClick = () => {
        onCloseDialog(); // Call the onCloseDialog function passed from the parent component
    };

    const handleSubmitClick = () => {
        // Navigate based on the selected option value
        if (selectedOption === 'newSales') {
            navigate('/salesIndex/New/newSalesOrder');
        } else if (selectedOption === 'newSPPB') {
            navigate('/salesIndex/New/newSPPB');
        } else if (selectedOption === 'newProfit') {
            navigate('/salesIndex/New/newProfit');
        } else if (selectedOption === 'newSalesInvoice'){
            navigate('/salesIndex/New/newSalesInvoice');
        } else if (selectedOption === 'newDeliveryOrder') {
            navigate('/salesIndex/New/newDeliveryOrder');
        } else if (selectedOption === 'newInvoice'){
            navigate('/salesIndex/New/newInvoice');
        }
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div>
                            <div className='new-form-title'>
                                New Purchase
                            </div>
                            <select className='new-form-dropdown' value={selectedOption} onChange={handleOptionChange}>
                                <option value="newSales">New Sales Order</option>
                                <option value="newSPPB">New SPPB</option>
                                <option value="newProfit">New Profit</option>
                                <option value="newDeliveryOrder">New Delivery Order</option>
                                <option value="newSalesInvoice">New Sales Invoice</option>
                                <option value="newInvoice">New Invoice</option>
                            </select>
                            <div className='space-between'/>
                            <div className='grid-container-form-submit'>
                                <div className='close-text-dialog' onClick={handleCloseClick}>Close</div>
                                <div className='space-between-width'/>
                                <div className='verification-button' onClick={handleSubmitClick}>Submit</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewSalesDialog;