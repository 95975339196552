import React, { useState } from 'react';
import TextBox from '../TextBox';
import { useNavigate } from 'react-router-dom';
import ErrorBox from '../ErrorBox';
import Loader from '../Loader';
import { API_BASE_URL } from '../../../App';

const NewBankAccountDialog = ({ onCloseDialog }) => {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(true);  
    const [errorDialog, setErrorDialog] = useState(false);  
    const [txtBankNumber, setTxtBankNumber] = useState('');
    const [txtBankName, setTextBankName] = useState('');
    const [txtBankBranch, setTxtBankBranch] = useState('');
    const [error, setError] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleCloseClick = () => {
        onCloseDialog(); 
    };

    if (!showDialog) {
        return null; 
    }

    const handleSubmit = async (event) => {
        setLoading(true);
        try{
            const API = `${API_BASE_URL}master/finance/insertbankaccount.php`;
            const response = await fetch (API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    bank_number : txtBankNumber,
                    bank_name : txtBankName,
                    bank_branch : txtBankBranch
                }),
            });

            if(response.status == 203){
                setErrorTitle("Insert Failed");
                setErrorMessage("Bank Account has been already exist in database !!");
                setErrorDialog(true);
            }

            if(response.status === 200){
                navigate('/settingIndex');
            }

            if(response.status === 500){
                setErrorTitle("Insert Failed");
                setErrorMessage("Error unable to insert bank account data!!");
                setErrorDialog(true);
            }

        } catch (error){
            setErrorTitle("Insert Account Code Failed");
            setErrorMessage("An error occurred during insert. Please try again later. : ", error);
            setErrorDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const dialogBox = <ErrorBox onCloseDialog={handleCloseDialog} title={errorTitle} message={errorMessage} />;
    
    return(
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            New Bank Account
                        </div>
                        {errorDialog && dialogBox}
                        {loading ? (<Loader/>) : (
                            <form>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Bank Number</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox 
                                            value={txtBankNumber}
                                            onChange={(e) => setTxtBankNumber(e.target.value)}
                                            placeholder="xxxx xxxx" type="text" maxLines={1}/>
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Bank Name</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox 
                                            value={txtBankName}
                                            onChange={(e) => setTextBankName(e.target.value)}
                                            placeholder="xxxx xxxx" type="text" maxLines={1}/>
                                    </div>
                                </div>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Bank Branch</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox 
                                            value={txtBankBranch}
                                            onChange={(e) => setTxtBankBranch(e.target.value)}
                                            placeholder="xxxx xxxx" type="text" maxLines={1}/>
                                    </div>
                                </div>
                            </form>
                        )}
                        <div className='space-between'/>
                        <div className='grid-container-form-submit'>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Close</div>
                            <div className='space-between-width'/>
                            <div className='verification-button' onClick={handleSubmit}>Submit</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}   

export default NewBankAccountDialog;