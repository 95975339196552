import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import ErrorBox from "../ErrorBox";
import Loader from "../Loader";
import TextBox from '../TextBox';
import { API_BASE_URL } from '../../../App';
import useAuthSession from '../../services/Session';
import TextArea from '../TextArea';

function NewProductDialog({ onCloseDialog }){
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(true);  
    const [error, setError] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [txtProductName, settxtProductName] = useState(''); 
    const [txtProductCode, settxtProductCode] = useState(''); 
    const [txtProductDesc, settxtProductDesc] = useState(''); 
    const { username } = useAuthSession();

    const handleCloseClick = () => {
        onCloseDialog(); 
    };

    if (!showDialog) {
        return null; 
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const dialogBox = <ErrorBox onCloseDialog={handleCloseDialog} title={errorTitle} message={errorMessage} />;
    
    const handleSubmit = async () => {
        
        if (
            !txtProductName
        ) {
            setErrorTitle("Submission Failed");
            setErrorMessage("Please fill in all fields !!");
            setError("Please fill in all fields");
            return;
        }

        setLoading(true);
        try {
            const API = `${API_BASE_URL}master/product/insertproduct.php`;
            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    product_name: txtProductName,
                    product_code: txtProductCode,
                    product_desc: txtProductDesc,
                    username : username
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to insert term data');
            }

            if (response.status === 500) {
                setErrorTitle("Insert Failed");
                setErrorMessage("Check your field again !!");
                setShowDialog(true);
            }

            if(response.status === 200){
                navigate('/settingIndex');
            }
        } catch (e){
            setErrorTitle("Insert Product Failed");
            setErrorMessage("An error occurred during insert. Please try again later.");
            setShowDialog(true);
        } finally {
            setLoading(false);
        }

    }
    
    return(
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            New Product Data
                        </div>
                        {error && dialogBox}
                        {loading ? (<Loader/>) : (
                            <form>
                                <div className="TwoGridForm">
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Code Product</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox 
                                            value={txtProductCode}
                                            onChange={(e) => settxtProductCode(e.target.value)}
                                            placeholder={"Insert your product name"}
                                        />
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Product Name</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox 
                                            value={txtProductName}
                                            onChange={(e) => settxtProductName(e.target.value)}
                                            placeholder={"Insert your product name"}
                                        />
                                    </div>
                                </div>
                                <div className="TwoGridForm">
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Product Desc</label>
                                        <div className='space-between-1vh'></div>
                                        <TextArea
                                            maxLines={5} 
                                            value={txtProductDesc}
                                            onChange={(e) => settxtProductDesc(e.target.value)}
                                            placeholder={"Insert your product name"}
                                        />
                                    </div>
                                </div>
                            </form>
                        )}
                        <div className='space-between'/>
                        <div className='grid-container-form-submit'>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Close</div>
                            <div className='space-between-width'/>
                            <div className='verification-button' onClick={handleSubmit}>Submit</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewProductDialog;