// App.js or wherever your App component is defined
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './main/Login';  // assuming this is your main login component
import ForgotPassword from './main/ForgotPassword';
import CreateAccount from './main/CreateAccount';
import PurchaseIndex from './main/page/purchase/PurchaseIndex';
import SalesIndex from './main/page/sales/SalesIndex';
import Home from './main/page/index/Index';
import SettingIndex from './main/page/setting/SettingIndex';
import SettingDetail from './main/page/setting/SettingDetail';
import FinanceIndex from './main/page/finance/FinanceIndex';
import WarehouseIndex from './main/page/warehouse/WarehouseIndex';
import AnalyticsIndex from './main/page/analytics/AnalyticsIndex';
import DocumentIndex from './main/page/document/DocumentIndex';
import HRIndex from './main/page/hr/HRIndex';
import NewPurchaseLocal from './main/page/purchase/NewPurchaseLocal';
import NewPurchaseImport from './main/page/purchase/NewPurchaseImport';
import DetailPurchaseLocal from './main/page/purchase/DetailPurchaseLocal';
import SeeAllPurchase from './main/page/purchase/SeeAllPurchase';
import NewSalesOrder from './main/page/sales/NewSalesOrder';
import NewSPPB from './main/page/sales/NewSPPB';
import NewProfit from './main/page/sales/NewProfit';
import DetailPurchaseImport from './main/page/purchase/DetailPurchaseImport';
import CompanySetting from './main/page/setting/CompanySetting';
import NotFound from './main/component/maintanance/NotFound';
import DetailCustomer from './main/page/setting/DetailCustomer';
import DetailSupplier from './main/page/setting/DetailSupplier';
import EmployeeList from './main/page/hr/EmployeeList';
import NewPurchasingInvoice from './main/page/purchase/NewPurchaseInvoice';
import NewPurchaseOrder from './main/page/purchase/NewPurchaseOrder';
import NewReceivingItems from './main/page/purchase/NewReceivingItems';
import NewDeliveryOrder from './main/page/sales/NewDeliveryOrder';
import NewInvoice from './main/page/sales/NewInvoice';
import NewSalesInvoice from './main/page/sales/NewSalesInvoice';
import ListProduct from './main/page/setting/ListProduct';
import DetailReceivingItems from './main/page/purchase/DetailReceivingItems';
import DetailInvoiceItems from './main/page/purchase/DetailPurchaseInvoice';
import SalesOrderDetail from './main/page/sales/SalesOrderDetail';
import DetailSPPB from './main/page/sales/DetailSPPB';
import DetailProfit from './main/page/sales/DetailProfit';
import DetailDeliveryOrder from './main/page/sales/DetailDelivery';
import SeeAllSalesOrder from './main/page/sales/SeeAllSalesOrder';
import SeeAllSPPB from './main/page/sales/SeeAllSPPB';
import SeeAllProfit from './main/page/sales/SeeAllProfit';
import SeeAllDeliveryOrder from './main/page/sales/SeeAllDeliveryOrder';
import SeeAllSalesInvoice from './main/page/sales/SeeAllSalesInvoice';
import DetailSalesInvoice from './main/page/sales/DetailSalesInvoice';
import ChangeProfile from './main/page/index/ChangeProfile';
import BukuKasFinance from './main/page/finance/BukuKasFinance';
import PembayaranFinance from './main/page/finance/PembayaranFinance';
import PenerimaanFinance from './main/page/finance/PenerimaanFinance';
import PenerimaanPembelianFinance from './main/page/finance/PenerimaanPembelian';
import PenerimaanPenjualanFinance from './main/page/finance/PenerimaanPenjualan';
import ChangePassword from './main/ChangePassword';
import NewPembayaran from './main/page/finance/NewPembayaran';
import NewPenerimaan from './main/page/finance/NewPenerimaan';
import NewPenerimaanPembelian from './main/page/finance/NewPenerimaanPembelian';
import NewPenerimaanPenjualan from './main/page/finance/NewPenerimaanPenjualan';
import SeeAllPurchaseInvoice from './main/page/purchase/SeeAllPurchaseInvoice';
import SeeAllPurchaseReceieve from './main/page/purchase/SeeAllReceieve';
import FinanceDetail from './main/page/setting/FinanceSetting';
import DetailPembayaran from './main/page/finance/DetailPembayaran';
import DetailPenerimaan from './main/page/finance/DetailPenerimaan';
import SalesOrder from './main/public/SalesOrder';
import AddNewInHouse from './main/page/warehouse/AddNewInhouse';
import AddNewOutHouse from './main/page/warehouse/AddNewOuthouse';
import DocxEditor from './main/services/DocxEditor';
import AddNewOutcustomer from './main/page/warehouse/AddNewOutcustomer';
import FindStock from './main/page/warehouse/FindStock';
import NewEmployee from './main/page/hr/NewEmployee';
import DetailEmployee from './main/page/hr/DetailEmployee';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/createAccount" element={<CreateAccount />} />
      <Route path="/changePassword" element={<ChangePassword />} />
      <Route path="/notFound" element={<NotFound />} />
      <Route path="/main/homePage" element={<Home />} />
      <Route path="/main/changeProfile" element={<ChangeProfile />} />
      <Route path="/seeAll/:menuName" element={<seeAllPage />} />
      <Route path="/purchaseIndex" element={<PurchaseIndex />} />
      <Route path="/purchaseIndex/New/purchaseLocal" element={<NewPurchaseLocal />} />
      <Route path="/purchaseIndex/Detail/purchaseLocal" element={<DetailPurchaseLocal />} />
      <Route path="/purchaseIndex/Detail/purchaseImport" element={<DetailPurchaseImport />} />
      <Route path="/purchaseIndex/SeeAll" element={<SeeAllPurchase />} />
      <Route path="/purchaseIndex/SeeAllPurchaseInvoice" element={<SeeAllPurchaseInvoice />} />
      <Route path="/purchaseIndex/SeeAllPurchaseReceieve" element={<SeeAllPurchaseReceieve />} />
      <Route path="/purchaseIndex/New/purchaseImport" element={<NewPurchaseImport />} />
      <Route path="/purchaseIndex/New/purchaseInvoice" element={<NewPurchasingInvoice />} />
      <Route path="/purchaseIndex/New/purchaseOrder" element={<NewPurchaseOrder />} />
      <Route path="/purchaseIndex/New/receivingItems" element={<NewReceivingItems />} />
      <Route path="/purchaseIndex/New/withParams/receivingItems/:PONumber" element={<NewReceivingItems />} />
      <Route path="/purchaseIndex/New/withParams/purchaseInvoice/:PONumber" element={<NewPurchasingInvoice />} />
      <Route path="/purchaseIndex/Detail/receivingItems/:PONumber" element={<DetailReceivingItems />} />
      <Route path="/purchaseIndex/Detail/invoice/:PONumber" element={<DetailInvoiceItems />} />
      <Route path="/salesIndex" element={<SalesIndex />} />
      <Route path="/salesIndex/New/newSalesOrder" element={<NewSalesOrder />} />
      <Route path="/salesIndex/Detail/salesOrder" element={<SalesOrderDetail />} />
      <Route path="/salesIndex/SeeAllSalesOrder" element={<SeeAllSalesOrder />} />
      <Route path="/salesIndex/New/newSPPB/withParams/:SONumber" element={<NewSPPB />} />
      <Route path="/salesIndex/Detail/SPPB" element={<DetailSPPB />} />
      <Route path="/salesIndex/SeeAllSPPB" element={<SeeAllSPPB />} />
      <Route path="/salesIndex/New/newProfit/withParams/:SONumber" element={<NewProfit />} />
      <Route path="/salesIndex/Detail/Profit" element={<DetailProfit />} />
      <Route path="/salesIndex/SeeAllProfit" element={<SeeAllProfit />} />
      <Route path="/salesIndex/New/newDeliveryOrder/withParams/:SONumber" element={<NewDeliveryOrder />} />
      <Route path="/salesIndex/Detail/DeliveryOrder" element={<DetailDeliveryOrder />} />
      <Route path="/salesIndex/SeeAllDeliveryOrder" element={<SeeAllDeliveryOrder />} />
      <Route path="/salesIndex/New/newSalesInvoice/withParams/:SONumber" element={<NewSalesInvoice />} />
      <Route path="/salesIndex/New/newInvoice" element={<NewInvoice />} />
      <Route path="/salesIndex/SeeAllSalesInvoice" element={<SeeAllSalesInvoice />} />
      <Route path="/salesIndex/Detail/SalesInvoice" element={<DetailSalesInvoice />} />
      <Route path="/salesIndex" element={<SalesIndex />} />
      <Route path="/financeIndex" element={<FinanceIndex />} />
      <Route path="/financeIndex/BukuKas" element={<BukuKasFinance />} />
      <Route path="/financeIndex/Pembayaran" element={<PembayaranFinance />} />
      <Route path="/financeIndex/New/Pembayaran" element={<NewPembayaran />} />
      <Route path="/financeIndex/Detail/DetailPembayaran/:id" element={<DetailPembayaran />} />
      <Route path="/financeIndex/Detail/DetailPenerimaan/:id" element={<DetailPenerimaan />} />
      <Route path="/financeIndex/Penerimaan" element={<PenerimaanFinance />} />
      <Route path="/financeIndex/New/Penerimaan" element={<NewPenerimaan />} />
      <Route path="/financeIndex/PenerimaanPembelian" element={<PenerimaanPembelianFinance />} />
      <Route path="/financeIndex/New/PenerimaanPembelian" element={<NewPenerimaanPembelian />} />
      <Route path="/financeIndex/PenerimaanPenjualan" element={<PenerimaanPenjualanFinance />} />
      <Route path="/financeIndex/New/PenerimaanPenjualan" element={<NewPenerimaanPenjualan />} />
      <Route path="/warehouseIndex" element={<WarehouseIndex />} />
      <Route path="/warehouseIndex/New/WarehouseIn" element={<AddNewInHouse />} />
      <Route path="/warehouseIndex/New/WarehouseOut" element={<AddNewOutHouse />} />
      <Route path="/warehouseIndex/New/WarehouseOutCustomer" element={<AddNewOutcustomer />} />
      <Route path="/warehouseIndex/Find" element={<FindStock />} />
      <Route path="/hrIndex" element={<HRIndex />} />
      <Route path="/hrIndex/employeeList" element={<EmployeeList />} />
      <Route path="/hrIndex/detailEmployee/:id" element={<DetailEmployee />} />
      <Route path="/hrIndex/newEmployee" element={<NewEmployee />} />
      <Route path="/analyticsIndex" element={<AnalyticsIndex />} />
      <Route path="/documentIndex" element={<DocumentIndex/>} />
      <Route path="/settingIndex" element={<SettingIndex />} />
      <Route path="/companySetting" element={<CompanySetting />} />
      <Route path="/settingIndex/Detail/:id" element={<SettingDetail />} />
      <Route path="/settingIndex/Detail/DetailCustomer/:id" element={<DetailCustomer />} />
      <Route path="/settingIndex/Detail/DetailSupplier/:id" element={<DetailSupplier />} />
      <Route path="/settingIndex/Detail/ListProduct" element={<ListProduct />} />
      <Route path="/settingIndex/Detail/FinanceSettings" element={<FinanceDetail />} />
      <Route path="/salesOrder/:customerID" element={<SalesOrder />} />
      <Route path="/services/DocxEditor" element={<DocxEditor />} />
    </Routes>
  );
}

export default App;
// export const API_BASE_URL = 'https://kevinngabriell.com/erpAPI-v.1.0/';
export const API_BASE_URL = 'https://venkensystems.com/erpAPI-v.1.0/';

export const formatDate = (dateString) => {
  const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  const date = new Date(dateString);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  return `${day} ${months[monthIndex]} ${year}`;
};

export const formatDatePOImport = (dateString) => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const date = new Date(dateString);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  // Determine the correct suffix for the day
  let daySuffix = 'th';
  if (day % 10 === 1 && day !== 11) {
    daySuffix = 'st';
  } else if (day % 10 === 2 && day !== 12) {
    daySuffix = 'nd';
  } else if (day % 10 === 3 && day !== 13) {
    daySuffix = 'rd';
  }

  return `${months[monthIndex]} ${day}${daySuffix}, ${year}`;
};


export const formatCurrencyIDR = (amount) => {
  return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR'
  }).format(amount).replace('Rp', 'Rp. ');
};

export const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  
  return `${day} ${months[monthIndex]} ${year} ${hours}:${minutes}:${seconds}`;
};

export const formatCurrency = (amount, currency) => {
  let currencyCode;
  switch (currency) {
      case 'SGD':
          currencyCode = 'en-SG';
          break;
      case 'USD':
          currencyCode = 'en-US';
          break;
      case 'Rp':
          currencyCode = 'id-ID';
          break;
      case 'CNY':
          currencyCode = 'zh-CN';
          break;
      case 'EUR':
          currencyCode = 'de-DE'; // Using Germany's locale for EUR formatting
          break;
      default:
          currencyCode = 'en-US'; 
          break;
  }
  return new Intl.NumberFormat(currencyCode, {
      style: 'currency',
      currency: currency
  }).format(amount);
};

export const formatDateForSubmit = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);
  return `${day}-${month}-${year}`;
};


