import React, { useState, useEffect } from 'react';
import TextBox from '../TextBox';
import ErrorBox from '../ErrorBox';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import { API_BASE_URL } from '../../../App';

const DetailTargetDialog = ({ onCloseDialog, id, year: initialYear, target: initialTarget }) => {
    const navigate = useNavigate();
    const [year, setYear] = useState(initialYear);
    const [target, setTarget] = useState(initialTarget);
    const [error, setError] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState([]);

    const handleCloseClick = () => {
        onCloseDialog(); 
    };

    const handleUpdateClick = async (e) => {
        e.preventDefault();
        
        setLoading(true);
        try {
            const apiUpdateTarget = `${API_BASE_URL}company/targeting/updatetargeting.php`;
            const response = await fetch(apiUpdateTarget, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    targeting_id: id,
                    target_year: year,
                    target_value: target,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update target data');
            }

            if (response.status === 500) {
                setErrorTitle("Update Target Failed");
                setErrorMessage("Check your field again !!");
            }

            if (response.status === 200) {
                navigate('/settingIndex');
            }
        } catch (error) {
            setErrorTitle("Update Target Failed");
            setErrorMessage("An error occurred during update. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            Detail Target Data
                        </div>
                        {error && <ErrorBox title={errorTitle} message={errorMessage} />}
                        {loading ? (<Loader/>) : (
                            <form>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Year</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={year} placeholder={'Insert target year'}/>
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Target</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={target} placeholder={'Insert target amount'} onChange={(e) => setTarget(e.target.value)} />
                                    </div>
                                </div>
                            </form>
                        )}
                        <div className='space-between'/>
                        <div className='grid-container-form-submit'>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Close</div>
                            <div className='space-between-width'/>
                            <div className='verification-button' onClick={handleUpdateClick}>Update</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailTargetDialog;
