import DocumentInactiveIcon from '../../../assets/Icon/DocumentInactive.png';
import { useNavigate } from 'react-router-dom';

function DocumentnonActive(){
    const navigate = useNavigate();
    const permissionAccess = localStorage.getItem('permissionAccess');

    const handleSalesMenu = (event) => {
        navigate('/documentIndex'); 
    };
    
    return(
        <div className="MenuContainerNonActive" onClick={handleSalesMenu}>
            <img src={DocumentInactiveIcon} />
            <div className='MenuNonActive'>Document</div>
        </div>
    );
}

export default DocumentnonActive;