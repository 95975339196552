import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorDialog from '../dialog/ErrorDialog';

function OneCardGrid({ headline, data, type }) {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');

    const handleSeeAllClick = (PO_Type_Name) => {

        if (type === 'Import' || type === 'Local'){
            navigate(`/purchaseIndex/SeeAll?poTypeName=${encodeURIComponent(PO_Type_Name)}`);
        } else if (type === 'Receive'){
            navigate('/purchaseIndex/SeeAllPurchaseReceieve');
        } else if (type === 'Invoice'){
            navigate('/purchaseIndex/SeeAllPurchaseInvoice');
        } else if (type === 'Sales'){
            navigate(`/salesIndex/SeeAllSalesOrder`);
        } else if (type === 'SPPB'){
            navigate(`/salesIndex/SeeAllSPPB`);
        } else if (type === 'Profit'){
            navigate(`/salesIndex/SeeAllProfit`);
        } else if (type === 'Delivery'){
            navigate(`/salesIndex/SeeAllDeliveryOrder`);
        } else if (type === 'Sales Invoice'){
            navigate(`/salesIndex/SeeAllSalesInvoice`);
        } else {
            setErrorTitle("Not Available");
            setShowDialog(true);
        }
    };

    const handleDetailClick = (PONumber) => {
        if (type === 'Import') {
            navigate(`/purchaseIndex/Detail/purchaseImport?poNumber=${encodeURIComponent(PONumber)}`);
        } else if (type === 'Local') {
            navigate(`/purchaseIndex/Detail/purchaseLocal?poNumber=${encodeURIComponent(PONumber)}`);
        } else if (type === 'Receive') {
            navigate(`/purchaseIndex/Detail/receivingItems/${encodeURIComponent(PONumber)}`);
        } else if (type === 'Invoice'){
            navigate(`/purchaseIndex/Detail/invoice/${encodeURIComponent(PONumber)}`);
        } else if (type === 'Sales') {
            navigate(`/salesIndex/Detail/salesOrder?soNumber=${encodeURIComponent(PONumber)}`);
        } else if (type === 'SPPB') {
            navigate(`/salesIndex/Detail/SPPB?SPPBNumber=${encodeURIComponent(PONumber)}`)
        } else if (type === 'Profit') {
            navigate(`/salesIndex/Detail/Profit?SONumber=${encodeURIComponent(PONumber)}`)
        } else if (type === 'Delivery') {
            navigate(`/salesIndex/Detail/DeliveryOrder?SONumber=${encodeURIComponent(PONumber)}`)
        } else if (type === 'Sales Invoice') {
            navigate(`/salesIndex/Detail/SalesInvoice?SONumber=${encodeURIComponent(PONumber)}`)
        } else {
            setErrorTitle("Not Available");
            setShowDialog(true);
        }
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    return (
        <div className='card-for-grid'>
            {showDialog && <ErrorDialog errorTitle={errorTitle} onCloseDialog={handleCloseDialog} />}
            <div className='grid-container'>
                <div className="card-headline">{headline}</div>
                {/* Call handleSeeAllClick with type directly without curly braces */}
                <div className='see-all-text' onClick={() => handleSeeAllClick(type)}>see all</div>
            </div>
            {data.map((item, index) => (
                <React.Fragment key={index}>
                    <div className='space-between-list' />
                    <div className='grid-container-smaller'>
                        <div className='column-container'>
                            <div className='list-title'>{item.companyName} | {item.PONumber}</div>
                            <div className='list-desc'>{item.orderDate}</div>
                        </div>
                        <div className='detail-card-smaller' onClick={() => handleDetailClick(item.PONumber)}>Detail</div>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
}

export default OneCardGrid;
