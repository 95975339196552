import React from 'react';
import { IconType } from 'react-icons';  // Use this for TypeScript type checking, if applicable

function TextBox(props) {
  const { value, onChange, icon: Icon, placeholder, type = 'text' } = props;

  return (
    <div className="textbox-container">
      {Icon && <Icon className="icon" />}
      <input
        type={type}
        value={value}
        onChange={onChange}
        className="textbox"
        placeholder={placeholder}
      />
    </div>
  );
}

export default TextBox;
