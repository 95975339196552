import React, { useState } from 'react';
import LoginImage from '../login.png';
import { useNavigate } from 'react-router-dom';
import PasswordTextBox from './component/PasswordTextBox';
import ErrorBox from './component/ErrorBox';
import Loader from './component/Loader';
import { API_BASE_URL } from '../App';
import useAuthSession from './services/Session';

function ChangePassword(){
    const { username } = useAuthSession();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');
    const [txtPassword, settxtPassword] = useState('');
    const [txtConfirmPassword, settxtConfirmPassword] = useState('');

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handlePasswordChange = (event) => {
        settxtPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        settxtConfirmPassword(event.target.value);
    };

    const dialogBox = <ErrorBox onCloseDialog={handleCloseDialog} title={errorTitle} message={errorMessage} />;

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validation conditions
        const defaultPassword = '123456';
        const password = txtPassword;
        const confirmPassword = txtConfirmPassword;

        const isDefaultPassword = password === defaultPassword && confirmPassword === defaultPassword;
        const isPasswordMatch = password === confirmPassword;
        const isValidLength = password.length >= 8;
        const hasUppercase = /[A-Z]/.test(password);
        const hasNumberOrSymbol = /[0-9!@#$%^&*]/.test(password);

        if (isDefaultPassword) {
            setErrorTitle('Error');
            setErrorMessage('Password cannot be the default password (123456).');
            setShowDialog(true);
            return;
        }

        if (!isPasswordMatch) {
            setErrorTitle('Error');
            setErrorMessage('Passwords do not match.');
            setShowDialog(true);
            return;
        }

        if (!isValidLength || !hasUppercase || !hasNumberOrSymbol) {
            setErrorTitle('Error');
            setErrorMessage('Password must be at least 8 characters long, contain an uppercase letter, and a number or symbol.');
            setShowDialog(true);
            return;
        }

        setLoading(true);
        try{
            const apiLogin = `${API_BASE_URL}user/updatepassword.php`;

            const response = await fetch(apiLogin, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    username: username,
                    new_password: txtPassword,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to login');
            }

            if (response.status === 500 || response.status === 404) {
                setErrorTitle("Change Password Failed");
                setErrorMessage("Error while updating password : " + response.message);
                setShowDialog(true);
            }

            if(response.status === 200){
                navigate('/');
            }

        } catch (error){
            setErrorTitle('Error');
            setErrorMessage('Error while updating password : ', error);
            setShowDialog(true);
            return;
        } finally {
            setLoading(false);
        }


    }

    return(
        <div className="App">
            <div className="AppLeftSection">
                <img src={LoginImage} className="App-logo" />
            </div>
            <div className="AppRightSection">
                <div className='LoginTitle'>Change Password</div>
                <div className='LoginDesc'>Your password still got the default password. Please change your default password into a strong password !!</div>
                {showDialog && dialogBox}
                {loading ? (<Loader/>) : (
                    <form className='LoginForm'>
                        <div>
                            <label className='LabelTextBox'>New Password</label>
                            <div className='space-between-1vh'></div>
                            <PasswordTextBox value={txtPassword} onChange={handlePasswordChange} />
                        </div>
                        <div className='space-between'></div>
                        <div>
                            <label className='LabelTextBox'>Confirm New Password</label>
                            <div className='space-between-1vh'></div>
                            <PasswordTextBox value={txtConfirmPassword} onChange={handleConfirmPasswordChange} />
                        </div>
                    </form>
                )}
                <button type="submit" className='SubmitButton' onClick={handleSubmit}>Change Password</button>
            </div>
        </div>
    );
}

export default ChangePassword;