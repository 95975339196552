import PurchasingNonActiveIcon from '../../../assets/Icon/PurchasingActive.png';
import { useNavigate } from 'react-router-dom';

function PurchaseActive(){
    const navigate = useNavigate();

    const handleHomepage = (event) => {
        console.log('button clicked');
        navigate('/purchaseIndex'); 
    };

    return (
        <div className="MenuContainer" onClick={handleHomepage}>
            <img src={PurchasingNonActiveIcon} />
            <div className='MenuActive'>Purchase</div>
        </div>
    );
}

export default PurchaseActive;