import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../App';

const CountdownTimer = ({ onCloseDialog }) => {
  const [time, setTime] = useState(60);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [verificationCode, setVerificationCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        setShowDialog(true); 
        let intervalId;
        if (time > 0 && isButtonDisabled) {
        intervalId = setInterval(() => {
            setTime(prevTime => prevTime - 1);
        }, 1000);
        } else {
        clearInterval(intervalId);
        if (time === 0) {
            setIsButtonDisabled(false); // Enable the button when the timer reaches 0
        }
        }

        return () => clearInterval(intervalId);
    }, [time, isButtonDisabled]);

    const handleClick = () => {
        setIsLoading(true);
        setIsButtonDisabled(true);
        setTime(60);

        const formData = new URLSearchParams();
        formData.append('action', '1');
        formData.append('createdBy', 'kage');

        fetch(`${API_BASE_URL}verification/verificationaction.php`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(), // Convert form data to string
        })
        .then(response => {
        if (!response.ok) {
            throw new Error('Failed to trigger action');
        }
        return response.json();
        })
        .then(() => {
        // Upon success of POST request, start the timer
        setIsButtonDisabled(true);
        return fetch(`${API_BASE_URL}verification/verification.php?createdBy=kage`);
        })
        .then(response => response.json())
        .then(data => {
        if (data.StatusCode === 200) {
            setVerificationCode(data.Data[0].code);
        }
        })
        .catch(error => {
        console.error('Error:', error);
        })
        .finally(() => {
        setIsLoading(false);
        });
    };

    const handleCloseClick = () => {
        onCloseDialog(); // Call the onCloseDialog function passed from the parent component
      };

    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        {verificationCode && (
                            <div className='verification-code'>{verificationCode}</div>
                        )}
                        <div className='verification-next'>Next verification code request in {time} seconds</div>
                        <div className='grid-container'>
                            <div
                            className={`verification-button ${isButtonDisabled || isLoading ? 'disabled' : ''}`}
                            onClick={isButtonDisabled || isLoading ? null : handleClick}
                            >
                            {isLoading ? 'Loading...' : 'Get Verification Code'}
                            </div>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Close</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CountdownTimer;
