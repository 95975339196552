import { saveAs } from 'file-saver';
import XLSX from 'sheetjs-style';

const ProfitgenerateExcel = (data) => {
    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.aoa_to_sheet([
        ['PROFIT CALCULATION'],
        ['SO:', data.soNumber],
        ['Cust.', data.customer],
        ['No', 'Nama Barang', 'Qty', 'Harga Jual Satuan', 'Landed Cost', 'PROFIT', 'Profit %'],
        ...data.items.map((item, index) => [
            index + 1,
            item.namaBarang,
            item.qty,
            item.hargaJualSatuan,
            item.landedCost,
            item.profit,
            item.profitPercentage,
        ]),
        ['Kurs:', data.kurs],
        [],
        ['TOP:', `${data.top} Hari`],
        ['TOTAL', '', '', '', '', '', ''],
        [],
        ['DIBUAT OLEH,', '', '', '', '', 'DI SETUJUI OLEH'],
        [],
        [],
        ['( AFIFAH )', '', '', '', '', '( SULANTO )', '', '( IRENE )'],
    ]);

    // Set the width of the columns
    ws['!cols'] = [
        { wch: 5 }, // No
        { wch: 25 }, // Nama Barang
        { wch: 8 }, // Qty
        { wch: 20 }, // Harga Jual Satuan
        { wch: 20 }, // Landed Cost
        { wch: 20 }, // PROFIT
        { wch: 15 }, // Profit %
    ];

    // Add styles
    ws['!merges'] = [
        { s: { r: data.items.length + 9, c: 0 }, e: { r: data.items.length + 9, c: 9 } }, // TOTAL row merge
        { s: { r: data.items.length + 11, c: 0 }, e: { r: data.items.length + 11, c: 1 } }, // DIBUAT OLEH, merge
        { s: { r: data.items.length + 11, c: 5 }, e: { r: data.items.length + 11, c: 9 } }, // DI SETUJUI OLEH merge
        { s: { r: data.items.length + 14, c: 0 }, e: { r: data.items.length + 14, c: 1 } }, // ( AFIFAH ) merge
        { s: { r: data.items.length + 14, c: 5 }, e: { r: data.items.length + 14, c: 7 } }, // ( SULANTO ) merge
        { s: { r: data.items.length + 14, c: 9 }, e: { r: data.items.length + 14, c: 11 } }, // ( IRENE ) merge
    ];

    // Apply styles
    const style = {
        font: {
            bold: true,
        },
    };

    ws['A1'].s = style;

    XLSX.utils.book_append_sheet(wb, ws, 'PROFIT');

    // Generate Excel file and trigger download
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const cleanSheetName = `SO${data.soNumber}`.replace(/[^a-zA-Z0-9]/g, '');
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `Profit-${cleanSheetName}.xlsx`);
}

export default ProfitgenerateExcel;
