import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import { API_BASE_URL } from '../../../App';

const ListPurchaseOrderDialog = ({ onCloseDialog, onSelectPurchaseOrder }) => {
    const [poList, setPoList] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleCloseClick = () => {
        onCloseDialog(); 
    };

    useEffect(() => {
        fetchListPO();
    }, []);

    const fetchListPO = async () => {
        setLoading(true);
        try {
            const response  = await fetch(API_BASE_URL + 'sales/getpurchaseorder.php');
            const data = await response.json();
            setPoList(data.Data);
        } catch (error){
            console.log("Error while fetching list PO");
        } finally {
            setLoading(false);
        }
    }

    const handleClickDetail = async (PONumber) => {
        try {
            setLoading(true);
            const API = `${API_BASE_URL}purchase/getdetailpurchaselocal.php?PONumber=${PONumber}`;
            const response = await fetch(API);
            const data = await response.json();
            console.log(data);
            onSelectPurchaseOrder(data);
        } catch (error) {
            console.error("Error while fetching detail purchase:", error);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
        const date = new Date(dateString);
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        return `${day} ${months[monthIndex]} ${year}`;
    };
    

    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div className='new-form-title'>
                        Purchase Order Number
                    </div>
                    {loading ? (<Loader/>) : (
                        <div>
                            <table className='table-100-w'>
                                <thead>
                                    <tr>
                                        <th>PO Number</th>
                                        <th>Date</th>
                                        <th>Supplier</th>
                                        <th>Origin</th>
                                        <th>Action</th>
                                        {/* Add more table headers if needed */}
                                    </tr>
                                </thead>
                                <tbody>
                                {poList.map(po => (
                                    <tr key={po.id}>
                                        <td onClick={() => handleClickDetail(po.PONumber)}>{po.PONumber}</td>
                                        <td>{formatDate(po.PODate)}</td>
                                        <td>{po.POSupplier}</td>
                                        <td>{po.POOrigin}</td>
                                        <td onClick={() => handleClickDetail(po.PONumber)}>Select</td>
                                    </tr>
                                ))}

                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ListPurchaseOrderDialog;