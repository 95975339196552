import React, { useEffect, useState } from 'react';
import ProfilSearchBox from "../../component/ProfilSearchBox";
import FourCardGrid from "../../component/card/FourCardGrid";
import NotFoundPage from "../../component/maintanance/NotFound";
import UnderConstruction from "../../component/maintanance/UnderConstruction";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRActive from "../../component/menu/HRActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import { useNavigate, useParams } from 'react-router-dom';
import NewEmployee from "./NewEmployee";
import Loader from '../../component/Loader';
import { API_BASE_URL, formatDate } from '../../../App';
import FormLabel from '../../component/FormLabel';

function DetailEmployee() {
    let { id } = useParams();
    const { username } = useAuthSession();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [detailEmployee, setDetailEmployee] = useState(null);

    useEffect(() => {
        const fetchDetailEmployee = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${API_BASE_URL}hr/HR.php?employee_id=${id}`);
                if (!response.ok) {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail employee data');
                    setShowDialog(true);
                }

                const data = await response.json();
                if (data.StatusCode === 200) {
                    setDetailEmployee(data.Data[0]);
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail employee data');
                    setShowDialog(true);
                }
            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail employee data: ' + error.message);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        fetchDetailEmployee();
    }, [id]);

    const handleDeleteEmployee = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${API_BASE_URL}hr/HR.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ action: 'delete', employee_id: id })
            });

            if (response.ok) {
                navigate('/hrIndex'); // Navigate back to the HR index page after deletion
            } else {
                const errorData = await response.json();
                setErrorCode('400');
                setErrorTitle('Error failed to delete employee data: ' + errorData.message);
                setShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to delete employee data: ' + error.message);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive />
                <SalesNonActive />
                <PurchaseNonActive />
                <FinanceNonActive />
                <WarehouseNonActive />
                <HRActive />
                <AnalyticsnonActive />
                <DocumentnonActive />
                <SettingNonActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                {loading ? (<Loader />) : (
                    detailEmployee ? (
                        <div>
                            <div className='card-for-grid'>
                                <div className='grid-container'>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Nama Karyawan"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailEmployee.employee_name}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Posisi Karyawan"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailEmployee.position}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Jenis Kelamin"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailEmployee.gender_name}</div>
                                    </div>
                                </div>
                                <div className='grid-container'>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Tempat Lahir"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailEmployee.employee_pob}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Tanggal Lahir"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{formatDate(detailEmployee.employee_dob)}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Mulai Bekerja"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{formatDate(detailEmployee.hire_date)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='grid-container-with-padding-left'>
                                <div className="only-100-width-card-reject" onClick={handleDeleteEmployee}>Delete Employee Data</div>
                            </div>
                        </div>
                    ) : (
                        <div>No employee data found.</div>
                    )
                )}
            </div>
        </div>
    );
}

export default DetailEmployee;
