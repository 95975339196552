import React, { useState, useEffect } from 'react';
import DashboardNonActive from '../../component/menu/DashboardNonActive';
import SalesNonActive from '../../component/menu/SalesNonActive';
import PurchaseNonActive from '../../component/menu/PurchaseNonActive';
import FinanceNonActive from '../../component/menu/FinanceNonActive';
import WarehouseNonActive from '../../component/menu/WarehouseNonActive';
import HRNonActive from '../../component/menu/HRNonActive';
import AnalyticsnonActive from '../../component/menu/AnalyticsNonActive';
import DocumentnonActive from '../../component/menu/DocumentNonActive';
import SettingActive from '../../component/menu/SettingActive';
import Logout from '../../component/menu/Logout';
import { useNavigate, useParams } from 'react-router-dom';
import ProfilSearchBox from '../../component/ProfilSearchBox';
import useAuthSession from '../../services/Session';
import Loader from '../../component/Loader';
import TextBox from '../../component/TextBox';
import TextArea from '../../component/TextArea';
import { API_BASE_URL } from '../../../App';
import ErrorDialog from '../../component/dialog/ErrorDialog';

function DetailSupplier(){
    const navigate = useNavigate();
    let { id } = useParams();
    const { username } = useAuthSession();
    const [loading, setLoading] = useState(false);
    const [editedValues, setEditedValues] = useState({});
    const [filteredOrigins, setFilteredOrigins] = useState([]);
    const [selectedOrigin, setSelectedOrigin] = useState('');
    const [filteredCurrency, setFilteredCurrency] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [filteredTerm, setFilteredTerm] = useState([]);
    const [selectedTerm, setSelectedTerm] = useState('');
    const [history, setHistory] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');


    const formatDate = (dateString) => {
        const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
        const date = new Date(dateString);
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        return `${day} ${months[monthIndex]} ${year}`;
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}master/supplier/getsupplierdetail.php?supplier_id=${id}`);
                if (!response.ok) {
                    setErrorCode('404');
                    setErrorTitle('Failed to fetch purchase data');
                    setShowDialog(true);
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setEditedValues(data.Data[0]);

                    setSelectedOrigin(data.Data[0].supplier_origin);
                    setSelectedCurrency(data.Data[0].supplier_currency);
                    setSelectedTerm(data.Data[0].supplier_term);
                } else {
                    setErrorCode('404');
                    setErrorTitle('Failed to load the data');
                    setShowDialog(true);
                }
            } catch (error) {
                setErrorCode('404');
                setErrorTitle(error.message);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        fetchOrigins(); 
        fetchCurrency();
        fetchTerm();
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}master/supplier/getsupplierhistory.php?company=${id}`);
                console.log(response); 
                if (!response.ok) {
                    setErrorCode('404');
                    setErrorTitle('Failed to fetch supplier history');
                    setShowDialog(true);
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setHistory(data.Data);
                } else {
                    setErrorCode('404');
                    setErrorTitle('Failed to fetch supplier history');
                    setShowDialog(true);
                }
            } catch (error) {
                setErrorCode('404');
                setErrorTitle(error.message);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const fetchOrigins = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}master/origin/getorigin.php`);
            const data = await response.json();
            if (data.StatusCode === 200) {
                setFilteredOrigins(data.Data);
            } else {
                setErrorCode('404');
                setErrorTitle('Failed to fetch origins');
                setShowDialog(true);
            }
        } catch (error) {
            setErrorCode('404');
            setErrorTitle('Error fetching origins:', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const fetchCurrency = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}master/currency/getallcurrency.php`);
            const data = await response.json();
            if (data.StatusCode === 200) {
                setFilteredCurrency(data.Data);
            } else {
                setErrorCode('404');
                setErrorTitle('Failed to fetch origins');
                setShowDialog(true);
            }
        } catch (error) {
            setErrorCode('404');
            setErrorTitle('Error fetching origins:', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const fetchTerm = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}master/term/getallterm.php`);
            const data = await response.json();
            if (data.StatusCode === 200) {
                setFilteredTerm(data.Data);
            } else {
                setErrorCode('404');
                setErrorTitle('Failed to fetch term');
                setShowDialog(true);
            }
        } catch (error) {
            setErrorCode('404');
            setErrorTitle('Error fetching origins:', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleOriginChange = (event) => {
        setSelectedOrigin(event.target.value);
    };

    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
    };

    const handleTermChange = (event) => {
        setSelectedTerm(event.target.value);
    }

    const handleInputChange = (key, value) => {
        setEditedValues(prevState => ({
            ...prevState,
            [key]: value
        }));
    };
    const handleSubmit = async (e) => {

        setLoading(true);
        try{
            const apiUpdateCustomer = API_BASE_URL + 'master/supplier/updatesupplier.php';
            const response = await fetch(apiUpdateCustomer, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    supplier_id: id,
                    supplier_name: editedValues.supplier_name,
                    supplier_origin: selectedOrigin,
                    supplier_address: editedValues.supplier_address,
                    supplier_phone: editedValues.supplier_phone,
                    supplier_pic_name: editedValues.supplier_pic_name,
                    supplier_pic_contact: editedValues.supplier_pic_contact,
                    supplier_currency: selectedCurrency,
                    supplier_term: selectedTerm,
                    supplier_bank: editedValues.supplier_bank_information
                }),
            });

            if (!response.ok) {
                setErrorCode('404');
                setErrorTitle('Failed to reset update customer data');
                setShowDialog(true);
            }

            if (response.status === 500) {
                setErrorCode('404');
                setErrorTitle('Failed to update customer data !! Check your field again !!');
                setShowDialog(true);
            }

            if(response.status === 200){
                navigate('/settingIndex');
            }
        } catch (error){
            setErrorCode('404');
            setErrorTitle(error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    return (
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive />
                <SalesNonActive />
                <PurchaseNonActive />
                <FinanceNonActive />
                <WarehouseNonActive />
                <HRNonActive />
                <AnalyticsnonActive />
                <DocumentnonActive />
                <SettingActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? (<Loader />) : (
                    <div>
                        <div className='grid-container'>
                            <div className='MenuName'>Detail Supplier </div>
                        </div>
                        <div className="card-for-grid">
                            <form>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier Name</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox placeholder={'Insert your supplier name'} value={editedValues.supplier_name} onChange={(e) => handleInputChange('supplier_name', e.target.value)}/>
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier Phone</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox placeholder={'Insert your supplier phone'} value={editedValues.supplier_phone} onChange={(e) => handleInputChange('supplier_phone', e.target.value)}/>
                                    </div>
                                </div>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier Address</label>
                                        <div className='space-between-1vh'></div>
                                        <TextArea placeholder={'Insert your supplier address'} value={editedValues.supplier_address} onChange={(e) => handleInputChange('supplier_address', e.target.value)}/>
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier Origin</label>
                                        <div className='space-between-1vh'></div>
                                        <select className='new-form-dropdown' value={selectedOrigin} onChange={handleOriginChange} >
                                            {filteredOrigins.map((origin) => (
                                                <option key={origin.origin_id} value={origin.origin_id}>
                                                    {origin['Country Name']}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier PIC Name</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox placeholder={'Insert your PIC Name'} value={editedValues.supplier_pic_name} onChange={(e) => handleInputChange('supplier_pic_name', e.target.value)}/>
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier PIC Contact</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox placeholder={'Insert your PIC Contact'} value={editedValues.supplier_pic_contact} onChange={(e) => handleInputChange('supplier_pic_contact', e.target.value)}/>
                                    </div>
                                </div>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier Currency</label>
                                        <div className='space-between-1vh'></div>
                                        <select className='new-form-dropdown' value={selectedCurrency} onChange={handleCurrencyChange}>
                                            {filteredCurrency.map((currency) => (
                                                <option key={currency.Id} value={currency.Id}>
                                                    {currency.Currency}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier Term</label>
                                        <div className='space-between-1vh'></div>
                                        <select className='new-form-dropdown' value={selectedTerm} onChange={handleTermChange}>
                                            {filteredTerm.map((term) => (
                                                <option key={term.term_id} value={term.term_id}>
                                                    {term.Term}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier Bank Information</label>
                                        <div className='space-between-1vh'></div>
                                        <TextArea placeholder={'Insert your supplier bank information'} value={editedValues.supplier_bank_information} onChange={(e) => handleInputChange('supplier_bank_information', e.target.value)}/>
                                    </div>
                                </div>
                                <div className='space-between'></div>
                                <div className='grid-container-form-submit'>
                                    <div className='verification-button' onClick={handleSubmit} >Update</div>
                                </div>
                            </form>
                        </div>
                        <div className='grid-container'>
                            <div className='MenuName'>Supplier History</div>
                        </div>
                        {history.map((item, index) => (
                                <div key={index} className="card-for-grid">
                                    <div className='grid-container-smaller'>
                                        <div className='column-container'>
                                            <div className='list-title'>{item.PONumber}</div>
                                            <div className='list-desc'>{formatDate(item.PODate)}</div>
                                        </div>
                                        <div className='detail-card-smaller'>{item.PO_Status_Name}</div>
                                    </div>
                                </div>
                            ))}
                        <div className='space-between'></div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default DetailSupplier;