import React, { useEffect, useState } from 'react';
import TextBox from '../TextBox';
import { useNavigate } from 'react-router-dom';
import ErrorBox from '../ErrorBox';
import Loader from '../Loader';
import { API_BASE_URL } from '../../../App';

const DetailBankAccount = ({ onCloseDialog, id }) => {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(true);
    const [errorDialog, setErrorDialog] = useState(false);
    const [txtBankAccountBefore, setTxtBankAccountBefore] = useState('');
    const [txtBankNameBefore, setTxtBankNameBefore] = useState('');
    const [txtBankBranchBefore, setTxtBankBranchBefore] = useState('');
    const [txtBankAccount, setTxtBankAccount] = useState('');
    const [txtBankName, setTxtBankName] = useState('');
    const [txtBankBranch, setTxtBankBranch] = useState('');
    const [error, setError] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}master/finance/getdetailbankaccount.php?bank_account=${id}`);
                const data = await response.json();

                if (data.StatusCode === 200) {
                    setTxtBankAccount(data.Data[0].bank_number);
                    setTxtBankName(data.Data[0].bank_name);
                    setTxtBankBranch(data.Data[0].bank_branch);
                    setTxtBankAccountBefore(data.Data[0].bank_number);
                    setTxtBankNameBefore(data.Data[0].bank_name);
                    setTxtBankBranchBefore(data.Data[0].bank_branch);
                } else {
                    throw new Error(data.Status || 'Unknown error');
                }
            } catch (error) {
                setErrorTitle('Error fetching bank account data');
                setErrorMessage(error.message);
                setErrorDialog(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const handleCloseClick = () => {
        onCloseDialog();
    };

    if (!showDialog) {
        return null;
    }

    const handleCloseErrorDialog = () => {
        setErrorDialog(false);
    };

    const dialogBox = errorDialog && <ErrorBox onCloseDialog={handleCloseErrorDialog} title={errorTitle} message={errorMessage} />;

    const handleDeleteBankAccount = async () => {
        setLoading(true);
        try{
            const API = `${API_BASE_URL}master/finance/deletebankaccount.php`;
            const response = await fetch (API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    bank_account : txtBankAccount
                }),
            });
            
            if(response.status === 200){
                navigate('/settingIndex');
            } else {
                setErrorTitle('Error');
                setErrorMessage('Error while deleting bank account !!');
                setErrorDialog(true);
            }

        } catch (error){
            setErrorTitle('Error Delete Bank Account');
            setErrorMessage(error.message);
            setErrorDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleUpdateClick = async () => {
        setLoading(true);
        try{
            const API = `${API_BASE_URL}master/finance/updatebankaccount.php`;
            const response = await fetch (API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    bank_number_new : txtBankAccount,
                    bank_name_new : txtBankName,
                    bank_branch_new : txtBankBranch,
                    bank_number_before : txtBankAccountBefore,
                    bank_name_before : txtBankNameBefore,
                    bank_branch_before : txtBankBranchBefore,
                }),
            });
            
            if(response.status === 200){
                navigate('/settingIndex');
            } else {
                setErrorTitle('Error');
                setErrorMessage('Error while updating bank account !!');
                setErrorDialog(true);
            }
        } catch (error){
            setErrorTitle('Error Update Bank Account');
            setErrorMessage(error.message);
            setErrorDialog(true);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            Detail Bank Account
                        </div>
                        {dialogBox}
                        {loading ? (<Loader />) : (
                            <form>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Bank Number</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox 
                                            value={txtBankAccount}
                                            placeholder="xxxx xxxx" type="text" maxLines={1}/>
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Bank Name</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox 
                                            value={txtBankName}
                                            onChange={(e) => setTxtBankName(e.target.value)}
                                            placeholder="xxxx xxxx" type="text" maxLines={1}/>
                                    </div>
                                </div>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Bank Branch</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox 
                                            value={txtBankBranch}
                                            onChange={(e) => setTxtBankBranch(e.target.value)}
                                            placeholder="xxxx xxxx" type="text" maxLines={1}/>
                                    </div>
                                </div>
                            </form>
                        )}
                        <div className='space-between'/>
                        <div className='grid-container-form-submit'>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Close</div>
                            <div className='space-between-width' />
                            <div className='redFont' onClick={handleDeleteBankAccount}>Delete</div>
                            <div className='space-between-width' />
                            <div className='verification-button' onClick={handleUpdateClick}>Update</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailBankAccount;
