import React, { useState, useEffect } from 'react';

const ErrorBox = ({ onCloseDialog, title, message }) => {
    const [showDialog, setShowDialog] = useState(false);
    useEffect(() => {
        setShowDialog(true); 
    });

    const handleCloseClick = () => {
        onCloseDialog(); // Call the onCloseDialog function passed from the parent component
    };

    return (
        <div className='ErrorBox-Container'>
            <div className='ErrorBox-Title'>{title}</div>
            <div className='ErrorBox-Caption'>{message}</div>
        </div>
    );
}

export default ErrorBox;