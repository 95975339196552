import React from 'react';
import { IconType } from 'react-icons';  

function TextArea(props) {
  const { value, onChange, icon: Icon, placeholder, type = 'text', maxLines } = props;

  const inputStyle = {
    maxHeight: maxLines ? `${maxLines * 1.5}em` : 'none', // Assuming each line is 1.5em tall
    resize: maxLines ? 'vertical' : 'none',
    fontFamily: 'inherit', // Ensures that the font inherits from the parent element
  };

  return (
    <div className="textbox-container">
      {Icon && <Icon className="icon" />}
      <textarea
        value={value}
        onChange={onChange}
        className="textbox"
        placeholder={placeholder}
        style={inputStyle}
      />
    </div>
  );
}

export default TextArea;
