import React, { useState, useEffect } from 'react';
import TextBox from '../TextBox';
import TextArea from '../TextArea';
import ErrorBox from '../ErrorBox';
import Loader from '../Loader';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../App';

const NewSupplierDialog = ({ onCloseDialog }) => {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(true);  
    const [txtSupplierName, settxtSupplierName] = useState('');
    const [txtSupplierPhone, settxtSupplierPhone] = useState('');
    const [txtSupplierAddress, settxtSupplierAddress] = useState('');
    const [txtSupplierBankInformation, settxtSupplierBankInformation] = useState('');
    const [txtSupplierPICName, settxtSupplierPICName] = useState('');
    const [txtSupplierPICContact, settxtSupplierPICContact] = useState('');
    const [origins, setOrigins] = useState([]);
    const [filteredOrigins, setFilteredOrigins] = useState([]);
    const [selectedOrigin, setSelectedOrigin] = useState('');
    const [currency, setCurrency] = useState([]);
    const [filteredCurrency, setFilteredCurrency] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [term, setTerm] = useState([]);
    const [filteredTerm, setFilteredTerm] = useState([]);
    const [selectedTerm, setSelectedTerm] = useState('');
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchOrigins();
        fetchCurrency();
        fetchTerm();
    }, []);

    const fetchTerm = async () => {
        try{
            const response = await fetch(API_BASE_URL + 'master/term/getallterm.php');
            const data = await response.json();
            if (data.StatusCode === 200) {
                setTerm(data.Data);
                setFilteredTerm(data.Data);
                setSelectedTerm(data.Data[0]?.term_id || '');
            } else {
                console.error('Failed to fetch term');
            }
        } catch (error){

        }
    }

    const fetchCurrency = async () => {
        try{
            const response = await fetch(API_BASE_URL + 'master/currency/getallcurrency.php');
            const data = await response.json();
            if (data.StatusCode === 200) {
                setCurrency(data.Data);
                setFilteredCurrency(data.Data);
                setSelectedCurrency(data.Data[0]?.Id || '');
            } else {
                console.error('Failed to fetch origins');
            }
        } catch (error){

        }
    }

    const fetchOrigins = async () => {
        try {
            const response = await fetch(API_BASE_URL + 'master/origin/getorigin.php');
            const data = await response.json();
            if (data.StatusCode === 200) {
                setOrigins(data.Data);
                setFilteredOrigins(data.Data);
                setSelectedOrigin(data.Data[0]?.origin_id || '');
            } else {
                console.error('Failed to fetch origins');
            }
        } catch (error) {
            console.error('Error fetching origins:', error);
        }
    };

    const handleOriginChange = (event) => {
        setSelectedOrigin(event.target.value);
    };

    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
    };

    const handleTermChange = (event) => {
        setSelectedTerm(event.target.value);
    };

    const handleCloseClick = () => {
        onCloseDialog();  // Call the onCloseDialog function passed from the parent component
    };

    if (!showDialog) {
        return null;  // Optionally render nothing if the dialog is not shown
    }

    const handleSubmit = async (event) => {
        event.preventDefault(); 

        if (
            !txtSupplierName ||
            !txtSupplierPhone ||
            !txtSupplierAddress ||
            !txtSupplierPICName ||
            !txtSupplierPICContact
        ) {
            setErrorTitle("Submission Failed");
            setErrorMessage("Please fill in all fields !!");
            setError("Please fill in all fields");
            return;
        }

        setLoading(true);
        try {
            const apiInsertSupplier = API_BASE_URL + 'master/supplier/insertsupplier.php';
            const response = await fetch(apiInsertSupplier, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    company_id: '1252f67e-bfda-11ee-9dcf-0e799759a249',
                    supplier_name: txtSupplierName,
                    supplier_origin: selectedOrigin,
                    supplier_address: txtSupplierAddress,
                    supplier_phone: txtSupplierPhone,
                    supplier_pic_name: txtSupplierPICName,
                    supplier_pic_contact: txtSupplierPICContact,
                    supplier_currency: selectedCurrency,
                    supplier_term: selectedTerm,
                    supplier_bank: txtSupplierBankInformation
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to insert customer data');
            }

            if (response.status === 500) {
                setErrorTitle("Insert Failed");
                setErrorMessage("Check your field again !!");
                setShowDialog(true);
            }

            if(response.status === 200){
                navigate('/settingIndex');
            }

        } catch (error) {
            setErrorTitle("Insert Supplier Failed");
            setErrorMessage("An error occurred during insert. Please try again later.");
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };
    
    const dialogBox = <ErrorBox onCloseDialog={handleCloseDialog} title={errorTitle} message={errorMessage} />;
    
    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            New Supplier Data
                        </div>
                        {error && dialogBox}
                        {loading ? (<Loader/>) : (
                            <form>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier Name</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox 
                                            value={txtSupplierName}
                                            onChange={(e) => settxtSupplierName(e.target.value)}
                                            placeholder="PT.xxxx xxxx xxxx" 
                                        />
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier Phone</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox 
                                            value={txtSupplierPhone}
                                            onChange={(e) => settxtSupplierPhone(e.target.value)}
                                            placeholder="(021) xxxx xxxx"
                                        />
                                    </div>
                                </div>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier Address</label>
                                        <div className='space-between-1vh'></div>
                                        <TextArea 
                                            value={txtSupplierAddress}
                                            onChange={(e) => settxtSupplierAddress(e.target.value)}
                                            maxLines={5} 
                                            placeholder="Jl. xxxx xxxx xxxx" 
                                        />
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier Origin</label>
                                        <select className='new-form-dropdown' value={selectedOrigin} onChange={handleOriginChange}>
                                            {filteredOrigins.map((origin) => (
                                                <option key={origin.origin_id} value={origin.origin_id}>
                                                    {origin['Country Name']}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier PIC Name</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox 
                                            value={txtSupplierPICName}
                                            onChange={(e) => settxtSupplierPICName(e.target.value)}
                                            placeholder="xxxx xxxx"
                                        />
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier PIC Contact</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox 
                                            value={txtSupplierPICContact}
                                            onChange={(e) => settxtSupplierPICContact(e.target.value)}
                                            placeholder="08xx xxxx xxxx"
                                        />
                                    </div>
                                </div>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier Currency</label>
                                        <div className='space-between-1vh'></div>
                                        <select className='new-form-dropdown' value={selectedCurrency} onChange={handleCurrencyChange}>
                                            {filteredCurrency.map((currency) => (
                                                <option key={currency.Id} value={currency.Id}>
                                                    {currency.Currency}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier Term</label>
                                        <div className='space-between-1vh'></div>
                                        <select className='new-form-dropdown' value={selectedTerm} onChange={handleTermChange}>
                                            {filteredTerm.map((term) => (
                                                <option key={term.term_id} value={term.term_id}>
                                                    {term.Term}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Supplier Bank Information</label>
                                        <div className='space-between-1vh'></div>
                                        <TextArea 
                                            value={txtSupplierBankInformation}
                                            onChange={(e) => settxtSupplierBankInformation(e.target.value)}
                                            maxLines={5} 
                                            placeholder="Insert supplier bank information" 
                                        />
                                    </div>
                                </div>   
                            </form>
                        ) }
                        
                        <div className='space-between'/>
                        <div className='grid-container-form-submit'>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Close</div>
                            <div className='space-between-width'/>
                            <div className='verification-button' onClick={handleSubmit}>Submit</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewSupplierDialog;
