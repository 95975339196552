import React, { useState, useEffect } from 'react';
import FormLabel from "../../component/FormLabel";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import TextBox from "../../component/TextBox";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingActive from "../../component/menu/SettingActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import { fetchCompanys } from "../../services/PurchaseService";
import useAuthSession from "../../services/Session";
import TextArea from '../../component/TextArea';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { API_BASE_URL } from '../../../App';
import NewTargettingDialog from '../../component/dialog/NewTargettingDialog';
import Loader from '../../component/Loader';
import { useNavigate } from 'react-router-dom';
import DetailTargetDialog from '../../component/dialog/DetailTarget';
import ErrorDialog from '../../component/dialog/ErrorDialog';

ChartJS.register(ArcElement, Tooltip, Legend);

function CompanySetting(){
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [companyData, setCompanyData] = useState({});
    const [targets, setTargets] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [showDialog1, setShowDialog1] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedTargetId, setSelectedTargetId] = useState('');
    const [selectedTargetYear, setSelectedTargetYear] = useState('');
    const [selectedTargetValue, setSelectedTargetValue] = useState(0);
    const [showErrorDialog, setShowErrorDialog] = useState(false);  
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');

    useEffect(() => {
        const fetchTargets = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}company/targeting/gettargeting.php?company_id=1252f67e-bfda-11ee-9dcf-0e799759a249`);
                const data = await response.json();
                if (response.ok) {
                    setTargets(data.Data);
                } else {
                    setErrorCode('404');
                    setErrorTitle('Failed to fetch data');
                    setShowErrorDialog(true);
                }
            } catch (error) {
                setErrorCode('404');
                setErrorTitle('Error fetching target data:', error);
                setShowErrorDialog(true);
            } finally {
                setLoading(false);
            }
        };

        fetchTargets();
    }, []);

    const handleCreateClick = () => {
        setShowDialog(true); 
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleCloseDialog1 = () => {
        setShowDialog1(false);
    };

    const dialogBox = <NewTargettingDialog onCloseDialog={handleCloseDialog}/>

    const createDoughnutData = (achieved, total) => {
        return {
            labels: ['Achieved', 'Remaining'],
            datasets: [{
                data: [achieved, total - achieved],
                backgroundColor: ['rgba(42, 133, 255, 1)', 'rgba(252, 252, 252, 1)'],
                borderWidth: 0,
            }],
        };
    };

    const handleFieldChange = (fieldName, value) => {
        setCompanyData(prevData => ({
            ...prevData,
            [fieldName]: value
        }));
    }

    const options = {
        maintainAspectRatio: true,
        aspectRatio: 1, 
        plugins: {
            legend: { display: false },
            tooltip: { enabled: true }
        }
    };

    const achieved = 500000000;
    const total = 1000000000;
    const percentage = (achieved / total) * 100;

    const centerTextPlugin = {
        id: 'centerText',
        afterDraw: (chart) => {
            const { ctx } = chart;
            ctx.save();
            const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
            ctx.font = '24px Arial';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = '#2A85FF';  // Color of the text
            ctx.fillText(`${percentage.toFixed(1)}%`, centerX, centerY);
            ctx.restore();
        }
    };

    ChartJS.register(centerTextPlugin);

    const fetchCompanyData = async () => {
        setLoading(true);
        try {
            const companyData = await fetchCompanys();
            if (companyData && companyData.length > 0) { // Check if data is not empty
                setCompanyData(companyData[0]);
            } else {
                setErrorCode('404');
                setErrorTitle('No company data received');
                setShowErrorDialog(true);
                setCompanyData([]);
            }
        } catch (error) {
            setErrorCode('404');
            setErrorTitle('Error fetching company data:', error);
            setShowErrorDialog(true);
            setCompanyData([]); 
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchCompanyData();
    }, []);

    const updateCompanyData = async () => {
        setLoading(true);
        try{
            const { company_name, company_phone, company_email, company_address, company_web, company_industry } = companyData;
            const company_id = '1252f67e-bfda-11ee-9dcf-0e799759a249';

            const requestBody = new URLSearchParams({
                company_id,
                company_name,
                company_address,
                company_phone,
                company_email,
                company_web,
                company_industry
            });

            const response = await fetch(`${API_BASE_URL}company/companyData/updatedetailcompany.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: requestBody
            });

            if (!response.ok) {
                setErrorCode('404');
                setErrorTitle('Failed to insert currency data');
                setShowErrorDialog(true);
            }

            if (response.status === 500) {
                setErrorCode('404');
                setErrorTitle('Failed to update company data !! Please check again your field !!');
                setShowErrorDialog(true);
            }

            if(response.status === 200){
                navigate('/settingIndex');
            }
        } catch (error) {
            setErrorCode('404');
            setErrorTitle('Error fetching data:', error);
            setShowErrorDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const DetailTargetClick = (targetId, targetYear, targetValue) => {
        setSelectedTargetId(targetId);
        setSelectedTargetYear(targetYear);
        setSelectedTargetValue(targetValue);
        setShowDialog1(true);
    };

    const handleCloseErrorDialog = () => {
        setShowErrorDialog(false);
    };

    return (
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseNonActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                {showErrorDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseErrorDialog} />}
                {showDialog && dialogBox}
                {showDialog1 && <DetailTargetDialog onCloseDialog={handleCloseDialog1} year={selectedTargetYear} target={selectedTargetValue} id={selectedTargetId}/>}
                <ProfilSearchBox username={username}/>
                {loading ? (<Loader/>) : (
                    <div>
                        <div className='grid-container'>
                            <div className='MenuName'>Company Module</div>
                        </div>
                        <div className="card-for-grid">
                            <div className="grid-container">
                                <div className="only-100-start">
                                    <FormLabel labelName={"Company Name"}/>
                                    <TextBox
                                        placeholder={"Insert your company name"}
                                        value={companyData ? companyData.company_name || '' : 'Loading...'} 
                                        onChange={(e) => handleFieldChange('company_name', e.target.value)}
                                    />
                                </div>
                                <div className="only-100-start">
                                    <FormLabel labelName={"Company Phone Number"}/>
                                    <TextBox 
                                        placeholder={"Insert your company phone number"} 
                                        value={companyData ? companyData.company_phone || '' : 'Loading...'} 
                                        onChange={(e) => handleFieldChange('company_phone', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="only-100-start">
                                    <FormLabel labelName={"Company Website"}/>
                                    <TextBox 
                                        placeholder={"Insert your company website"} 
                                        value={companyData ? companyData.company_web || '' : 'Loading...'} 
                                        onChange={(e) => handleFieldChange('company_web', e.target.value)}
                                    />
                                </div>
                                <div className="only-100-start">
                                    <FormLabel labelName={"Company Email"}/>
                                    <TextBox 
                                        placeholder={"Insert your company email"} 
                                        value={companyData ? companyData.company_email || '' : 'Loading...'} 
                                        onChange={(e) => handleFieldChange('company_email', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="only-100-start">
                                    <FormLabel labelName={"Company Address"}/>
                                    <TextArea 
                                        placeholder={"Insert your company address"} 
                                        value={companyData ? companyData.company_address || '' : 'Loading...'} 
                                        onChange={(e) => handleFieldChange('company_address', e.target.value)}
                                        maxLines={5}
                                    />
                                </div>
                                <div className="only-100-start">
                                    <FormLabel labelName={"Company Industry"}/>
                                    <TextBox 
                                        placeholder={"Insert your company industry"} 
                                        value={companyData ? companyData.company_industry || '' : 'Loading...'}
                                        onChange={(e) => handleFieldChange('company_industry', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='grid-container-form-submit'>
                                <div className='verification-button' onClick={updateCompanyData}>Update</div>
                            </div>
                        </div>
                        <div className='grid-container'>
                            <div className='MenuName'>Target Settings</div>
                            <div className='create-new-button' onClick={handleCreateClick}>Create New Target</div>
                        </div>
                        <div className="target-grid-container">
                            {targets.map((target, index) => {
                                const total = target.target_value;
                                const achieved = total * 0.5; // Example calculation, replace with actual achieved value
                                const data = createDoughnutData(achieved, total);

                                return (
                                    <div key={target.targeting_id} className={`card-for-grid-100 ${index % 2 === 0 ? 'new-row-start' : ''}`}>
                                        <div className='MenuName' onClick={() => DetailTargetClick(target.targeting_id, target.target_year, target.target_value)}>Target {target.target_year}</div>
                                        <div className='space-between'></div>
                                        <div className='chart'>
                                            <Doughnut data={data} options={options}/>
                                        </div>
                                        <div className='space-between'></div>
                                        <div className='target-company-one'>Target : <div className='target-company-two'>Rp. {achieved.toLocaleString()} / </div><div className='target-company-three'>Rp. {total.toLocaleString()}</div></div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className='space-between'></div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CompanySetting;