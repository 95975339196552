import { useEffect, useState } from "react";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import FourCardGrid from "../../component/card/FourCardGrid";
import NotFoundPage from "../../component/maintanance/NotFound";
import UnderConstruction from "../../component/maintanance/UnderConstruction";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRActive from "../../component/menu/HRActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import { useNavigate } from 'react-router-dom';
import ErrorBox from "../../component/ErrorBox";
import Loader from "../../component/Loader";
import TextBox from "../../component/TextBox";
import FormLabel from "../../component/FormLabel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_BASE_URL, formatDateForSubmit } from "../../../App";
import ErrorDialog from "../../component/dialog/ErrorDialog";

function NewEmployee(){
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [loading, setLoading] = useState(false);
    const [DOB, setDOB] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [employeeName, setEmployeeName] = useState('');
    const [employeePosition, setEmployeePosition] = useState('');
    const [POB, setPOB] = useState('');
    const [allGenders, setAllGenders] = useState([]);
    const [selectedGender, setSelectedGender] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');

    useEffect(() => {
        fetchGenderData();
    }, []);

    const fetchGenderData = async () => {
        try{
            setLoading(true);
            const response = await fetch(`${API_BASE_URL}master/gender/getallgender.php`);
            const data = await response.json();
            if(data.StatusCode === 200){
                setAllGenders(data.Data);
                setSelectedGender(data.Data[0]?.id || '');
            } else {
                throw new Error('Failed to fetch gender');
            }
        } catch (error) {
            console.log('Error failed to fetch gender', error);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async () => {
        if(employeeName === ''){
            setErrorCode('400');
            setErrorTitle('Nama karyawan tidak dapat kosong !!');
            setShowDialog(true);
        } else if (POB === ''){
            // add check if employee is less then 10 years from now then not valid
            setErrorCode('400');
            setErrorTitle('Tempat dan tanggal lahir karyawan tidak valid !!');
            setShowDialog(true);
        } else if (employeePosition == ''){
            setErrorCode('400');
            setErrorTitle('Posisi karyawan tidak dapat kosong !!');
            setShowDialog(true);
        } else {
            try{
                setLoading(true);
    
                const API = API_BASE_URL + 'hr/HR.php';
    
                let params = new URLSearchParams({
                    employee_name: employeeName,
                    employee_position : employeePosition,
                    employee_gender : selectedGender,
                    employee_pob : POB,
                    employee_dob : formatDateForSubmit(DOB),
                    employee_start_date : formatDateForSubmit(startDate)
                });
    
                const response = await fetch(API, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: params
                });
    
                if(response.status === 200){
                    navigate(`/hrIndex`);
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error while inserting employee data !!');
                    setShowDialog(true);
                }
    
            } catch (error){
                setErrorCode('400');
                setErrorTitle('Karyawan tidak dapat diinput : ', error);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        }

        
    }

    const handleGenderChange = (event) => {
        const genderID = event.target.value;
        setSelectedGender(genderID);
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    return(
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseNonActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Pendataan Karyawan</div>
                </div>
                {loading ? (<Loader/>) : (
                    <div>
                        <div className='card-for-grid'>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Nama Karyawan"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox placeholder={'Masukkan nama karyawan'} value={employeeName} onChange={(e) => setEmployeeName(e.target.value)}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Posisi Karyawan"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox placeholder={'Masukkan posisi karyawan'} value={employeePosition} onChange={(e) => setEmployeePosition(e.target.value)}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Jenis Kelamin"} />
                                    <div className='space-between-1vh'></div>
                                    <select className='new-form-dropdown' value={selectedGender} onChange={handleGenderChange}>
                                        {allGenders.map((gender) => (
                                            <option key={gender.id} value={gender.id}>
                                                {gender['gender_name']}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Tempat Lahir"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={POB} placeholder={'Masukkan tempat lahir karyawan'} onChange={(e) => setPOB(e.target.value)}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Tanggal Lahir"} />
                                    <div className='space-between-1vh'></div>
                                    {!showDialog && (
                                        <DatePicker
                                            className='date-picker' 
                                            dateFormat="dd MMMM yyyy"
                                            selected={DOB} 
                                            onChange={(date) => setDOB(date)} 
                                        />
                                    )}
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Mulai Bekerja"} />
                                    <div className='space-between-1vh'></div>
                                    {!showDialog && (
                                        <DatePicker
                                            className='date-picker' 
                                            dateFormat="dd MMMM yyyy"
                                            selected={startDate} 
                                            onChange={(date) => setStartDate(date)} 
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='grid-container-with-padding-left'>
                            <div className="only-100-width-card-approve" onClick={handleSubmit}>Submit</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default NewEmployee;