import { useNavigate } from "react-router-dom";
import useAuthSession from "../../services/Session";
import { useEffect, useState } from "react";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import SalesActive from "../../component/menu/SalesActive";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import Logout from "../../component/menu/Logout";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import ExportExcel from "../../services/ExcelExportServices";
import { API_BASE_URL, formatDate } from "../../../App";
import Loader from "../../component/Loader";
import ErrorDialog from "../../component/dialog/ErrorDialog";
import DatePicker from "react-datepicker";
import FormLabel from "../../component/FormLabel";
import TextBox from "../../component/TextBox";

function SeeAllSalesOrder() {
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [loading, setLoading] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [salesOrder, setSalesOrder] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [searchTrigger, setSearchTrigger] = useState(false);
    const [SONumber, setSONumber] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetchSalesData();
                setSalesOrder(response);
            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error fetching sales order data:', error);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [searchTrigger]);

    const fetchSalesData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}sales/getallsalesorder.php?startdate=${startDate.toISOString().split('T')[0]}&enddate=${endDate.toISOString().split('T')[0]}&SONumber=${SONumber}`);
            if (!response.ok) {
                throw new Error('Failed to fetch sales data');
            }
            const data = await response.json();
            return data.Data || [];
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error fetching sppb data:', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleExportClick = async () => {
        setIsExporting(true);
        try {
            await ExportExcel({ excelData: salesOrder, fileName: 'salesorder_data' });
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error exporting to Excel:', error);
            setShowDialog(true);
        }
        setIsExporting(false);
    };

    const handleClick = (PONumber) => {
        navigate(`/salesIndex/Detail/salesOrder?soNumber=${encodeURIComponent(PONumber)}`);
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleSearchClick = () => {
        setSearchTrigger(!searchTrigger);
    };

    return (
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive />
                <SalesActive />
                <PurchaseNonActive />
                <FinanceNonActive />
                <WarehouseNonActive />
                <HRNonActive />
                <AnalyticsnonActive />
                <DocumentnonActive />
                <SettingNonActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                <div className='grid-container-smaller'>
                    <div className='MenuName'>See All Sales Order</div>
                </div>
                <div className='grid-container-smaller'>
                    <div className='FilterDate'>
                        <div>
                            <FormLabel labelName={"Sales Order Number"}/>
                            <div className='space-between-1vh'></div>
                            <TextBox value={SONumber} placeholder={'Insert your sales order number'} onChange={(e) => setSONumber(e.target.value)}/>
                        </div>
                        <div>
                            <FormLabel labelName={"Start SO Date"}/>
                            <div className='space-between-1vh'></div>
                            <DatePicker
                                className='date-picker'
                                selected={startDate}
                                dateFormat="dd MMMM yyyy"
                                onChange={(date) => setStartDate(date)}
                                placeholderText='Select sales date'
                            />
                        </div>
                        <div>
                            <FormLabel labelName={"End SO Date"}/>
                            <div className='space-between-1vh'></div>
                            <DatePicker
                                className='date-picker'
                                selected={endDate}
                                dateFormat="dd MMMM yyyy"
                                onChange={(date) => setEndDate(date)}
                                placeholderText='Select sales date'
                            />
                        </div>
                        <button className='seeAllDetailCard' onClick={handleSearchClick}>Search</button>
                    </div>
                    <button className='FilterExport' onClick={handleExportClick} disabled={isExporting}>
                        {isExporting ? (<Loader/>) : 'Export to Excel'}
                    </button>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? <Loader /> : (
                    salesOrder.map((sales, index) => (
                        <div key={index} className='seeAllCard' onClick={() => handleClick(sales.SONumber)}>
                            <div className='grid-container-smaller'>
                                <div>
                                    <div className='seeAllTitle'>{sales.SONumber}</div>
                                    <div className='seeAllDetail'>Sales order date {formatDate(sales.SODate)}</div>
                                </div>
                                <div className='seeAllDetailCard'>{sales.SO_Status_Name}</div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default SeeAllSalesOrder;
