import { useEffect, useState } from "react";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceActive from "../../component/menu/FinanceActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import FormLabel from "../../component/FormLabel";
import DatePicker from "react-datepicker";
import { API_BASE_URL, formatCurrencyIDR, formatDateTime } from "../../../App";
import Loader from "../../component/Loader";
import ErrorDialog from "../../component/dialog/ErrorDialog";

function BukuKasFinance() {
    const { username } = useAuthSession();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [allBank, setAllBank] = useState([]);
    const [selectedBank, setSelectedBank] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [allBukuKas, setAllBukuKas] = useState([]);
    const [beginningBalance, setBeginningBalance] = useState(0);
    const [endBalance, setEndBalance] = useState(0);

    useEffect(() => {
        fetchBankAccount();
    }, []);

    useEffect(() => {
        if (isSearch) {
            fetchBukuKasData();
        }
    }, [isSearch]);

    const fetchBankAccount = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}finance/allbankaccount.php`);
            const data = await response.json();

            if (data.StatusCode === 200) {
                setAllBank(data.Data);
                if (data.Data.length > 0) {
                    setSelectedBank(data.Data[0].bank_number);
                }
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch bank accounts');
                setShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch bank account: ' + error.message);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const fetchBukuKasData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}finance/getbukukas.php?bank_account=${selectedBank}&start_date=${startDate.toISOString().split('T')[0]}&end_date=${endDate.toISOString().split('T')[0]}`);
            if (!response.ok) {
                throw new Error('Failed to fetch buku kas data');
            }
            const data = await response.json();
            console.log("Fetched data:", data); // Debug log
            setAllBukuKas(data.transactions || []);
            setBeginningBalance(data.beginning_balance || 0);
            setEndBalance(data.end_balance || 0);
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch buku kas data: ' + error.message);
            setShowDialog(true);
        } finally {
            setLoading(false);
            setIsSearch(false);
        }
    };

    const handleBankAccountChange = (event) => {
        setSelectedBank(event.target.value);
    };

    const handleSearchClick = () => {
        setIsSearch(true);
    };

    let currentBalance = parseFloat(beginningBalance); 

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    return (
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive />
                <SalesNonActive />
                <PurchaseNonActive />
                <FinanceActive />
                <WarehouseNonActive />
                <HRNonActive />
                <AnalyticsnonActive />
                <DocumentnonActive />
                <SettingNonActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                <div className='MenuName'>Finance Module</div>
                <div className='grid-container-smaller'>
                    <div className='FilterDate'>
                        <div>
                            <FormLabel labelName={"Bank"} />
                            <div className='space-between-1vh'></div>
                            <select className='new-form-dropdown' value={selectedBank} onChange={handleBankAccountChange}>
                                {allBank.map((bank) => (
                                    <option key={bank.bank_number} value={bank.bank_number}>
                                        {bank.bank_number} - {bank.bank_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <FormLabel labelName={"Start Date"} />
                            <div className='space-between-1vh'></div>
                            <DatePicker
                                className='date-picker'
                                selected={startDate}
                                dateFormat="dd MMMM yyyy"
                                onChange={(date) => setStartDate(date)}
                                placeholderText='Select sales date'
                            />
                        </div>
                        <div>
                            <FormLabel labelName={"End Date"} />
                            <div className='space-between-1vh'></div>
                            <DatePicker
                                className='date-picker'
                                selected={endDate}
                                dateFormat="dd MMMM yyyy"
                                onChange={(date) => setEndDate(date)}
                                placeholderText='Select end date'
                            />
                        </div>
                        <button className='seeAllDetailCard' onClick={handleSearchClick}>Search</button>
                    </div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? <Loader /> : (
                    <div className="card-for-grid">
                        <table className="table-layout">
                            <thead className='table-title' style={{ marginBottom: '10px' }}>
                                <tr>
                                    <th>Tanggal</th>
                                    <th>No. Sumber</th>
                                    <th>No. Cek</th>
                                    <th>Keterangan</th>
                                    <th>Pemasukan (Dr)</th>
                                    <th>Pengeluaran (Cr)</th>
                                    <th>Sisa Saldo</th>
                                </tr>
                            </thead>
                            <tbody className='table-content'>
                                <tr>
                                    <td colSpan="6" style={{ textAlign: 'right' }}>Beginning Balance:</td>
                                    <td>{formatCurrencyIDR(beginningBalance)}</td>
                                </tr>
                                {allBukuKas.map((item, index) => {
                                    const pemasukan = item.category_name === 'Penerimaan'
                                        ? parseFloat(item.amount || item.paid_amount || 0)
                                        : item.company_name ? parseFloat(item.paid_amount || 0) : 0;
                                    const pengeluaran = item.category_name === 'Pembayaran'
                                        ? parseFloat(item.amount || item.paid_amount || 0)
                                        : item.supplier_name ? parseFloat(item.paid_amount || 0) : 0;

                                    const validPemasukan = isNaN(pemasukan) ? 0 : pemasukan;
                                    const validPengeluaran = isNaN(pengeluaran) ? 0 : pengeluaran;
                                    currentBalance += validPemasukan - validPengeluaran;

                                    return (
                                        <tr key={index}>
                                            <td>{formatDateTime(item.transaction_date || item.paymentdate)}</td>
                                            <td>{item.bank_account || item.bank}</td>
                                            <td>{item.chequeno}</td>
                                            <td>{item.account_name_alias || item.supplier_name || item.company_name}</td>
                                            <td>{formatCurrencyIDR(validPemasukan)}</td>
                                            <td>{formatCurrencyIDR(validPengeluaran)}</td>
                                            <td>{formatCurrencyIDR(currentBalance)}</td>
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <td colSpan="6" style={{ textAlign: 'right' }}>End Balance:</td>
                                    <td>{formatCurrencyIDR(endBalance)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default BukuKasFinance;
