import React, { useState, useEffect } from 'react';
import TextBox from '../TextBox';
import ErrorBox from '../ErrorBox';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import { API_BASE_URL } from '../../../App';

const DetailUsersDialog = ({user, onCloseDialog }) => {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(true);  
    const [error, setError] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [selectedPermission, setSelectedPermission] = useState('');

    useEffect(() => {
        setLoading(true);
        fetch(`${API_BASE_URL}company/permission/getlistpermission.php`)
            .then(response => response.json())
            .then(data => {
                if (data.Status === "Success") {
                    setPermissions(data.Data);
                } else {
                    setError(data.Message);
                }
            })
            .catch(error => {
                setError(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleCloseClick = () => {
        onCloseDialog(); 
    };

    const handleDeleteClick = async(event) => {
        event.preventDefault(); 

        setLoading(true);
        try {
            const apiDeleteUser = `${API_BASE_URL}user/deleteuser.php`;
            const response = await fetch(apiDeleteUser, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    username: user.Username,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to delete users data');
            }

            if (response.status === 500) {
                setErrorTitle("Delete Failed");
                setErrorMessage("Check your field again !!");
                setShowDialog(true);
            }

            if(response.status === 200){
                navigate('/settingIndex');
            }
        } catch (error){
            setErrorTitle("Delete Users Failed");
            setErrorMessage("An error occurred during delete. Please try again later.");
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleResetClick = async (event) => {
        event.preventDefault(); 

        setLoading(true);
        try {
            const apiUpdatePermission = `${API_BASE_URL}user/resetaccount.php`;
            const response = await fetch(apiUpdatePermission, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    username: user.Username,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to reset user account');
            }

            if (response.status === 500) {
                setErrorTitle("Reset Account Failed");
                setErrorMessage("Check your field again !!");
                setShowDialog(true);
            }


            if(response.status === 200){
                navigate('/settingIndex');
            }
        } catch (error){
            setErrorTitle("Reset Account Failed");
            setErrorMessage("An error occurred during reset. Please try again later.");
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleUpdateClick = async (event) => {
        event.preventDefault(); 

        setLoading(true);
        try {
            const apiUpdatePermission = `${API_BASE_URL}company/permission/updatepermission.php`;
            const response = await fetch(apiUpdatePermission, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    permission_id: selectedPermission,
                    username: user.Username,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update permission data');
            }

            if (response.status === 500) {
                setErrorTitle("Insert Failed");
                setErrorMessage("Check your field again !!");
                setShowDialog(true);
            }


            if(response.status === 200){
                navigate('/settingIndex');
            }
        } catch (error){
            setErrorTitle("Update Permission Failed");
            setErrorMessage("An error occurred during update. Please try again later.");
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    if (!showDialog) {
        return null; 
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const dialogBox = <ErrorBox onCloseDialog={handleCloseDialog} title={errorTitle} message={errorMessage} />;
    
    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            Detail Users Data
                        </div>
                        {error && dialogBox}
                        {loading ? (<Loader/>) : (
                            <form>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Name</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={user.Name}/>
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Username</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={user.Username}/>
                                    </div>
                                </div>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Company</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={user["Company Name"]}/>
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Permission</label>
                                        <select  className='new-form-dropdown' value={selectedPermission} onChange={e => setSelectedPermission(e.target.value)}>
                                            <option value={user["Permission Name"]}>Select Permission</option>
                                            {permissions.map(permission => (
                                                <option key={permission.permission_id} value={permission.permission_id}>{permission.permission_access}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </form>
                        )}
                        <div className='space-between'/>
                        <div className='grid-container-form-submit'>
                            <div className='verification-button' onClick={handleUpdateClick}>Update</div>
                            <div className='space-between-width'/>
                            <div className='redFont' onClick={handleDeleteClick}>Delete Account</div>
                            <div className='space-between-width'/>
                            <div className='close-text-dialog' onClick={handleResetClick}>Reset Account</div>
                            <div className='space-between-width'/>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Close</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailUsersDialog;