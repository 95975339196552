import React, { useState, useEffect } from 'react';
import FormLabel from "../../component/FormLabel";
import Loader from "../../component/Loader";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseActive from "../../component/menu/PurchaseActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL, formatCurrency, formatDate, formatDateTime } from '../../../App';
import TextBox from '../../component/TextBox';
import ErrorDialog from '../../component/dialog/ErrorDialog';

function DetailInvoiceItems(){
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [loading, setLoading] = useState(false);
    let { PONumber } = useParams();
    const [detailInvoiceItems, setDetailInvoiceItems] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');

    useEffect(() => {
        const fetchInvoiceDetails = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${API_BASE_URL}purchase/invoice/getdetailinvoiceitems.php?PONumber=${PONumber}`);
                if (!response.ok) {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail invoice items data');
                    setShowDialog(true);
                }

                const data = await response.json();
                if (data.StatusCode === 200) {
                    setDetailInvoiceItems(data.Data);
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail invoice items data');
                    setShowDialog(true);
                }
            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail invoice items data : ', error);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        }

        if (PONumber) {
            fetchInvoiceDetails();
        }
    }, [PONumber]);
     
    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const DPPCountW = (harga, quantity) => {
        const DPPResult = harga * quantity;
        return DPPResult;
    };

    const PPNCountW = (harga, quantity) => {
        const PPNResult = (harga * quantity) * (detailInvoiceItems.Details[0].PPNPercentage / 100);
        return PPNResult;
    };

    let totalDPP = 0;
    let totalPPN = 0;
    let totalHargaSatuan = 0;
    
    if (detailInvoiceItems) {
        detailInvoiceItems.Items.forEach((item, index) => {
            const DPP = DPPCountW(item.UnitPrice, item.Quantity);
            const PPN = PPNCountW(item.UnitPrice, item.Quantity);
            totalDPP += parseFloat(DPP);
            totalPPN += parseFloat(PPN);
            totalHargaSatuan += parseFloat(item.UnitPrice);
        });
    }

    return(
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive />
                <SalesNonActive />
                <PurchaseActive />
                <FinanceNonActive />
                <WarehouseNonActive />
                <HRNonActive />
                <AnalyticsnonActive />
                <DocumentnonActive />
                <SettingNonActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Purchase Module</div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? <Loader/> : (
                    detailInvoiceItems ? (
                        <div>
                            <div className='card-for-grid'>
                                <div className='title-for-card'>Purchase Status</div>
                                <div className='grid-container'>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Status"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailInvoiceItems.Details[0].PO_Status_Name}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Created By"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailInvoiceItems.Details[0].InsertBy}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Created At"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{formatDateTime(detailInvoiceItems.Details[0].InsertDt)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-for-grid'>
                                <div className='title-for-card'>Purchase Invoice Details</div>
                                <div className='grid-container'>
                                    <div className='only-100-width'>
                                        <FormLabel labelName={"Vendor"} />
                                        <TextBox value={detailInvoiceItems.Details[0]?.supplier_name} />
                                    </div>
                                    <div className='only-100-width'>
                                        <FormLabel labelName={"PO Number"} />
                                        <TextBox value={detailInvoiceItems.Details[0]?.PONumber} />
                                    </div>
                                    <div className='only-100-width'>
                                        <FormLabel labelName={"Invoice Number"} />
                                        <TextBox value={detailInvoiceItems.Details[0]?.invoiceNumber} />
                                    </div>
                                </div>
                                <div className='grid-container'>
                                    <div className='only-100-width'>
                                        <FormLabel labelName={"Invoice Date"} />
                                        <TextBox value={formatDate(detailInvoiceItems.Details[0]?.invoiceDate)} />
                                    </div>
                                    <div className='only-100-width'>
                                        <FormLabel labelName={"Ship Date"} />
                                        <TextBox value={formatDate(detailInvoiceItems.Details[0]?.shipDate)} />
                                    </div>
                                    <div className='only-100-width'>
                                        <FormLabel labelName={"Kurs"} />
                                        <TextBox value={detailInvoiceItems.Details[0]?.kurs} />
                                    </div>
                                </div>
                                {detailInvoiceItems.Items.map((item, index) => (
                                    <div key={index}>
                                        <div className='ItemNameTemplateForm'>
                                            <FormLabel labelName={`Item #${index + 1}`} />
                                        </div>
                                        <div className="grid-container">
                                            <div className="only-100-width">
                                                <FormLabel labelName={`Product Name`}/>
                                                <div className='space-between-1vh'></div>
                                                <TextBox value={item.ProductName} readOnly />
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={`Quantity (Kg)`}/>
                                                <div className='space-between-1vh'></div>
                                                <TextBox value={item.Quantity} readOnly />
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={`Packaging Size (Kg)`}/>
                                                <div className='space-between-1vh'></div>
                                                <TextBox value={item.PackagingSize}/>
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={`Unit Price ${detailInvoiceItems ? `(${detailInvoiceItems.Details[0].currency_name})` : 'IDR'}`}/>
                                                <div className='space-between-1vh'></div>
                                                <TextBox value={formatCurrency(item.UnitPrice, detailInvoiceItems.Details[0].currency_name)}/>
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={`Total ${detailInvoiceItems ? `(${detailInvoiceItems.Details[0].currency_name})` : 'IDR'}`}/>
                                                <div className='space-between-1vh'></div>
                                                <TextBox value={formatCurrency(item.UnitPrice * item.Quantity, detailInvoiceItems.Details[0].currency_name)}/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {detailInvoiceItems.Details[0].POType === '741ead94-d157-11ee-8' ? (
                                        <div className='grid-container'>
                                            <div className="only-100-width"></div>
                                            <div className="only-100-width"></div>
                                            <div className="only-100-width"></div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"VAT (11%)"}/>
                                            </div>
                                            <div className="only-100-width">
                                                <TextBox value={formatCurrency(totalPPN, detailInvoiceItems ? detailInvoiceItems.Details[0].currency_name : 'IDR')}/>
                                            </div>
                                        </div>) 
                                : (<div></div>)}
                                {detailInvoiceItems.Details[0].POType === '741ead94-d157-11ee-8' ? (
                                    <div className='grid-container'>
                                        <div className="only-100-width"></div>
                                        <div className="only-100-width"></div>
                                        <div className="only-100-width"></div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={"Total"}/>
                                        </div>
                                        <div className="only-100-width">
                                            <TextBox value={formatCurrency(totalDPP + totalPPN, detailInvoiceItems ? detailInvoiceItems.Details[0].currency_name : 'IDR')}/>
                                        </div>
                                    </div>) 
                                : (<div className='grid-container'>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"VAT (11%)"}/>
                                    </div>
                                    <div className="only-100-width">
                                        <TextBox value={formatCurrency(totalDPP, detailInvoiceItems ? detailInvoiceItems.Details[0].currency_name : 'IDR')}/>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    ) : <div>No data available</div>
                )}
            </div>
        </div>
    );
}

export default DetailInvoiceItems;