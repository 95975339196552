import React, { useEffect, useState } from 'react';
import TextBox from '../TextBox';
import { useNavigate } from 'react-router-dom';
import ErrorBox from '../ErrorBox';
import Loader from '../Loader';
import { API_BASE_URL } from '../../../App';
import FormLabel from '../FormLabel';
import TextArea from '../TextArea';

const DetailProductDialog = ({ onCloseDialog, id }) => {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(true);
    const [errorDialog, setErrorDialog] = useState(false);
    const [txtProductCodeBefore, setTxtProductCodeBefore] = useState('');
    const [txtProductNameBefore, setTxtProductNameBefore] = useState('');
    const [txtProductDescBefore, setTxtProductDescBefore] = useState('');
    const [txtProductCode, setTxtProductCode] = useState('');
    const [txtProductName, setTxtProductName] = useState('');
    const [txtProductDesc, setTxtProductDesc] = useState('');
    const [error, setError] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try{
                const response = await fetch(`${API_BASE_URL}master/product/getdetailproduct.php?product_code=${id.Code}`);
                const data = await response.json();

                if (data.StatusCode === 200) {
                    setTxtProductCode(data.Data[0].skuID);
                    setTxtProductDesc(data.Data[0].productDesc);
                    setTxtProductName(data.Data[0].productName);

                    setTxtProductCodeBefore(data.Data[0].skuID);
                    setTxtProductDescBefore(data.Data[0].productDesc);
                    setTxtProductNameBefore(data.Data[0].productName);
                } else {
                    throw new Error(data.Status || 'Unknown error');
                }
            } catch (error){
                setErrorTitle('Error fetching productdata');
                setErrorMessage(error.message);
                setErrorDialog(true);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [id])

    const handleCloseClick = () => {
        onCloseDialog();
    };

    if (!showDialog) {
        return null;
    }

    const handleCloseErrorDialog = () => {
        setErrorDialog(false);
    };

    const dialogBox = errorDialog && <ErrorBox onCloseDialog={handleCloseErrorDialog} title={errorTitle} message={errorMessage} />;

    const handleUpdateClick = async () => {
        setLoading(true);
        try{
            const API = `${API_BASE_URL}master/product/updateproduct.php`;
            const response = await fetch (API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    product_code_new : txtProductCode,
                    product_name_new : txtProductName,
                    product_desc_new : txtProductDesc,
                    product_code_before : txtProductCodeBefore,
                    product_name_before : txtProductNameBefore,
                    product_desc_before : txtProductDescBefore,
                }),
            });
            
            if(response.status === 200){
                navigate('/settingIndex');
            } else {
                setErrorTitle('Error');
                setErrorMessage('Error while updating product !!');
                setErrorDialog(true);
            }
        } catch (error){
            setErrorTitle('Error Update Product');
            setErrorMessage(error.message);
            setErrorDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleDeleteClick = async () => {
        setLoading(true);
        try{
            const API = `${API_BASE_URL}master/product/deleteproduct.php`;
            const response = await fetch (API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    product_code : txtProductCodeBefore
                }),
            });
            
            if(response.status === 200){
                navigate('/settingIndex');
            } else {
                setErrorTitle('Error');
                setErrorMessage('Error while deleting bank account !!');
                setErrorDialog(true);
            }

        } catch (error){
            setErrorTitle('Error Delete Bank Account');
            setErrorMessage(error.message);
            setErrorDialog(true);
        } finally {
            setLoading(false);
        }
    }

    return(
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            Detail Product
                        </div>
                        {dialogBox}
                        {loading ? (<Loader/>) : (
                            <form>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Code Product</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={txtProductCode} onChange={(e) => setTxtProductCode(e.target.value)}/>
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Product Name</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={txtProductName} onChange={(e) => setTxtProductName(e.target.value)}/>
                                    </div>
                                </div>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Product Description</label>
                                        <div className='space-between-1vh'></div>
                                        <TextArea value={txtProductDesc} onChange={(e) => setTxtProductDesc(e.target.value)}/>
                                    </div>
                                </div>
                            </form>
                        )}
                        <div className='space-between'/>
                        <div className='grid-container-form-submit'>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Close</div>
                            <div className='space-between-width' />
                            <div className='redFont' onClick={handleDeleteClick}>Delete</div>
                            <div className='space-between-width' />
                            <div className='verification-button' onClick={handleUpdateClick}>Update</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailProductDialog;