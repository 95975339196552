import { useEffect, useState } from "react";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import NotFoundPage from "../../component/maintanance/NotFound";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceActive from "../../component/menu/FinanceActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import BukuKas from '../../../assets/Icon/BukuKas.png';
import Pembayaran from '../../../assets/Icon/Pembayaran.png';
import Penerimaan from '../../../assets/Icon/Penerimaan.png';
import PenerimaanPembelian from '../../../assets/Icon/PenerimaanPembelian.png';
import PenerimaanPenjualan from '../../../assets/Icon/PenerimaanPenjualan.png';
import { useNavigate } from "react-router-dom";
import SalesCardDashbord from "../../component/card/SalesCardDashboard";
import Loader from "../../component/Loader";
import { API_BASE_URL, formatCurrencyIDR } from "../../../App";

function FinanceIndex() {
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const permissionAccess = localStorage.getItem('permissionAccess');
    const [loading, setLoading] = useState(true);
    const [supplierOutstanding, setSupplierOutstanding] = useState(0);
    const [customerOutstanding, setCustomerOutstanding] = useState(0);

    useEffect(() => {
        const fetchFinanceData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}finance/financestastics.php`);
                if (!response.ok) {
                    throw new Error('Failed to fetch finance data');
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    const { supplierOutstanding, totalSupplierOutstanding, customerOutstanding, totalCustomerOutstanding } = data.Data;
                    setSupplierOutstanding(totalSupplierOutstanding);
                    setCustomerOutstanding(totalCustomerOutstanding);
                } else {
                    setSupplierOutstanding(0);
                    setCustomerOutstanding(0);
                }
            } catch (error) {
                console.error('Error fetching finance data:', error);
                setSupplierOutstanding(0);
                setCustomerOutstanding(0);
            } finally {
                setLoading(false);
            }
        };

        fetchFinanceData();
    }, []);

    const handlePenerimaanNavigate = () => {
        navigate('/financeIndex/Penerimaan');
    }

    const handlePembayaranNavigate = () => {
        navigate('/financeIndex/Pembayaran');
    }

    const handlePenerimaanPembelianNavigate = () => {
        navigate('/financeIndex/New/PenerimaanPembelian');
    }

    const handlePenerimaanPenjualanNavigate = () => {
        navigate('/financeIndex/New/PenerimaanPenjualan');
    }

    const handleBukuKasNavigate = () => {
        navigate('/financeIndex/BukuKas');
    }

    return (
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive />
                <SalesNonActive />
                <PurchaseNonActive />
                <FinanceActive />
                <WarehouseNonActive />
                <HRNonActive />
                <AnalyticsnonActive />
                <DocumentnonActive />
                <SettingNonActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                {permissionAccess === 'Full access' ? (
                    loading ? <Loader /> : (
                        <div>
                            <div className='grid-container-smaller'>
                                <div className='MenuName'>Finance Module</div>
                            </div>
                            <SalesCardDashbord
                                headline={'Finance Overview'}
                                title1="Supplier Outstanding"
                                content1={`${formatCurrencyIDR(supplierOutstanding)}`}
                                title2="Customer Outstanding"
                                content2={`${formatCurrencyIDR(customerOutstanding)}`}
                            />
                            <div>
                                <div className='grid-container'>
                                    <div className='card-for-grid-finance' onClick={handlePenerimaanNavigate}>
                                        <img src={Penerimaan} />
                                        <div className='settings-title'>Penerimaan</div>
                                        <div className='settings-desc'>Use this menu to do the penerimaan in finance department</div>
                                    </div>
                                    <div className='card-for-grid-finance' onClick={handlePembayaranNavigate}>
                                        <img src={Pembayaran} />
                                        <div className='settings-title'>Pembayaran</div>
                                        <div className='settings-desc'>Use this menu to do the pembayaran in finance department</div>
                                    </div>
                                    <div className='card-for-grid-finance' onClick={handlePenerimaanPembelianNavigate}>
                                        <img src={PenerimaanPembelian} />
                                        <div className='settings-title'>Penerimaan Pembelian</div>
                                        <div className='settings-desc'>Use this menu to do the penerimaan pembelian in finance department</div>
                                    </div>
                                    <div className='card-for-grid-finance' onClick={handlePenerimaanPenjualanNavigate}>
                                        <img src={PenerimaanPenjualan} />
                                        <div className='settings-title'>Penerimaan Penjualan</div>
                                        <div className='settings-desc'>Use this menu to do the penerimaan penjualan in finance department</div>
                                    </div>
                                </div>
                                <div className='grid-container'>
                                    <div className='card-for-grid-finance' onClick={handleBukuKasNavigate}>
                                        <img src={BukuKas} />
                                        <div className='settings-title'>Buku Kas</div>
                                        <div className='settings-desc'>Use this menu to find buku kas in finance department</div>
                                    </div>
                                    <div className="only-100-width">
                                    </div>
                                    <div className="only-100-width">
                                    </div>
                                    <div className="only-100-width">
                                    </div>
                                    <div className="only-100-width">
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <div>
                        <div className='grid-container-smaller'>
                            <div className='MenuName'>Finance Module</div>
                        </div>
                        <NotFoundPage menuName={'Finance'} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default FinanceIndex;
