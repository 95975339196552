import React from 'react';
import { FaKey, FaEye } from 'react-icons/fa';

function PasswordTextBox(props) {
  const { value, onChange } = props;

  const [values, setValues] = React.useState({
      password: "",
      showPassword: false,
  });

  const handleClickShowPassword = () => {
      setValues({
          ...values,
          showPassword: !values.showPassword,
      });
  };

  const handleMouseDownPassword = (event) => {
      event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
      setValues({
          ...values,
          [prop]: event.target.value,
      });
  };

  return (
    <div className="textbox-container">
      <FaKey className="icon" />
      <input
        type={
          values.showPassword
              ? "text"
              : "password"
      }
        value={value}
        onChange={onChange}
        secureTextEntry={true}
        className="passwordTextBox"
        placeholder="Input your password"
      />
      <FaEye className="icon" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}/>
      
    </div>
  );
}

export default PasswordTextBox;