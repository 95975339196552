import React, { useState, useEffect } from 'react';
import FormLabel from "../../component/FormLabel";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseActive from "../../component/menu/PurchaseActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import TextArea from '../../component/TextArea';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PurchaseLocalTemplate from '../../component/form/PurchaseLocalTemplate';
import {fetchPayments, fetchCompanys, fetchLocalSuppliers } from '../../services/PurchaseService';
import ErrorBox from '../../component/ErrorBox';
import ErrorDialog from '../../component/dialog/ErrorDialog';
import SuccessDialog from '../../component/dialog/SuccessDialog';
import { API_BASE_URL } from '../../../App';
import TextBox from '../../component/TextBox';
import Loader from '../../component/Loader';
import ListProduct from '../setting/ListProduct';
import { useNavigate } from 'react-router-dom';
import { fetchNPWP } from '../../services/SalesServices';

function NewPurchaseLocal(){
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [filteredSuppliers, setFilteredSuppliers] = useState([]);
    const [selectedSupplier, setselectedSupplier] = useState('');
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [selectedPayment, setselectedPayment] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [shippingDate, setShippingDate] = useState(new Date());
    const [items, setItems] = useState([]);
    const [companyData, setCompanyData] = useState({});
    const [picName, setPicName] = useState('');
    const [ErrorShowDialog, setErrorShowDialog] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [PONumber, setPONumber] = useState('');
    const [showProductdialog, setShowProductdialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [filteredPPN, setFilteredPPN] = useState([]);
    const [selectedPPN, setselectedPPNs] = useState('');
    const [selectedTax, setselectedTax] = useState('');

    useEffect(() => {
        fetchSupplierData('1252f67e-bfda-11ee-9dcf-0e799759a249');
        fetchPaymentData();
        fetchCompanyData();
        fetchPONumber();
        fetchNPWPData();
    }, []);

    const handleNPWPChange = (event) => {
        const newPPNID = event.target.value;
        setselectedPPNs(newPPNID);
        fetchTaxPercantage(newPPNID);
    };

    const fetchTaxPercantage = async(newPPNID) => {
        if (!newPPNID) return;
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}master/ppn/getdetailppnpercantage.php?PPNType_id=${newPPNID}`);
            const data = await response.json();
            if (data.StatusCode === 200) {
                const percentageFROMAPI = data.Data[0]?.PPNPercentage || 'No data';
                setselectedTax(percentageFROMAPI);
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch tax percentage !!');
                setShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch tax percentage : ', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const fetchNPWPData = async() => {
        try{
            const npwpData = await fetchNPWP();
            setFilteredPPN(npwpData);
            setselectedPPNs(npwpData[0]?.PPNType_id || '');
            setselectedTax(npwpData[0]?.PPNPercentage || '');
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to NPWP : ', error);
            setShowDialog(true);
        }
    }

    const handleProductOpenDialog = () => {
        setShowProductdialog(true);
    };

    const handleProductCloseDialog = () => {
        setShowProductdialog(false);
    };

    const fetchPONumber = async () => {
        setLoading(true);
        try{
            const response = await fetch(API_BASE_URL + 'purchase/recievelastPOnumber.php');
            const data = await response.json();
            if(data.StatusCode === 200){
                setPONumber(data.Data.PONumber);
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch PO Number');
                setErrorShowDialog(true);
            }
        } catch (e){
            setErrorCode('400');
            setErrorTitle('Error failed to fetch PO Number : ', e);
            setErrorShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const fetchPaymentData = async () => {
        setLoading(true);
        try {
            const paymentData = await fetchPayments();
            setFilteredPayments(paymentData);
            setselectedPayment(paymentData[0]?.Id || '');
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch payment data  : ', error);
            setErrorShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const fetchSupplierData = async (companyID) => {
        setLoading(true);
        try {
            const supplierData = await fetchLocalSuppliers(companyID);
            setFilteredSuppliers(supplierData);
            setselectedSupplier(supplierData[0]?.supplier_id || '');
            fetchPicName(supplierData[0]?.supplier_id); 
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch supplier data : ', error);
            setErrorShowDialog(true);
        } finally {
            setLoading(false);
        }
    }    

    const fetchPicName = async (supplierId) => {
        if (!supplierId) return;
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}master/supplier/getpicnamebasedonsupplier.php?supplier=${supplierId}`);
            const data = await response.json();
            if (data.StatusCode === 200) {
                const picNameFromAPI = data.Data[0]?.supplier_pic_name || 'No PIC assigned';
                setPicName(picNameFromAPI);
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch PIC Name');
                setErrorShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch PIC Name : ', error);
            setErrorShowDialog(true);
            setPicName('Failed to load PIC name');
        } finally{
            setLoading(false);
        }
    };
    
    const fetchCompanyData = async () => {
        setLoading(true);
        try {
            const companyData = await fetchCompanys();
            if (companyData && companyData.length > 0) { // Check if data is not empty
                setCompanyData(companyData);
            } else {
                setCompanyData([]);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch company data : ', error);
            setErrorShowDialog(true);
        } finally {
            setLoading(false);
        }
    }
    
    const handleSupplierChange = (event) => {
        const newSupplierId = event.target.value;
        if (newSupplierId !== selectedSupplier) {
            setselectedSupplier(newSupplierId);
            fetchPicName(newSupplierId); // Fetch new PIC name only if the supplier changes
        }
    };
    
    const handlePaymentChange = (event) => {
        setselectedPayment(event.target.value);
    };

    const handleNewClick = async (event) => {
        event.preventDefault(); 
        const productNameNull = items.some(item => !item.productname);
    
        if (productNameNull || items.length === 0) {
            setErrorCode('400');
            setErrorTitle('Error item cannot be 0 and product name cannot be null !!');
            setErrorShowDialog(true);
        } else {
            setLoading(true);
            try {
                const API = API_BASE_URL + 'purchase/insertpurchaselocal.php';
            
                let params = new URLSearchParams({
                    purchase_order_number: PONumber,
                    purchase_order_date: startDate,
                    purchase_order_supplier: selectedSupplier,
                    purchase_order_shipment: shippingDate,
                    purchase_order_payment: selectedPayment,
                    purchase_order_origin: '10',
                    purchase_order_status: 'd7ab6134-d157-11ee-8',
                    purchase_order_type: '741ead94-d157-11ee-8',
                    purchase_order_currency: '6044d37e-d147-11ee-8',
                    purchase_order_ppn: selectedPPN,
                    insert_by: username,
                    product_length: items.length
                });
            
                items.forEach((item, index) => {
                    params.append(`purchase_order_product_name_${index + 1}`, item.productname);
                    params.append(`purchase_order_product_quantity_${index + 1}`, item.quantity);
                    params.append(`purchase_order_product_packaging_size_${index + 1}`, item.packaging);
                    params.append(`purchase_order_product_unit_price_${index + 1}`, item.unitPrice);
                });
            
                const response = await fetch(API, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: params
                });

                if(response.status === 200){
                    navigate(`/purchaseIndex`);
                }

                if(response.status === 500){
                    setErrorCode('500');
                    setErrorTitle('Error failed to insert purchase local : PO Number has been used !!!');
                    setErrorShowDialog(true);
                }
            } catch (e) {
                setErrorCode('400');
                setErrorTitle('Error failed to insert purchase local : ', e);
                setErrorShowDialog(true);
            } finally {
                setLoading(false);
            }
            
        }
    }
    
    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleErrorCloseDialog = () => {
        setErrorShowDialog(false);
    };

    const errorDialogBox = <ErrorDialog onCloseDialog={handleErrorCloseDialog} errorCode={errorCode} errorMessage={errorTitle}/>
    const dialogBox = <SuccessDialog onCloseDialog={handleCloseDialog} successMessage={"Purchase Order Local has been successfully inputted to systems"}/>;

    const handleSelectPurchaseOrder = (purchaseOrderData) => {
        const itemsData = purchaseOrderData.Data;
        let nonNullItems = [];
        let nonNullCount = 0;
        let nextId = 1; 
    
        for (let i = 1; i <= 5; i++) {
            const itemsKey = `Items ${i}`;
            const itemsArray = itemsData[itemsKey];
            const allNonNull = itemsArray.every(item => Object.values(item).every(value => value !== null));
    
            if (allNonNull) {
                nonNullCount++;
                itemsArray.forEach(item => {
                    nonNullItems.push({
                        id: nextId++,
                        label: `Item #${nextId - 1}`,
                        productname: item[`Product Name${i}`],
                        quantity: Number(item[`POQuantity${i}`]),
                        sat: 0, // Assuming SAT refers to packaging size
                        currency: '', // Assuming currency, needs adjustment based on actual data
                        harga: 0,
                        total: 0, // Computed later in SalesOrderTemplate
                        kurs: 0, // Assuming this needs to be set, or calculate if needed
                        dpp: 0, // Same as above
                        ppn: 0  // Same as above
                    });
                });
            }
        }

        setItems(nonNullItems);
        handleProductCloseDialog();
    };

    return(
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            {showProductdialog && <ListProduct onCloseDialog={handleProductCloseDialog}  onSelectPurchaseOrder={handleSelectPurchaseOrder}/>}
            <div className='MainMenu'>
                {showDialog && dialogBox}
                <ProfilSearchBox username={username}/>
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Purchase Module</div>
                </div>
                {ErrorShowDialog && errorDialogBox}
                {loading ? <Loader/> : (
                    <div>
                        <div className='card-for-grid'>
                            <div className='title-for-card'>Purchase Order Local</div>
                            {/* First Row included order number, date */}
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Order Number"}/>
                                    {/* <TextBox value={"VIK/24/II/0001"}/> */}
                                    <TextBox value={PONumber} onChange={(e) => setPONumber(e.target.value)}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"To"}/>
                                    <select className='new-form-dropdown' value={selectedSupplier} onChange={handleSupplierChange}>
                                        {filteredSuppliers.map((supplier) => (
                                            <option key={supplier.supplier_id} value={supplier.supplier_id}>
                                                {supplier['Supplier']}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Date"}/>
                                    <DatePicker 
                                        className='date-picker' 
                                        selected={startDate} 
                                        onChange={(date) => setStartDate(date)} 
                                        dateFormat="dd MMMM yyyy"
                                    />
                                </div>
                            </div>
                            {/* Second Row included Attn, Shipment, and Payment */}
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Attn"}/>
                                    <div className='autofill-form'>{picName}</div>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Shipment"}/>
                                    <DatePicker 
                                        className='date-picker' 
                                        selected={shippingDate} 
                                        onChange={(date) => setShippingDate(date)} 
                                        dateFormat="dd MMMM yyyy"
                                    />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Payment"}/>
                                    <select className='new-form-dropdown' value={selectedPayment} onChange={handlePaymentChange}>
                                        {filteredPayments.map((payment) => (
                                            <option key={payment.Id} value={payment.Id}>
                                                {payment['Payment Method']}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"PPN / No. PPN"}/>
                                    <select className='new-form-dropdown' value={selectedPPN} onChange={handleNPWPChange}>
                                        {filteredPPN.map((ppn) => (
                                            <option key={ppn.PPNType_id} value={ppn.PPNType_id}>
                                                {ppn['PPNType_name Name']}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="only-100-width"></div>
                                <div className="only-100-width"></div>
                            </div>
                            <PurchaseLocalTemplate items={items} setItems={setItems} selectedTax={selectedTax}/>
                            {/* Third Row included Invoice Under and Delivery Address */}
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Invoice Under"}/>
                                    <div className='company-data-purchase'>
                                        {companyData.length > 0 ? companyData[0].company_name : 'Loading...'}
                                    </div>

                                    <div className='company-data-purchase'>
                                        {companyData.length > 0 ? companyData[0].company_address : 'Loading...'}
                                    </div>

                                </div>
                                <div className="only-100-width">
                                    
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Delivery Address"}/>
                                    <TextArea placeholder="Remarks" type="text" maxLines={5} value={"Pergudangan Taman Tekno\nSektor XI Blok K3 No. 47, BSD - Tangerang\nTelp : 02175880017"}/>
                                </div>
                            </div>
                        </div>
                        <div className='card-for-grid'>
                            <div className='title-for-card'>Document</div>
                        </div>
                        {/* Action Button Grid */}
                        <div className='grid-container-with-padding-left'>
                            {/* <div className="only-100-width-card-reject" onClick={handleUploadPDF}>Upload PDF</div> */}
                            <div className="only-100-width-card-approve" onClick={handleNewClick}>Submit</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NewPurchaseLocal;
