import React from 'react';

function TwoCardGrid({ headline, data, seeAllLink, detailLinks }){

    const handleSeeAllClick = (link) => {
        console.log(link);
    }

    const handleDetailClick = (link) => {
        console.log(link);
    }

    return (
        <div className='card-for-grid-divide-two'>
            <div className='grid-container'>
                <div className="card-headline">{headline}</div>
                <div className='see-all-text'>see all</div>
            </div>
            {data.map((item, index) => (
                <React.Fragment key={index}>
                <div className='grid-container'>
                    <div className='column-container'>
                        <div className='list-title'>{item.companyName}</div>
                        <div className='list-desc'>{item.orderDate}</div>
                    </div>
                    <div className='detail-card' onClick={() => handleDetailClick(detailLinks[index])}>Detail</div>
                </div>
                </React.Fragment>
            ))}
        </div>
    );
}

export default TwoCardGrid;