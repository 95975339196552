import React, { useState, useEffect } from 'react';
import FormLabel from "../../component/FormLabel";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import PurchaseOrderTemplate from "../../component/form/PurchaseOrderTemplate";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseActive from "../../component/menu/PurchaseActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import TextBox from '../../component/TextBox';
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL, formatCurrency, formatDate } from '../../../App';
import Loader from '../../component/Loader';
import ErrorDialog from '../../component/dialog/ErrorDialog';

function NewPurchasingInvoice(){
    const navigate = useNavigate();
    let { PONumber } = useParams();
    const { username } = useAuthSession();
    const [VendorName, setVendorName] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [detailReceiveItems, setDetailReceiveItems] = useState(null);
    const [loading, setLoading] = useState(false);
    const [kurs, setKurs] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [quantities, setQuantities] = useState([]);
    const [hargasatuan, setHargaSatuan] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (PONumber != null && PONumber !== '') {
                setLoading(true);
                try {
                    const response = await fetch(`${API_BASE_URL}purchase/receivingitems/getdetailreceiveitems.php?PONumber=${PONumber}`);
                    const data = await response.json();
    
                    if (data.StatusCode === 200) {
                        setDetailReceiveItems(data.Data);

                        const initialQuantities = data.Data.Items.map(item => item.Quantity);
                        setQuantities(initialQuantities);

                        const initialHargaSatuan = data.Data.Items.map(item => item.UnitPrice);
                        setHargaSatuan(initialHargaSatuan);
                    } else {
                        setErrorCode('400');
                        setErrorTitle('Error failed to fetch detail receiving items data');
                        setShowDialog(true);
                    }
                } catch (error) {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail receiving items data : ', error);
                    setShowDialog(true);
                } finally {
                    setLoading(false);
                }
            }
        };
    
        fetchData();
    }, [PONumber]);

    const saveInvoice = async () => {
        if(invoiceNumber === null || invoiceNumber === ''){
            setErrorCode('400');
            setErrorTitle('Error invoice number cannot be null');
            setShowDialog(true);
        } else {
            try {
                setLoading(true);
                const API = API_BASE_URL + 'purchase/invoice/insertinvoice.php';
            
                const Supplier = detailReceiveItems.Details[0].supplier_id;
                const InvoiceDate = detailReceiveItems.Details[0].ShipDate;
                let TermID = detailReceiveItems.Details[0].term_id;

                if (TermID === null || TermID === '') {
                    TermID = 'f1a97d1d-185d-11ef-9';
                } 
                
                if (kurs === null || kurs === ''){
                    setKurs('000');
                }

                let params = new URLSearchParams({
                    purchase_order_number: PONumber,
                    purchase_order_supplier: Supplier,
                    invoice_number: invoiceNumber,
                    invoice_date: InvoiceDate,
                    ship_date: InvoiceDate,
                    kurs: kurs, 
                    term: TermID,
                    username: username,
                    total_amount: totalDPP + totalPPN,
                    insert_by: username,
                    product_length: detailReceiveItems.Items.length
                });
            
                detailReceiveItems.Items.forEach((item, index) => {
                    params.append(`purchase_order_product_name_${index + 1}`, item.ProductName);
                    params.append(`purchase_order_product_quantity_${index + 1}`, quantities[index]);
                    params.append(`purchase_order_product_packaging_size_${index + 1}`, item.PackagingSize);
                    params.append(`purchase_order_product_unit_price_${index + 1}`, hargasatuan[index]);
                });
            
                const response = await fetch(API, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: params
                });
            
                if (response.status === 200) {
                    navigate(`/purchaseIndex`);
                } else {
                    const errorData = await response.json();
                    setErrorCode('400');
                    setErrorTitle('Error failed to insert new purchase invoice' + response.message);
                    setShowDialog(true);
                }
            
            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error failed to insert new purchase invoice : ', error);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }

            
        }
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleQuantityChange = (e, index) => {
        const value = e.target.value;
        setQuantities(prevQuantities => {
            const newQuantities = [...prevQuantities];
            newQuantities[index] = value;
            return newQuantities;
        });
    };

    const handleHargaSatuanChange = (e, index) => {
        const value = e.target.value;
        setHargaSatuan(prevHargaSatuan => {
            const newHargaSatuan = [...prevHargaSatuan];
            newHargaSatuan[index] = value;
            return newHargaSatuan;
        })
    };

    const DPPCountW = (harga, quantity) => {
        const DPPResult = harga * quantity;
        return DPPResult;
    };

    const PPNCountW = (harga, quantity) => {
        const PPNResult = (harga * quantity) * (detailReceiveItems.Details[0].PPNPercentage / 100);
        return PPNResult;
    };

    let totalDPP = 0;
    let totalPPN = 0;
    let totalHargaSatuan = 0;
    
    if (detailReceiveItems) {
        detailReceiveItems.Items.forEach((item, index) => {
            const DPP = DPPCountW(hargasatuan[index], quantities[index]);
            const PPN = PPNCountW(hargasatuan[index], quantities[index]);
            totalDPP += parseFloat(DPP);
            totalPPN += parseFloat(PPN);
            totalHargaSatuan += parseFloat(hargasatuan[index]);
        });
    }

    return(
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Purchase Module</div>
                </div>
                {loading ? (<Loader/>) : (
                    detailReceiveItems ? (
                    <div>
                    <div className='card-for-grid'>
                        <div className='title-for-card'>Purchase Order Invoice</div>
                        <div className='grid-container'>
                            <div className="only-100-width">
                                <FormLabel labelName={"Vendor"}/>
                                {detailReceiveItems ? (
                                    <TextBox value={detailReceiveItems.Details[0].supplier_name} />
                                ) : (
                                    <TextBox value={VendorName} readonly/>
                                )}
                            </div>
                            <div className="only-100-width">
                                <FormLabel labelName={"PO Number"}/>
                                {PONumber != null && PONumber !== '' ? 
                                    <TextBox value={PONumber}/> 
                                : 
                                    <div>Select PO Number</div>
                                }
                            </div>
                            <div className="only-100-width">
                                <FormLabel labelName={"Invoice Number"}/>
                                <TextBox placeholder={"Insert your invoice number"} value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)} />
                            </div>
                        </div>
                        <div className='grid-container'>
                            <div className="only-100-width">
                                <FormLabel labelName={"Invoice Date"}/>
                                {detailReceiveItems ? (
                                    <TextBox value={formatDate(detailReceiveItems.Details[0].ShipDate)} />
                                ) : (
                                    <TextBox value={VendorName} readonly/>
                                )}
                            </div>
                            <div className="only-100-width">
                                <FormLabel labelName={"Ship Date"}/>
                                {detailReceiveItems ? (
                                    <TextBox value={formatDate(detailReceiveItems.Details[0].ShipDate)} />
                                ) : (
                                    <TextBox value={VendorName} readonly/>
                                )}
                            </div>
                            <div className="only-100-width">
                                <FormLabel labelName={"Kurs"}/>
                                <TextBox placeholder={"Input your kurs"} value={kurs} onChange={(e) => setKurs(e.target.value)}/>
                            </div>
                        </div>
                        <div className='grid-container'>
                            <div className="only-100-width">
                                <FormLabel labelName={"Term"}/>
                                {detailReceiveItems ? (
                                    <TextBox value={detailReceiveItems.Details[0].term_name ? detailReceiveItems.Details[0].term_name : '-'}/>
                                ) : (
                                    <TextBox value={VendorName} readonly/>
                                )}
                                
                            </div>
                            <div className="only-100-width">
                            </div>
                            <div className="only-100-width">
                                
                            </div>
                        </div>
                        {detailReceiveItems.Items.map((item, index) => (
                            <div key={index}>
                                <div className='ItemNameTemplateForm'>
                                    <FormLabel labelName={`Item #${index + 1}`} />
                                </div>
                                <div className="grid-container">
                                    <div className="only-100-width">
                                        <FormLabel labelName={`Product Name`}/>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={item.ProductName} readOnly />
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={`Quantity (Kg)`}/>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={quantities[index]} onChange={(e) => handleQuantityChange(e, index)}/>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={`Packaging Size (Kg)`}/>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={item.PackagingSize}/>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={`Unit Price ${detailReceiveItems ? `(${detailReceiveItems.Details[0].currency_name})` : 'IDR'}`}/>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={hargasatuan[index]} onChange={(e) => handleHargaSatuanChange(e, index)}/>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={`Total ${detailReceiveItems ? `(${detailReceiveItems.Details[0].currency_name})` : 'IDR'}`}/>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={formatCurrency(quantities[index] * hargasatuan[index], detailReceiveItems ? detailReceiveItems.Details[0].currency_name : 'IDR')}/>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {detailReceiveItems.Details[0].POType === '741ead94-d157-11ee-8' ? (
                                <div className='grid-container'>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"VAT (11%)"}/>
                                    </div>
                                    <div className="only-100-width">
                                        <TextBox value={formatCurrency(totalPPN, detailReceiveItems ? detailReceiveItems.Details[0].currency_name : 'IDR')}/>
                                    </div>
                                </div>) 
                        : (<div></div>)}
                        {detailReceiveItems.Details[0].POType === '741ead94-d157-11ee-8' ? (
                                <div className='grid-container'>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Total"}/>
                                    </div>
                                    <div className="only-100-width">
                                        <TextBox value={formatCurrency(totalDPP + totalPPN, detailReceiveItems ? detailReceiveItems.Details[0].currency_name : 'IDR')}/>
                                    </div>
                                </div>) 
                            : (<div className='grid-container'>
                                <div className="only-100-width"></div>
                                <div className="only-100-width"></div>
                                <div className="only-100-width"></div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"VAT (11%)"}/>
                                </div>
                                <div className="only-100-width">
                                    <TextBox value={formatCurrency(totalDPP, detailReceiveItems ? detailReceiveItems.Details[0].currency_name : 'IDR')}/>
                                </div>
                            </div>)}             
                        </div>
                        <div className='grid-container-with-padding-left'>
                            <div className="only-100-width-card-submit" onClick={() => saveInvoice()}>Save</div>
                        </div>
                    </div>
                ) :(<div></div>)
                )}
            </div>
        </div>
    );
}

export default NewPurchasingInvoice;