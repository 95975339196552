import React, { useState, useEffect } from 'react';
import DashboardNonActive from '../../component/menu/DashboardNonActive';
import '../../component/Component.css';
import Logout from '../../component/menu/Logout';
import ProfilSearchBox from '../../component/ProfilSearchBox';
import PurchaseNonActive from '../../component/menu/PurchaseNonActive';
import SalesActive from '../../component/menu/SalesActive';
import OneCardGrid from '../../component/card/OneCardGrid';
import SettingNonActive from '../../component/menu/SettingNonActive';
import { useNavigate } from 'react-router-dom';
import NewSalesDialog from '../../component/menu/NewSalesDialogBox';
import useAuthSession from '../../services/Session';
import FinanceNonActive from '../../component/menu/FinanceNonActive';
import WarehouseNonActive from '../../component/menu/WarehouseNonActive';
import HRNonActive from '../../component/menu/HRNonActive';
import AnalyticsnonActive from '../../component/menu/AnalyticsNonActive';
import DocumentnonActive from '../../component/menu/DocumentNonActive';
import { API_BASE_URL, formatCurrencyIDR, formatDate, formatDateTime } from '../../../App';
import Loader from '../../component/Loader';
import SalesCardDashbord from '../../component/card/SalesCardDashboard';
import ErrorDialog from '../../component/dialog/ErrorDialog';
import NotFoundPage from '../../component/maintanance/NotFound';

function SalesIndex(){
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(false);
    const { username } = useAuthSession();
    const [salesOrder, setSalesOrder] = useState([]);
    const [salesSPPB, setSalesSPPB] = useState([]);
    const [salesProfit, setSalesProfit] = useState([]);
    const [salesDelivery, setSalesDelivery] = useState([]);
    const [salesInvoice, setSalesInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const [index, setIndex] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);  
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const permissionAccess = localStorage.getItem('permissionAccess');
    
    useEffect(() => {
        const fetchSalesOrder = async () => {
            setLoading(true);
            try {
                const response = await fetch(API_BASE_URL +  'sales/gettopsalesorder.php');
                const data = await response.json();
                if (data.Data.length === 0) {
                    setSalesOrder(['-']);
                } else {
                    setSalesOrder(data.Data); 
                }
            } catch (error) {
                
            } finally {
                setLoading(false);
            }
        };

        const fetchSalesSPPB = async () => {
            setLoading(true);
            try {
                const response = await fetch(API_BASE_URL +  'sales/gettopsppb.php');
                const data = await response.json();
                if (data.Data.length === 0) {
                    setSalesSPPB(['-']);
                } else {
                    setSalesSPPB(data.Data); 
                }
            } catch (error) {
                
            } finally {
                setLoading(false);
            }
        };

        const fetchSalesProfit = async () => {
            setLoading(true);
            try {
                const response = await fetch(API_BASE_URL +  'sales/gettopprofit.php');
                const data = await response.json();
                if (data.Data.length === 0) {
                    setSalesProfit(['-']);
                } else {
                    setSalesProfit(data.Data); 
                }
            } catch (error) {
                
            } finally {
                setLoading(false);
            }
        };

        const fetchStatistic = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}master/getoveralldashboard.php`);
                if (!response.ok) {
                    throw new Error('Failed to fetch sales order data');
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setIndex(data.Data);
                } else {
                    setErrorCode('404');
                    setErrorTitle('Failed to fetch data');
                    setShowErrorDialog(true);
                }
            } catch (error) {
                
            } finally {
                setLoading(false);
            }
        };

        const fetchDelivery= async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}sales/gettopdeliveryorder.php`);
                if (!response.ok) {
                    throw new Error('Failed to fetch sales order data');
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setSalesDelivery(data.Data);
                } else {
                    setErrorCode('404');
                    setErrorTitle('Failed to fetch data');
                    setShowErrorDialog(true);
                }
            } catch (error) {
                
            } finally {
                setLoading(false);
            }
        };

        const fetchSalesInvoice = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}sales/gettopsalesinvoice.php`);
                if (!response.ok) {
                    throw new Error('Failed to fetch sales order data');
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setSalesInvoice(data.Data);
                } else {
                    setErrorCode('404');
                    setErrorTitle('Failed to fetch data');
                    setShowErrorDialog(true);
                }
            } catch (error) {
                
            } finally {
                setLoading(false);
            }
        };

        fetchStatistic();
        fetchSalesOrder();
        fetchSalesSPPB();
        fetchSalesProfit();
        fetchDelivery();
        fetchSalesInvoice();
    }, [])

    const handleNewClick = (event) => {
        navigate('/salesIndex/New/newSalesOrder');
        event.preventDefault(); 
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const dialogBox = <NewSalesDialog onCloseDialog={handleCloseDialog}/>;

    const handleCloseErrorDialog = () => {
        setShowErrorDialog(false);
    };

    return (
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesActive/>
                <PurchaseNonActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                {showDialog && dialogBox}
                <ProfilSearchBox username={username}/>
                {showErrorDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseErrorDialog} />}
                {permissionAccess === 'Full access' || permissionAccess === 'Sales only' ? (
                    <div>
                        {loading ? (<Loader/>) : (
                    <div>
                        <div className='grid-container-smaller'>
                            <div className='MenuName'>Sales Module</div>
                            <div className='create-new-button' onClick={handleNewClick}>New Sales Order</div>
                        </div>
                        <SalesCardDashbord 
                            headline="Overview"
                            title1="Target"
                            content1={formatCurrencyIDR(index.total_target)}
                            title2="Total Sales"
                            content2={formatCurrencyIDR(index.total_sales)}
                        />
                        {/* Space Between */}
                        <div className='space-between'/>
                         {/* Sales Order List */}
                         <OneCardGrid
                            headline="Sales Order"
                            data={salesOrder.map(order => ({
                                companyName: order.company_name,
                                orderDate: formatDate(order.SODate),
                                PONumber: order.SONumber
                            }))}
                            type="Sales"
                        />
                        {/* Space Between */}
                        <div className='space-between'/>
                        <OneCardGrid
                            headline="SPPB"
                            data={salesSPPB.map(sppb => ({
                                companyName: sppb.company_name,
                                orderDate: formatDate(sppb.SPPBDate),
                                PONumber: sppb.SPPBNumber
                            }))}
                            type="SPPB"
                        />
                        {/* Space Between */}
                        <div className='space-between'/>
                        {/* SPPB List */}
                        <OneCardGrid
                            headline="Profit"
                            data={salesProfit.map(profit => ({
                                companyName: profit.company_name,
                                orderDate: formatDate(profit.InsertDt),
                                PONumber: profit.SalesNumber
                            }))}
                            type="Profit"
                        />
                        {/* Space Between */}
                        <div className='space-between'/>
                        {/* Profit List */}
                        <OneCardGrid
                            headline="Delivery Order"
                            data={salesDelivery.map(delivery => ({
                                companyName: delivery.company_name,
                                orderDate: formatDate(delivery.DeliveryDate),
                                PONumber: delivery.DONumber
                            }))}
                            type="Delivery"
                        />
                        <div className='space-between'></div>
                        {/* Space Between */}
                        {/* Profit List */}
                        <OneCardGrid
                            headline="Sales Invoice"
                            data={salesInvoice.map(invoice => ({
                                companyName: invoice.company_name,
                                orderDate: formatDate(invoice.invoiceDate),
                                PONumber: invoice.invoiceNumber
                            }))}
                            type="Sales Invoice"
                        />
                        {/* <div className='space-between'></div> */}
                        {/* <OneCardGrid
                            headline="Customer Outstanding"
                            data={salesInvoice.map(invoice => ({
                                companyName: invoice.company_name,
                                orderDate: formatDate(invoice.invoiceDate),
                                PONumber: invoice.invoiceNumber
                            }))}
                            type="Sales Invoice"
                        /> */}
                    </div>
                )}
                    </div>
                ) :(
                    <div>
                        <div className='grid-container-smaller'>
                            <div className='MenuName'>Sales Module</div>
                        </div>
                        <NotFoundPage menuName={'Sales'}/>
                    </div>
                )}
            </div>
        </div>
    );
} 

export default SalesIndex;