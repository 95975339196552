import FinanceNonActiveIcon from '../../../assets/Icon/FinanceInactive.png';
import { useNavigate } from 'react-router-dom';

function FinanceNonActive(){
    const navigate = useNavigate();

    const handleHomepage = (event) => {
        navigate('/financeIndex'); 
    };

    return (
        <div className="MenuContainerNonActive" onClick={handleHomepage}>
            <img src={FinanceNonActiveIcon} />
            <div className='MenuNonActive'>Finance</div>
        </div>
    );
}

export default FinanceNonActive;