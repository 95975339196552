import { API_BASE_URL } from "../../App";

async function fetchLocalSuppliers(companyId) {
    try {
        const response = await fetch(`${API_BASE_URL}master/supplier/getalllocalsupplier.php?company=${companyId}`);
        const data = await response.json();
        if (data.StatusCode === 200) {
            return data.Data;
        } else {
            throw new Error('Failed to fetch suppliers');
        }
    } catch (error) {
        throw new Error('Error fetching suppliers:', error);
    }
}

async function fetchImportSuppliers(companyId) {
    try {
        const response = await fetch(`${API_BASE_URL}master/supplier/getallimportsupplier.php?company=${companyId}`);
        const data = await response.json();
        if (data.StatusCode === 200) {
            return data.Data;
        } else {
            throw new Error('Failed to fetch suppliers');
        }
    } catch (error) {
        throw new Error('Error fetching suppliers:', error);
    }
}

async function fetchPayments() {
    try {
        const response = await fetch(`${API_BASE_URL}master/payment/getallpayment.php`);
        const data = await response.json();
        if (data.StatusCode === 200) {
            return data.Data;
        } else {
            throw new Error('Failed to fetch payments');
        }
    } catch (error) {
        throw new Error('Error fetching payments:', error);
    }
}

async function fetchShippings() {
    try {
        const response = await fetch(`${API_BASE_URL}master/shipping/getallshipping.php`);
        const data = await response.json();
        if (data.StatusCode === 200) {
            return data.Data;
        } else {
            throw new Error('Failed to fetch shippings');
        }
    } catch (error) {
        throw new Error('Error fetching shippings:', error);
    }
}

async function fetchCompanys(){
    try {
        const response = await fetch(`${API_BASE_URL}company/companyData/getdetailcompany.php?company_id=1252f67e-bfda-11ee-9dcf-0e799759a249`);
        console.log(response);
        const data = await response.json();
        if (data.StatusCode === 200) {
            return data.Data;
        } else {
            throw new Error('Failed to fetch company data');
        }
    } catch (error) {
        throw new Error('Error fetching company data:', error);
    }
}

export {fetchImportSuppliers, fetchLocalSuppliers, fetchPayments, fetchShippings, fetchCompanys};