import { saveAs } from 'file-saver';
import XLSX from 'sheetjs-style';

const SOgenerateExcel = (data) => {
    // Create a new workbook and a worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
        ['SALES ORDER'],
        ['No SO:', data.noSO, '', 'Customer:', data.customer],
        ['Tanggal:', data.date, '', 'ALAMAT:', data.address],
        ['NPWP:', data.ppnNoPpn, '', 'PO NO:', data.poNo],
        ['NO', 'BARANG', 'QTY', 'SAT', 'CURR', 'HARGA @', 'TOTAL', 'KURS', 'DPP', 'PPN'],
        ...data.items.map((item, index) => [
            index + 1,
            item.barang,
            item.qty,
            item.sat,
            item.curr,
            item.harga,
            item.total,
            item.kurs,
            item.dpp,
            item.ppn,
        ]),
        [],
        ['TOP:', `${data.top} Hari`],
        [],
        ['', '', '', '', '', 'Total:', data.total, 0, data.dppTotal, data.ppnTotal],
        [],
        ['DIKIRIM Tgl:', data.dikirimTgl],
        ['DIKIRIM KE:', data.dikirimKe],
        [],
        ['DIBUAT OLEH,', '', '', 'DISETUJUI OLEH,'],
        [data.dibuatOleh, '', '', data.disetujuiOleh],
        [],
        ['( AFIFAH )', '', '', '( SULANTO )', '', '( IRENE )'],
    ]);

    // Set the width of the columns
    ws['!cols'] = [
        { wch: 10 }, // NO
        { wch: 30 }, // BARANG
        { wch: 10 }, // QTY
        { wch: 10 }, // SAT
        { wch: 10 }, // CURR
        { wch: 15 }, // HARGA @
        { wch: 15 }, // TOTAL
        { wch: 10 }, // KURS
        { wch: 15 }, // DPP
        { wch: 15 }, // PPN
    ];

    const cleanSheetName = `SO${data.noSO}`.replace(/[^a-zA-Z0-9]/g, '');
    XLSX.utils.book_append_sheet(wb, ws, `SO`);

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `SO/${cleanSheetName}.xlsx`);
};

export default SOgenerateExcel;
