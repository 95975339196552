import { useState } from "react";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import AnalyticsNonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentNonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseActive from "../../component/menu/WarehouseActive";
import useAuthSession from "../../services/Session";
import Loader from "../../component/Loader";
import FormLabel from "../../component/FormLabel";
import TextBox from "../../component/TextBox";
import { API_BASE_URL, formatDate } from "../../../App";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import WarehouseExportExcelProductCodeBased from "../../services/WarehouseExcelExportPCBased";
import ErrorDialog from "../../component/dialog/ErrorDialog";

function FindStock() {
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [kodeProduk, setKodeProduk] = useState('');
    const [lot, setLot] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [lotSuggestions, setLotSuggestions] = useState([]);
    const [showLotSuggestions, setLotShowSuggestions] = useState(false);
    const [data, setData] = useState(null);

    const handleKodeProdukChange = async (e) => {
        const value = e.target.value;
        setKodeProduk(value);

        if (value.length > 0) {
            try {
                const response = await fetch(`${API_BASE_URL}warehouse/productquery.php?find=${value}`);
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setSuggestions(data.products);
                    setShowSuggestions(true);
                } else {
                    setSuggestions([]);
                    setShowSuggestions(false);
                }
            } catch (error) {
                console.error("Error fetching product suggestions:", error);
                setSuggestions([]);
                setShowSuggestions(false);
            }
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    };

    const handleLotChange = async (e) => {
        const value = e.target.value;
        setLot(value);

        if(value.length > 0) {
            try {
                const response = await fetch(`${API_BASE_URL}warehouse/lotquery.php?find=${value}`);
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setLotSuggestions(data.products);
                    setLotShowSuggestions(true);
                } else {
                    setLotSuggestions([]);
                    setLotShowSuggestions(false);
                }
            } catch (error) {
                console.error("Error fetching product suggestions:", error);
                setLotSuggestions([]);
                setLotShowSuggestions(false);
            }
        } else {
            setLotSuggestions([]);
            setLotShowSuggestions(false);
        }
    }

    const handleSuggestionClick = (suggestion) => {
        setKodeProduk(suggestion.skuID);
        setShowSuggestions(false);
    };

    const handleLotSuggestionClick = (suggestion) => {
        setLot(suggestion.lot);
        setKodeProduk(suggestion.skuID);
        setLotShowSuggestions(false);
    };

    const handleSearch = async () => {
        setLoading(true);
        setData(null);
        try {
            // const response = await fetch(`${API_BASE_URL}warehouse/getalltransaction.php?kodeBarang=${kodeProduk}`);
            let response;
            if (lot) {
                response = await fetch(`${API_BASE_URL}warehouse/gettransactionbylot.php?lot=${lot}`);
            } else if (kodeProduk) {
                response = await fetch(`${API_BASE_URL}warehouse/getalltransaction.php?kodeBarang=${kodeProduk}`);
            }
            const result = await response.json();
            if (result.StatusCode === 200) {
                setData(result.Data);
            } else {
                setData(null);
                alert(result.Message);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            alert("Error fetching data");
        } finally {
            setLoading(false);
        }
    };

    const handleExport = () => {
        WarehouseExportExcelProductCodeBased({ excelData: data, fileName: `Transaksi_${kodeProduk}` });
    };

    return (
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive />
                <SalesNonActive />
                <PurchaseNonActive />
                <FinanceNonActive />
                <WarehouseActive />
                <HRNonActive />
                <AnalyticsNonActive />
                <DocumentNonActive />
                <SettingNonActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                <div className='MenuName'>Cari Stock Produk</div>
                <div className='grid-container-smaller'>
                    <div className='FilterDate'>
                        <div className="only-100-width">
                            <FormLabel labelName={"Kode Produk"} />
                            <div className='space-between-1vh'></div>
                            <TextBox placeholder={"Masukkan kode produk"} value={kodeProduk} onChange={handleKodeProdukChange} />
                            {showSuggestions && (
                                <ul className="suggestions-list">
                                    {suggestions.map((suggestion, index) => (
                                        <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                            {suggestion.skuID} - {suggestion.productName}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className="only-100-width">
                            <FormLabel labelName={"LOT"} />
                            <div className='space-between-1vh'></div>
                            <TextBox placeholder={"Masukkan nomor LOT"} value={lot} onChange={handleLotChange} />
                            {showLotSuggestions && (
                                <ul className="suggestions-list">
                                    {lotSuggestions.map((lotSuggestions, index) => (
                                        <li key={index} onClick={() => handleLotSuggestionClick(lotSuggestions)}>
                                            {lotSuggestions.lot} - {lotSuggestions.productName}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <button className='seeAllDetailCard' onClick={handleSearch}>Cari</button>
                    </div>
                </div>
                {loading ? <Loader /> : data ? (
                    <div>
                        {lot ? (
                            <div className='seeAllCard'>
                                <div className='space-between'></div>
                                <div className="title-for-card-2">Lot: {data['Nomor Lot']}</div>
                                <div className="title-for-card-2">Kode Produk: {data['Kode Barang']}</div>
                                <div className="title-for-card-2">Nama Produk: {data['Nama Barang']}</div>
                                <div className='space-between'></div>
                                <table className='table-layout'>
                                    <thead>
                                        <tr>
                                            <th>Tanggal</th>
                                            <th>Keterangan</th>
                                            <th>Masuk</th>
                                            <th>Keluar</th>
                                            <th>Sisa</th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-content'>
                                        {data.Transaksi.map((transaction, index) => (
                                            <tr key={index}>
                                                <td>{format(new Date(transaction['Tanggal Transaksi']), 'd MMMM yyyy', { locale: id })}</td>
                                                <td>{transaction['Keterangan/Customer']}</td>
                                                <td>{transaction['Jumlah Barang Masuk']}</td>
                                                <td>{transaction['Jumlah Barang Keluar']}</td>
                                                <td>{transaction['Sisa Barang']}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='space-between'></div>
                            </div>
                        ) : (
                            <div className='seeAllCard'>
                                <div className='space-between'></div>
                                <div className="title-for-card-2">Kode Produk: {data['Kode Barang']}</div>
                                <div className="title-for-card-2">Nama Produk: {data['Nama Barang']}</div>
                                <div className='space-between'></div>
                                <table className='table-layout'>
                                    <thead>
                                        <tr>
                                            <th>Tanggal</th>
                                            <th>Keterangan</th>
                                            <th>Masuk</th>
                                            <th>Keluar</th>
                                            <th>Sisa</th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-content'>
                                        {data.Transaksi.map((transaction, index) => (
                                            <tr key={index}>
                                                <td>{format(new Date(transaction['Tanggal Transaksi']), 'd MMMM yyyy', { locale: id })}</td>
                                                <td>{transaction['Keterangan/Customer']}</td>
                                                <td>{transaction['Jumlah Barang Masuk']}</td>
                                                <td>{transaction['Jumlah Barang Keluar']}</td>
                                                <td>{transaction['Sisa Barang']}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='space-between'></div>
                            </div>
                        )}
                        <div className='grid-container-with-padding-left'>
                            <div className="only-100-width-card-reject" onClick={handleExport}>Export ke Excel</div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className='UnderConstructionCaption'>Silakan masukkan kode produk atau nomor LOT dan klik "Cari"</div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default FindStock;
