import HRNonActiveIcon from '../../../assets/Icon/HRActive.png';
import { useNavigate } from 'react-router-dom';

function HRActive(){
    const navigate = useNavigate();

    const handleHomepage = (event) => {
        navigate('/hrIndex'); 
    };

    return (
        <div className="MenuContainer" onClick={handleHomepage}>
            <img src={HRNonActiveIcon} />
            <div className='MenuActive'>HR</div>
        </div>
    );
}

export default HRActive;