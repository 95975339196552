import { useNavigate, useSearchParams } from "react-router-dom";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import SalesActive from "../../component/menu/SalesActive";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import Logout from "../../component/menu/Logout";
import useAuthSession from "../../services/Session";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import { useEffect, useState } from "react";
import Loader from "../../component/Loader";
import FormLabel from "../../component/FormLabel";
import { API_BASE_URL, formatDate, formatDateTime } from "../../../App";
import TextBox from "../../component/TextBox";
import TextArea from "../../component/TextArea";
import ErrorDialog from "../../component/dialog/ErrorDialog";
import ReactDatePicker from "react-datepicker";
import SPPBgenerateExcel from "../../services/SPPBExcelServices";

function DetailSPPB(){
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [searchParams] = useSearchParams();
    const SPPBNumber = searchParams.get('SPPBNumber');
    const [detailSPPB, setDetailSPPB] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [sppbDate, setSppbDate] = useState(new Date());
    const [quantities, setQuantities] = useState([]);
    const [sendDates, setSendDates] = useState([]);
    const [sendTo, setSendTo] = useState([]);
    const permissionAccess = localStorage.getItem('permissionAccess');

    useEffect(() => {
        const fetchSPPBDetail = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}sales/getdetailsppb.php?SPPBNumber=${SPPBNumber}`);
                if (!response.ok) {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sppb data');
                    setShowDialog(true);
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setDetailSPPB(data.Data);
                    setSppbDate(new Date(data.Data.Details[0].SPPBDate));

                    const initialQuantities = data.Data.Items.map(item => item.Quantity);
                    setQuantities(initialQuantities);

                    const initialSendDates = data.Data.Items.map(item => item.SendDate);
                    setSendDates(initialSendDates);

                    const initialSendTo = data.Data.Items.map(item => item.SendTo);
                    setSendTo(initialSendTo);
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sppb data');
                    setShowDialog(true);
                }
                
            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail sppb data', error.message);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        if (SPPBNumber) {
            fetchSPPBDetail();
        }
    }, [SPPBNumber]);

    const handleApproveAction = async() => {
        setLoading(true);

        try{
            const APIupdate = API_BASE_URL + 'sales/approvesppb.php';
            const response = await fetch(APIupdate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    sppb_number: detailSPPB.Details[0].SPPBNumber,
                    update_by: username
                }),
            });

            if (!response.ok) {
                setErrorCode('400');
                setErrorTitle('Error failed to approve sppb data');
                setShowDialog(true);
            }

            if (response.status === 500) {
                setErrorCode('400');
                setErrorTitle('Error failed to approve sppb data');
                setShowDialog(true);
            }
            
            if(response.status === 200){
                navigate('/salesIndex');
            }
        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error failed to approve sppb data', error);
            setShowDialog(true);
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleRejectAction = async() => {
        setLoading(true);

        try{
            const APIupdate = API_BASE_URL + 'sales/rejectsppb.php';
            const response = await fetch(APIupdate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    sppb_number: detailSPPB.Details[0].SPPBNumber,
                    update_by: username
                }),
            });

            if (!response.ok) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch to reject sppb data');
                setShowDialog(true);
            }

            if (response.status === 500) {
                setErrorCode('500');
                setErrorTitle('Error failed to fetch to reject sppb data');
                setShowDialog(true);
            }
            
            if(response.status === 200){
                navigate('/salesIndex');
            }
        } catch (error){
            setErrorCode('500');
            setErrorTitle('Error failed to fetch to reject sppb data : ', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleProfit = async(SONumber) => {
        navigate(`/salesIndex/New/newProfit/withParams/${encodeURIComponent(SONumber)}`);
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleQuantityChange = (e, index) => {
        const value = e.target.value;
        setQuantities(prevQuantities => {
            const newQuantities = [...prevQuantities];
            newQuantities[index] = value;
            return newQuantities;
        });
    };

    const handleSendDateChange = (date, index) => {
        setSendDates(prevSendDates => {
            const newSendDates = [...prevSendDates];
            newSendDates[index] = date;
            return newSendDates;
        });
    };

    const handleSendToChange = (e, index) => {
        const value = e.target.value;
        setSendTo(prevSendTo => {
            const newSendTo = [...prevSendTo];
            newSendTo[index] = value;
            return newSendTo;
        });
    };

    const handleResubmit = async () => {
        setLoading(true);
        try{
            const API = API_BASE_URL + 'sales/revisedsppb.php';

            let params = new URLSearchParams({
                sales_order_number : detailSPPB.Details[0].SPPBNumber,
                sales_order_date : sppbDate,
                insert_by : username,
                product_length : detailSPPB.Items.length
            });

            detailSPPB.Items.forEach((item, index) => {
                params.append(`sales_order_PO_${index + 1}`, item.PONumber);
                params.append(`sales_order_product_name_${index + 1}`, item.ProductName);
                params.append(`sales_order_send_to_before_${index + 1}`, item.SendTo);
                params.append(`sales_order_send_date_before_${index + 1}`, item.SendDate);
                params.append(`sales_order_quantity_before_${index + 1}`, item.Quantity);
                params.append(`sales_order_send_to_after_${index + 1}`, sendTo[index]);
                params.append(`sales_order_send_date_after_${index + 1}`, sendDates[index]);
                params.append(`sales_order_quantity_after_${index + 1}`, quantities[index]);
            })

            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params
            });

            if(response.status === 200){
                navigate(`/salesIndex`);
            }

            if(response.status === 500){
                setErrorCode('400');
                setErrorTitle('Error while update sales order data !!');
                setShowDialog(true);
            }

        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error fetching revised sales order data:', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleExportToExcel = () => {
        const data = {
            noSO: detailSPPB.Details[0].SPPBNumber,
            date: formatDate(detailSPPB.Details[0].SPPBDate),
            customer: detailSPPB.Details[0].company_name,
            items: detailSPPB.Items.map((item, index) => ({
                poNo: item.PONumber,
                dikirimKe: item.SendTo,
                dikirimTgl: formatDate(item.SendDate),
                barang: item.ProductName,
                qty: item.Quantity,
                sat: item.uomName,
                keterangan: item.Description,
            })),
        };

        SPPBgenerateExcel(data);
    }

    return(
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesActive/>
                <PurchaseNonActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Sales Module</div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? (<Loader/>) : detailSPPB ? (
                    <div> 
                        <div className='card-for-grid'>
                            <div className='title-for-card'>SPPB Status</div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Status"} />
                                    <div className='space-between-1vh'></div>
                                    <div>{detailSPPB.Details[0].SO_Status_Name}</div>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Created By"} />
                                    <div className='space-between-1vh'></div>
                                    <div>{detailSPPB.Details[0].InsertBy}</div>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Created At"} />
                                    <div className='space-between-1vh'></div>
                                    <div>{formatDateTime(detailSPPB.Details[0].InsertDt)}</div>
                                </div>
                            </div>
                        </div>
                        <div className='card-for-grid'>
                            <div className='title-for-card'>SPPB Detail</div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"No. SPPB"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailSPPB.Details[0].SPPBNumber} />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Date"} />
                                    <div className='space-between-1vh'></div>
                                    {detailSPPB.Details[0].SO_Status_Name === 'SPPB Rejected' ? (
                                       <ReactDatePicker
                                            selected={sppbDate}
                                            onChange={(date) => setSppbDate(date)}
                                            dateFormat="dd MMMM yyyy"
                                            className="datepicker"
                                        />
                                    ) : (
                                        <TextBox value={formatDate(detailSPPB.Details[0].SPPBDate)} />
                                    )}
                                    
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Customer"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailSPPB.Details[0].company_name} />
                                </div>
                            </div>
                            {/* Scrollable Item */}
                            <div className="scrollable-horizontal">
                                {detailSPPB.Items.map((item, index) => (
                                    <div key={index}>
                                        <div className='ItemNameTemplateForm'>
                                            <FormLabel labelName={`Item #${index + 1}`} />
                                        </div>
                                        <div className='grid-container-scrollable'>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"Purchase Order Number"} />
                                                <TextBox value={item.PONumber} />
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"Dikirim Ke"} />
                                                {detailSPPB.Details[0].SO_Status_Name === 'SPPB Rejected' ? (
                                                    <TextArea maxLines={5} value={sendTo[index]} onChange={(e) => handleSendToChange(e, index)} />
                                                ) : (
                                                    <TextArea maxLines={5} value={item.SendTo} />
                                                )}
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"Tanggal Kirim"} />
                                                {detailSPPB.Details[0].SO_Status_Name === 'SPPB Rejected' ? (
                                                    <ReactDatePicker
                                                        selected={sendDates[index]}
                                                        onChange={(date) => handleSendDateChange(date, index)}
                                                        dateFormat="dd MMMM yyyy"
                                                        className="datepicker"
                                                    />
                                                ) : (
                                                    <TextBox value={formatDate(item.SendDate)} />
                                                )}
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"Nama Barang"} />
                                                <TextBox value={item.ProductName} />
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"QTY"} />
                                                {detailSPPB.Details[0].SO_Status_Name === 'SPPB Rejected' ? <TextBox value={quantities[index]} onChange={(e) => handleQuantityChange(e, index)} /> : <TextBox value={item.Quantity} />}
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"SAT"} />
                                                <TextBox value={item.uomName} />
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"Keterangan"} />
                                                <TextBox value={item.Description} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='grid-container-with-padding-left'>
                            {
                                detailSPPB.Details[0].SO_Status_Name === 'SPPB Approved' || detailSPPB.Details[0].SO_Status_Name === 'Delivery Order Approved' || detailSPPB.Details[0].SO_Status_Name === 'Profit Approved' ? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={handleExportToExcel}>Export Excel</div>
                                    </div>
                                ) : (
                                    <div></div>
                                )
                            }
                            {
                                detailSPPB.Details[0].SO_Status_Name === 'SPPB Draft' && permissionAccess === 'Full access' ? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={handleApproveAction}>Approve</div>
                                    </div>
                                ) :  detailSPPB.Details[0].SO_Status_Name === 'SPPB Approved' ? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={() => handleProfit(detailSPPB.Details[0].SPPBNumber)}>Create Profit</div>
                                    </div>
                                ) : (
                                    <div></div>
                                )
                            }
                            {
                                detailSPPB.Details[0].SO_Status_Name === 'SPPB Draft' && permissionAccess === 'Full access'? (
                                    <div className="only-100-width-card-reject">
                                        <div className="" onClick={handleRejectAction}>Reject</div>
                                    </div>
                                ) : 
                                detailSPPB.Details[0].SO_Status_Name === 'SPPB Rejected' ? (
                                    <div className="only-100-width-card-reject">
                                        <div className="" onClick={handleResubmit}>Resubmit</div>
                                    </div>
                                ) : (
                                    <div></div>
                                )
                            }
                        </div>
                    </div>
                ) : (
                    <Loader/>
                )}
            </div>
        </div>
    );
}

export default DetailSPPB;