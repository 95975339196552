import HRNonActiveIcon from '../../../assets/Icon/HRInactive.png';
import { useNavigate } from 'react-router-dom';

function HRNonActive(){
    const navigate = useNavigate();

    const handleHomepage = (event) => {
        navigate('/hrIndex'); 
    };

    return (
        <div className="MenuContainerNonActive" onClick={handleHomepage}>
            <img src={HRNonActiveIcon} />
            <div className='MenuNonActive'>HR</div>
        </div>
    );
}

export default HRNonActive;