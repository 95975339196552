import React from 'react';

function FourCardGrid(props) {
    const { headline, title1, title2, title3, title4, content1, content2, content3, content4 } = props;

    return(
        <div className='card-for-grid'>
            <div className='grid-container'>
                <div className="card-headline">{headline}</div>
            </div>
            <div className='grid-container'>
                <div className="four-grid-card-one">
                    <div className="four-grid-card-title">{title1}</div>
                    <div className="four-grid-card-desc">{content1}</div>
                </div>
                <div className="four-grid-card-two">
                    <div className="four-grid-card-title">{title2}</div>
                    <div className="four-grid-card-desc">{content2}</div>
                </div>
                <div className="four-grid-card-three">
                    <div className="four-grid-card-title">{title3}</div>
                    <div className="four-grid-card-desc">{content3}</div>
                </div>
                <div className="four-grid-card-four">
                    <div className="four-grid-card-title">{title4}</div>
                    <div className="four-grid-card-desc">{content4}</div>
                </div>
            </div>
        </div>
    );
}
 
export default FourCardGrid;
