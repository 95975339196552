import { useNavigate, useParams } from "react-router-dom";
import useAuthSession from "../../services/Session";
import { useEffect, useState } from "react";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import FinanceActive from "../../component/menu/FinanceActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import Logout from "../../component/menu/Logout";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import { API_BASE_URL, formatCurrencyIDR, formatDate } from "../../../App";
import Loader from "../../component/Loader";
import FormLabel from "../../component/FormLabel";
import TextBox from "../../component/TextBox";
import ErrorDialog from "../../component/dialog/ErrorDialog";

function DetailPembayaran (){
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [loading, setLoading] = useState(false);
    let { id } = useParams();
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [voucherNo, setVoucherNo] = useState('');
    const [memo, setMemo] = useState('');
    const [chequeNo, setChequeNo] = useState('');
    const [payee, setPayee] = useState('');
    const [memoAccount, setMemoAccount] = useState('');
    const [amount, setAmount] = useState('');
    const [accountAmount, setAccountAmount] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [depositTo, setDepositTo] = useState('');
    const [startDate, setStartDate] = useState('');
    const [bankName, setBankName] = useState('');

    useEffect(() => {
        fetchDetailPembayaran();
    }, [])

    const fetchDetailPembayaran = async () => {
        setLoading(true);
        try{
            const response = await fetch(`${API_BASE_URL}finance/getdetailpembayaran.php?id_transaction=${id}`);
            const data = await response.json();

            if (data.StatusCode === 200) {
                setDepositTo(data.Data[0].bank_account);
                setVoucherNo(data.Data[0].voucher_no);
                setChequeNo(data.Data[0].chequeno);
                setStartDate(data.Data[0].date);
                setMemo(data.Data[0].memo);
                setPayee(data.Data[0].payee);
                setAmount(data.Data[0].amount);
                setAccountNumber(data.Data[0].accountcode);
                setAccountName(data.Data[0].account_name_alias);
                setAccountAmount(data.Data[0].accountamount);
                setMemoAccount(data.Data[0].accountmemo);
                setBankName(data.Data[0].bank_name);
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch last PO Number');
                setShowDialog(true);
            }
        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error failed to fetch detail penerimaan data : ' + error.message);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleDeleteTransaction = async () => {
        setLoading(true);
        try{
            const API = `${API_BASE_URL}/finance/deletetransaction.php`;
            let params= new URLSearchParams({
                id_transaction: id,
            });

            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params
            });

            if(response.status === 200){
                navigate(`/financeIndex`);
            }

            if(response.status === 500){
                setErrorCode('500');
                setErrorTitle('Error failed to delte finance data !!');
                setShowDialog(true);
            }


        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error failed to delete penerimaan data : ' + error.message);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    return (
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>    
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseNonActive/>
                <FinanceActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div className='grid-container-with-padding-left'>
                    <div className="only-100-width">
                        <div className='MenuName'>Finance Module</div>
                    </div>
                    <div className="only-100-width">
                    </div>
                    <div className="only-100-width">
                        <div className="DeleteTemplateForm" onClick={handleDeleteTransaction}>Delete Transaction</div>
                    </div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? <Loader/> : (
                    <div>
                        <div className='card-for-grid'>
                            <div className='title-for-card'>Detail Pembayaran</div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Paid From"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={`${depositTo} - ${bankName}`} />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Voucher No"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={voucherNo}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Cheque No"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={chequeNo}/>
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Date"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={formatDate(startDate)}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Memo"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={memo}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Payee"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={payee}/>
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Amount"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={formatCurrencyIDR(amount)}/>
                                </div>
                                <div className="only-100-width">
                                </div>
                                <div className="only-100-width">
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Account No"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={accountNumber}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Account Name"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={accountName}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Amount"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={formatCurrencyIDR(accountAmount)}/>
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Memo"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={memoAccount}/>
                                </div>
                                <div className="only-100-width">
                                </div>
                                <div className="only-100-width">
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default DetailPembayaran;