import React, { useEffect, useState } from 'react';
import TextBox from '../TextBox';
import { useNavigate } from 'react-router-dom';
import ErrorBox from '../ErrorBox';
import Loader from '../Loader';
import { API_BASE_URL } from '../../../App';

const DetailFinanceCategory = ({ onCloseDialog, id }) => {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(true);  
    const [errorDialog, setErrorDialog] = useState(false);
    const [txtFinanceCategory, setTxtFinanceCategory] = useState('');
    const [txtFinanceCategoryID, setTxtFinanceCategoryId] = useState('');
    const [error, setError] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}master/finance/getdetailfinancecategory.php?category_id=${id}`);
                const data = await response.json();

                if (data.StatusCode === 200) {
                    setTxtFinanceCategoryId(data.Data[0].category_id);
                    setTxtFinanceCategory(data.Data[0].category_name);
                } else {
                    throw new Error(data.Status || 'Unknown error');
                }
            } catch (error) {
                setErrorTitle('Error fetching bank account data');
                setErrorMessage(error.message);
                setErrorDialog(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const handleCloseClick = () => {
        onCloseDialog(); 
    };

    if (!showDialog) {
        return null; 
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleCloseErrorDialog = () => {
        setErrorDialog(false);
    };

    const dialogBox = errorDialog && <ErrorBox onCloseDialog={handleCloseErrorDialog} title={errorTitle} message={errorMessage} />;

    return(
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            Detail Finance Category
                        </div>
                        {
                            loading ? <Loader/> : (
                                <form>
                                    <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Finance Category</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox 
                                            value={txtFinanceCategory}
                                            onChange={(e) => setTxtFinanceCategory(e.target.value)}
                                            placeholder="xxxx" type="text" maxLines={1}/>
                                    </div>
                                    
                                    </div>
                                </form>
                            )
                        }
                        <div className='space-between'/>
                        <div className='grid-container-form-submit'>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Close</div>
                        </div>
                    </div>
                </div>
            </div>
            {dialogBox}
        </div>
    );
}

export default DetailFinanceCategory;