import { useNavigate } from "react-router-dom";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceActive from "../../component/menu/FinanceActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import { useEffect, useState } from "react";
import { API_BASE_URL, formatCurrency, formatDate } from "../../../App";
import Pagination from "../../component/Paging";
import ErrorDialog from "../../component/dialog/ErrorDialog";
import Loader from "../../component/Loader";

function PenerimaanPembelianFinance (){
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [loading, setLoading] = useState(false);
    const [allPenerimaan, setAllPenerimaan] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const itemsPerPage = 25;

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetchAllPenerimaan(currentPage, itemsPerPage);
                if (response.StatusCode === 200) {
                    setAllPenerimaan(response.Data);
                    setTotalItems(response.totalItems); // Ensure the API response includes totalItems
                } else {
                    throw new Error(response.Status || 'Unknown error');
                }
            } catch (error) {
                // setErrorCode('400');
                // setErrorTitle(`Error fetching penerimaan data: ${error.message}`);
                // setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage]);

    const fetchAllPenerimaan = async (page, limit) => {
        try {
            const response = await fetch(`${API_BASE_URL}finance/getallpenerimaanpembelian.php?page=${page}&limit=${limit}`);
            const data = await response.json();
            return data;
        } catch (error) {
            throw new Error('Failed to fetch penerimaan');
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePenerimaanPembelian = () => {
        navigate('/financeIndex/New/PenerimaanPembelian');
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    return(
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseNonActive/>
                <FinanceActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div className='grid-container'>
                    <div className='MenuName'>Finance Module</div>
                    <div className='create-new-button' onClick={handlePenerimaanPembelian}>Create New Penerimaan Pembelian</div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? <Loader /> : (
                    allPenerimaan.map((penerimaan, index) => (
                        <div key={index} className='seeAllCard' >
                            <div className='grid-container-smaller'>
                                <div className="only-100-finance">
                                    <div className="only-100-start-nopadding">
                                        <div className='seeAllTitle'>{penerimaan.invoice_number}</div>
                                        <div className='seeAllDetail'>{formatDate(penerimaan.invoiceDate)}</div>
                                    </div>
                                </div>
                                <div className="only-100-end">
                                    <div className="only-100-end-nopadding">
                                        <div className='seeAllTitle'>{formatCurrency(penerimaan.due_amount, penerimaan.currency_name)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
                <Pagination
                    totalItems={totalItems} 
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </div>
        </div>
    );
}

export default PenerimaanPembelianFinance;