import SalesNonActiveIcon from '../../../assets/Icon/SalesInactive.png';
import { useNavigate } from 'react-router-dom';

function SalesNonActive(){
    const navigate = useNavigate();

    const handleSalesMenu = (event) => {
        navigate('/salesIndex'); 
    };

    return(
        <div className="MenuContainerNonActive" onClick={handleSalesMenu}>
            <img src={SalesNonActiveIcon} />
            <div className='MenuNonActive'>Sales</div>
        </div>
    );
}

export default SalesNonActive;