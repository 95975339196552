import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const WarehouseExportExcel = async ({ excelData, fileName, period }) => {
    if (!excelData || excelData.length === 0) {
        console.error('No data to export');
        return;
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
    const fileExtension = '.xlsx';

    try {
        // Helper function to format dates
        const formatDate = (dateStr) => {
            const date = new Date(dateStr);
            return date.toLocaleDateString('id-ID', { day: '2-digit', month: 'long', year: 'numeric' });
        };

        // Extract unique dates
        const dates = Array.from(new Set(excelData.flatMap(product => product.daily_transactions.map(transaction => transaction.date)))).sort();

        // Prepare headers
        const headers = ['Kode Produk', 'Nama Produk', 'BB', 'In', 'Out', 'End Balance'];
        const secondRowHeaders = ['kodeProduk', 'namaProduk', 'BB', 'In', 'Out', 'End Balance'];

        dates.forEach(date => {
            const formattedDate = formatDate(date);
            headers.push(formattedDate, ''); // Each date spans two columns
            secondRowHeaders.push('In', 'Out');
        });

        // Prepare data
        const formattedData = excelData.map(product => {
            const row = {
                'Kode Produk': product.kodeProduk,
                'Nama Produk': product.namaProduk,
                BB: product.BB,
                In: product.In,
                Out: product.Out,
                'End Balance': product.ending_balance,
            };
            dates.forEach(date => {
                const transaction = product.daily_transactions.find(trans => trans.date === date) || { In: 0, Out: 0 };
                row[`${date} In`] = transaction.In;
                row[`${date} Out`] = transaction.Out;
            });
            return row;
        });

        // Create worksheet
        const ws = XLSX.utils.aoa_to_sheet([
            [],
            ['LAPORAN STOCK BARANG PT.VENKEN INTERNATIONAL KIMIA'],
            [`PERIODE ${period}`],
            headers,
            secondRowHeaders,
            ...formattedData.map(Object.values)
        ]);

        // Set header styles and merges
        const mergeCells = [];
        ws['A2'].s = { font: { bold: true }, alignment: { horizontal: 'center' } };
        ws['A3'].s = { font: { bold: true }, alignment: { horizontal: 'center' } };
        ws['A4'].s = { font: { bold: true }, alignment: { horizontal: 'center' } };
        
        // Set auto width for all columns
        const wscols = [
            { wpx: 100 }, // Kode Produk
            { wpx: 200 }, // Nama Produk
            { wpx: 100 }, // BB
            { wpx: 100 }, // In
            { wpx: 100 }, // Out
            { wpx: 100 }, // End Balance
        ];
        dates.forEach(() => {
            wscols.push({ wpx: 100 }); // In
            wscols.push({ wpx: 100 }); // Out
        });

        ws['!cols'] = wscols;

        mergeCells.push({ s: { r: 1, c: 0 }, e: { r: 1, c: headers.length - 1 } });
        mergeCells.push({ s: { r: 2, c: 0 }, e: { r: 2, c: headers.length - 1 } });

        let headerColIndex = 6; // Starting index for the dates columns
        dates.forEach(date => {
            mergeCells.push({ s: { r: 3, c: headerColIndex }, e: { r: 3, c: headerColIndex + 1 } });
            headerColIndex += 2;
        });

        ['A4', 'B4', 'C4', 'D4', 'E4', 'F4'].forEach((cell, index) => {
            mergeCells.push({ s: { r: 3, c: index }, e: { r: 4, c: index } });
        });

        ws['!merges'] = mergeCells;

        // Create workbook
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    } catch (error) {
        console.error('Error generating Excel file:', error);
    }
};

export default WarehouseExportExcel;
