import React, { useState, useEffect } from 'react';
import FormLabel from "../../component/FormLabel";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseActive from "../../component/menu/PurchaseActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import TextBox from '../../component/TextBox';
import TextArea from '../../component/TextArea';
import DatePicker from "react-datepicker";
import { useParams } from 'react-router-dom';
import { API_BASE_URL, formatCurrency } from '../../../App';
import ListPurchaseOrderDialog from '../../component/dialog/ListPurchaseOrder';
import { useNavigate } from 'react-router-dom';
import Loader from '../../component/Loader';
import ErrorDialog from '../../component/dialog/ErrorDialog';

function NewReceivingItems (){
    let { PONumber } = useParams();
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [receiveDate, setReceiveDate] = useState(new Date());
    const [shipDate, setShipDate] = useState(new Date());
    const [detailPurchaseLocal, setDetailPurchaseLocal] = useState(null);
    const [showPODialog, setShowPODialog] = useState(false);
    const [items, setItems] = useState([]);
    const [VendorName, setVendorName] = useState('');
    const [shipVia, setshipVia] = useState([]);
    const [listShipVia, setListShipVia] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [quantities, setQuantities] = useState([]);
    const [hargasatuan, setHargaSatuan] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (PONumber != null && PONumber !== '') {
                try {
                    setLoading(true);
                    const response = await fetch(`${API_BASE_URL}purchase/getdetailpurchaseimport.php?PONumber=${PONumber}`);
                    const data = await response.json();
    
                    if (data.StatusCode === 200) {
                        setDetailPurchaseLocal(data.Data);

                        const initialQuantities = data.Data.Items.map(item => item.POQuantity);
                        setQuantities(initialQuantities);
                        console.log(quantities);

                        const initialHargaSatuan = data.Data.Items.map(item => item.POUnitPrice);
                        setHargaSatuan(initialHargaSatuan);
                    } else {
                        setErrorCode('400');
                        setErrorTitle('Error failed to fetch detail purchase local data');
                        setShowDialog(true);
                    }
                } catch (error) {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail purchase local data : ', error);
                    setShowDialog(true);
                } finally {
                    setLoading(false);
                }
            }
        };

        const fetchShipVia = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${API_BASE_URL}master/shipVia/getallshipvia.php`);
                const data = await response.json();

                if (data.StatusCode === 200) {
                    setListShipVia(data.Data);
                    setshipVia(data.Data[0].shipID || '');
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail purchase local data');
                    setShowDialog(true);
                }
            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail purchase local data : ', error);
                setShowDialog(true);
            } finally{
                setLoading(false);
            }
        }
    
        fetchData();
        fetchShipVia();
    }, [PONumber]);

    const handleShipViaChange = (event) => {
        setshipVia(event.target.value);
    };

    const handlePOOpenDialog = () => {
        setShowPODialog(true);
    };

    const handlePOCloseDialog = () => {
        setShowPODialog(false);
    };

    const handleSelectPurchaseOrder = (purchaseOrderData) => {
        const itemsData = purchaseOrderData.Data;
        let nonNullItems = [];
        let nonNullCount = 0;
        let nextId = 1; 

        for (let i = 1; i <= 5; i++) {
            const itemsKey = `Items ${i}`;
            const itemsArray = itemsData[itemsKey];

            const allNonNull = itemsArray.every(item => Object.values(item).every(value => value !== null));

            if (itemsArray && itemsArray.length > 0) {
                const item = itemsArray[0];

                const productNameKey = `POProductName${i}`;
                const quantityKey = `POQuantity${i}`;
                const unitPriceKey = `POUnitPrice${i}`;
                const packagingKey = `POPackagingSize${i}`;

                if(item[productNameKey] !== null){
                    nonNullCount++;

                    itemsArray.forEach(item => {
                        console.log(item[`POProductName${i}`]);
                        nonNullItems.push({
                            id: nextId++,
                            label: `Item #${nextId - 1}`,
                            productName: item[`POProductName${i}`],
                            quantity: Number(item[`POQuantity${i}`]),
                        });
                    });
                }
            }
        }

        setItems(nonNullItems);
        setVendorName(itemsData.Details[0].SupplierName);
        handlePOCloseDialog();
    };

    const saveOnly = async () => {
            try{
                setLoading(true);
                const API = API_BASE_URL + 'purchase/receivingitems/insertreceivingitems.php';
                const supplierID = detailPurchaseLocal.Details[0].POSupplier;

                let params = new URLSearchParams({
                    purchase_order_supplier : supplierID,
                    purchase_order_number : PONumber,
                    receiving_date : receiveDate,
                    ship_date : shipDate, 
                    ship_via : shipVia,
                    insert_by: username,
                    product_length : detailPurchaseLocal.Items.length
                });

                detailPurchaseLocal.Items.forEach((item, index) => {
                    params.append(`purchase_order_product_name_${index + 1}`, item.POProductName);
                    params.append(`purchase_order_product_quantity_${index + 1}`, quantities[index]);
                    params.append(`purchase_order_product_packaging_size_${index + 1}`, item.POPackagingSize);
                    params.append(`purchase_order_product_unit_price_${index + 1}`, hargasatuan[index]);
                });

                const response = await fetch(API, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: params
                });

                if(response.status === 200){
                    navigate(`/purchaseIndex`);
                }

            } catch (error){
                setErrorCode('400');
                setErrorTitle('Error failed to submit receiving items : ', error);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
    }

    const handleQuantityChange = (e, index) => {
        const value = e.target.value;
        setQuantities(prevQuantities => {
            const newQuantities = [...prevQuantities];
            newQuantities[index] = value;
            return newQuantities;
        });
    };

    const handleHargaSatuanChange = (e, index) => {
        const value = e.target.value;
        setHargaSatuan(prevHargaSatuan => {
            const newHargaSatuan = [...prevHargaSatuan];
            newHargaSatuan[index] = value;
            return newHargaSatuan;
        })
    };
    
    const saveCreateInvoice = async () => {
        if(detailPurchaseLocal === '' || detailPurchaseLocal === null){
            navigate(`/purchaseIndex/New/withParams/purchaseInvoice/${encodeURIComponent(PONumber)}`);
        } else if (detailPurchaseLocal !== '' || detailPurchaseLocal !== null){
                try{
                    setLoading(true);
                    const API = API_BASE_URL + 'purchase/receivingitems/insertreceivingitems.php';
                    const supplierID = detailPurchaseLocal.Details[0].POSupplier;

                    let params = new URLSearchParams({
                        purchase_order_supplier : supplierID,
                        purchase_order_number : PONumber,
                        receiving_date : receiveDate,
                        ship_date : shipDate, 
                        ship_via : shipVia,
                        insert_by: username,
                        product_length : detailPurchaseLocal.Items.length
                    });

                    detailPurchaseLocal.Items.forEach((item, index) => {
                        params.append(`purchase_order_product_name_${index + 1}`, item.POProductName);
                        params.append(`purchase_order_product_quantity_${index + 1}`, quantities[index]);
                        params.append(`purchase_order_product_packaging_size_${index + 1}`, item.POPackagingSize);
                        params.append(`purchase_order_product_unit_price_${index + 1}`, hargasatuan[index]);
                    });

                    const response = await fetch(API, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: params
                    });
    
                    if(response.status === 200){
                        navigate(`/purchaseIndex/New/withParams/purchaseInvoice/${encodeURIComponent(PONumber)}`);
                    }

                } catch (error){
                    setErrorCode('400');
                    setErrorTitle('Error insert new receiving items data:', error.message);
                    setShowDialog(true);
                } finally {
                    setLoading(false);
                }
        }
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const DPPCountW = (harga, quantity) => {
        const DPPResult = harga * quantity;
        return DPPResult;
    };

    const PPNCountW = (harga, quantity) => {
        const PPNResult = (harga * quantity) * (detailPurchaseLocal.Details[0].PPNPercentage / 100);
        return PPNResult;
    };

    let totalDPP = 0;
    let totalPPN = 0;
    let totalHargaSatuan = 0;
    
    if (detailPurchaseLocal) {
        detailPurchaseLocal.Items.forEach((item, index) => {
            const DPP = DPPCountW(hargasatuan[index], quantities[index]);
            const PPN = PPNCountW(hargasatuan[index], quantities[index]);
            totalDPP += parseFloat(DPP);
            totalPPN += parseFloat(PPN);
            totalHargaSatuan += parseFloat(hargasatuan[index]);
        });
    }

    return(
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
            {showPODialog && <ListPurchaseOrderDialog onCloseDialog={handlePOCloseDialog} onSelectPurchaseOrder={handleSelectPurchaseOrder} />}
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Purchase Module</div>
                </div>
                <div className='card-for-grid'>
                <div className='title-for-card'>Receiving Items</div>
                {loading ? <Loader/> : detailPurchaseLocal ? (
                    <div>
                        <div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Vendor"}/>
                                    {detailPurchaseLocal ? (
                                        <TextBox value={detailPurchaseLocal.Details[0].SupplierName} />
                                    ) : (
                                        <TextBox value={VendorName} readonly/>
                                    )}
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"PO Number"} />
                                    {PONumber != null && PONumber !== '' ? 
                                        <TextBox value={PONumber}/> 
                                    : 
                                        <div onClick={handlePOOpenDialog}>Select PO Number</div>
                                    }
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Receiving Date"}/>
                                    {!showPODialog && (
                                        <DatePicker 
                                            className='date-picker' 
                                            selected={receiveDate} 
                                            onChange={(date) => setReceiveDate(date)} 
                                            dateFormat="dd MMMM yyyy"
                                        />
                                    )}
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Alamat"}/>
                                    {detailPurchaseLocal ? (
                                        <TextArea maxLines={5} value={detailPurchaseLocal.Details[0].SupplierAddress} />
                                    ) : (
                                        <TextBox/>
                                    )}
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Ship Date"}/>
                                    {!showPODialog && (
                                        <DatePicker 
                                            className='date-picker' 
                                            selected={shipDate} 
                                            onChange={(date) => setShipDate(date)} 
                                            dateFormat="dd MMMM yyyy"
                                        />
                                    )}
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Ship Via"}/>
                                    <select className='new-form-dropdown' value={shipVia} onChange={handleShipViaChange}>
                                        {listShipVia.map((shipVia) => (
                                            <option key={shipVia.shipID} value={shipVia.shipID}>
                                                {shipVia.shipName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {detailPurchaseLocal.Items.map((item, index) => (
                                <div key={index}>
                                    <div className='ItemNameTemplateForm'>
                                        <FormLabel labelName={`Item #${index + 1}`} />
                                    </div>
                                    <div className="grid-container">
                                        <div className="only-100-width">
                                            <FormLabel labelName={`Product Name`}/>
                                            <div className='space-between-1vh'></div>
                                            <TextBox value={item.POProductName} readOnly />
                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={`Quantity (Kg)`}/>
                                            <div className='space-between-1vh'></div>
                                            <TextBox value={quantities[index]} onChange={(e) => handleQuantityChange(e, index)}/>
                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={`Packaging Size (Kg)`}/>
                                            <div className='space-between-1vh'></div>
                                            <TextBox value={item.POPackagingSize}/>
                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={`Unit Price ${detailPurchaseLocal ? detailPurchaseLocal.Details[0].CurrencyName : 'IDR'}`}/>
                                            <div className='space-between-1vh'></div>
                                            <TextBox value={hargasatuan[index]} onChange={(e) => handleHargaSatuanChange(e, index)}/>
                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={`Total ${detailPurchaseLocal ? detailPurchaseLocal.Details[0].CurrencyName : 'IDR'}`}/>
                                            <div className='space-between-1vh'></div>
                                            <TextBox value={formatCurrency(quantities[index] * hargasatuan[index], detailPurchaseLocal ? detailPurchaseLocal.Details[0].CurrencyName : 'IDR')}/>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {detailPurchaseLocal.Details[0].POType === '741ead94-d157-11ee-8' ? (
                                <div className='grid-container'>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"VAT (11%)"}/>
                                    </div>
                                    <div className="only-100-width">
                                        <TextBox value={formatCurrency(totalPPN, detailPurchaseLocal ? detailPurchaseLocal.Details[0].CurrencyName : 'IDR')}/>
                                    </div>
                                </div>) 
                            : (<div></div>)}
                            {detailPurchaseLocal.Details[0].POType === '741ead94-d157-11ee-8' ? (
                                <div className='grid-container'>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Total"}/>
                                    </div>
                                    <div className="only-100-width">
                                        <TextBox value={formatCurrency(totalDPP + totalPPN, detailPurchaseLocal ? detailPurchaseLocal.Details[0].CurrencyName : 'IDR')}/>
                                    </div>
                                </div>) 
                            : (<div className='grid-container'>
                                <div className="only-100-width"></div>
                                <div className="only-100-width"></div>
                                <div className="only-100-width"></div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Total"}/>
                                </div>
                                <div className="only-100-width">
                                    <TextBox value={formatCurrency(totalDPP, detailPurchaseLocal ? detailPurchaseLocal.Details[0].CurrencyName : 'IDR')}/>
                                </div>
                            </div>)}
                        </div>
                        <div className='grid-container-with-padding-left'>
                            <div className="only-100-width-card-submit" onClick={() => saveOnly()}>Save</div>
                            <div className="only-100-width-card-submit" onClick={() => saveCreateInvoice()}>Save & Buat Faktur</div>
                        </div>
                    </div>
                ) : (<Loader/>)}
                </div>
            </div>
        </div>
    );
}

export default NewReceivingItems;