import DocumentInactiveIcon from '../../../assets/Icon/DocumentActive.png';
import { useNavigate } from 'react-router-dom';

function DocumentActive(){
    const navigate = useNavigate();
    const permissionAccess = localStorage.getItem('permissionAccess');

    const handleSalesMenu = (event) => {
        navigate('/documentIndex'); 
    };

    return(
        <div className="MenuContainer" onClick={handleSalesMenu}>
            <img src={DocumentInactiveIcon} />
            <div className='MenuActive'>Document</div>
        </div>
    );
}

export default DocumentActive;