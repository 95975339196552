import { saveAs } from 'file-saver';
import XLSX from 'sheetjs-style';

const SPPBgenerateExcel = (data) => {
    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.aoa_to_sheet([
        ['SURAT PERMINTAAN PENGELUARAN BARANG (SPPB)'],
        ['NO. SPPB:', data.noSO, '', 'TANGGAL:', data.date],
        ['Customer:', data.customer],
        ['', 'PO CUSTOMER', 'DIKIRIM KE', 'Tgl Kirim', 'Nama Barang', 'QTY', 'SAT', 'KETERANGAN'],
        ...data.items.map((item, index) => [
            index + 1,
            item.poNo,
            item.dikirimKe,
            item.dikirimTgl,
            item.barang,
            item.qty,
            item.sat,
            item.keterangan || '', // Assuming there might be a "keterangan" field in item
        ]),
        [],
        ['DIBUAT OLEH,', '', '', 'DISETUJUI OLEH,'],
        [],
        [],
        ['( AFIFAH )', '', '', '( SULANTO )', '', '( IRENE )'],
    ]);

    ws['!cols'] = [
        { wch: 10 }, // No.
        { wch: 30 }, // PO CUSTOMER
        { wch: 30 }, // DIKIRIM KE
        { wch: 15 }, // Tgl Kirim
        { wch: 30 }, // Nama Barang
        { wch: 10 }, // QTY
        { wch: 10 }, // SAT
        { wch: 30 }, // KETERANGAN
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'SPPB');

    // Generate Excel file and trigger download
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const cleanSheetName = `SO${data.noSO}`.replace(/[^a-zA-Z0-9]/g, '');
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `SPPB/${cleanSheetName}.xlsx`);
}

export default SPPBgenerateExcel;