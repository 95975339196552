import React from 'react';
import AnalyticsInactiveIcon from '../../../assets/Icon/AnalyticsInactive.png';
import { useNavigate } from 'react-router-dom';

function AnalyticsnonActive(){
    const navigate = useNavigate();
    const permissionAccess = localStorage.getItem('permissionAccess');

    const handleSalesMenu = (event) => {
        navigate('/analyticsIndex'); 
    };

    return(
        <div className="MenuContainerNonActive" onClick={handleSalesMenu}>
            <img src={AnalyticsInactiveIcon} />
            <div className='MenuNonActive'>Analytics</div>
        </div>
    );
}

export default AnalyticsnonActive;
