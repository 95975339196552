import React from 'react';

function SalesCardDashbord(props) {
    const { headline, title1, title2, content1, content2, } = props;

    return(
        <div className='card-for-grid'>
            <div className='grid-container'>
                <div className="card-headline">{headline}</div>
            </div>
            <div className='grid-container'>
                <div className="four-grid-card-one">
                    <div className="four-grid-card-title">{title1}</div>
                    <div className="four-grid-card-desc">{content1}</div>
                </div>
                <div className="four-grid-card-two">
                    <div className="four-grid-card-title">{title2}</div>
                    <div className="four-grid-card-desc">{content2}</div>
                </div>
            </div>
        </div>
    );
}
 
export default SalesCardDashbord;
