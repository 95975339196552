import { useEffect, useState } from "react";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import FourCardGrid from "../../component/card/FourCardGrid";
import NewWarehouseDialog from "../../component/dialog/NewWarehouseDialogBox";
import NotFoundPage from "../../component/maintanance/NotFound";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseActive from "../../component/menu/WarehouseActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import ExportExcel from "../../services/ExcelExportServices";
import { API_BASE_URL } from "../../../App";
import Loader from "../../component/Loader";
import WarehouseExportExcel from "../../services/WarehouseExcelExportServices";
import FindProductWarehouseReport from "../../component/dialog/FindProductWarehouseReport";
import { useNavigate } from "react-router-dom";
import W1Image from '../../../assets/Icon/w1.png';
import W2Image from '../../../assets/Icon/w2.png';
import W3Image from '../../../assets/Icon/w3.png';

function WarehouseIndex() {
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const permissionAccess = localStorage.getItem('permissionAccess');
    const [showDialog, setShowDialog] = useState(false);
    const [findReportShowDialog, setFindReportShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [summaryData, setSummaryData] = useState({
        totalItems: 0,
        expiringItems: 0,
        receivedItems: 0,
        issuedItems: 0,
    });
    const [period, setPeriod] = useState('');

    useEffect(() => {
        const fetchSummaryData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}warehouse/summary.php`);
                if (!response.ok) {
                    throw new Error('Failed to fetch summary data');
                }
                const data = await response.json();
                console.log('Fetched data:', data.data);
                setSummaryData(data.data || {
                    totalItems: 0,
                    expiringItems: 0,
                    receivedItems: 0,
                    issuedItems: 0,
                });
            } catch (error) {
                console.error('Error fetching summary data:', error); // Debug log
            } finally {
                setLoading(false);
            }
        };

        fetchSummaryData();
    }, []);

    const fetchWarehouseData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}warehouse/weeklyreport.php`);
            if (!response.ok) {
                throw new Error('Failed to fetch warehouse data');
            }
            const data = await response.json();
            console.log('Fetched data:', data.data);

            // Extract and set the period from the API response
            if (data.data && data.data.length > 0) {
                const dates = Array.from(new Set(data.data.flatMap(product => product.daily_transactions.map(transaction => transaction.date)))).sort();
                const periodStart = new Date(dates[0]).toLocaleDateString('id-ID', { day: '2-digit', month: 'long', year: 'numeric' });
                const periodEnd = new Date(dates[dates.length - 1]).toLocaleDateString('id-ID', { day: '2-digit', month: 'long', year: 'numeric' });
                setPeriod(`${periodStart} - ${periodEnd}`);
            }

            return data.data || [];
        } catch (error) {
            console.error('Error fetching warehouse data:', error); // Debug log
            return [];
        }
    };

    const handleNewClick = (event) => {
        event.preventDefault();
        setShowDialog(true);
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleNewClickFindReportShowDialog = (event) => {
        event.preventDefault();
        setFindReportShowDialog(true);
    };

    const handleCloseFindReportShowDialog = () => {
        setFindReportShowDialog(false);
    };

    const handleExportClick = async () => {
        setLoading(true);
        try {
            const data = await fetchWarehouseData();
            await WarehouseExportExcel({ excelData: data, fileName: 'WeeklyStockReport', period });
        } catch (error) {
            console.error('Error exporting data:', error); // Debug log
        } finally {
            setLoading(false);
        }
    };

    const handleDirectFind = () => {
        navigate('/warehouseIndex/Find');
    }

    return (
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive />
                <SalesNonActive />
                <PurchaseNonActive />
                <FinanceNonActive />
                <WarehouseActive />
                <HRNonActive />
                <AnalyticsnonActive />
                <DocumentnonActive />
                <SettingNonActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                {showDialog && <NewWarehouseDialog onCloseDialog={handleCloseDialog} />}
                {findReportShowDialog && <FindProductWarehouseReport onCloseDialog={handleCloseFindReportShowDialog} />}
                <ProfilSearchBox username={username} />
                {permissionAccess === 'Full access' || permissionAccess === 'Warehouse only' || permissionAccess === 'Sales only' ? (
                    <div>
                        {loading ? <Loader /> : (
                            <div>
                                <div className='grid-container-smaller'>
                                    <div className='MenuName'>Warehouse Module</div>
                                    <div className='create-new-button' onClick={handleNewClick}>Atur Barang</div>
                                </div>
                                <FourCardGrid
                                    headline="Warehouse"
                                    title1="Total Barang"
                                    content1={summaryData.totalItems}
                                    title2="Barang Akan Expired"
                                    content2={summaryData.expiringItems}
                                    title3="Barang Masuk"
                                    content3={summaryData.receivedItems}
                                    title4="Barang Keluar"
                                    content4={summaryData.issuedItems}
                                />
                                <div className='grid-container'>
                                    <div className="card-for-grid-divide-two" onClick={handleExportClick}>
                                        <img src={W3Image} className='UnderConstructionImage'></img>
                                        <div className='settings-title'>Buat Laporan Stock Mingguan</div>
                                        <div className="settings-desc-2">Klik disini untuk membuat laporan stock mingguan dan hasil akan berbentuk file Excel</div>
                                    </div>
                                    <div className="card-for-grid-divide-two" onClick={handleNewClickFindReportShowDialog}>
                                        <img src={W2Image} className='UnderConstructionImage'></img>
                                        <div className='settings-title'>Buat Catatan Stock</div>
                                        <div className="settings-desc-2">Klik disini untuk membuat catatan stock dan hasil akan berbentuk file Excel</div>
                                    </div>
                                    <div className="card-for-grid-divide-two" onClick={handleDirectFind}>
                                        <img src={W1Image} className='UnderConstructionImage'></img>
                                        <div className='settings-title'>Cari Catatan Stock</div>
                                        <div className="settings-desc-2">Klik disini untuk membuat catatan stock dan hasil akan berbentuk file Excel</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <div className='grid-container-smaller'>
                            <div className='MenuName'>Warehouse Module</div>
                        </div>
                        <NotFoundPage menuName={'Warehouse'}/>
                    </div>
                )}
            </div>
        </div>
    );
}

export default WarehouseIndex;
