import React, { useState, useEffect } from 'react';
import LogoutIcon from '../../../assets/Icon/Logout.png';
import { useNavigate } from 'react-router-dom';

function Logout(){
    const navigate = useNavigate();
    const [loginTime, setLoginTime] = useState(null);

    const handleLogout = (event) => {
        localStorage.removeItem('loggedInTime');
        localStorage.removeItem('username');
        localStorage.removeItem('permissionAccess');
        localStorage.removeItem('companyId');
        setLoginTime(null);
        navigate('/'); 
    };

    return(
        <div className="MenuContainer" onClick={handleLogout}>
            <img src={LogoutIcon} />
            <div className='MenuLogout'>Logout</div>
        </div>
    );
}

export default Logout;