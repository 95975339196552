import React, { useState, useEffect } from 'react';
import FormLabel from "../../component/FormLabel";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesActive from "../../component/menu/SalesActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL } from '../../../App';
import TextBox from '../../component/TextBox';
import Loader from '../../component/Loader';
import TextArea from '../../component/TextArea';
import "react-datepicker/dist/react-datepicker.css";
import { FaTrash } from 'react-icons/fa';
import ErrorDialog from '../../component/dialog/ErrorDialog';

function NewSPPB() {
    const navigate = useNavigate();
    let { SONumber } = useParams();
    const { username } = useAuthSession();
    const [startDate, setStartDate] = useState(new Date());
    const [detailSalesOrder, setDetailSalesOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');

    useEffect(() => {
        const fetchSalesDetail = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}sales/getdetailsalesorder.php?SONumber=${SONumber}`);
                if (!response.ok) {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sales order data');
                    setShowDialog(true);
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setDetailSalesOrder(data.Data);
                    const itemsWithAddress = data.Data.Items.map(item => ({
                        ...item,
                        SOSendTo: item.SOSendTo || data.Data.Details[0].company_address,
                    }));
                    setDetailSalesOrder({
                        ...data.Data,
                        Items: itemsWithAddress,
                    });
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sales order data');
                    setShowDialog(true);
                }
            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail sales order data : ', error.message);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        if (SONumber) {
            fetchSalesDetail();
        }
    }, [SONumber]);

    const handleSubmit = async () => {
        let valid = true;
        let validationMessage = '';

        detailSalesOrder.Items.forEach((item, index) => {
            if (!item.SOSendTo || !item.SOSendDate) {
                valid = false;
                validationMessage += `Item #${index + 1} is missing "Dikirim ke" or "Tgl Kirim". `;
            }
        });

        if (!valid) {
            setErrorCode('400');
            setErrorTitle(validationMessage);
            setShowDialog(true);
            return;
        } else {
            setLoading(true);
            try{
                const API = API_BASE_URL + 'sales/insertsppb.php';

                let params = new URLSearchParams({
                    sppb_number: detailSalesOrder.Details[0].SONumber,
                    sppb_date: startDate,
                    customer_id: detailSalesOrder.Details[0].company_id,
                    insert_by: username,
                    product_length: detailSalesOrder.Items.length
                });

                detailSalesOrder.Items.forEach((item, index) => {
                    const description = item.Description || '';
        
                    params.append(`SPPB_PO_${index + 1}`, item.purchaseOrderNumber);
                    params.append(`sppb_send_to_${index + 1}`, item.SOSendTo);
                    params.append(`sppb_send_date_${index + 1}`, item.SOSendDate);
                    params.append(`sppb_product_name_${index + 1}`, item.ProductName);
                    params.append(`sppb_quantity_${index + 1}`, item.Quantity);
                    params.append(`sppb_measure_${index + 1}`, item.uomID);
                    params.append(`sppb_desc_${index + 1}`, description);
                });

                const response = await fetch(API, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: params
                });

                if(response.status === 200){
                    navigate(`/salesIndex`);
                }

                if(response.status === 500){
                    setErrorCode('400');
                    setErrorTitle('Error failed to submit SPPB data');
                    setShowDialog(true);
                }

            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error failed to submit SPPB data', error);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleDeleteItem = (index) => {
        const updatedItems = detailSalesOrder.Items.filter((_, i) => i !== index);
        setDetailSalesOrder({
            ...detailSalesOrder,
            Items: updatedItems
        });
    };

    const handleInputChange = (index, field, value) => {
        const updatedItems = detailSalesOrder.Items.map((item, i) =>
            i === index ? { ...item, [field]: value } : item
        );
        setDetailSalesOrder({
            ...detailSalesOrder,
            Items: updatedItems
        });
    };


    const handleCloseDialog = () => {
        setShowDialog(false);
    };
    
    return (
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive />
                <SalesActive />
                <PurchaseNonActive />
                <FinanceNonActive />
                <WarehouseNonActive />
                <HRNonActive />
                <AnalyticsnonActive />
                <DocumentnonActive />
                <SettingNonActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Sales Module</div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                { loading ? (<Loader/>) : 
                detailSalesOrder ? (
                    <div>
                        <div className='card-for-grid'>
                            <div className='title-for-card'>New Surat Permintaan Pengeluaran Barang (SPPB)</div>
                            {/* First Row included order number, date */}
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"No. SPPB"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailSalesOrder.Details[0].SONumber} readOnly />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Date"} />
                                    <DatePicker
                                        dateFormat="dd MMMM yyyy"
                                        className="datepicker"
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                    />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Customer"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailSalesOrder.Details[0].company_name} readOnly />
                                </div>
                            </div>
                            <div className="scrollable-horizontal">
                                {detailSalesOrder.Items.map((item, index) => (
                                    <div key={index}>
                                        <div className='ItemNameTemplateForm'>
                                            <FormLabel labelName={`Item #${index + 1}`} />
                                            <div onClick={() => handleDeleteItem(item.id)} className='DeleteTemplateForm'>
                                                <FaTrash className="icon" />
                                                Delete {item.label}
                                            </div>
                                        </div>
                                        <div className='grid-container-scrollable'>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"PO Customer"} />
                                                <TextBox value={item.purchaseOrderNumber} readOnly />
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"Dikirim ke"} />
                                                <TextArea
                                                    value={item.SOSendTo || ''}
                                                    onChange={(e) => handleInputChange(index, 'SOSendTo', e.target.value)}
                                                />
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"Tgl Kirim"} />
                                                <DatePicker
                                                    dateFormat="dd MMMM yyyy"
                                                    className="datepicker"
                                                    selected={item.SOSendDate ? new Date(item.SOSendDate) : null}
                                                    onChange={(date) => handleInputChange(index, 'SOSendDate', date)}
                                                />
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"Nama Barang"} />
                                                <TextBox value={item.ProductName} readOnly />
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"QTY"} />
                                                <TextBox value={item.Quantity} onChange={(e) => handleInputChange(index, 'Quantity', e.target.value)}/>
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"SAT"} />
                                                <TextBox value={item.uomName} readOnly />
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"Keterangan"} />
                                                <TextBox
                                                    value={item.Description || ''}
                                                    onChange={(e) => handleInputChange(index, 'Description', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='grid-container-with-padding-left'>
                            {/* <div className="only-100-width-card-reject" onClick={handleUploadPDF}>Upload PDF</div> */}
                            <div className="only-100-width-card-approve" onClick={handleSubmit}>Submit</div>
                        </div>
                    </div>
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
}

export default NewSPPB;
