import React from 'react';
import DashboardNonActive from '../../component/menu/DashboardNonActive';
import SalesNonActive from '../../component/menu/SalesNonActive';
import PurchaseNonActive from '../../component/menu/PurchaseNonActive';
import FinanceNonActive from '../../component/menu/FinanceNonActive';
import WarehouseNonActive from '../../component/menu/WarehouseNonActive';
import HRActive from '../../component/menu/HRActive';
import AnalyticsnonActive from '../../component/menu/AnalyticsNonActive';
import DocumentnonActive from '../../component/menu/DocumentNonActive';
import SettingNonActive from '../../component/menu/SettingNonActive';
import Logout from '../../component/menu/Logout';
import ProfilSearchBox from '../../component/ProfilSearchBox';
import useAuthSession from '../../services/Session';
import NotFoundPage from '../../component/maintanance/NotFound';

function EmployeeList(){
    const { username } = useAuthSession();
    const permissionAccess = localStorage.getItem('permissionAccess');
    
    return(
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseNonActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                {permissionAccess === 'Full access'  ? 
                    (
                        <div>
                            
                            
                            {/* <div></div> */}
                            
                        </div>
                    ) : 
                    (<NotFoundPage menuName={'HR'}/>)
                }
            </div>
        </div>
    );
}

export default EmployeeList;