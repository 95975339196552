import React, { useState, useEffect } from 'react';
import TextBox from '../TextBox';
import ErrorBox from '../ErrorBox';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import { API_BASE_URL } from '../../../App';

const DetailPaymentDialog = ({payments, onCloseDialog }) => {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(true);  
    const [error, setError] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleCloseClick = () => {
        onCloseDialog(); 
    };

    if (!showDialog) {
        return null; 
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const dialogBox = <ErrorBox onCloseDialog={handleCloseDialog} title={errorTitle} message={errorMessage} />;

    const handleDeleteClick = async (event) => {
        setLoading(true);
        try {
            const apiDeletePayment = `${API_BASE_URL}master/payment/deletepayment.php`;
            const response = await fetch(apiDeletePayment, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    payment_id: payments.Id,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to delete payment data');
            }

            if (response.status === 500) {
                setErrorTitle("Delete Failed");
                setErrorMessage("Check your field again !!");
                setShowDialog(true);
            }

            if(response.status === 200){
                navigate('/settingIndex');
            }
        } catch (error){
            setErrorTitle("Delete Users Failed");
            setErrorMessage("An error occurred during delete. Please try again later.");
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            Detail Payment Data
                        </div>
                        {error && dialogBox}
                        {loading ? (<Loader/>) : (
                            <form>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Payment Name</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={payments["Payment Method"]}/>
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        
                                    </div>
                                </div>
                            </form>
                        )}
                        <div className='space-between'/>
                        <div className='grid-container-form-submit'>
                            <div className='redFont' onClick={handleDeleteClick}>Delete</div>
                            <div className='space-between-width'/>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Close</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailPaymentDialog;