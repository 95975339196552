import dashboardNonActiveIcon from '../../../assets/Icon/DashboardInactive.png';
import { useNavigate } from 'react-router-dom';

function DashboardNonActive(){
    const navigate = useNavigate();

    const handleHomepage = (event) => {
        navigate('/main/homePage'); 
    };

    return(
        <div className="MenuContainerNonActive" onClick={handleHomepage}>
            <img src={dashboardNonActiveIcon} />
            <div className='MenuNonActive'>Dashboard</div>
        </div>
    );
}

export default DashboardNonActive;