import WarehouseNonActiveIcon from '../../../assets/Icon/WarehouseActive.png';
import { useNavigate } from 'react-router-dom';

function WarehouseActive(){
    const navigate = useNavigate();

    const handleHomepage = (event) => {
        navigate('/warehouseIndex'); 
    };

    return (
        <div className="MenuContainer" onClick={handleHomepage}>
            <img src={WarehouseNonActiveIcon} />
            <div className='MenuActive'>Warehouse</div>
        </div>
    );
}

export default WarehouseActive;