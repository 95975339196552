import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';

const WarehouseExportExcelProductCodeBased = ({ excelData, fileName }) => {
    if (!excelData || excelData.length === 0) {
        console.error('No data to export');
        return;
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const currentDateTime = format(new Date(), 'yyyyMMdd_HHmmss');

    const ws = XLSX.utils.json_to_sheet([]);

    // Merge cells for product code and name
    ws['!merges'] = [
        { s: { r: 1, c: 1 }, e: { r: 1, c: 2 } }, // Kode Produk merge
        { s: { r: 2, c: 1 }, e: { r: 2, c: 2 } }  // Nama Produk merge
    ];

    // Add Kode Produk and Nama Produk rows
    XLSX.utils.sheet_add_aoa(ws, [[`Kode Produk : ${excelData['Kode Barang']}`]], { origin: 'B2' });
    XLSX.utils.sheet_add_aoa(ws, [[`Nama Produk : ${excelData['Nama Barang']}`]], { origin: 'B3' });

    // Add headers
    XLSX.utils.sheet_add_aoa(ws, [
        ['TGL', 'KETERANGAN', 'MASUK', 'KELUAR', 'SISA']
    ], { origin: 'B4' });

    // Format tanggal dan tambahkan data transaksi
    const formattedData = excelData.Transaksi.map(transaction => ({
        'TGL': format(new Date(transaction['Tanggal Transaksi']), 'd MMMM yyyy', { locale: id }),
        'KETERANGAN': transaction['Keterangan/Customer'],
        'MASUK': transaction['Jumlah Barang Masuk'],
        'KELUAR': transaction['Jumlah Barang Keluar'],
        'SISA': transaction['Sisa Barang']
    }));

    XLSX.utils.sheet_add_json(ws, formattedData, { origin: 'B5', skipHeader: true });

    const wb = { Sheets: { 'Sheet1': ws }, SheetNames: ['Sheet1'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `${fileName}_${currentDateTime}${fileExtension}`);
};

export default WarehouseExportExcelProductCodeBased;
