import React, { useState, useEffect } from 'react';
import { FormLabel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../App';
import TextBox from '../TextBox';
import WarehouseExportExcelProductCodeBased from '../../services/WarehouseExcelExportPCBased';

const FindProductWarehouseReport = ({ onCloseDialog }) => {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(false);
    const [kodeProduk, setKodeProduk] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    useEffect(() => {
        setShowDialog(true); 
    }, []);

    const handleCloseClick = () => {
        onCloseDialog(); 
    };

    const handleKodeProdukChange = async (e) => {
        const value = e.target.value;
        setKodeProduk(value);

        if (value.length > 0) {
            try {
                const response = await fetch(`${API_BASE_URL}warehouse/productquery.php?find=${value}`);
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setSuggestions(data.products);
                    setShowSuggestions(true);
                } else {
                    setSuggestions([]);
                    setShowSuggestions(false);
                }
            } catch (error) {
                console.error("Error fetching product suggestions:", error);
                setSuggestions([]);
                setShowSuggestions(false);
            }
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setKodeProduk(suggestion.skuID);
        setShowSuggestions(false);
    };

    const handleSubmitClick = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}warehouse/getalltransaction.php?kodeBarang=${kodeProduk}`);
            const result = await response.json();
            if (result.StatusCode === 200) {
                WarehouseExportExcelProductCodeBased({ excelData: result.Data, fileName: `Transaksi_${kodeProduk}` });
            } else {
                alert(result.Message);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            alert("Error fetching data");
        }
    };

    return(
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            Pencarian Laporan Stock Produk 
                        </div>
                        <div className='TwoGridForm'>
                            <div className='TwoGridFormContainer'>
                                <label className='TwoGridFormLabel'>Kode Produk</label>
                                <div className='space-between-1vh'></div>
                                <TextBox placeholder={"Masukkan kode produk"} value={kodeProduk} onChange={handleKodeProdukChange} />
                                    {showSuggestions && (
                                        <ul className="suggestions-list">
                                            {suggestions.map((suggestion, index) => (
                                                <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                                    {suggestion.skuID} - {suggestion.productName}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                            </div>
                        </div>
                        <div className='space-between'/>
                        <div className='grid-container-form-submit'>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Tutup</div>
                            <div className='space-between-width' />
                            <div className='verification-button' onClick={handleSubmitClick}>Export ke Excel</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FindProductWarehouseReport;
