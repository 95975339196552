import React, { useState } from 'react';
import TextBox from '../TextBox';
import TextArea from '../TextArea';
import ErrorBox from '../ErrorBox';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import { API_BASE_URL } from '../../../App';

const NewCustomerDialog = ({ onCloseDialog }) => {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(true);  
    const [customerName, setCustomerName] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [customerAddress, setCustomerAddress] = useState('');
    const [customerPICName, setCustomerPICName] = useState('');
    const [customerPICContact, setCustomerPICContact] = useState('');
    const [customerTOP, setCustomerTOP] = useState('');
    const [error, setError] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleCloseClick = () => {
        onCloseDialog(); 
    };

    if (!showDialog) {
        return null; 
    }

    const handleSubmit = async (event) => {
        event.preventDefault(); 
    
        if (
            !customerName ||
            !customerPhone ||
            !customerAddress ||
            !customerPICName ||
            !customerPICContact
        ) {
            setErrorTitle("Submission Failed");
            setErrorMessage("Please fill in all fields !!");
            setError("Please fill in all fields");
            return;
        }

        if (isNaN(customerTOP)) {
            setErrorTitle("Invalid Input");
            setErrorMessage("Customer TOP should be a number.");
            setError("Customer TOP should be a number");
            return;
        }
    
        setLoading(true);
        try {
            const apiInsertCustomer = `${API_BASE_URL}master/customer/insertcustomer.php`;
            const response = await fetch(apiInsertCustomer, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    company_id: '1252f67e-bfda-11ee-9dcf-0e799759a249',
                    company_name: customerName,
                    company_address: customerAddress,
                    company_phone: customerPhone,
                    company_pic_name: customerPICName,
                    company_pic_contact: customerPICContact,
                    company_top: customerTOP
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to insert customer data');
            }

            if (response.status === 500) {
                setErrorTitle("Insert Failed");
                setErrorMessage("Check your field again !!");
                setShowDialog(true);
            }

            if(response.status === 200){
                
                navigate('/settingIndex');
                setCustomerName('');
                setCustomerPhone('');
                setCustomerAddress('');
                setCustomerPICName('');
                setCustomerPICContact('');
                setCustomerTOP('');
            }
        } catch (error) {
            setErrorTitle("Insert Customer Failed");
            setErrorMessage("An error occurred during insert. Please try again later.");
            setShowDialog(true);
        } finally {
            setLoading(false); // End loading
        }

        setCustomerName('');
        setCustomerPhone('');
        setCustomerAddress('');
        setCustomerPICName('');
        setCustomerPICContact('');
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const dialogBox = <ErrorBox onCloseDialog={handleCloseDialog} title={errorTitle} message={errorMessage} />;
    

    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            New Customer Data
                        </div>
                        {error && dialogBox}
                        {loading ? (<Loader/>) : (
                            <form>
                            <div className='TwoGridForm'>
                                <div className='TwoGridFormContainer'>
                                    <label className='TwoGridFormLabel'>Customer Name</label>
                                    <div className='space-between-1vh'></div>
                                    <TextBox 
                                        value={customerName}
                                        onChange={(e) => setCustomerName(e.target.value)}
                                        placeholder="PT.xxxx xxxx xxxx" type="text"
                                    />
                                </div>
                                <div className='space-between-width-10'></div>
                                <div className='TwoGridFormContainer'>
                                    <label className='TwoGridFormLabel'>Customer Phone</label>
                                    <div className='space-between-1vh'></div>
                                    <TextBox 
                                        value={customerPhone}
                                        onChange={(e) => setCustomerPhone(e.target.value)}
                                        placeholder="(021) xxxx xxxx" type="text" 
                                    />
                                </div>
                            </div>
                            <div className='TwoGridForm'>
                                <div className='TwoGridFormContainer'>
                                    <label className='TwoGridFormLabel'>Customer Address</label>
                                    <div className='space-between-1vh'></div>
                                    <TextArea 
                                        value={customerAddress}
                                        onChange={(e) => setCustomerAddress(e.target.value)}
                                        placeholder="Jl. Axxx Xxxxxx, Kota Xxxx, Provinsi Xxxxx" type="text" maxLines={5}
                                    />
                                </div>
                                <div className='space-between-width-10'></div>
                                <div className='TwoGridFormContainer'>
                                    <label className='TwoGridFormLabel'>Customer PIC Name</label>
                                    <div className='space-between-1vh'></div>
                                    <TextBox 
                                        value={customerPICName}
                                        onChange={(e) => setCustomerPICName(e.target.value)}
                                        placeholder="Enter customer PIC name" type="text"
                                    />
                                </div>
                            </div>
                            <div className='TwoGridForm'>
                                <div className='TwoGridFormContainer'>
                                    <label className='TwoGridFormLabel'>Customer PIC Contact</label>
                                    <div className='space-between-1vh'></div>
                                    <TextBox 
                                        value={customerPICContact}
                                        onChange={(e) => setCustomerPICContact(e.target.value)}
                                        placeholder="08xx xxxx xxxx" type="text"
                                    />
                                </div>
                                <div className='space-between-width-10'></div>
                                <div className='TwoGridFormContainer'>
                                    <label className='TwoGridFormLabel'>Customer TOP</label>
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={customerTOP}
                                        onChange={(e) => setCustomerTOP(e.target.value)} placeholder="30" type="number"/>
                                </div>
                            </div>
                            
                        </form>
                        )}
                        <div className='space-between'/>
                        <div className='grid-container-form-submit'>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Close</div>
                            <div className='space-between-width'/>
                            <div className='verification-button' onClick={handleSubmit}>Submit</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewCustomerDialog;
