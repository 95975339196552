import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NewWarehouseDialog = ({ onCloseDialog }) => {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(false);
    const [selectedOption, setSelectedOption] = useState('warehouseIn'); // Default selected option

    useEffect(() => {
        setShowDialog(true); 
    }, []);

    const handleCloseClick = () => {
        onCloseDialog(); // Call the onCloseDialog function passed from the parent component
    };

    const handleSubmitClick = () => {
        // Navigate based on the selected option value
        if (selectedOption === 'warehouseIn') {
            navigate('/warehouseIndex/New/WarehouseIn');
        } else if (selectedOption === 'warehouseOthers') {
            navigate('/warehouseIndex/New/WarehouseOut');
        } else if (selectedOption === 'warehouseOut') {
            navigate('/warehouseIndex/New/WarehouseOutCustomer');
        } 
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            Pencatatan Barang
                        </div>
                        <select className='new-form-dropdown' value={selectedOption} onChange={handleOptionChange}>
                            <option value="warehouseIn">Barang Masuk</option>
                            <option value="warehouseOut">Barang Keluar</option>
                            <option value="warehouseOthers">Barang Sample</option>
                        </select>
                        <div className='space-between'/>
                        <div className='grid-container-form-submit'>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Tutup</div>
                            <div className='space-between-width'/>
                            <div className='verification-button' onClick={handleSubmitClick}>Berikutnya</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewWarehouseDialog;
