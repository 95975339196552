import { useNavigate } from "react-router-dom";
import useAuthSession from "../../services/Session";
import { useEffect, useState } from "react";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import SalesActive from "../../component/menu/SalesActive";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import Logout from "../../component/menu/Logout";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import ExportExcel from "../../services/ExcelExportServices";
import { API_BASE_URL, formatDateTime } from "../../../App";
import Loader from "../../component/Loader";
import ErrorDialog from "../../component/dialog/ErrorDialog";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function SeeAllProfit() {
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [loading, setLoading] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [salesOrder, setSalesOrder] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [searchTrigger, setSearchTrigger] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetchSalesData();
                setSalesOrder(response);
            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error fetching sales profit data:', error.message);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [searchTrigger]);

    const fetchSalesData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}sales/getallprofit.php?startdate=${startDate.toISOString().split('T')[0]}&enddate=${endDate.toISOString().split('T')[0]}`);
            if (!response.ok) {
                throw new Error('Failed to fetch sales data');
            }
            const data = await response.json();
            return data.Data || [];
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const handleExportClick = async () => {
        setIsExporting(true);
        try {
            await ExportExcel({ excelData: salesOrder, fileName: 'sales_profit' });
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error exporting to Excel:', error.message);
            setShowDialog(true);
        }
        setIsExporting(false);
    };

    const handleClick = (PONumber) => {
        navigate(`/salesIndex/Detail/Profit?SONumber=${encodeURIComponent(PONumber)}`)
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleSearchClick = () => {
        setSearchTrigger(!searchTrigger);
    };

    return (
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesActive/>
                <PurchaseNonActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div className='grid-container-smaller'>
                    <div className='MenuName'>See All Profit</div>
                </div>
                <div className='grid-container-smaller'>
                    <div className='FilterDate'>
                        {/* <DatePicker
                            className='new-form-dropdown'
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            placeholderText='Select start date'
                        />
                        <DatePicker
                            className='new-form-dropdown'
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            placeholderText='Select end date'
                        />
                        <button className='FilterSearch' onClick={handleSearchClick}>Search</button> */}
                    </div>
                    <button className='FilterExport' onClick={handleExportClick} disabled={isExporting}>
                        {isExporting ? 'Exporting...' : 'Export to Excel'}
                    </button>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? <Loader/> : (
                    salesOrder.map((sales, index) => (
                        <div key={index} className='seeAllCard' onClick={() => handleClick(sales.SalesNumber)}>
                            <div className='grid-container-smaller'>
                                <div>
                                    <div className='seeAllTitle'>{sales.SalesNumber}</div>
                                    <div className='seeAllDetail'>Sales order date {formatDateTime(sales.InsertDt)}</div>
                                </div>
                                <div className='seeAllDetailCard'>{sales.SO_Status_Name}</div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default SeeAllProfit;
