import ProfilSearchBox from "../../component/ProfilSearchBox";
import FourCardGrid from "../../component/card/FourCardGrid";
import NotFoundPage from "../../component/maintanance/NotFound";
import UnderConstruction from "../../component/maintanance/UnderConstruction";
import AnalyticsActive from "../../component/menu/AnalyticsActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../App";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function AnalyticsIndex(){
    const { username } = useAuthSession();
    const permissionAccess = localStorage.getItem('permissionAccess');
    const [salesData, setSalesData] = useState([]);
    const [purchaseData, setPurchaseData] = useState([]);
    const [outstandData, setOutstandData] = useState(0);
    const [customerOutstandData, setCustomerOutstandData] = useState(0);
    const [loading, setLoading] = useState(false);
    const [index, setIndex] = useState(false);
    const [customerOutstand, setCustomerOutstand] = useState([]);
    const [orderCountData, setOrderCountData] = useState([]);
    const [topPurchaseProductsData, setTopPurchaseProductsData] = useState([]);
    const [salesOutstandCustomers, setSalesOutstandCustomers] = useState([]);
    const [targetSales, setTargetSales] = useState(0);
    const [currentSales, setCurrentSales] = useState(0);
    const [topSalesProductsData, setTopSalesProductsData] = useState([]);


    useEffect(() => {
        const fetchSalesStatistic = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}master/getoveralldashboard.php`);
                if (!response.ok) {
                    throw new Error('Failed to fetch sales order data');
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setIndex(data.Data);
                    setSalesData(data.Data.sales_chart);
                    setPurchaseData(data.Data.purchase_chart);
                    setOutstandData(data.Data.total_outstand_supplier);
                    setCustomerOutstandData(data.Data.total_outstand_customer);
                    setOrderCountData(data.Data.order_count_by_country);
                    setTopPurchaseProductsData(data.Data.top_purchase_products);
                    setSalesOutstandCustomers(data.Data.sales_outstand_customers);
                    setTargetSales(data.Data.total_target);
                    setCurrentSales(data.Data.total_sales);
                    setTopSalesProductsData(data.Data.top_sales_products);
                } else {
                    // Handle the case when the API returns an error status code
                }
            } catch (error) {
                // Handle the error appropriately
                console.error('Error fetching the data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSalesStatistic();
    }, []);

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const chartData = {
        labels: salesData.map(item => monthNames[item.month - 1]),
        datasets: [
            {
                label: 'Total Sales',
                data: salesData.map(item => item.total_sales),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
        ],
    };

    const purchaseChartData = {
        labels: purchaseData.map(item => monthNames[item.month - 1]),
        datasets: [
            {
                label: 'Total Import',
                data: purchaseData.map(item => item.total_import),
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
            },
            {
                label: 'Total Local',
                data: purchaseData.map(item => item.total_local),
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
            },
        ],
    };

    const outstandChartData = {
        labels: ['Outstanding Supplier'],
        datasets: [
            {
                label: 'Total Due',
                data: [outstandData],
                backgroundColor: 'rgba(255, 206, 86, 0.6)',
            },
        ],
    };

    const customerOutstandChartData = {
        labels: ['Outstanding Customer'],
        datasets: [
            {
                label: 'Total Due',
                data: [customerOutstandData],
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
            },
        ],
    };

    const orderCountChartData = {
        labels: orderCountData.map(item => item.country),
        datasets: [
            {
                label: 'Order Count',
                data: orderCountData.map(item => item.order_count),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(255, 159, 64, 0.6)',
                ],
            },
        ],
    };

    const topPurchaseProductsChartData = {
        labels: topPurchaseProductsData.map(item => item.product_name),
        datasets: [
            {
                label: 'Total Purchase',
                data: topPurchaseProductsData.map(item => item.total_purchase),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
        ],
    };

    const targetCurrentSalesChartData = {
        labels: ['Target Sales', 'Current Sales'],
        datasets: [
            {
                label: 'Sales',
                data: [index.total_target, index.total_sales],
                backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)'],
            },
        ],
    };

    const topSalesProductsChartData = {
        labels: topSalesProductsData.map(item => item.product_name),
        datasets: [
            {
                label: 'Total Sales',
                data: topSalesProductsData.map(item => item.total_sales),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
        ],
    };
    
    

    const options = {
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false
                }
            },
            y: {
                stacked: true,
                beginAtZero: true,
                grid: {
                    display: true
                }
            }
        }
    };

    return(
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseNonActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                {permissionAccess === 'Full access'  ? 
                    (
                        <div>
                            <div className='grid-container-smaller'>
                                <div className='MenuName'>Analytics Module</div>
                            </div>
                            <div className='card-for-grid'>
                                <div className='grid-container'>
                                    <div className="card-headline">Statistik Purchasing</div>
                                </div>
                                <div className='space-between-1vh'></div>
                                <Bar data={purchaseChartData} options={options} />
                                <div className='space-between-1vh'></div>
                                <div className='space-between-1vh'></div>
                                <div className='grid-container'>
                                    <div className="only-100">
                                        <div className="card-headline">Outstanding To Supplier</div>
                                        <div className='space-between-1vh'></div>
                                        <Bar data={outstandChartData} options={options} />
                                        <div className='space-between-1vh'></div>
                                    </div>
                                    {/* <div className="only-100">
                                        <div className="card-headline">Outstanding To Customer</div>
                                        <div className='space-between-1vh'></div>
                                        <Bar data={customerOutstandChartData} options={options} />
                                        <div className='space-between-1vh'></div>
                                    </div> */}
                                    <div className="only-100">
                                        <div className="card-headline">Supplier Origin</div>
                                        <div className='space-between-1vh'></div>
                                        <Pie data={orderCountChartData} options={{ responsive: true }} />
                                        <div className='space-between-1vh'></div>
                                    </div>
                                </div>
                                <div className='space-between-1vh'></div>
                                <div className='space-between-1vh'></div>
                                <div className='grid-container'>
                                    <div className="card-headline">Top Product Purchasing</div>
                                </div>
                                <Bar data={topPurchaseProductsChartData} options={options} />
                            </div>
                            <div className='card-for-grid'>
                                <div className='grid-container'>
                                    <div className="card-headline">Statistik Sales</div>
                                </div>
                                <div className='space-between-1vh'></div>
                                <Bar data={chartData} options={options} />
                                <div className='space-between-1vh'></div>
                                <div className='space-between-1vh'></div>
                                <div className='grid-container'>
                                    <div className="only-100">
                                        <div className="card-headline">Outstanding Customer</div>
                                        <div className='space-between-1vh'></div>
                                        <Bar data={customerOutstandChartData} options={options} />
                                        <div className='space-between-1vh'></div>
                                    </div>
                                    <div className="only-100">
                                        <div className="card-headline">Target & Sales</div>
                                        <div className='space-between-1vh'></div>
                                        <Bar data={targetCurrentSalesChartData} options={options} />
                                        <div className='space-between-1vh'></div>
                                    </div>
                                </div>
                                <div className='space-between-1vh'></div>
                                <div className='space-between-1vh'></div>
                                <div className='grid-container'>
                                    <div className="card-headline">Top Product Sales</div>
                                </div>
                                <Bar data={topSalesProductsChartData} options={options} />
                            </div>
                            {/* <div className='card-for-grid'>
                                <div className='grid-container'>
                                    <div className="card-headline">Statistik Finance</div>
                                </div>
                            </div>
                            <div className='card-for-grid'>
                                <div className='grid-container'>
                                    <div className="card-headline">Statistik Warehouse</div>
                                </div>
                            </div> */}
                        </div>
                    ) : 
                    (<div>
                        <div className='grid-container-smaller'>
                            <div className='MenuName'>Analytics Module</div>
                        </div>
                        <NotFoundPage menuName={'Analytics'}/>
                    </div>)
                }
            </div>
        </div>
    );
}

export default AnalyticsIndex;
