import { useNavigate, useParams } from "react-router-dom";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesActive from "../../component/menu/SalesActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import { useEffect, useState } from "react";
import Loader from "../../component/Loader";
import TextBox from "../../component/TextBox";
import FormLabel from "../../component/FormLabel";
import { API_BASE_URL, formatCurrencyIDR, formatDate } from "../../../App";
import TextArea from "../../component/TextArea";
import ErrorDialog from "../../component/dialog/ErrorDialog";

function NewSalesInvoice(){
    const { username } = useAuthSession();
    const navigate = useNavigate();
    let { SONumber } = useParams();
    const [detailSO, setdetailSO] = useState(null);
    const [deliverySO, setdeliveryOder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');

    useEffect(() => {
        const fetchSODetail = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}sales/getdetailsalesorder.php?SONumber=${SONumber}`);
                if (!response.ok) {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sales order data');
                    setShowDialog(true);
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setdetailSO(data.Data);
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sales order data');
                    setShowDialog(true);
                }
            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail sales order data', error.message);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        const fetchDeliveryDetail = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}sales/getdetaildelivery.php?SONumber=${SONumber}`);
                if (!response.ok) {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail delivery order data');
                    setShowDialog(true);
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setdeliveryOder(data.Data);
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail delivery order data');
                    setShowDialog(true);
                }
            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail delivery order data', error.message);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        if (SONumber) {
            fetchSODetail();
            fetchDeliveryDetail();
        }
    }, [SONumber]);

    let totalQuantity = 0;
    let totalUnitPrice = 0;
    let totalTax = 0;
    let totalAmount = 0;

    if (detailSO && deliverySO) {
        detailSO.Items.forEach(item => {
            const deliveryItem = deliverySO.Items.find(dItem => dItem.productName === item.ProductName) || {};
            const quantity = parseInt(deliveryItem.productQTY) || 0;
            const unitPrice = item.HargaSatuan || 0;
            const taxPercentage = detailSO.Details[0].PPNPercentage || 0;

            const tax = (quantity * unitPrice * taxPercentage) / 100;
            const total = (quantity * unitPrice) + tax;

            totalQuantity += quantity;
            totalUnitPrice += unitPrice * quantity;
            totalTax += tax;
            totalAmount += total;
        });
    }

    const handleSubmit = async () => {

        setLoading(true);
        try{
            const API = API_BASE_URL + 'sales/insertsalesinvoice.php';
            let params = new URLSearchParams({
                customer_id: detailSO.Details[0].company_id,
                invoice_id: detailSO.Details[0].SONumber,
                invoice_date: deliverySO.Details[0].DeliveryDate,
                ship_to: deliverySO.Details[0].ShipTo,
                bill_to: deliverySO.Details[0].BillTo,
                insert_by: username,
                product_length: detailSO.Items.length,
                total_amount: totalAmount,
            });

            detailSO.Items.forEach((item, index) => {
                const deliveryItem = deliverySO.Items.find(dItem => dItem.productName === item.ProductName) || {};
                const quantity = deliveryItem.productQTY || 0;
                const unitPrice = item.HargaSatuan || 0;
                const taxPercentage = detailSO.Details[0].PPNPercentage || 0;
                const tax = (quantity * unitPrice * taxPercentage) / 100;
    
                params.append(`SO_${index + 1}`, detailSO.Details[0].SONumber);
                params.append(`product_name_${index + 1}`, item.ProductName);
                params.append(`quantity_${index + 1}`, deliveryItem.productQTY);
                params.append(`price_${index + 1}`, item.HargaSatuan);
                params.append(`tax_${index + 1}`, tax);
                params.append(`do_number_${index + 1}`, detailSO.Details[0].SONumber);
            });

            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params
            });

            if(response.status === 200){
                navigate(`/salesIndex`);
            }

            if(response.status === 500){
                setErrorCode('500');
                setErrorTitle('Error failed to submit sales invoice data !!');
                setShowDialog(true);
            }

        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error failed to submit sales invoice data !!', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleUploadPDF = () => {
        setErrorCode('404');
        setErrorTitle('This menu is not available and waiting for document module process');
        setShowDialog(true);
    }

    return(
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesActive/>
                <PurchaseNonActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Sales Module</div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? <Loader/> : (
                    detailSO && deliverySO ? (
                        <div>
                            <div className='card-for-grid'>
                                <div className='title-for-card'>New Sales Invoice</div>
                                <div className='grid-container'>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Customer Name"} />
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={detailSO.Details[0].company_name} />
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Invoice Number"} />
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={detailSO.Details[0].SONumber} />
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Invoice Date"} />
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={formatDate(deliverySO.Details[0].DeliveryDate)} />
                                    </div>
                                </div>
                                <div className='grid-container'>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Bill To"} />
                                        <div className='space-between-1vh'></div>
                                        <TextArea maxLines={5} value={deliverySO.Details[0].BillTo} />
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Ship To"} />
                                        <div className='space-between-1vh'></div>
                                        <TextArea maxLines={5} value={deliverySO.Details[0].ShipTo} />
                                    </div>
                                    <div className="only-100-width">

                                    </div>
                                </div>
                                <div className="scrollable-horizontal">
                                    {detailSO.Items.map((item, index) => {
                                        const deliveryItem = deliverySO.Items.find(dItem => dItem.productName === item.ProductName) || {};
                                        const quantity = deliveryItem.productQTY || 0;
                                        const unitPrice = item.HargaSatuan || 0;
                                        const taxPercentage = detailSO.Details[0].PPNPercentage || 0;

                                        const tax = (quantity * unitPrice * taxPercentage) / 100;
                                        const total = (quantity * unitPrice) + tax;
                                        return(
                                            <div key={index}>
                                                <div className='ItemNameTemplateForm'>
                                                    <FormLabel labelName={`Item #${index + 1}`} />
                                                </div>
                                                <div className='grid-container-scrollable'>
                                                    <div className="only-100-width">
                                                        <FormLabel labelName={"Nama Barang"} />
                                                        <TextBox value={item.ProductName} />
                                                    </div>
                                                    <div className="only-100-width">
                                                        <FormLabel labelName={"QTY"} />
                                                        <TextBox value={deliveryItem.productQTY} />
                                                    </div>
                                                    <div className="only-100-width">
                                                        <FormLabel labelName={"Unit Price"} />
                                                        <TextBox value={formatCurrencyIDR(item.HargaSatuan)} />
                                                    </div>
                                                    <div className="only-100-width">
                                                        <FormLabel labelName={"Tax"} />
                                                        <TextBox value={formatCurrencyIDR(tax)} />
                                                    </div>
                                                    <div className="only-100-width">
                                                        <FormLabel labelName={"Total Amount"} />
                                                        <TextBox value={formatCurrencyIDR(total)} />
                                                    </div>
                                                    <div className="only-100-width">
                                                        <FormLabel labelName={"No. SO"} />
                                                        <TextBox value={detailSO.Details[0].SONumber} />
                                                    </div>
                                                    <div className="only-100-width">
                                                        <FormLabel labelName={"No. DO"} />
                                                        <TextBox value={detailSO.Details[0].SONumber} />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div>
                                        <div className='grid-container-scrollable'>
                                            <div className="only-100-width">
                                                Total
                                            </div>
                                            <div className="only-100-width">
                                                <TextBox value={totalQuantity}/>
                                            </div>
                                            <div className="only-100-width">
                                                <TextBox value={formatCurrencyIDR(totalUnitPrice)}/>
                                            </div>
                                            <div className="only-100-width">
                                                <TextBox value={formatCurrencyIDR(totalTax)}/>
                                            </div>
                                            <div className="only-100-width">
                                                <TextBox value={formatCurrencyIDR(totalAmount)}/>
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='space-between-1vh'></div>
                                <div className='grid-container-with-padding-left'>
                                    <div className="only-100-width-card-reject" onClick={handleUploadPDF}>Upload PDF</div>
                                    <div className="only-100-width-card-approve" onClick={handleSubmit}>Submit</div>
                                </div>
                            </div>
                        </div>
                    ) : <div>No data available</div>
                )}
            </div>
        </div>
    );
}

export default NewSalesInvoice;