import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function useAuthSession() {
    const navigate = useNavigate();
    const [loginTime, setLoginTime] = useState(null);
    const [username, setUsername] = useState('');

    // Check login session and set state
    useEffect(() => {
        const loggedInTime = localStorage.getItem('loggedInTime');
        const storedUsername = localStorage.getItem('username');
        if (loggedInTime) {
            setLoginTime(parseInt(loggedInTime, 10));
            setUsername(storedUsername);
        } else {
            navigate('/'); // Redirect to login if no session time is found
        }
    }, [navigate]);

    // Logout user after 2 hours
    useEffect(() => {
        if (loginTime !== null) {
            const interval = setInterval(() => {
                const elapsedTime = Date.now() - loginTime;
                if (elapsedTime >= 2 * 60 * 60 * 1000) { // 2 hours
                    clearInterval(interval);
                    localStorage.clear(); // Optionally clear the localStorage
                    navigate('/'); // Redirect to login
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [loginTime, navigate]);

    return { username };
}

export default useAuthSession;