import React, { useState } from 'react';

const SuccessDialog = ({ onCloseDialog, successTitle, successMessage, successMenu, successCode }) => {
    const [showDialog, setShowDialog] = useState(true);

    const handleCloseClick = () => {
        onCloseDialog();  // Call the onCloseDialog function passed from the parent component
    };

    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            <span role="img" aria-label="Success Emoji">✅</span> Success {successCode} !!!
                        </div>
                        <div>{successMessage}</div>
                        <div className='grid-container'>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Close</div>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Submit</div>
                        </div>
                        {successMenu && Array.isArray(successMenu) && (
                            <div className='success-menu'>
                                {successMenu.map((item, index) => (
                                    <span key={index} className='success-menu-item'>{item}</span>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessDialog;
