import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const ExportExcel = async ({ excelData, fileName }) => {
    if (!excelData || excelData.length === 0) {
        console.error('No data to export');
        return;
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
    const fileExtension = '.xlsx';

    try {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    } catch (error) {
        console.error('Error generating Excel file:', error);
    }
};

export default ExportExcel;
