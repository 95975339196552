import React from 'react';
import { FaUser } from 'react-icons/fa';

function UsernameTextBox(props) {
  const { value, onChange } = props;

  return (
    <div className="textbox-container">
      <FaUser className="icon" />
      <input
        type="text"
        value={value}
        onChange={onChange}
        className="textbox"
        placeholder="Input your username"
      />
    </div>
  );
}

export default UsernameTextBox;