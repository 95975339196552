import { useNavigate, useParams } from "react-router-dom";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesActive from "../../component/menu/SalesActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import { useEffect, useState } from "react";
import Loader from "../../component/Loader";
import TextBox from "../../component/TextBox";
import FormLabel from "../../component/FormLabel";
import { API_BASE_URL } from "../../../App";
import TextArea from "../../component/TextArea";
import ReactDatePicker from "react-datepicker";
import ErrorDialog from "../../component/dialog/ErrorDialog";

function NewDeliveryOrder(){
    const { username } = useAuthSession();
    const navigate = useNavigate();
    let { SONumber } = useParams();
    const [detailSO, setdetailSO] = useState(null);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');

    useEffect(() => {
        const fetchSODetail = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}sales/getdetailsalesorder.php?SONumber=${SONumber}`);
                if (!response.ok) {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sales order data');
                    setShowDialog(true);
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setdetailSO(data.Data);
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sales order data');
                    setShowDialog(true);
                }
            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error fetching detail sales data:', error.message);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        if (SONumber) {
            fetchSODetail();
        }
    }, [SONumber]);

    const handleApproveAction = async() => {
        setLoading(true);
        try{
            const API = API_BASE_URL + 'sales/insertdeliveryorder.php';

            let params = new URLSearchParams({
                customer_id: detailSO.Details[0].company_id,
                po_number: detailSO.Details[0].purchaseOrderNumber,
                do_number: detailSO.Details[0].SONumber,
                delivery_date: startDate,
                bill_to: `${detailSO.Details[0].company_name}\n${detailSO.Details[0].company_address}`,
                ship_to: `${detailSO.Details[0].company_name}\n${detailSO.Details[0].company_address}`,
                insert_by: username,
                product_length: detailSO.Items.length,
            });

            detailSO.Items.forEach((item, index) => {
                const description = item.keterangan || '';
    
                params.append(`product_name_${index + 1}`, item.ProductName);
                params.append(`quantity_${index + 1}`, item.Quantity);
                params.append(`keterangan_${index + 1}`, description);
            });

            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params
            });


            if(response.status === 200){
                navigate(`/salesIndex`);
            }

            if(response.status === 500){
                setErrorCode('500');
                setErrorTitle('Error failed to fetch submit delivery order data');
                setShowDialog(true);
            }

        } catch (error){
            setErrorCode('500');
            setErrorTitle('Error failed to fetch submit delivery order data : ', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
        
    }

    const handleInputChange = (index, field, value) => {
        const updatedItems = detailSO.Items.map((item, i) =>
            i === index ? { ...item, [field]: value } : item
        );
        setdetailSO({
            ...detailSO,
            Items: updatedItems
        });
    };

    const handleShipToInputChange = (field, value) => {
        setdetailSO({
            ...detailSO,
            Details: [
                {
                    ...detailSO.Details[0],
                    shipTo: value
                }
            ]
        });
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleUploadPDF = () => {
        setErrorCode('404');
        setErrorTitle('This menu is not available and waiting for document module process');
        setShowDialog(true);
    }
    
    return(
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesActive/>
                <PurchaseNonActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Sales Module</div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? <Loader/> : (
                    detailSO ? (
                    <div>
                        <div className='card-for-grid'>
                            <div className='title-for-card'>New Delivery Order</div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Customer Name"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailSO.Details[0].company_name} />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"PO Number"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailSO.Details[0].purchaseOrderNumber} />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Delivery Number"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailSO.Details[0].SONumber} />
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Delivery Date"} />
                                    <div className='space-between-1vh'></div>
                                    <ReactDatePicker
                                        className='datepicker'
                                        dateFormat="dd MMMM yyyy"
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                    />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Bill To"} />
                                    <div className='space-between-1vh'></div>
                                    <TextArea
                                        maxLines={5}
                                        value={`${detailSO.Details[0].company_name}\n${detailSO.Details[0].company_address}`}
                                    />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Ship To"} />
                                    <div className='space-between-1vh'></div>
                                    <TextArea
                                        maxLines={5}
                                        value={`${detailSO.Details[0].company_name}\n${detailSO.Details[0].company_address}`}
                                        onChange={(e) => handleShipToInputChange('shipTo', e.target.value)}
                                    />

                                </div>
                            </div>
                            <div className="scrollable-horizontal">
                                {detailSO.Items.map((item, index) => {
                                    return(
                                        <div key={index}>
                                            <div className='ItemNameTemplateForm'>
                                                <FormLabel labelName={`Item #${index + 1}`} />
                                            </div>
                                            <div className='grid-container-scrollable'>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"Nama Barang"} />
                                                    <TextBox value={item.ProductName} />
                                                </div>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"QTY"} />
                                                    <TextBox
                                                        value={item.Quantity}
                                                        onChange={(e) => handleInputChange(index, 'Quantity', e.target.value)}
                                                    />
                                                </div>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"Keterangan"} />
                                                    <TextBox
                                                        value={item.keterangan || ''}
                                                        onChange={(e) => handleInputChange(index, 'keterangan', e.target.value)}
                                                        placeholder={"Input keterangan"}
                                                    />
                                                </div>
                                                <div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className='grid-container-with-padding-left'>

                            <div className="only-100-width-card-approve" onClick={handleApproveAction}>Submit</div>
                        </div>
                    </div>
                ) : <div>No data available</div>
                 )}
            </div>
        </div>
    );
}

export default NewDeliveryOrder;