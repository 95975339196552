import UnderConstructionVector from '../../../assets/Icon/NotFound.png';

function NotFoundPage({menuName}){
    return (
        <div className="UnderConstructionContainer">
            <img src={UnderConstructionVector} className='UnderConstructionImage'/>
            <div className='UnderConstructionTitle'>⛔️ 404 Not Found ⛔️</div>
            <div className='UnderConstructionCaption'>
                Oops! The <strong>{menuName}</strong> page you're looking for seems to be lost in cyberspace. We're working hard to find it. In the meantime, feel free to explore other areas of our site!
            </div>
        </div>
    );
};

export default NotFoundPage;