import React, { useState, useEffect } from 'react';
import FormLabel from "../../component/FormLabel";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesActive from "../../component/menu/SalesActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL, formatCurrencyIDR } from '../../../App';
import TextBox from '../../component/TextBox';
import Loader from '../../component/Loader';
import ErrorDialog from '../../component/dialog/ErrorDialog';

function NewSPPB(){
    const navigate = useNavigate();
    const { username } = useAuthSession();
    let { SONumber } = useParams();
    const [detailSO, setdetailSO] = useState(null);
    const [detailSPPB, setdetailSPPB] = useState(null);
    const [loading, setLoading] = useState(false);
    const [landedCosts, setLandedCosts] = useState({});
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');

    useEffect(() => {
        const fetchSODetail = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}sales/getdetailsalesorder.php?SONumber=${SONumber}`);
                if (!response.ok) {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sales order data');
                    setShowDialog(true);
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setdetailSO(data.Data);
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sales order data');
                    setShowDialog(true);
                }
            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail sales order data : ', error.message);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        const fetchSPPBDetail = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}sales/getdetailsppb.php?SPPBNumber=${SONumber}`);
                if (!response.ok) {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sppb data');
                    setShowDialog(true);
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setdetailSPPB(data.Data);
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sppb data');
                    setShowDialog(true);
                }
            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail sppb data', error.message);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        if (SONumber) {
            fetchSODetail();
            fetchSPPBDetail();
        }
    }, [SONumber]);

    const parseCurrency = (value) => {
        return parseFloat(value.replace(/[^0-9.-]+/g, ""));
    };

    const handleLandedCostChange = (itemId, value) => {
        setLandedCosts(prevLandedCosts => {
            return {
                ...prevLandedCosts,
                [itemId]: parseCurrency(value)
            };
        });
    };
    
    const calculateProfit = (hargaJual, landedCost) => {
        if (!landedCost) return 0;
        return hargaJual - landedCost;
    };

    const calculateProfitPercentage = (hargaJual, landedCost) => {
        if (!landedCost) return 0;
        return ((hargaJual - landedCost) / landedCost) * 100;
    };

    const totalValues = detailSO ? detailSO.Items.reduce((totals, item, index) => {
        const landedCost = landedCosts[index] || 0;
        const profit = calculateProfit(item.HargaSatuan, landedCost);

        totals.totalQuantity += parseInt(item.Quantity, 10);
        totals.totalHargaJual += item.HargaSatuan * item.Quantity;
        totals.totalLandedCost += landedCost * item.Quantity;
        totals.totalProfit += profit * item.Quantity;
        if (totals.totalLandedCost > 0) {
            totals.totalProfitPercentage = ((totals.totalProfit / totals.totalLandedCost) * 100);
        }
        return totals;
    }, { totalQuantity: 0, totalHargaJual: 0, totalLandedCost: 0, totalProfit: 0, totalProfitPercentage: 0 }) : {};

    const handleSubmit = async () => {
        const hasNaN = Object.values(landedCosts).some(cost => isNaN(cost));

        if (hasNaN === true) {
            setErrorCode('400');
            setErrorTitle('Landed costs must be valid numbers');
            setShowDialog(true);
        } else {
            setLoading(true);
            try{
                const API = API_BASE_URL + 'sales/insertsalesprofit.php';
                let params = new URLSearchParams({
                    sales_number: detailSO.Details[0].SONumber,
                    customer_id: detailSO.Details[0].company_id,
                    insert_by: username,
                    product_length: detailSPPB.Items.length
                });

                detailSO.Items.forEach((item, index) => {
                    const sppbItem = detailSPPB.Items[index];
                    params.append(`PO_${index + 1}`, item.purchaseOrderNumber);
                    params.append(`SO_${index + 1}`, sppbItem.SPPBNumber);
                    params.append(`product_name_${index + 1}`, sppbItem.ProductName);
                    params.append(`quantity_${index + 1}`, sppbItem.Quantity);
                    params.append(`price_${index + 1}`, item.HargaSatuan);
                    params.append(`landed_cost_${index + 1}`, landedCosts[index]);
                });

                const response = await fetch(API, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: params
                });

                if(response.status === 200){
                    navigate(`/salesIndex`);
                }

                if(response.status === 500){
                    setErrorCode('500');
                    setErrorTitle('Error failed to fetch submit sales profit data');
                    setShowDialog(true);
                }

            } catch (error){
                setErrorCode('400');
                setErrorTitle('Error failed to fetch submit sales profit data : ', error);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        }
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleUploadPDF = () => {
        setErrorCode('404');
        setErrorTitle('This menu is not available and waiting for document module process');
        setShowDialog(true);
    }
    
    return (
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive />
                <SalesActive />
                <PurchaseNonActive />
                <FinanceNonActive />
                <WarehouseNonActive />
                <HRNonActive />
                <AnalyticsnonActive />
                <DocumentnonActive />
                <SettingNonActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Sales Module</div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                { loading ? (<Loader/>) : 
                detailSO ? (
                    <div>
                        <div className='card-for-grid'>
                            <div className='title-for-card'>New Profit Calculation</div>
                            {/* First Row included order number, date */}
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Sales Order Number"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailSO.Details[0].SONumber} />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Customer"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailSO.Details[0].company_name} />
                                </div>
                                <div className="only-100-width">
                                    
                                </div>
                            </div>
                            <div className="scrollable-horizontal">
                                {detailSO.Items.map((item, index) => {
                                    const landedCost = landedCosts[index] || 0;
                                    const profit = calculateProfit(item.HargaSatuan, landedCost);
                                    const profitPercentage = calculateProfitPercentage(item.HargaSatuan, landedCost);

                                    return (
                                        <div key={index}>
                                            <div className='ItemNameTemplateForm'>
                                                <FormLabel labelName={`Item #${index + 1}`} />
                                            </div>
                                            <div className='grid-container-scrollable'>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"Nama Barang"} />
                                                    <TextBox value={item.ProductName} />
                                                </div>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"QTY"} />
                                                    <TextBox value={item.Quantity} />
                                                </div>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"Harga Jual Satuan"} />
                                                    <TextBox value={formatCurrencyIDR(item.HargaSatuan)} />
                                                </div>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"Landed Cost"} />
                                                    <TextBox 
                                                        value={landedCost}
                                                        onChange={(e) => handleLandedCostChange(index, e.target.value)}
                                                    />
                                                </div>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"Profit"} />
                                                    <TextBox value={formatCurrencyIDR(profit)} />
                                                </div>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"Profit %"} />
                                                    <TextBox value={`${profitPercentage.toFixed(2)}%`} />
                                                </div>
                                                <div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div>
                                    <div className='grid-container-scrollable'>
                                        <div className="only-100-width">

                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={"Total QTY"} />
                                            <TextBox value={totalValues.totalQuantity} />
                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={"Total Harga Jual Satuan"} />
                                            <TextBox value={formatCurrencyIDR(totalValues.totalHargaJual)} />
                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={"Total Landed Cost"} />
                                            <TextBox value={formatCurrencyIDR(totalValues.totalLandedCost)} />
                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={"Total Profit"} />
                                            <TextBox value={formatCurrencyIDR(totalValues.totalProfit)} />
                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={"Total Profit %"} />
                                            <TextBox value={`${totalValues.totalProfitPercentage.toFixed(2)}%`} />
                                        </div>
                                        <div>
                                       </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='grid-container-with-padding-left'>
                            {/* <div className="only-100-width-card-reject" onClick={handleUploadPDF}>Upload PDF</div> */}
                            <div className="only-100-width-card-approve" onClick={handleSubmit}>Submit</div>
                        </div>
                    </div>
                ) : <Loader />}
            </div>
        </div>
    );
}

export default NewSPPB;
