import dashboardActiveIcon from '../../../assets/Icon/DashboardActive.png';
import { useNavigate } from 'react-router-dom';

function DashboardActive(){
    const navigate = useNavigate();

    const handleHomepage = (event) => {
        navigate('/main/homePage'); 
    };

    return(
        <div className="MenuContainer" onClick={handleHomepage}>
            <img src={dashboardActiveIcon} />
            <div className='MenuActive'>Dashboard</div>
        </div>
    );
}

export default DashboardActive;