import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import { API_BASE_URL } from '../../../App';

const ListProductDialog = ({ onCloseDialog, onSelectPurchaseOrder }) => {
    const [productList, setProductList] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleCloseClick = () => {
        onCloseDialog(); 
    };

    useEffect(() => {
        fetchListProduct();
    }, [])

    const fetchListProduct = async () => {
        setLoading(true);
        try{
            const response  = await fetch(API_BASE_URL + 'master/product/getallproduct.php');
            const data = await response.json();
            setProductList(data.Data);
        } catch (error){
            console.log("Error while getting data product" + error);
        } finally { 
            setLoading(false);
        }
    }

    const handleClickDetail = async (ProductName) => {
        try {
            setLoading(true);
            onSelectPurchaseOrder(ProductName);
        } catch (error) {
            console.error("Error while fetching detail purchase:", error);
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div className='new-form-title'>
                        List Product
                    </div>
                    <div className='scrollable-table-container'>
                        <table className='table-100-w scrollable-vertical'>
                            <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {productList.map(product => (
                                <tr key={product.id}>
                                    <td>{product["Product Name"]}</td>
                                    <td onClick={() => handleClickDetail(product["Product Name"])}>Select</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='space-between'/>
                    <div className='grid-container-form-submit'>
                            <div className='redFont'></div>
                            <div className='space-between-width'/>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Close</div>
                        </div>
                </div>
            </div>
        </div>
    );
}

export default ListProductDialog;