import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import AnalyticsNonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentNonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingActive from "../../component/menu/SettingActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import useAuthSession from "../../services/Session";
import { API_BASE_URL } from '../../../App';
import ErrorDialog from '../../component/dialog/ErrorDialog';
import Loader from '../../component/Loader';
import Pagination from '../../component/Paging';
import NewBankAccountDialog from '../../component/dialog/NewBankAccount';
import NewAccountCode from '../../component/dialog/NewAccountCode';
import NewFinanceCategoryDialog from '../../component/dialog/NewFinanceCategory';
import DetailBankAccount from '../../component/dialog/DetailBankAccount';
import DetailAccountCode from '../../component/dialog/DetailAccountCode';
import DetailFinanceCategory from '../../component/dialog/DetailFinanceCategory';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function TabNavigation() {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [allAccountCode, setAllAccountCode] = useState([]);
  const [allBankAccount, setAllBankAccount] = useState([]);
  const [allFinanceCategory, setAllFinanceCategory] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [bankCurrentPage, setBankCurrentPage] = useState(1);
  const [financeCategoryCurrentPage, setFinanceCategoryCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalBankAccountItems, setTotalBankAccountItems] = useState(0);
  const [totalFinanceCategoryItems, setTotalFinanceCategoryItems] = useState(0);
  const itemsPerPage = 25;
  const [showBankAccountCreationDialog, setShowBankAccountCreationDialog] = useState(false);
  const [showBankAccountDetailDialog, setShowBankAccountDetailDialog] = useState(false);
  const [showAccountCodeCreationDialog, setShowAccountCodeCreationDialog] = useState(false);
  const [showAccountCodeDdetailDialog, setShowAccountCodeDetailDialog] = useState(false);
  const [showFinanceCategoryCreationDialog, setShowFinanceCategoryCreationDialog] = useState(false);
  const [showFinanceCategoryDetailDialog, setShowFinanceCategoryDetailDialog] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedBankAccountId, setSelectedBankAccountId] = useState(null);
  const [selectedFinanceCategoryId, setSelectedFinanceCategoryId] = useState(null);

  useEffect(() => {
      const fetchData = async () => {
          setLoading(true);
          try {
              const response = await fetchAccountCode(currentPage, itemsPerPage);
              if (response.StatusCode === 200) {
                  setAllAccountCode(response.Data);
                  setTotalItems(response.totalItems); // Ensure the API response includes totalItems
              } else {
                  throw new Error(response.Status || 'Unknown error');
              }
          } catch (error) {
              setErrorCode('400');
              setErrorTitle(`Error fetching account code data: ${error.message}`);
              setShowDialog(true);
          } finally {
              setLoading(false);
          }
      };

      const fetchBankData = async () => {
        setLoading(true);
        try{
          const response = await fetchBankAccount(bankCurrentPage, itemsPerPage);
          if (response.StatusCode === 200) {
            setAllBankAccount(response.Data);
            setTotalBankAccountItems(response.totalItems); // Ensure the API response includes totalItems
          } else {
            throw new Error(response.Status || 'Unknown error');
          }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle(`Error fetching bank account data: ${error.message}`);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
      }

      const fetchCategoryData = async () => {
        setLoading(true);
        try{
          const response = await fetchFinanceCategory(financeCategoryCurrentPage, itemsPerPage);
          if (response.StatusCode === 200) {
            setAllFinanceCategory(response.Data);
            setTotalFinanceCategoryItems(response.totalItems); // Ensure the API response includes totalItems
          } else {
            throw new Error(response.Status || 'Unknown error');
          }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle(`Error fetching finance category data: ${error.message}`);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
      }

      fetchData();
      fetchBankData();
      fetchCategoryData();
  }, [currentPage, bankCurrentPage, financeCategoryCurrentPage]);

  const fetchFinanceCategory = async (page, limit) => {
    try{
      const response = await fetch(`${API_BASE_URL}master/finance/getfinancecategory.php?page=${page}&limit=${limit}`);
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Failed to fetch penerimaan');
    }
  }

  const fetchBankAccount = async (page, limit) => {
    try{
      const response = await fetch(`${API_BASE_URL}master/finance/getallbankaccount.php?page=${page}&limit=${limit}`);
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Failed to fetch penerimaan');
    }
  }

  const fetchAccountCode = async (page, limit) => {
    try{
      const response = await fetch(`${API_BASE_URL}master/finance/getallaccountcode.php?page=${page}&limit=${limit}`);
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Failed to fetch penerimaan');
    }
  }
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePageChange = (page) => {
      setCurrentPage(page);
  };

  const handleBankPageChange = (page) => {
    setBankCurrentPage(page);
  };

  const handleCategoryPageChange = (page) => {
    setFinanceCategoryCurrentPage(page);
  };

  const handleCloseDialog = () => {
      setShowDialog(false);
  };

  const handleBankAccountCreateClick = () => setShowBankAccountCreationDialog(true);
  const handleAccountCodeCreateClick = () => setShowAccountCodeCreationDialog(true);
  const handleFinanceCategoryCreateClick = () => setShowFinanceCategoryCreationDialog(true);
  const handleBankAccountDetailClick = (id) => {
    setSelectedBankAccountId(id);
    setShowBankAccountDetailDialog(true);
  };

  const handleAccountCodeDetailClick = (id) => {
    setSelectedAccountId(id);
    setShowAccountCodeDetailDialog(true)
  };

  const handleFinanceCategoryDetailClick = (id) => {
    setSelectedFinanceCategoryId(id);
    setShowFinanceCategoryDetailDialog(true);
  };

  const renderDialog = () => {
    return <NewBankAccountDialog onCloseDialog={(handleCloseDialog) => setShowBankAccountCreationDialog(false)}/>
  }

  const accountCodeRenderDialog = () => {
    return <NewAccountCode onCloseDialog={(handleCloseDialog) => setShowAccountCodeCreationDialog(false)}/>
  }

  const financeCategoryRenderDialog = () => {
    return <NewFinanceCategoryDialog onCloseDialog={(handleCloseDialog) => setShowFinanceCategoryCreationDialog(false)}/>
  }

  const bankAccountDetailRenderDialog = () => {
    return (
      <DetailBankAccount
        onCloseDialog={() => setShowBankAccountDetailDialog(false)}
        id={selectedBankAccountId}
      />
    );
  };

  const accountCodeDetailRenderDialog = () => {
    return (
      <DetailAccountCode 
        onCloseDialog={(handleCloseDialog) => setShowAccountCodeDetailDialog(false)}
        id={selectedAccountId}
      />)
  }

  const financeCategoryDetailRenderDialog = () => {
    return (
      <DetailFinanceCategory 
        id={selectedFinanceCategoryId}
        onCloseDialog={(handleCloseDialog) => setShowFinanceCategoryDetailDialog(false)}
      />
    );
  }

  return (
    <div>
      <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Bank Account" />
        <Tab label="Account Code" />
        <Tab label="Finance Category" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <div className='grid-container'>
          <div className='MenuName'>Bank Account</div>
          <div className='create-new-button' onClick={handleBankAccountCreateClick}>New Bank Account</div>
        </div>
        {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
        {showBankAccountCreationDialog && renderDialog()}
        {showBankAccountDetailDialog && bankAccountDetailRenderDialog()}
        {loading ? <Loader/> : (
          <div className='card-for-grid'>
            {allBankAccount && Array.isArray(allBankAccount) && allBankAccount.length > 0 && (
              <table className='table-layout'>
                <thead className='table-title' style={{ marginBottom: '10px' }}>
                  <tr>
                    <th>Bank Number</th>
                    <th>Bank Name</th>
                    <th>Bank Branch</th>
                  </tr>
                </thead>
                <tbody className='table-content'>
                  {allBankAccount.map((row, index) => (
                    <React.Fragment key ={index}>
                      <tr onClick={() => handleBankAccountDetailClick(row.bank_number)}>
                        <td style={{ padding: '10px' }}>{row.bank_number}</td>
                        <td style={{ padding: '10px' }}>{row.bank_name}</td>
                        <td style={{ padding: '10px' }}>{row.bank_branch}</td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
        <Pagination
          totalItems={totalBankAccountItems} 
          itemsPerPage={itemsPerPage}
          currentPage={bankCurrentPage}
          onPageChange={handleBankPageChange}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className='grid-container'>
          <div className='MenuName'>Account Code</div>
          <div className='create-new-button' onClick={handleAccountCodeCreateClick}>New Account Code</div>
        </div>
        {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
        {showAccountCodeDdetailDialog && accountCodeDetailRenderDialog()}
        {showAccountCodeCreationDialog && accountCodeRenderDialog()}
        {loading ? <Loader/> : (
          <div className='card-for-grid'>
            {allAccountCode && Array.isArray(allAccountCode) && allAccountCode.length > 0 && (
              <table className='table-layout'>
                <thead className='table-title' style={{ marginBottom: '10px' }}>
                  <tr>
                    <th>Code</th>
                    <th>Account Name</th>
                    <th>Account Name Alias</th>
                  </tr>
                </thead>
                <tbody className='table-content'>
                  {allAccountCode.map((row, index) => (
                    <React.Fragment key ={index}>
                      <tr onClick={() => handleAccountCodeDetailClick(row.code)}>
                        <td style={{ padding: '10px' }}>{row.code}</td>
                        <td style={{ padding: '10px' }}>{row.account_name}</td>
                        <td style={{ padding: '10px' }}>{row.account_name_alias}</td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
        <Pagination
          totalItems={totalItems} 
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className='grid-container'>
          <div className='MenuName'>Finance Category</div>
          <div className='create-new-button' onClick={handleFinanceCategoryCreateClick}>New Finance Category</div>
        </div>
        {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
        {showFinanceCategoryDetailDialog && financeCategoryDetailRenderDialog()}
        {showFinanceCategoryCreationDialog && financeCategoryRenderDialog()}
        {loading ? <Loader/> : (
          <div className='card-for-grid'>
            {allFinanceCategory && Array.isArray(allFinanceCategory) && allFinanceCategory.length > 0 && (
              <table className='table-layout'>
                <thead className='table-title' style={{ marginBottom: '10px' }}>
                  <tr>
                    <th>Category Name</th>
                  </tr>
                </thead>
                <tbody className='table-content'>
                  {allFinanceCategory.map((row, index) => (
                    <React.Fragment key ={index}>
                      <tr onClick={() => handleFinanceCategoryDetailClick(row.category_id)}>
                        <td style={{ padding: '10px' }}>{row.category_name}</td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
        <Pagination
          totalItems={totalFinanceCategoryItems} 
          itemsPerPage={itemsPerPage}
          currentPage={financeCategoryCurrentPage}
          onPageChange={handleCategoryPageChange}
        />
      </TabPanel>
    </div>
  );
}

function FinanceDetail(props) {
  const { username } = useAuthSession();

  return (
    <div className="Menu">
      <div className='MenuApp'>
        <div className='MenuSpace'></div>
        <DashboardNonActive />
        <SalesNonActive />
        <PurchaseNonActive />
        <FinanceNonActive />
        <WarehouseNonActive />
        <HRNonActive />
        <AnalyticsNonActive />
        <DocumentNonActive />
        <SettingActive />
        <Logout />
      </div>
      <div className='MainMenu'>
        <ProfilSearchBox username={username} />
        <TabNavigation />
      </div>
    </div>
  );
}

export default FinanceDetail;
