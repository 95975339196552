import { useEffect, useState } from "react";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceActive from "../../component/menu/FinanceActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import Loader from "../../component/Loader";
import FormLabel from "../../component/FormLabel";
import ReactDatePicker from "react-datepicker";
import TextBox from "../../component/TextBox";
import TextArea from "../../component/TextArea";
import { API_BASE_URL, formatCurrencyIDR, formatDate } from "../../../App";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../../component/dialog/ErrorDialog";

function NewPenerimaanPenjualan(){
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [loading, setLoading] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [allBank, setAllBank] = useState([]);
    const [selectedBank, setSelectedBank] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [allCustomer, setAllCustomer] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [chequeDate, setChequeDate] = useState(new Date());
    const [rate, setRate] = useState('');
    const [formNo, setFormNo] = useState('');
    const [chequeNo, setChequeNo] = useState('');
    const [chequeAmount, setChequeAmount] = useState('');
    const [memo, setMemo] = useState('');
    const [allSalesInvoice, setAllSalesInvoice] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [paymentAmounts, setPaymentAmounts] = useState({});

    useEffect(() => {
        fetchBankAccount();
        fetchAllCustomer();
    }, []);

    const fetchAllSalesInvoice = async (customer_id) => {
        setLoading(true);
        setErrorMessage('');
        setCheckedItems([]);
        setPaymentAmounts({});

        try{
            const response = await fetch(`${API_BASE_URL}finance/showssalesinvoice.php?customer_id=${customer_id}`);
            const data = await response.json();

            if (data.StatusCode === 200) {
                setAllSalesInvoice(data.Data);
            } else {
                setErrorMessage('The supplier has not issued any invoice yet.');
                setAllSalesInvoice([]); // Clear previous data
            }
        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error failed to fetch all supplier: ' + error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const fetchBankAccount = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}finance/allbankaccount.php`);
            const data = await response.json();

            if (data.StatusCode === 200) {
                setAllBank(data.Data);
                setSelectedBank(data.Data[0].bank_number);
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch last PO Number');
                setShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch bank account: ' + error.message);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const fetchAllCustomer = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}master/customer/getallcustomer.php?company=1252f67e-bfda-11ee-9dcf-0e799759a249`);
            const data = await response.json();

            if (data.StatusCode === 200) {
                setAllCustomer(data.Data);
                fetchAllSalesInvoice(data.Data[0].supplier_id);

            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch all supplier');
                setShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch all supplier: ' + error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleRateChange = (event) => {
        const formattedAmount = event.target.value.replace(/\D/g, '');
        setRate(new Intl.NumberFormat('id-ID').format(formattedAmount));
    };

    const handleBankAccountChange = (event) => {
        const newBankAccount = event.target.value;
        setSelectedBank(newBankAccount);
    };

    const handleCustomerChange = (event) => {
        const newCustomer = event.target.value;
        setSelectedCustomer(newCustomer);
        fetchAllSalesInvoice(newCustomer);
    };

    const handleMemoChange = (event) => {
        setMemo(event.target.value);
    }

    const handleChequeNumber = (event) => {
        setChequeNo(event.target.value);
    }

    const handleFormNoChange = (event) => {
        setFormNo(event.target.value);
    }

    const handleChequeAmount = (event) => {
        const formattedAmount = event.target.value.replace(/\D/g, '');
        setChequeAmount(new Intl.NumberFormat('id-ID').format(formattedAmount));
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems((prev) => {
        if (checked) {
            // Add item to the checked list
            return [...prev, name];
        } else {
            // Remove item from the checked list
            return prev.filter((item) => item !== name);
        }
        });
    };

    const handlePaymentAmountChange = (event, invoiceNumber) => {
        const formattedAmount = event.target.value.replace(/\D/g, '');
        setPaymentAmounts((prev) => ({
            ...prev,
            [invoiceNumber]: new Intl.NumberFormat('id-ID').format(formattedAmount),
        }));
    };

    const handleSubmit = async () => {
        console.log(checkedItems.length);
        if (checkedItems.length === 0) {
            setShowDialog(true);
            setErrorCode('500');
            setErrorTitle('No invoice selected. Please select at least one invoice.');
            return;
        }

        setLoading(true);
        try{
            // console.log(selectedBank);
            const API = `${API_BASE_URL}finance/insertpenerimaanpenjualan.php`;
            let params = new URLSearchParams({
                customer_id : selectedCustomer,
                payment_date : paymentDate,
                form_no : formNo,
                bank_number : selectedBank,
                rate : rate,
                cheque_no : chequeNo,
                cheque_date : chequeDate,
                cheque_amount : chequeAmount,
                memo : memo,
                invoice_length : checkedItems.length,
                username : username
            });

            checkedItems.forEach((invoiceNumber, index) => {
                const invoice = allSalesInvoice.find(invoice => invoice.invoice_number === invoiceNumber);
                const paymentAmount = paymentAmounts[invoiceNumber] || '0';
                const cleanedPaymentAmount = parseInt(paymentAmount.replace(/\./g, ''), 10);
                if(cleanedPaymentAmount > invoice.due_amount){
                    setErrorCode('500');
                    setErrorTitle('Error paid amount cannot larger than invoice amount');
                    setShowDialog(true);
                }

                params.append(`invoice_number_${index + 1}`, invoiceNumber);
                params.append(`invoice_date_${index + 1}`, invoice.invoiceDate);
                params.append(`invoice_amount_${index + 1}`, invoice.due_amount);
                params.append(`paymount_amount_${index + 1}`, cleanedPaymentAmount);
            });

            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params
            });

            if(response.status === 200){
                navigate(`/financeIndex`);
            }

            if(response.status === 500){
                setErrorCode('500');
                setErrorTitle('Error failed to fetch submit sales profit data');
                setShowDialog(true);
            }
        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error failed to insert new penerimaan pembelian: ' + error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    return(
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseNonActive/>
                <FinanceActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Finance Module</div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? (<Loader/>) : (
                    <div>
                        <div className='card-for-grid'>
                            <div className='title-for-card'>Penerimaan Pembelian</div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Received From"}/>
                                    <div className='space-between-1vh'></div>
                                    <select className='new-form-dropdown' value={selectedCustomer} onChange={handleCustomerChange}>
                                        {allCustomer.map((customer) => (
                                            <option key={customer.company_id} value={customer.company_id}>
                                                {customer['Company Name']}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Payment Date"}/>
                                    <div className='space-between-1vh'></div>
                                    {!showDialog && (
                                        <ReactDatePicker
                                            className='date-picker'
                                            selected={paymentDate}
                                            onChange={(date) => setPaymentDate(date)}
                                            dateFormat="dd MMMM yyyy"
                                        />
                                    )}
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Form No."}/>
                                    <div className='space-between-1vh'></div>
                                    <TextBox placeholder={'Insert Form Number'} value={formNo} onChange={handleFormNoChange}/>
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Bank"}/>
                                    <div className='space-between-1vh'></div>
                                    <select className='new-form-dropdown' value={selectedBank} onChange={handleBankAccountChange}>
                                        {allBank.map((bank) => (
                                            <option key={bank.bank_number} value={bank.bank_number}>
                                                {bank.bank_number} - {bank.bank_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Rate"}/>
                                    <div className='space-between-1vh'></div>
                                    <TextBox placeholder={'Insert rate'} value={rate} onChange={handleRateChange} />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Cheque No."}/>
                                    <div className='space-between-1vh'></div>
                                    <TextBox placeholder={'Insert Cheque Number'} value={chequeNo} onChange={handleChequeNumber} />
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Cheque Date"}/>
                                    <div className='space-between-1vh'></div>
                                    {!showDialog && (
                                        <ReactDatePicker
                                            className='date-picker'
                                            selected={chequeDate}
                                            onChange={(date) => setChequeDate(date)}
                                            dateFormat="dd MMMM yyyy"
                                        />
                                    )}
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Cheque Amount"}/>
                                    <div className='space-between-1vh'></div>
                                    <TextBox placeholder={'Insert cheque amount'} value={chequeAmount} onChange={handleChequeAmount} />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Memo"}/>
                                    <div className='space-between-1vh'></div>
                                    <TextArea maxLines={5} placeholder={'Insert your memo'} value={memo} onChange={handleMemoChange} />
                                </div>
                            </div>
                            {errorMessage && <div className='error-message'>{errorMessage}</div>}
                            {allSalesInvoice.length > 0 && (
                                <div className='grid-container'>
                                    <table className='table-layout'>
                                        <thead className='table-title' style={{ marginBottom: '10px' }}>
                                            <tr>
                                                <th style={{ padding: '10px' }}>Invoice Number</th>
                                                <th style={{ padding: '10px' }}>Date</th>
                                                <th style={{ padding: '10px' }}>Amount</th>
                                                <th style={{ padding: '10px' }}>Payment Amount</th>
                                                <th style={{ padding: '10px' }}>Bayar</th>
                                            </tr>
                                        </thead>
                                        <tbody className='table-content'>
                                            {allSalesInvoice.map((invoice) => (
                                                <tr key={invoice.invoice_number}>
                                                    <td>{invoice.invoice_number}</td>
                                                    <td>{formatDate(invoice.invoiceDate)}</td>
                                                    <td>{formatCurrencyIDR(invoice.due_amount)}</td>
                                                    <td >
                                                        <TextBox placeholder={"Insert pay amount here"} value={paymentAmounts[invoice.invoice_number] || ''} 
                                                            onChange={(event) => handlePaymentAmountChange(event, invoice.invoice_number)}/>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            name={invoice.invoice_number}
                                                            checked={checkedItems.includes(invoice.invoice_number, invoice.invoiceDate)}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <div className='grid-container-with-padding-left'>
                                <div className="only-100-width-card-submit" onClick={handleSubmit}>Submit</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default NewPenerimaanPenjualan;
