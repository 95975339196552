import PurchasingNonActiveIcon from '../../../assets/Icon/PurchasingInactive.png';
import { useNavigate } from 'react-router-dom';

function PurchaseNonActive(){
    const navigate = useNavigate();

    const handleHomepage = (event) => {
        console.log('button clicked');
        navigate('/purchaseIndex'); 
    };

    return (
        <div className="MenuContainerNonActive" onClick={handleHomepage}>
            <img src={PurchasingNonActiveIcon} />
            <div className='MenuNonActive'>Purchase</div>
        </div>
    );
}

export default PurchaseNonActive;