import React, { useState, useEffect } from 'react';
import FormLabel from "../../component/FormLabel";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesActive from "../../component/menu/SalesActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SalesOrderTemplate from '../../component/form/SalesOrderTemplate';
import ListPurchaseOrderDialog from '../../component/dialog/ListPurchaseOrder';
import { fetchCustomer, fetchNPWP } from '../../services/SalesServices';
import { API_BASE_URL } from '../../../App';
import TextArea from '../../component/TextArea';
import { useNavigate } from 'react-router-dom';
import ErrorDialog from '../../component/dialog/ErrorDialog';
import Loader from '../../component/Loader';
import TextBox from '../../component/TextBox';

function NewSalesOrder(){
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [startDate, setStartDate] = useState(new Date());
    const [dikirimKe, setDikirimKe] = useState(new Date());  
    const [dikirimTgl, setDikirimTgl] = useState(new Date()); 
    const [items, setItems] = useState([]);
    const [showPODialog, setShowPODialog] = useState(false);
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const [selectedCustomers, setselectedCustomers] = useState('');
    const [customerAddress, setCustomerAddress] = useState('');
    const [customerTOP, setCustomerTOP] = useState('');
    const [filteredPPN, setFilteredPPN] = useState([]);
    const [selectedPPN, setselectedPPNs] = useState('');
    const [SONumber, setSONumber] = useState('');
    const [selectedTax, setselectedTax] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCustomerData();
        fetchNPWPData();
        fetchSONumber();
    }, []);

    const fetchSONumber = async () => {
        setLoading(true);
        try{
            const response = await fetch(API_BASE_URL + 'sales/getsalesnumber.php');
            const data = await response.json();
            if(data.StatusCode === 200){
                setSONumber(data.Data.PONumber);
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to Sales Number !!');
                setShowDialog(true);
                return;
            }
        } catch (e){
            console.error('Error fetching PO Number:', e);
        } finally {
            setLoading(false);
        }
    }

    const handlePOOpenDialog = () => {
        setShowPODialog(true);
    };

    const handlePOCloseDialog = () => {
        setShowPODialog(false);
    };

    const handleCustomerChange = (event) => {
        const newCustomerID = event.target.value;
        setselectedCustomers(newCustomerID);
        fetchPicName(newCustomerID); 
    };

    const handleNPWPChange = (event) => {
        const newPPNID = event.target.value;
        setselectedPPNs(newPPNID);
        fetchTaxPercantage(newPPNID);
    };

    const fetchTaxPercantage = async(newPPNID) => {
        if (!newPPNID) return;
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}master/ppn/getdetailppnpercantage.php?PPNType_id=${newPPNID}`);
            const data = await response.json();
            if (data.StatusCode === 200) {
                const percentageFROMAPI = data.Data[0]?.PPNPercentage || 'No data';
                setselectedTax(percentageFROMAPI);
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch tax percentage !!');
                setShowDialog(true);
            }
        } catch (error) {
            console.log('Error failed to fetch tax percentage : ', error);
        } finally {
            setLoading(false);
        }
    }

    const fetchNPWPData = async() => {
        try{
            const npwpData = await fetchNPWP();
            setFilteredPPN(npwpData);
            setselectedPPNs(npwpData[0]?.PPNType_id || '');
            setselectedTax(npwpData[0]?.PPNPercentage || '');
        } catch (error) {
            console.log('Error failed to NPWP : ', error);
        }
    }

    const fetchCustomerData = async () => {
        try {
            const customerData = await fetchCustomer();
            setFilteredCustomers(customerData);
            setselectedCustomers(customerData[0]?.company_id || '');
            fetchPicName(customerData[0]?.company_id );
        } catch (error) {
            console.log('Error failed to fetch customer', error);
        }
    }

    const fetchPicName = async (customerID) => {
        if (!customerID) return;
        try {
            const response = await fetch(`${API_BASE_URL}master/customer/getcustomeraddress.php?company_id=${customerID}`);
            const data = await response.json();
            if (data.StatusCode === 200) {
                const addressFROMAPI = data.Data[0]?.company_address || 'No data';
                const topFROMAPI = data.Data[0]?.company_top || 'No data';
                setCustomerAddress(addressFROMAPI);
                setCustomerTOP(topFROMAPI);
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to customer address !!');
                setShowDialog(true);
            }
        } catch (error) {
            console.log('Error failed to fetch customer address : ', error);
        }
    };

    const handleSelectPurchaseOrder = (purchaseOrderData) => {
        const itemsData = purchaseOrderData.Data;
        const poNumber = itemsData['Details'] && itemsData['Details'][0] ? itemsData['Details'][0]['PONumber'] : null;
    
        let nonNullItems = [];
        let nextId = 1; 
    
        const itemsArray = itemsData['Items'];
        if (itemsArray) {
            itemsArray.forEach(item => {
                const allNonNull = Object.values(item).every(value => value !== null);
    
                if (allNonNull) {
                    nonNullItems.push({
                        id: nextId++,
                        label: `Item #${nextId - 1}`,
                        productname: item['POProductName'],
                        quantity: Number(item['POQuantity']),
                        sat: 0,
                        currency: 0,
                        harga: 0,
                        total: 0,
                        kurs: 0,
                        dpp: 0,
                        ppn: 0,
                        purchaseordernumber: poNumber
                    });
                }
            });
        }

        setItems(nonNullItems);
        handlePOCloseDialog();
    };
    
    const handleNewClick = async (event) => {
        event.preventDefault(); 
        const productNameNull = items.some(item => !item.productname);
        const QuantityNull = items.some(item => !item.quantity);
        const hargaProdukNull = items.some(item => !item.harga);
        const satuanNull = items.some(item => !item.sat);

        if(productNameNull){
            setErrorCode('400');
            setErrorTitle('Nama produk masih kosong !!');
            setShowDialog(true);
        } else if (QuantityNull){
            setErrorCode('400');
            setErrorTitle('Quantity produk masih kosong !!');
            setShowDialog(true);
        } else if (satuanNull) {
            setErrorCode('400');
            setErrorTitle('Satuan produk masih kosong !!');
            setShowDialog(true);
        } else if (hargaProdukNull){
            setErrorCode('400');
            setErrorTitle('Harga produk masih kosong !!');
            setShowDialog(true);
        } else {
                
            try{
                const API = API_BASE_URL + 'sales/insertsalesorder.php';

                const formattedStartDate = startDate ? startDate.toLocaleDateString('en-GB') : '';
                const formattedDikirimKe = dikirimKe ? dikirimKe.toLocaleDateString('en-GB') : '';
                const formattedDikirimTgl = dikirimTgl ? dikirimTgl.toLocaleDateString('en-GB') : '';

                let params = new URLSearchParams({
                    sales_order_number: SONumber,
                    sales_order_date: formattedStartDate,
                    sales_order_ppn: selectedPPN,
                    sales_order_customer: selectedCustomers,
                    sales_order_send_to: formattedDikirimKe,
                    sales_order_send_date: formattedDikirimTgl,
                    insert_by: username,
                    product_length: items.length
                });

                items.forEach((item, index) => {
                    params.append(`sales_order_PO_${index + 1}`, item.purchaseordernumber);
                    params.append(`sales_order_product_name_${index + 1}`, item.productname);
                    params.append(`sales_order_product_quantity_${index + 1}`, item.quantity);
                    params.append(`sales_order_satuan_${index + 1}`, item.sat);
                    params.append(`sales_order_matauang_${index + 1}`, `6044d37e-d147-11ee-8`);
                    params.append(`sales_order_hargasatuan_${index + 1}`, item.harga);
                    params.append(`sales_order_kurs_${index + 1}`, 0);
                });

                const response = await fetch(API, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: params
                });

                if(response.status === 200){
                    navigate(`/salesIndex`);
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error while inserting sales order data !!');
                    setShowDialog(true);
                }

            } catch (e){
                setErrorCode('400');
                setErrorTitle('Error while inserting sales order data : ', e);
                setShowDialog(true);
            }
        }
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    
    return(
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesActive/>
                <PurchaseNonActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
            {showPODialog && <ListPurchaseOrderDialog onCloseDialog={handlePOCloseDialog} onSelectPurchaseOrder={handleSelectPurchaseOrder} />}
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Sales Module</div>
                </div>
                {loading ? (<Loader/>) : (
                    <div>
                        <div className='card-for-grid'>
                            <div className='title-for-card'>New Sales Order</div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Order Number"}/>
                                    <TextBox value={SONumber} onChange={(e) => setSONumber(e.target.value)}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Date"}/>
                                    {!showPODialog && (
                                        <DatePicker 
                                            className='date-picker' 
                                            selected={startDate} 
                                            onChange={(date) => setStartDate(date)} 
                                            placeholderText='Select sales date'
                                            dateFormat="dd MMMM yyyy"
                                        />
                                    )}
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"PPN / No. PPN"}/>
                                    <select className='new-form-dropdown' value={selectedPPN} onChange={handleNPWPChange}>
                                        {filteredPPN.map((ppn) => (
                                            <option key={ppn.PPNType_id} value={ppn.PPNType_id}>
                                                {ppn['PPNType_name Name']}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Customer"}/>
                                    <select className='new-form-dropdown' value={selectedCustomers} onChange={handleCustomerChange}>
                                        {filteredCustomers.map((customer) => (
                                            <option key={customer.company_id} value={customer.company_id}>
                                                {customer['Company Name']}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Address"}/>
                                    <TextArea maxLines={5} value={customerAddress}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Customer TOP"}/>
                                    <TextBox value={customerTOP}/>
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Dikirim Ke"}/>
                                    {!showPODialog && (
                                        <DatePicker 
                                            className='date-picker' 
                                            selected={dikirimKe} 
                                            onChange={(date) => setDikirimKe(date)} 
                                            placeholderText='Select dikirim ke date'
                                            dateFormat="dd MMMM yyyy"
                                        />
                                    )}

                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Dikirim Tgl"}/>
                                    {!showPODialog && (
                                        <DatePicker 
                                            className='date-picker' 
                                            selected={dikirimTgl} 
                                            onChange={(date) => setDikirimTgl(date)} 
                                            placeholderText='Select dikirim tanggal date'
                                            dateFormat="dd MMMM yyyy"
                                        />
                                    )}
                                </div>
                                <div className="only-100-width">
                                    
                                </div>
                            </div>
                            <SalesOrderTemplate items={items} setItems={setItems} selectedTax={selectedTax} />
                        </div>
                        <div className='grid-container-with-padding-left'>
                            <div className="only-100-width-card-approve" onClick={handleNewClick}>Submit</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default NewSalesOrder;