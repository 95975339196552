import React, { useEffect, useState } from 'react';
import ProfilSearchBox from "../../component/ProfilSearchBox";
import FourCardGrid from "../../component/card/FourCardGrid";
import NotFoundPage from "../../component/maintanance/NotFound";
import UnderConstruction from "../../component/maintanance/UnderConstruction";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRActive from "../../component/menu/HRActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import { useNavigate } from 'react-router-dom';
import NewEmployee from "./NewEmployee";
import Loader from '../../component/Loader';
import { API_BASE_URL } from '../../../App';

function HRIndex() {
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const permissionAccess = localStorage.getItem('permissionAccess');
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);

    const handleNewClick = () => {
        navigate('/hrIndex/newEmployee');
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    useEffect(() => {
        const fetchEmployeeData = async () => {
            setLoading(true);
            try {
                const response = await fetchEmployee();
                setEmployeeList(response);
            } catch (error) {
                console.log('Error fetching employee data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchEmployeeData();
    }, []);

    const fetchEmployee = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}hr/HR.php`);
            if (!response.ok) {
                throw new Error('Failed to fetch employee data');
            }
            const data = await response.json();
            return data.Data || [];
        } catch (error) {
            console.log('Error fetching employee list:', error);
            return [];
        }
    };

    const handleDetailClick = (employeeID) => {
        navigate(`/hrIndex/detailEmployee/${encodeURIComponent(employeeID)}`)
    }

    return (
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive />
                <SalesNonActive />
                <PurchaseNonActive />
                <FinanceNonActive />
                <WarehouseNonActive />
                <HRActive />
                <AnalyticsnonActive />
                <DocumentnonActive />
                <SettingNonActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                {permissionAccess === 'Full access' ? (
                    loading ? (
                        <Loader />
                    ) : (
                        <div>
                            <div className='grid-container-smaller'>
                                <div className='MenuName'>HR Module</div>
                                <div className='create-new-button' onClick={handleNewClick}>Add New Employee</div>
                            </div>
                            <div>
                                {employeeList.length === 0 ? (
                                    <div className='no-data-message'>
                                        HR module has no data. Please insert data !!
                                    </div>
                                ) : (
                                    employeeList.map((employee, index) => (
                                        <div key={index} className='seeAllCard'>
                                            <div className='grid-container-smaller'>
                                                <div>
                                                    <div className='seeAllTitle'>{employee.employee_name}</div>
                                                    <div className='seeAllDetail'>{employee.position}</div>
                                                </div>
                                                <div className='seeAllDetailCard' onClick={() => handleDetailClick(employee.employee_id)}>Detail</div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    )
                ) : (
                    <div>
                        <div className='grid-container-smaller'>
                            <div className='MenuName'>HR Module</div>
                        </div>
                        <NotFoundPage menuName={'HR'} />
                    </div>
                )}
                {showDialog && <NewEmployee onCloseDialog={handleCloseDialog} />}
            </div>
        </div>
    );
}

export default HRIndex;
