import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DashboardNonActive from '../../component/menu/DashboardNonActive';
import SalesNonActive from '../../component/menu/SalesNonActive';
import PurchaseNonActive from '../../component/menu/PurchaseNonActive';
import Logout from '../../component/menu/Logout';
import ProfilSearchBox from '../../component/ProfilSearchBox';
import { API_BASE_URL } from '../../../App';
import CountdownTimer from '../../component/VerifyCodeButton';
import useAuthSession from '../../services/Session';
import SettingActive from '../../component/menu/SettingActive';
import FinanceNonActive from '../../component/menu/FinanceNonActive';
import WarehouseNonActive from '../../component/menu/WarehouseNonActive';
import HRNonActive from '../../component/menu/HRNonActive';
import AnalyticsnonActive from '../../component/menu/AnalyticsNonActive';
import DocumentnonActive from '../../component/menu/DocumentNonActive';
import NewCustomerDialog from '../../component/dialog/NewCustomerDialog';
import NewSupplierDialog from '../../component/dialog/NewSupplierDialog';
import NewTermDialog from '../../component/dialog/NewTermDialog';
import NewPaymentDialog from '../../component/dialog/NewPaymentDialog';
import NewCurrencyDialog from '../../component/dialog/NewCurrencyDialog';
import DetailUsersDialog from '../../component/dialog/DetailUsers';
import { useNavigate } from 'react-router-dom';
import DetailTermDialog from '../../component/dialog/DetailTerm';
import DetailPaymentDialog from '../../component/dialog/DetailPayment';
import DetailCurrencyDialog from '../../component/dialog/DetailCurrency';
import NewProductDialog from '../../component/dialog/NewProductDialog';
import Loader from '../../component/Loader';
import ErrorDialog from '../../component/dialog/ErrorDialog';
import DetailProductDialog from '../../component/dialog/DetailProduct';

function SettingDetail(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [settingData, setSettingData] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [isData] = useState(null);
    const { username } = useAuthSession();
    let { id } = useParams();
    const [selectedRow, setSelectedRow] = useState(null);
    const [showCreationDialog, setShowCreationDialog] = useState(false);
    const [showDetailDialog, setShowDetailDialog] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');

    const handleRowClick = (rowIndex) => {
        setSelectedRow(rowIndex === selectedRow ? null : rowIndex);
    };

    useEffect(() => {
        fetch(API_BASE_URL + `master/settings/Setting.php?action=2&settingID=${id}`)
            .then(response => response.json())
            .then(data => {
                if (data.Status === "Success") {
                    setSettingData(data.Data[0]); // Assuming there's only one item in the array
                } else {
                    setErrorCode('400');
                    setErrorTitle(data.Status);
                    setShowDialog(true);
                }
            })
            .catch(error => {
                setErrorCode('400');
                setErrorTitle(error);
                setShowDialog(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    useEffect(() => {
        if (settingData && settingData.isData === "1") {
            fetch(API_BASE_URL + settingData.dataURL)
                .then(response => response.json())
                .then(data => {
                    setTableData(data.Data);
                })
                .catch(error => {
                    setErrorCode('400');
                    setErrorTitle(error);
                    setShowDialog(true);
                });
        }
    }, [settingData]);

    useEffect(() => {
        if (tableData && tableData.length > 0) {
            const headers = Object.keys(tableData[0]); // Get the keys of the first item
            setDynamicHeaders(headers);
        }
    }, [tableData]);


    if (loading) {
        return <Loader/>;
    }

    if (!settingData) {
        return <div>No data found.</div>;
    }

    if (isData === "0") {
        return <div>No data available for this setting.</div>;
    }

    const handleCreateClick = () => setShowCreationDialog(true);
    const handleClickDetail = (e, row) => {
        e.stopPropagation(); // Prevent event from bubbling to row click
        setSelectedRow(row);
        setShowDetailDialog(true);
    };
    

    const renderDialog = () => {
        if (!settingData) return null;
        switch (settingData.settingName) {
            case 'Customer': return <NewCustomerDialog onCloseDialog={(handleCloseDialog) => setShowCreationDialog(false)} />;
            case 'Supplier': return <NewSupplierDialog onCloseDialog={(handleCloseDialog) => setShowCreationDialog(false)} />;
            case 'Term': return <NewTermDialog onCloseDialog={(handleCloseDialog) => setShowCreationDialog(false)} />;
            case 'Payment': return <NewPaymentDialog onCloseDialog={(handleCloseDialog) => setShowCreationDialog(false)} />;
            case 'Currency': return <NewCurrencyDialog onCloseDialog={(handleCloseDialog) => setShowCreationDialog(false)} />;
            case 'Users': return <CountdownTimer onCloseDialog={(handleCloseDialog) => setShowCreationDialog(false)} />;
            case 'Product': return <NewProductDialog onCloseDialog={(handleCloseDialog) => setShowCreationDialog(false)} />;
            default: return null;
        }
    };

    const renderDetailDialog = () => {
        if(settingData.settingName === 'Users'){
            const user = tableData[selectedRow];
            return <DetailUsersDialog user={user} onCloseDialog={() => setShowDetailDialog(false)} />;
        } else if (settingData.settingName === 'Customer') {
            const customer = tableData[selectedRow];
            navigate(`/settingIndex/Detail/DetailCustomer/${encodeURIComponent(customer.company_id)}`);
        } else if (settingData.settingName === 'Supplier'){
            const supplier = tableData[selectedRow];
            navigate(`/settingIndex/Detail/DetailSupplier/${encodeURIComponent(supplier.supplier_id)}`);
        } else if (settingData.settingName === 'Term'){
            const term = tableData[selectedRow];
            return <DetailTermDialog terms={term} onCloseDialog={() => setShowDetailDialog(false)} />;
        } else if (settingData.settingName === 'Payment'){
            const payment = tableData[selectedRow];
            return <DetailPaymentDialog payments={payment} onCloseDialog={() => setShowDetailDialog(false)} />;
        } else if (settingData.settingName === 'Currency'){
            const currency = tableData[selectedRow];
            return <DetailCurrencyDialog currencies={currency} onCloseDialog={() => setShowDetailDialog(false)} />;
        } else if (settingData.settingName === 'Product'){
            const id = tableData[selectedRow];
            return <DetailProductDialog id={id} onCloseDialog={() => setShowDetailDialog(false)} />;
        } else {
            return null;
        }
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    return (
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseNonActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                {showCreationDialog && renderDialog()}
                {showDetailDialog && renderDetailDialog()}
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                <ProfilSearchBox username={username}/>
                <div className='grid-container'>
                    <div className='MenuName'>{settingData.settingName} Module</div>
                    <div className='create-new-button' onClick={handleCreateClick}>Create New {settingData.settingName}</div>
                </div>
                {settingData.settingName === 'Company'}
                <div className='card-for-grid'>
                    {tableData && Array.isArray(tableData) && tableData.length > 0 && (
                        <table className='table-layout'>
                            {/* Render table headers */}
                            <thead className='table-title' style={{ marginBottom: '10px' }}>
                                <tr>
                                    {dynamicHeaders.slice(1).map((header, index) => ( // Skip the first header
                                        <th key={index} style={{ padding: '10px' }}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            {/* Render table body */}
                            <tbody className='table-content'>
                                {tableData.map((row, rowIndex) => (
                                    <React.Fragment key={rowIndex}>
                                        <tr onClick={(e) => handleRowClick(rowIndex)}>
                                            {Object.keys(row).map((key, cellIndex) => {
                                                // Include rowIndex to be passed
                                                if (cellIndex === 0) return null;
                                                return <td key={cellIndex} style={{ padding: '10px' }} onClick={(e) => handleClickDetail(e, rowIndex)}>{row[key]}</td>;
                                            })}
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SettingDetail;
