import React, { useState } from 'react';
import UsernameTextBox from './component/UsernameTextBox';
import PasswordTextBox from './component/PasswordTextBox';
import { useNavigate } from 'react-router-dom';
import LoginImage from '../login.png';
import ErrorBox from './component/ErrorBox';
import Loader from './component/Loader';
import { API_BASE_URL } from '../App';

function Login() {
    const navigate = useNavigate();
    const [txtUsername, settxtUsername] = useState('');
    const [txtPassword, settxtPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');

    const handleTextChange = (event) => {
        settxtUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        settxtPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setShowDialog(false);

        if(txtUsername !== '' && txtPassword !== ''){
            setLoading(true); 
            try {
                const apiLogin = `${API_BASE_URL}user/login.php`;
                // const apiLogin = "https://kevinngabriell.com/erpAPI-v.1.0/user/login.php";
                const response = await fetch(apiLogin, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams({
                        username: txtUsername,
                        password: txtPassword,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to login');
                }

                if (response.status === 204 || response.status === 203) {
                    setErrorTitle("Login Failed");
                    setErrorMessage("Check your username and password again !!");
                    setShowDialog(true);
                }

                const responseData = await response.json();

                if(response.status === 200 && responseData.permissionAccess && responseData.companyId){
                    console.log("Login successful, navigating now");
                    if(txtPassword === '123456'){
                        console.log("Navigating to change password page");
                        const currentTime = Date.now();
                        localStorage.setItem('loggedInTime', currentTime);
                        localStorage.setItem('username', txtUsername);
                        localStorage.setItem('permissionAccess', responseData.permissionAccess);
                        localStorage.setItem('companyId', responseData.companyId);
                        navigate('/changePassword');
                    } else {
                        const currentTime = Date.now();
                        localStorage.setItem('loggedInTime', currentTime);
                        localStorage.setItem('username', txtUsername);
                        localStorage.setItem('permissionAccess', responseData.permissionAccess);
                        localStorage.setItem('companyId', responseData.companyId);
                        console.log("Navigating to main home page");
                        navigate('/main/homePage');
                    }
                    // const currentTime = Date.now();
                    //     localStorage.setItem('loggedInTime', currentTime);
                    //     localStorage.setItem('username', txtUsername);
                    //     localStorage.setItem('permissionAccess', responseData.permissionAccess);
                    //     localStorage.setItem('companyId', responseData.companyId);
                    //     console.log("Navigating to main home page");
                    //     navigate('/changePassword');
                }

            } catch (error) {
                setErrorTitle("Login Failed");
                setErrorMessage("An error occurred during login. Please try again later.");
                setShowDialog(true);
            } finally {
                setLoading(false); // End loading
            }
        } else {
            const title = txtUsername === '' ? "Invalid Username" : "Invalid Password";
            const message = txtUsername === '' ? "Your username is invalid or not filled. Please try again !!" : "Password field is empty. Please enter your password !!";
            setErrorTitle(title);
            setErrorMessage(message);
            setShowDialog(true);
        }
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const dialogBox = <ErrorBox onCloseDialog={handleCloseDialog} title={errorTitle} message={errorMessage} />;

    const handleForgotPass = () => {
        navigate('/forgot-password');
    };

    const handleCreateAccount = () => {
        navigate('/createAccount');
    };
    
    return (
        <div className="App">
            <div className="AppLeftSection">
                <img src={LoginImage} className="App-logo" alt="Logo" />
            </div>
            <div className="AppRightSection">
                <div className='LoginTitle'>Login</div>
                <div className='LoginDesc'>Login to access ERP Systems</div>
                {showDialog && dialogBox}
                {loading ? (
                    <Loader/>
                ) : (
                    <form className='LoginForm'>
                        <div>
                            <label className='LabelTextBox'>Username</label>
                            <div className='space-between-1vh'></div>
                            <UsernameTextBox value={txtUsername} onChange={handleTextChange} />
                        </div>
                        <div className='space-between'></div>
                        <div>
                            <label className='LabelTextBox'>Password</label>
                            <div className='space-between-1vh'></div>
                            <PasswordTextBox value={txtPassword} onChange={handlePasswordChange} />
                        </div>
                        <div className='space-between-1vh'></div>
                        <div className='ForgotPassword' onClick={handleForgotPass}>Forgot Password ?</div>
                        <div className='space-between'></div>
                    </form>
                )}
                <button type="submit" className='SubmitButton' onClick={handleSubmit}>Submit</button>
                <div className='GradientText' onClick={handleCreateAccount}>
                    <div className='GradientTextOne'>Don't have an account?</div>
                    <div className='GradientTextTwo'>Create Account</div>
                </div>
            </div>
        </div>
    );
}

export default Login;
