import React, { useState } from 'react';
import TextBox from "../TextBox";
import { useNavigate } from 'react-router-dom';
import ErrorBox from '../ErrorBox';
import Loader from '../Loader';
import { API_BASE_URL } from '../../../App';

const NewTargettingDialog = ({ onCloseDialog }) => {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(true);  
    const [txtTargetYear, settxtTargetYear] = useState('');
    const [txtTargetAmount, settxtTargetAmount] = useState('');
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleCloseClick = () => {
        onCloseDialog();  // Call the onCloseDialog function passed from the parent component
    };

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const handleYearChange = (e) => {
        setSelectedYear(parseInt(e.target.value));
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); 
    
        // Validation for txtTargetYear
        if (!/^\d{4}$/.test(txtTargetYear)) {
            setErrorTitle("Validation Failed");
            setErrorMessage("Target year must be numeric and have a length of 4 digits.");
            setShowDialog(true);
            return;
        }
    
        // Validation for txtTargetAmount
        if (!txtTargetAmount) {
            setErrorTitle("Submission Failed");
            setErrorMessage("Please fill in all fields !!");
            setError("Please fill in all fields");
            return;
        }
    
        setLoading(true);
        try {
            const apiInsertTarget = `${API_BASE_URL}company/targeting/inserttargeting.php`;
            const response = await fetch(apiInsertTarget, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    company_id: '1252f67e-bfda-11ee-9dcf-0e799759a249',
                    target_year: txtTargetYear, // Use selectedYear state variable here
                    target_value: txtTargetAmount,
                }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to insert target data');
            }
    
            if (response.status === 500) {
                setErrorTitle("Insert Failed");
                setErrorMessage("Check your field again !!");
                setShowDialog(true);
            }
    
            if (response.status === 200) {
                navigate('/settingIndex');
            }
    
        } catch (e) {
            setErrorTitle("Insert Target Failed");
            setErrorMessage("An error occurred during insert. Please try again later.");
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }
    
    

    const handleCloseDialog = () => {
        setShowDialog(false);
    };
    
    const dialogBox = <ErrorBox onCloseDialog={handleCloseDialog} title={errorTitle} message={errorMessage} />;

    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            New Targeting Data
                        </div>
                        {error && dialogBox}
                        {loading ? (<Loader/>) : (
                            <form>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Targeting Year</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox
                                            value={txtTargetYear}
                                            onChange={(e) => settxtTargetYear(e.target.value)}
                                            placeholder = {'Input targeting year'}
                                        />
                                    </div>
                                    <div className='space-between-width-10'></div>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Targeting Amount</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox
                                            value={txtTargetAmount}
                                            onChange={(e) => settxtTargetAmount(e.target.value)}
                                            placeholder = {'Input targeting amount'}
                                        />
                                    </div>
                                </div>
                            </form>
                        )}
                        <div className='space-between'/>
                        <div className='grid-container-form-submit'>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Close</div>
                            <div className='space-between-width'/>
                            <div className='verification-button' onClick={handleSubmit}>Submit</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewTargettingDialog;