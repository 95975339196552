import React, { useState, useEffect } from 'react';
import FormLabel from "../FormLabel";
import TextBox from '../TextBox';
import { FaTrash } from 'react-icons/fa';
import ListProductDialog from '../dialog/ListProduct';
import { API_BASE_URL, formatCurrencyIDR } from '../../../App';

function PurchaseImportTemplate({ items, setItems, selectedTax }) {
    const [count, setCount] = useState(0);
    const [total, setTotal] = useState(0);
    const [showProductdialog, setShowProductdialog] = useState(false);
    const [selectedProductNames, setSelectedProductNames] = useState({});
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const handleProductOpenDialog = () => {
        setShowProductdialog(true);
    };

    const handleProductCloseDialog = () => {
        setShowProductdialog(false);
    };

    useEffect(() => {
        calculateTotal();
    }, [items, selectedTax]);

    const calculateTotal = () => {
        let sum = 0;
        items.forEach(item => {
            sum += item.quantity * item.unitPrice;
        });
        setTotal(sum);
    };

    const handleAddItem = () => {
        setCount(prevCount => prevCount + 1);
        const newItem = {
            id: count + 1,
            label: `Item #${count + 1}`,
            productname: '',
            quantity: 0,
            unitPrice: 0,
            packaging: 0
        };

        setItems(prevItems => [...prevItems, newItem]);
    };

    const handleDeleteItem = (id) => {
        const updatedItems = items.filter(item => item.id !== id);
        setItems(updatedItems);
    };

    const handleQuantityChange = (index, value) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setItems(prevItems =>
                prevItems.map(item =>
                    item.id === index ? { ...item, quantity: value } : item
                )
            );
        }
    };

    const handlePackagingSizeChange = (index, value) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setItems(prevItems =>
                prevItems.map(item =>
                    item.id === index ? { ...item, packaging: value } : item
                )
            );
        }
    };

    const handleUnitPriceChange = (index, value) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setItems(prevItems =>
                prevItems.map(item =>
                    item.id === index ? { ...item, unitPrice: value } : item
                )
            );
        }
    };

    const handleProductNameChange = async (id, value) => {
        setItems(prevItems =>
            prevItems.map(item =>
                item.id === id ? { ...item, productname: value } : item
            )
        );

        if (value.length > 0) {
            try {
                const response = await fetch(`${API_BASE_URL}warehouse/productquery.php?find=${value}`);
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setSuggestions(data.products);
                    setShowSuggestions(true);
                } else {
                    setSuggestions([]);
                    setShowSuggestions(false);
                }
            } catch (error) {
                console.error("Error fetching product suggestions:", error);
                setSuggestions([]);
                setShowSuggestions(false);
            }
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    };

    const handleSuggestionClick = (id, suggestion) => {
        setItems(prevItems =>
            prevItems.map(item =>
                item.id === id ? { ...item, productname: suggestion.productName } : item
            )
        );
        setShowSuggestions(false);
    };

    const getVATNotFormat = () => total * (selectedTax / 100);

    const getVAT = () => {
        const perhitunganVAT = total * (selectedTax / 100);
        return formatCurrencyIDR(perhitunganVAT);
    };

    const getAfterVATFormatted = () => {
        const vatAmount = getVATNotFormat();
        const afterVatTotal = vatAmount + total;
        return formatCurrencyIDR(afterVatTotal);
    };

    const handleSelectPurchaseOrder = (purchaseOrderData) => {
        const lastId = items.length > 0 ? items[items.length - 1].id : 0;
        const newId = lastId + 1;

        setItems(prevItems => [
            ...prevItems,
            {
                id: newId,
                label: `Item #${newId}`,
                productname: purchaseOrderData,
                quantity: 0,
                unitPrice: 0,
                packaging: 0
            }
        ]);
        handleProductCloseDialog();
    };

    return (
        <div>
            {items.map(item => (
                <div key={item.id}>
                    {showProductdialog && <ListProductDialog onCloseDialog={handleProductCloseDialog} onSelectPurchaseOrder={handleSelectPurchaseOrder} />}
                    <div className='ItemNameTemplateForm'>
                        <FormLabel labelName={item.label} />
                        <div onClick={() => handleDeleteItem(item.id)} className='DeleteTemplateForm'>
                            <FaTrash className="icon" />
                            Delete {item.label}
                        </div>
                    </div>
                    <div className='grid-container'>
                        <div className="only-100-width">
                            <FormLabel labelName={"Product Name"} />
                            <TextBox
                                value={item.productname}
                                onChange={e => handleProductNameChange(item.id, e.target.value)}
                                placeholder={"Insert product name"}
                            />
                            {showSuggestions && (
                                <ul className="suggestions-list">
                                    {suggestions.map((suggestion, index) => (
                                        <li key={index} onClick={() => handleSuggestionClick(item.id, suggestion)}>
                                            {suggestion.skuID} - {suggestion.productName}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className="only-100-width">
                            <FormLabel labelName={"Quantity (Kg)"} />
                            <TextBox value={item.quantity} onChange={e => handleQuantityChange(item.id, e.target.value)} />
                        </div>
                        <div className="only-100-width">
                            <FormLabel labelName={"Packaging Size (Kg)"} />
                            <TextBox value={item.packaging} onChange={e => handlePackagingSizeChange(item.id, e.target.value)} />
                        </div>
                        <div className="only-100-width">
                            <FormLabel labelName={"Unit Price (IDR)"} />
                            <TextBox value={item.unitPrice.toLocaleString()} onChange={e => handleUnitPriceChange(item.id, e.target.value)} placeholder="0.00" />
                        </div>
                        <div className="only-100-width">
                            <FormLabel labelName={"Total (IDR)"} />
                            <TextBox value={formatCurrencyIDR((item.quantity * item.unitPrice))} readOnly />
                        </div>
                    </div>
                </div>
            ))}
            <div className='grid-container'>
                <div className="only-100-width">
                </div>
                <div className="only-100-width">
                </div>
                <div className="only-100-width">
                </div>
                <div className="only-100-width">
                    <FormLabel labelName={"VAT"} />
                </div>
                <div className="only-100-width">
                    <TextBox value={getVAT().toLocaleString()} readOnly />
                </div>
            </div>
            <div className='grid-container'>
                <div className="only-100-width">
                </div>
                <div className="only-100-width">
                </div>
                <div className="only-100-width">
                </div>
                <div className="only-100-width">
                    <FormLabel labelName={"Total"} />
                </div>
                <div className="only-100-width">
                    <TextBox value={getAfterVATFormatted()} readOnly />
                </div>
            </div>
            <div className='AddItemTemplate' onClick={handleAddItem} style={{ cursor: 'pointer' }} >Click Here To Add Item</div>
        </div>
    );
}

export default PurchaseImportTemplate;
