import AnalyticsInactiveIcon from '../../../assets/Icon/AnalyticsActive.png';
import { useNavigate } from 'react-router-dom';

function AnalyticsActive(){
    const navigate = useNavigate();
    const permissionAccess = localStorage.getItem('permissionAccess');

    const handleSalesMenu = (event) => {
        navigate('/analyticsIndex'); 
    };

    return(
        <div className="MenuContainer" onClick={handleSalesMenu}>
            <img src={AnalyticsInactiveIcon} />
            <div className='MenuActive'>Analytics</div>
        </div>
    );
}

export default AnalyticsActive;