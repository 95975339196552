import React, { useState } from 'react';
import LoginImage from '../login.png';
import { useNavigate } from 'react-router-dom';
import UsernameTextBox from './component/UsernameTextBox';
import PasswordTextBox from './component/PasswordTextBox';
import Loader from './component/Loader';
import { PiDesktopTowerFill } from "react-icons/pi";
import TextBox from './component/TextBox';
import ErrorBox from './component/ErrorBox';
import { API_BASE_URL } from '../App';

function ForgotPassword() {
    const navigate = useNavigate();
    const [txtUsername, settxtUsername] = useState('');
    const [txtVerificationCode, settxtVerificationCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');

    const handleTextChange = (event) => {
        settxtUsername(event.target.value);
    };

    const handleVerificationChange = (event) => {
        settxtVerificationCode(event.target.value);
    };

    const handleLogin = () => {
        navigate('/');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);
        try{
            const apiLogin = `${API_BASE_URL}user/forgotpassword.php`;

            const response = await fetch(apiLogin, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    username: txtUsername,
                    verfication_code: txtVerificationCode,
                }),
            });

            if (response.status === 204) {
                setErrorTitle("Reset Failed");
                setErrorMessage("Your verification code is not valid !!");
                setShowDialog(true);
            }

            if(response.status === 200){
                navigate('/');
            }

            if(response.status === 500 || response.status === 404){
                setErrorTitle("Reset Failed");
                setErrorMessage("Error at : " + response.message);
                setShowDialog(true);
            }

        } catch (error){
            setErrorTitle('Error');
            setErrorMessage('Error while reseting password : ', error);
            setShowDialog(true);
            return;
        } finally {
            setLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const dialogBox = <ErrorBox onCloseDialog={handleCloseDialog} title={errorTitle} message={errorMessage} />;

    return (
        <div className="App">
            <div className="AppLeftSection">
                <img src={LoginImage} className="App-logo" />
            </div>
            <div className="AppRightSection">
                <div className='LoginTitle'>Forgot Password</div>
                <div className='LoginDesc'>Fill out your data to reset password</div>
                {showDialog && dialogBox}
                {loading ? (
                    <Loader/>
                ) : (
                    <form className='LoginForm'>
                        <div>
                            <label className='LabelTextBox'>Username</label>
                            <div className='space-between-1vh'></div>
                            <UsernameTextBox value={txtUsername} onChange={handleTextChange} />
                        </div>
                        <div className='space-between'></div>
                        <div>
                            <label className='LabelTextBox'>Verification Code</label>
                            <div className='space-between-1vh'></div>
                            <TextBox value={txtVerificationCode} onChange={handleVerificationChange} placeholder={"Input your verification code"} icon={PiDesktopTowerFill}/>
                        </div>
                        <div className='space-between-1vh'></div>
                    </form>
                )}
                <button type="submit" className='SubmitButton' onClick={handleSubmit}>Submit</button>
                <div className='GradientText' onClick={handleLogin}>
                    <div className='GradientTextOne'>Back to login?</div>
                    <div className='GradientTextTwo'>Login Here</div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;