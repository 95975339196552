import React, { useState } from 'react';

const ErrorDialog = ({ onCloseDialog, errorTitle, errorMessage, errorMenu, errorCode }) => {
    const [showDialog, setShowDialog] = useState(true);

    const handleCloseClick = () => {
        onCloseDialog();  // Call the onCloseDialog function passed from the parent component
    };

    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            <span role="img" aria-label="Error Emoji">❌</span> Error {errorCode} !!! <span role="img" aria-label="Error Emoji">❌</span>
                        </div>
                        <div className='space-between-1vh'></div>
                        <div>{errorMessage}</div>
                        <div className='space-between-1vh'></div>
                        <div className='grid-container'>
                            <div className='close-text-dialog'></div>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Oke</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorDialog;
