import { useEffect, useState } from "react";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseActive from "../../component/menu/WarehouseActive";
import useAuthSession from "../../services/Session";
import Loader from "../../component/Loader";
import FormLabel from "../../component/FormLabel";
import TextBox from "../../component/TextBox";
import { API_BASE_URL } from "../../../App";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../../component/dialog/ErrorDialog";

function AddNewOutHouse() {
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [loading, setLoading] = useState(false);
    const [lot, setLot] = useState('');
    const [kodeProduk, setKodeProduk] = useState('');
    const [namaProduk, setNamaProduk] = useState('');
    const [jumlahBarang, setJumlahBarang] = useState('');
    const [keteranganBarang, setKeteranganBarang] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [uomOptions, setUomOptions] = useState([]);
    const [selectedUOM, setSelectedUOM] = useState('');

    useEffect(() => {
        fetchUomOptions();
    }, []);

    const fetchUomOptions = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}master/uom/getalluom.php`);
            const data = await response.json();
            setUomOptions(data.Data.map(uom => ({ key: uom.uomID, value: uom.uomName })));
        } catch (error) {
            console.error('Error fetching UOM options:', error);
        }
    };

    const handleLotChange = async (e) => {
        const value = e.target.value;
        setLot(value);

        if (value.length > 0) {
            try {
                const response = await fetch(`${API_BASE_URL}warehouse/lotquery.php?find=${value}`);
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setSuggestions(data.products);
                    setShowSuggestions(true);
                } else {
                    setSuggestions([]);
                    setShowSuggestions(false);
                }
            } catch (error) {
                console.error("Error fetching lot suggestions:", error);
                setSuggestions([]);
                setShowSuggestions(false);
            }
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setLot(suggestion.lot);
        setKodeProduk(suggestion.skuID);
        setNamaProduk(suggestion.productName);
        setJumlahBarang(suggestion.endbalance);
        setShowSuggestions(false);
    };

    const handleSubmit = async () => {
        if (!lot || !kodeProduk || !jumlahBarang || !keteranganBarang || !selectedUOM) {
            setErrorTitle('Semua kolom wajib diisi');
            setErrorCode('VALIDATION_ERROR');
            setShowDialog(true);
            return;
        }

        setLoading(true);
        try {
            const API = `${API_BASE_URL}warehouse/productout.php`;

            let params = new URLSearchParams({
                lot: lot,
                kodeProduk: kodeProduk,
                jumlahBarang: jumlahBarang,
                unitOfMeasureID: selectedUOM,
                keteranganBarang: keteranganBarang,
                username: username,
            });

            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params
            });

            if (response.status === 200) {
                navigate(`/warehouseIndex`);
            } else if (response.status === 500) {
                setErrorCode('500');
                setErrorTitle('Error: Query failed to execute.');
                setShowDialog(true);
            } else if (response.status === 400) {
                setErrorCode('400');
                setErrorTitle('Nomor LOT tidak ditemukan');
                setShowDialog(true);
            }

        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error while inserting warehouse data: ' + error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleUOMChange = (e) => {
        setSelectedUOM(e.target.value);
    };

    return (
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive />
                <SalesNonActive />
                <PurchaseNonActive />
                <FinanceNonActive />
                <WarehouseActive />
                <HRNonActive />
                <AnalyticsnonActive />
                <DocumentnonActive />
                <SettingNonActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Warehouse Module</div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? <Loader /> : (
                    <div>
                        <div className='card-for-grid'>
                            <div className='title-for-card'>Pendataan Barang Keluar</div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Nomor Lot"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox placeholder={"Masukkan nomor LOT"} value={lot} onChange={handleLotChange} />
                                    {showSuggestions && (
                                        <ul className="suggestions-list">
                                            {suggestions.map((suggestion, index) => (
                                                <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                                    {suggestion.lot} - {suggestion.skuID} - {suggestion.productName}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Kode Produk"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox placeholder={"Masukkan kode produk"} value={kodeProduk} onChange={(e) => setKodeProduk(e.target.value)} />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Nama Produk"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox placeholder={"Masukkan nama produk"} value={namaProduk} onChange={(e) => setNamaProduk(e.target.value)} />
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"SAT"} />
                                    <select value={selectedUOM} onChange={handleUOMChange}>
                                        <option value="">Select SAT</option>
                                        {uomOptions.map(uom => (
                                            <option key={uom.key} value={uom.key}>{uom.value}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Jumlah Barang"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox placeholder={"Masukkan jumlah barang"} value={jumlahBarang} onChange={(e) => setJumlahBarang(e.target.value)} />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Customer/Keterangan"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox placeholder={"Masukkan nama konsumen atau keterangan"} value={keteranganBarang} onChange={(e) => setKeteranganBarang(e.target.value)} />
                                </div>
                            </div>
                            <div className="grid-container-with-padding-left">
                                <div className="only-100-width-card-submit" onClick={handleSubmit}>
                                    Kumpul
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default AddNewOutHouse;
