import React, { useState, useEffect } from 'react';
import FormLabel from "../../component/FormLabel";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import TextArea from "../../component/TextArea";
import TextBox from "../../component/TextBox";
import PurchaseOrderTemplate from "../../component/form/PurchaseOrderTemplate";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseActive from "../../component/menu/PurchaseActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import DatePicker from "react-datepicker";

function NewPurchaseOrder(){
    const [startDate, setStartDate] = useState(new Date());
    const { username } = useAuthSession();
    const [items, setItems] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    
    return(
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Purchase Module</div>
                </div>
                <div className='card-for-grid'>
                <div className='title-for-card'>New Purchase Order</div>
                <div className='grid-container'>
                    <div className="only-100-width">
                        <FormLabel labelName={"Vendor"}/>
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"PO Number"}/>
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"PO Date"}/>
                        <DatePicker
                            className='new-form-dropdown'
                            selected={startDate}
                            onChange={(date) => {setStartDate(date);}}
                            dateFormat="yyyy-MM-dd"
                        />
                    </div>
                </div>
                <div className='grid-container'>
                    <div className="only-100-width">
                        <FormLabel labelName={"Alamat"}/>
                        <TextArea placeholder={"Input address"}/>
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"Ship To"}/>
                        <TextArea placeholder={"Input venken address"}/>
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"Rate"}/>
                        <TextBox placeholder={"Inpput kurs"}/>
                    </div>
                </div>
                <div className='grid-container'>
                    <div className="only-100-width">
                        <FormLabel labelName={"Term"}/>
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"Ship Via"}/>
                    </div>
                    <div className="only-100-width">
                        
                    </div>
                </div>
                <PurchaseOrderTemplate items={items} setItems={setItems} />
                </div>
                <div className='grid-container-with-padding-left'>
                    <div className="only-100-width-card-submit">Save</div>
                    <div className="only-100-width-card-submit">Save & Terima Barang</div>
                </div>
            </div>
        </div>
    );
}

export default NewPurchaseOrder;