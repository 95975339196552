import React, { useEffect, useState } from 'react';
import DashboardActive from '../../component/menu/DashboardActive';
import '../../component/Component.css';
import SalesNonActive from '../../component/menu/SalesNonActive';
import Logout from '../../component/menu/Logout';
import ProfilSearchBox from '../../component/ProfilSearchBox';
import PurchaseNonActive from '../../component/menu/PurchaseNonActive';
import SettingNonActive from '../../component/menu/SettingNonActive';
import useAuthSession from '../../services/Session';
import FinanceNonActive from '../../component/menu/FinanceNonActive';
import WarehouseNonActive from '../../component/menu/WarehouseNonActive';
import DocumentnonActive from '../../component/menu/DocumentNonActive';
import AnalyticsnonActive from '../../component/menu/AnalyticsNonActive';
import HRNonActive from '../../component/menu/HRNonActive';
import { API_BASE_URL, formatCurrencyIDR } from '../../../App';
import Loader from '../../component/Loader';
import SalesCardDashbord from '../../component/card/SalesCardDashboard';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function Home() {
    const { username } = useAuthSession();
    const [loading, setLoading] = useState(false);
    const [index, setIndex] = useState(false);
    const [salesData, setSalesData] = useState([]);
    const permissionAccess = localStorage.getItem('permissionAccess');
    const [customerOutstand, setCustomerOutstand] = useState([]);

    useEffect(() => {
        const fetchStatistic = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}master/getoveralldashboard.php`);
                if (!response.ok) {
                    throw new Error('Failed to fetch sales order data');
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setIndex(data.Data);
                    setSalesData(data.Data.sales_chart);
                } else {
                    // Handle the case when the API returns an error status code
                }
            } catch (error) {
                // Handle the error appropriately
                console.error('Error fetching the data', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchCustomerOutstanding = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}finance/alloutstandingcustomer.php`);
                if (!response.ok) {
                    throw new Error('Failed to fetch customer outstanding data');
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setCustomerOutstand(data.Data);
                } else {
                    // Handle the case when the API returns an error status code
                }
            } catch (error) {
                // Handle the error appropriately
                console.error('Error fetching the data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchStatistic();
        fetchCustomerOutstanding();
    }, []);

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const chartData = {
        labels: salesData.map(item => monthNames[item.month - 1]),
        datasets: [
            {
                label: 'Total Sales',
                data: salesData.map(item => item.total_sales),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
        ],
    };

    const options = {
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                beginAtZero: false,
                grid: {
                    display: true
                }
            }
        }
    };
    

    return (
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardActive/>
                <SalesNonActive/>
                <PurchaseNonActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div className='MenuName'>Dashboard</div>
                {loading ? (<Loader/>) : (
                    <div>
                        <SalesCardDashbord 
                            headline="Overview"
                            title1="Target"
                            content1={formatCurrencyIDR(index.total_target)}
                            title2="Total Sales"
                            content2={formatCurrencyIDR(index.total_sales)}
                        />
                        {permissionAccess === 'Full access' || permissionAccess === 'Purchase only' ? (
                            <div>
                                <div className='grid-container'>
                                    <div className='card-for-grid-divide-two'>
                                        <div className='grid-container'>
                                            <div className="card-headline">Outstanding Purchasing</div>
                                        </div>
                                    </div>
                                    <div className='card-for-grid-divide-two'>
                                        <div className='grid-container'>
                                            <div className="card-headline">Purchase by Month</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div></div>)}
                        {permissionAccess === 'Full access' || permissionAccess === 'Sales only' ? (
                            <div>
                                <div className='grid-container'>
                                    <div className='card-for-grid-divide-two-no'>
                                        <div className='grid-container'>
                                            <div className="card-headline-padding">Outstanding Customer</div>
                                        </div>
                                        {customerOutstand.map((custout, index) => (
                                        <div key={index} className='seeAllCard'>
                                            <div className='grid-container'>
                                                <div>
                                                    <div className='seeAllTitle'>{custout.invoiceNumber}</div>
                                                    <div className='seeAllDetail'>{custout.SO_Status_Name} | {formatCurrencyIDR(custout.due_amount)}</div>
                                                </div>
                                                <div className='seeAllDetailCard'>{custout.outstandingDays} Days</div>
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                    <div className='card-for-grid-divide-two'>
                                        <div className='grid-container'>
                                            <div className="card-headline">Sales by Month</div>
                                        </div>
                                        <Bar data={chartData} options={options} />
                                    </div>
                                </div>
                            </div>
                        ) : (<div></div>)}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Home;
