import React, { useState, useEffect } from 'react';
import FormLabel from "../FormLabel";
import TextBox from "../TextBox";
import { FaTrash } from 'react-icons/fa';

function PurchaseOrderTemplate({items, setItems}){
    const [count, setCount] = useState(0);
    const [total, setTotal] = useState(0);

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(amount).replace('Rp', 'Rp. ');
    };

    return(
        <div>
            {items.map(item => (
                <div key={item.id}>
                    <div className='ItemNameTemplateForm'>
                        <FormLabel labelName={item.label}/>
                        <div className='DeleteTemplateForm'>
                            <FaTrash className="icon" />
                            Delete {item.label}
                        </div>
                    </div>
                    <div className='grid-container'>
                        <div className="only-100-width">
                            <FormLabel labelName={"Product Name"}/>
                            <TextBox placeholder={"Insert your product name"}/>
                        </div>
                        <div className="only-100-width">
                            <FormLabel labelName={"Qty"}/>
                            <TextBox placeholder={"Insert your product name"}/>
                        </div>
                        <div className="only-100-width">
                            <FormLabel labelName={"Unit Price"}/>
                            <TextBox placeholder={"Insert your product name"}/>
                        </div>
                        <div className="only-100-width">
                            <FormLabel labelName={"Amount"}/>
                            <TextBox placeholder={"Insert your product name"}/>
                        </div>
                    </div>
                </div>
            ))}
            <div className='grid-container'>
                <div className="only-100-width">
                </div>
                <div className="only-100-width">
                </div>
                <div className="only-100-width">
                </div>
                <div className="only-100-width">
                    <TextBox placeholder={"Total Order"}/>
                </div>
            </div>
        </div>
    ); 
}

export default PurchaseOrderTemplate;