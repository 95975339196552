import React, { useState } from 'react';
import LoginImage from '../login.png';
import { useNavigate } from 'react-router-dom';
import PasswordTextBox from './component/PasswordTextBox';
import ErrorBox from './component/ErrorBox';
import Loader from './component/Loader';
import TextBox from './component/TextBox';
import { IoMdPeople } from "react-icons/io";
import { PiDesktopTowerFill } from "react-icons/pi";
import { API_BASE_URL } from '../App';

function CreateAccount() {
    const navigate = useNavigate();
    const [txtName, settxtName] = useState('');
    const [txtUsername, settxtUsername] = useState('');
    const [txtPassword, settxtPassword] = useState('');
    const [txtVerificationCode, settxtVerificationCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');

    const handleNameChange = (event) => {
        settxtName(event.target.value);
    };

    const handleUsernameChange = (event) => {
        settxtUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        settxtPassword(event.target.value);
    };

    const handleVerificationChange = (event) => {
        settxtVerificationCode(event.target.value);
    };

    const handleForgotPass = () => {
        navigate('/forgot-password');
    };

    const handleLogin = () => {
        navigate('/');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setShowDialog(false);

        if(txtName.trim() === '' || txtUsername.trim() === '' || txtPassword.trim() === '' || txtVerificationCode.trim() === '') {
            setErrorTitle("Incomplete Form");
            setErrorMessage("Please fill out all fields !");
            setShowDialog(true);
            return;
        }

        setLoading(true);
        try{
            const apiCreateUser = `${API_BASE_URL}user/createuser.php`
            const response = await fetch(apiCreateUser, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    first_name: txtName,
                    last_name: txtName,
                    username: txtUsername,
                    password: txtPassword,
                    verification_code: txtVerificationCode,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to insert customer data');
            }

            if (response.status === 500) {
                setErrorTitle("Create Account Failed");
                setErrorMessage("Check your username or verification code !!");
                setShowDialog(true);
            }
            
            if(response.status === 200){
                navigate('/');
            }

        } catch (e){
            setErrorTitle("Create Account Failed");
            setErrorMessage("Check your username or verification code !!");
            setShowDialog(true);
        } finally {
            setLoading(false);
        }

    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const dialogBox = <ErrorBox onCloseDialog={handleCloseDialog} title={errorTitle} message={errorMessage} />;

    return (
        <div className="App">
            <div className="AppLeftSection">
                <img src={LoginImage} className="App-logo" />
            </div>
            <div className="AppRightSection">
                <div className='LoginTitle'>Create Account</div>
                <div className='LoginDesc'>Fill out the data to register into system</div>
                {showDialog && dialogBox}
                {loading ? (
                    <Loader/>
                ) : (
                    <form className='LoginForm'>
                        <div>
                            <label className='LabelTextBox'>Name</label>
                            <div className='space-between-1vh'></div>
                            <TextBox value={txtName} onChange={handleNameChange} placeholder={"Input your name"} icon={IoMdPeople}/>
                        </div>
                        <div className='space-between'></div>
                        <div>
                            <label className='LabelTextBox'>Username</label>
                            <div className='space-between-1vh'></div>
                            <TextBox value={txtUsername} onChange={handleUsernameChange} placeholder={"Input your username"} icon={IoMdPeople}/>
                        </div>
                        <div className='space-between'></div>
                        <div>
                            <label className='LabelTextBox'>Password</label>
                            <div className='space-between-1vh'></div>
                            <PasswordTextBox value={txtPassword} onChange={handlePasswordChange} />
                        </div>
                        <div className='space-between'></div>
                        <div>
                            <label className='LabelTextBox'>Verification Code</label>
                            <div className='space-between-1vh'></div>
                            <TextBox value={txtVerificationCode} onChange={handleVerificationChange} placeholder={"Input your verification code"} icon={PiDesktopTowerFill}/>
                        </div>
                        <div className='space-between-1vh'></div>
                        <div className='ForgotPassword' onClick={handleForgotPass}>Forgot Password ?</div>
                    </form>
                )}
                <button type="submit" className='SubmitButton' onClick={handleSubmit}>Submit</button>
                <div className='GradientText' onClick={handleLogin}>
                    <div className='GradientTextOne'>Already Have an Account?</div>
                    <div className='GradientTextTwo'>Login Here</div>
                </div>
            </div>
        </div>
    );
}

export default CreateAccount;