import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorDialog from '../../component/dialog/ErrorDialog';

function SettingGridCard({ images, titles, captions, ids }) {
    const navigate = useNavigate();
    const permissionAccess = localStorage.getItem('permissionAccess');
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');

    const handleDetail = (id) => {

        if(titles == 'Users'){
            if(permissionAccess === 'Full access'){
                navigate(`/settingIndex/Detail/${encodeURIComponent(id)}`);
            } else {
                setErrorCode('404');
                setErrorTitle('You have no access for this menu. Please contact your administrator to access this menu !!!');
                setShowDialog(true);
            }
        } else if (titles == 'Product'){
            if(permissionAccess === 'Full access' || permissionAccess === 'Sales only' || permissionAccess === 'Purchase only'){
                navigate(`/settingIndex/Detail/${encodeURIComponent(id)}`);
            } else {
                setErrorCode('404');
                setErrorTitle('You have no access for this menu. Please contact your administrator to access this menu !!!');
                setShowDialog(true);
            }
        } else if (titles == 'Customer'){
            if(permissionAccess === 'Full access' || permissionAccess === 'Sales only'){
                console.log('tes');
                navigate(`/settingIndex/Detail/${encodeURIComponent(id)}`);
            } else {
                setErrorCode('404');
                setErrorTitle('You have no access for this menu. Please contact your administrator to access this menu !!!');
                setShowDialog(true);
            }
        } else if (titles == 'Company'){
            if(permissionAccess === 'Full access'){
                navigate('/companySetting');
            } else {
                setErrorCode('404');
                setErrorTitle('You have no access for this menu. Please contact your administrator to access this menu !!!');
                setShowDialog(true);
            }
        } else if (titles == 'Supplier'){
            if(permissionAccess === 'Full access' || permissionAccess === 'Purchase only'){
                navigate(`/settingIndex/Detail/${encodeURIComponent(id)}`);
            } else {
                setErrorCode('404');
                setErrorTitle('You have no access for this menu. Please contact your administrator to access this menu !!!');
                setShowDialog(true);
            }
        } else if (titles == 'Term'){
            if(permissionAccess === 'Full access' || permissionAccess === 'Sales only' || permissionAccess === 'Purchase only'){
                navigate(`/settingIndex/Detail/${encodeURIComponent(id)}`);
            } else {
                setErrorCode('404');
                setErrorTitle('You have no access for this menu. Please contact your administrator to access this menu !!!');
                setShowDialog(true);
            }
        } else if (titles == 'Origin'){
            if(permissionAccess === 'Full access' || permissionAccess === 'Purchase only'){
                navigate(`/settingIndex/Detail/${encodeURIComponent(id)}`);
            } else {
                setErrorCode('404');
                setErrorTitle('You have no access for this menu. Please contact your administrator to access this menu !!!');
                setShowDialog(true);
            }
        } else if (titles == 'Payment'){
            if(permissionAccess === 'Full access' || permissionAccess === 'Purchase only' || permissionAccess === 'Sales only'){
                navigate(`/settingIndex/Detail/${encodeURIComponent(id)}`);
            } else {
                setErrorCode('404');
                setErrorTitle('You have no access for this menu. Please contact your administrator to access this menu !!!');
                setShowDialog(true);
            }
        } else if (titles == 'Currency'){
            if(permissionAccess === 'Full access'){
                navigate(`/settingIndex/Detail/${encodeURIComponent(id)}`);
            } else {
                setErrorCode('404');
                setErrorTitle('You have no access for this menu. Please contact your administrator to access this menu !!!');
                setShowDialog(true);
            }
        } else if (titles == 'Finance'){
            if(permissionAccess === 'Full access'){
                navigate(`/settingIndex/Detail/FinanceSettings`);
            } else {
                setErrorCode('404');
                setErrorTitle('You have no access for this menu. Please contact your administrator to access this menu !!!');
                setShowDialog(true);
            }
        } else {
            setErrorCode('404');
            setErrorTitle('This menu is not ready yet. Please contact your administrator to access this menu !!!');
            setShowDialog(true);
        }
        
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    return (
        <div className="grid-container">
            {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
            {titles && titles.map((title, index) => (
                <div className="card-for-grid-settings" key={index} onClick={() => handleDetail(ids[index])}>
                    <img src={`data:image/png;base64,${images[index]}`} alt=''/>
                    <div className='settings-title'>{title}</div>
                    <div className='settings-desc'>{captions && captions[index]}</div>
                </div>
            ))}
        </div>
    );
}

export default SettingGridCard;
