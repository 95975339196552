import React, { useEffect, useState } from 'react';
import ProfilSearchBox from "../../component/ProfilSearchBox";
import AnalyticsNonActive from "../../component/menu/AnalyticsNonActive";
import DashboardActive from "../../component/menu/DashboardActive";
import DocumentNonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import FormLabel from '../../component/FormLabel';
import TextBox from '../../component/TextBox';
import Loader from '../../component/Loader';
import { API_BASE_URL } from '../../../App';
import PasswordTextBox from '../../component/PasswordTextBox';

function ChangeProfile() {
    const { username } = useAuthSession();
    const [file, setFile] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [profile, setProfile] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchProfile = async () => {
            setLoading(true);

            try {
                const response = await fetch(`${API_BASE_URL}user/detailuser.php?username=${username}`);
                const data = await response.json();
                
                console.log(response);
                if (data.StatusCode === 200) {
                    setProfile(data.Data[0]);
                    console.log(data.Data[0]);
                }
            } catch (error) {
                console.error('Error fetching profile:', error);
            } finally {
                setLoading(false);
            }
        };

        if (username) {
            fetchProfile();
        }
    }, [username]);

    return (
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardActive />
                <SalesNonActive />
                <PurchaseNonActive />
                <FinanceNonActive />
                <WarehouseNonActive />
                <HRNonActive />
                <AnalyticsNonActive />
                <DocumentNonActive />
                <SettingNonActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                {loading ? (<Loader/>) : (
                    <div>
                        <div className='card-for-grid'>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Nama Lengkap"}/>
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={profile.full_name}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Username"}/>
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={profile.username} placeholder={'Insert your username'}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Akses"}/>
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={profile.permission_access}/>
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Password Baru"}/>
                                    <div className='space-between-1vh'></div>
                                    <PasswordTextBox placeholder={'Insert your new password'}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Konfirmasi Password Baru"}/>
                                    <div className='space-between-1vh'></div>
                                    <PasswordTextBox placeholder={'Confirm your new password'}/>
                                </div>
                                <div className="only-100-width">

                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ChangeProfile;
