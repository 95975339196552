import React, { useState } from 'react';
import mammoth from 'mammoth';
import { saveAs } from 'file-saver';

const DocxToHtmlConverter = () => {
  const [htmlContent, setHtmlContent] = useState('');

  const handleDocxUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const arrayBuffer = reader.result;
      const result = await mammoth.convertToHtml({ arrayBuffer }, { transformDocument: mammoth.images.imgElement });
      setHtmlContent(result.value);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleHtmlDownload = () => {
    const blob = new Blob([htmlContent], { type: 'text/html' });
    saveAs(blob, 'converted_document.html');
  };

  return (
    <div>
      <input type="file" onChange={handleDocxUpload} />
      <button onClick={handleHtmlDownload}>Download HTML</button>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default DocxToHtmlConverter;
