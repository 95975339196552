import { API_BASE_URL } from "../../App";

async function fetchCustomer(){
    try{
        const response = await fetch(`${API_BASE_URL}master/customer/getallcustomer.php?company=1252f67e-bfda-11ee-9dcf-0e799759a249`);
        const data = await response.json();
        if (data.StatusCode === 200) {
            return data.Data;
        } else {
            throw new Error('Failed to fetch customer');
        }
    } catch (error){
        throw new Error('Error fetching customer data : ', error)
    }
}

async function fetchNPWP(){
    try{
        const response = await fetch(`${API_BASE_URL}master/ppn/getallppn.php`);
        const data = await response.json();
        if (data.StatusCode === 200) {
            return data.Data;
        } else {
            throw new Error('Failed to fetch customer');
        }
    } catch (error){
        throw new Error('Error fetching customer data : ', error)
    }
}

export {fetchCustomer, fetchNPWP};