import SalesActiveIcon from '../../../assets/Icon/SalesIActive.png';
import { useNavigate } from 'react-router-dom';

function SalesActive(){
    const navigate = useNavigate();

    const handleSalesMenu = (event) => {
        navigate('/salesIndex'); 
    };

    return(
        <div className="MenuContainer" onClick={handleSalesMenu}>
            <img src={SalesActiveIcon} />
            <div className='MenuActive'>Sales</div>
        </div>
    );
}

export default SalesActive;