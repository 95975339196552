import { useNavigate, useSearchParams } from "react-router-dom";
import useAuthSession from "../../services/Session";
import { useEffect, useState } from "react";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import SalesActive from "../../component/menu/SalesActive";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import Logout from "../../component/menu/Logout";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import Loader from "../../component/Loader";
import TextArea from "../../component/TextArea";
import FormLabel from "../../component/FormLabel";
import TextBox from "../../component/TextBox";
import { API_BASE_URL, formatDate, formatDateTime } from "../../../App";
import ErrorDialog from "../../component/dialog/ErrorDialog";
import ReactDatePicker from "react-datepicker";

function DetailDeliveryOrder() {
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [searchParams] = useSearchParams();
    const SONumber = searchParams.get('SONumber');
    const [detailSPPB, setDetailSPPB] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [sppbDate, setSppbDate] = useState(new Date());
    const [quantities, setQuantities] = useState([]);
    const [keterangans, setKeterangans] = useState([]);
    const permissionAccess = localStorage.getItem('permissionAccess');

    useEffect(() => {
        const fetchDeliveryDetail = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}sales/getdetaildelivery.php?SONumber=${SONumber}`);
                console.log(response);
                if (!response.ok) {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail delivery order data');
                    setShowDialog(true);
                    return;
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setDetailSPPB(data.Data);
                    setSppbDate(new Date(data.Data.Details[0].DeliveryDate));

                    const initialQuantities = data.Data.Items.map(item => item.productQTY);
                    setQuantities(initialQuantities);

                    const initialKeterangans = data.Data.Items.map(item => item.Keterangan);
                    setKeterangans(initialKeterangans);
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail delivery order data');
                    setShowDialog(true);
                }
            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail delivery order data: ' + error.message);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        if (SONumber) {
            fetchDeliveryDetail();
        }
    }, [SONumber]);

    const handleRejectAction = async () => {
        setLoading(true);
        try {
            const APIupdate = `${API_BASE_URL}sales/rejectdelivery.php`;
            const response = await fetch(APIupdate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    sales_number: detailSPPB.Details[0].DONumber,
                    update_by: username,
                }),
            });

            if (!response.ok) {
                setErrorCode('400');
                setErrorTitle('Error failed to reject delivery order data');
                setShowDialog(true);
                return;
            }

            if (response.status === 500) {
                setErrorCode('400');
                setErrorTitle('Error failed to reject delivery order data');
                setShowDialog(true);
                return;
            }

            if (response.status === 200) {
                navigate('/salesIndex');
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to reject delivery order data: ' + error.message);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleApproveAction = async () => {
        setLoading(true);
        try {
            const APIupdate = `${API_BASE_URL}sales/approvedelivery.php`;
            const response = await fetch(APIupdate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    sales_number: detailSPPB.Details[0].DONumber,
                    update_by: username,
                }),
            });

            if (!response.ok) {
                setErrorCode('400');
                setErrorTitle('Error failed to approve delivery order data');
                setShowDialog(true);
                return;
            }

            if (response.status === 500) {
                setErrorCode('400');
                setErrorTitle('Error failed to approve delivery order data');
                setShowDialog(true);
                return;
            }

            if (response.status === 200) {
                navigate('/salesIndex');
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to approve delivery order data: ' + error.message);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleInvoiceAction = () => {
        navigate(`/salesIndex/New/newSalesInvoice/withParams/${encodeURIComponent(detailSPPB.Details[0].DONumber)}`);
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleQuantityChange = (e, index) => {
        const newQuantities = [...quantities];
        newQuantities[index] = e.target.value;
        setQuantities(newQuantities);
    };

    const handleKeteranganChange = (e, index) => {
        const newKeterangans = [...keterangans];
        newKeterangans[index] = e.target.value;
        setKeterangans(newKeterangans);
    };

    const handleDateChange = (date) => {
        setSppbDate(date);
    };

    const handleResubmit = async () => {
        setLoading(true);
        try {
            const API = `${API_BASE_URL}sales/reviseddeliveryorder.php`;

            let params = new URLSearchParams({
                sales_order_number: detailSPPB.Details[0].DONumber,
                delivery_date: sppbDate,
                insert_by: username,
                product_length: detailSPPB.Items.length,
            });

            detailSPPB.Items.forEach((item, index) => {
                params.append(`sales_order_product_name_${index + 1}`, item.productName);
                params.append(`sales_order_quantity_before_${index + 1}`, item.productQTY);
                params.append(`sales_order_keterangan_before_${index + 1}`, item.Keterangan);
                params.append(`sales_order_keterangan_after_${index + 1}`, keterangans[index]);
                params.append(`sales_order_quantity_after_${index + 1}`, quantities[index]);
            });

            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params,
            });

            if (response.status === 200) {
                navigate(`/salesIndex`);
            }

            if (response.status === 500) {
                setErrorCode('400');
                setErrorTitle('Error while updating sales order data !!');
                setShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error fetching revised sales order data: ' + error.message);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const createSPPB = async (SONumber) => {
        navigate(`/salesIndex/New/newSPPB/withParams/${encodeURIComponent(SONumber)}`);
    };

    const handleProfit = async (SONumber) => {
        navigate(`/salesIndex/New/newDeliveryOrder/withParams/${encodeURIComponent(SONumber)}`);
    };

    const handleSPPB = async (SONumber) => {
        navigate(`/salesIndex/New/newProfit/withParams/${encodeURIComponent(SONumber)}`);
    };

    return (
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive />
                <SalesActive />
                <PurchaseNonActive />
                <FinanceNonActive />
                <WarehouseNonActive />
                <HRNonActive />
                <AnalyticsnonActive />
                <DocumentnonActive />
                <SettingNonActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Sales Module</div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? (
                    <Loader />
                ) : (
                    detailSPPB ? (
                        <div>
                            <div className='card-for-grid'>
                                <div className='title-for-card'>Delivery Status</div>
                                <div className='grid-container'>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Status"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailSPPB.Details[0].SO_Status_Name}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Created By"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailSPPB.Details[0].InsertBy}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Created At"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{formatDateTime(detailSPPB.Details[0].InsertDt)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-for-grid'>
                                <div className='title-for-card'>Delivery Detail</div>
                                <div className='grid-container'>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Customer Name"} />
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={detailSPPB.Details[0].company_name} />
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"PO Number"} />
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={detailSPPB.Details[0].PONumber} />
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Delivery Number"} />
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={detailSPPB.Details[0].DONumber} />
                                    </div>
                                </div>
                                <div className='grid-container'>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Delivery Date"} />
                                        <div className='space-between-1vh'></div>
                                            {detailSPPB.Details[0].SO_Status_Name === 'Delivery Order Rejected' ? (
                                                <ReactDatePicker
                                                    selected={sppbDate}
                                                    onChange={date => handleDateChange(date)}
                                                    dateFormat="dd MMMM yyyy"
                                                    className="date-picker"
                                                    readOnly={false}
                                                />
                                            ) : (
                                                <TextBox value={formatDate(detailSPPB.Details[0].DeliveryDate)} />
                                            )}
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Bill To"} />
                                        <div className='space-between-1vh'></div>
                                        <TextArea maxLines={5} value={detailSPPB.Details[0].BillTo} />
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Ship To"} />
                                        <div className='space-between-1vh'></div>
                                        <TextArea maxLines={5} value={detailSPPB.Details[0].ShipTo} />
                                    </div>
                                </div>
                                <div className="scrollable-horizontal">
                                    {detailSPPB.Items.map((item, index) => (
                                        <div key={index}>
                                            <div className='ItemNameTemplateForm'>
                                                <FormLabel labelName={`Item #${index + 1}`} />
                                            </div>
                                            <div className='grid-container-scrollable'>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"Nama Barang"} />
                                                    <TextBox value={item.productName} />
                                                </div>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"QTY"} />
                                                    {detailSPPB.Details[0].SO_Status_Name === 'Delivery Order Rejected' ? (
                                                        <TextBox value={quantities[index]} onChange={e => handleQuantityChange(e, index)} />
                                                    ) : (
                                                        <TextBox value={item.productQTY} />
                                                    )}
                                                </div>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"Keterangan"} />
                                                    {detailSPPB.Details[0].SO_Status_Name === 'Delivery Order Rejected' ? (
                                                        <TextBox value={keterangans[index]} onChange={e => handleKeteranganChange(e, index)} />
                                                    ) : (
                                                        <TextBox value={item.Keterangan} />
                                                    )}
                                                </div>
                                                <div className="only-100-width"></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='grid-container-with-padding-left'>
                                {detailSPPB.Details[0].SO_Status_Name === 'Delivery Order Draft' && permissionAccess === 'Full access' ? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={handleApproveAction}>Approve</div>
                                    </div>
                                ) : detailSPPB.Details[0].SO_Status_Name === 'Delivery Order Approved' ? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={() => handleInvoiceAction(detailSPPB.Details[0].SPPBNumber)}>Create Sales Invoice</div>
                                    </div>
                                ) : detailSPPB.Details[0].SO_Status_Name === 'Delivery Order Rejected' ? (
                                    <div className="only-100-width-card-reject">
                                        <div onClick={handleResubmit}>Resubmit</div>
                                    </div>
                                ) : detailSPPB.Details[0].SO_Status_Name === 'Profit Approved' ? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={() => handleProfit(detailSPPB.Details[0].SPPBNumber)}>Create Sales Invoice</div>
                                    </div>
                                ) : detailSPPB.Details[0].SO_Status_Name === 'SPPB Approved' ? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={() => handleSPPB(detailSPPB.Details[0].SPPBNumber)}>Create Sales Invoice</div>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                {detailSPPB.Details[0].SO_Status_Name === 'Delivery Order Draft' && permissionAccess === 'Full access' ? (
                                    <div className="only-100-width-card-reject">
                                        <div onClick={handleRejectAction}>Reject</div>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <Loader />
                    )
                )}
            </div>
        </div>
    );
}

export default DetailDeliveryOrder;
