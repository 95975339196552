import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { formatCurrencyIDR } from '../../App';

const InvoiceExcelExport = (excelData, fileName) => {
    if (!excelData || excelData.length === 0) {
        console.error('No data to export');
        return;
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
    const fileExtension = '.xlsx';

    try {
        const ws_data = [
            [], // Row 1
            [], // Row 2
            ["", "", "", "", "", "", "", "", formatDate(new Date())], // Row 3, Kolom I3
            ["", "", "", "", "", "", "Nama Customer"], // Row 4, Kolom G4
            ["", "", "", "", "", "", "Alamat Customer"], // Row 5, Kolom G5
            [], // Row 6
            ["", "", "", "", "", "", "", "", "INV-001"], // Row 7, Kolom I7
            [], // Row 8
            ["QTY", "", "Nama Barang", "", "", "", "HARGA", "", ""], // Row 9
        ];

        // Add transaction data starting from row 10
        excelData.forEach((item, index) => {
            ws_data.push([
                item.jumlahBarangMasuk, // QTY di Kolom A
                item.namaProduk, // Nama Barang di Kolom B
                "", // Kosong di Kolom C
                "", "", "", formatCurrencyIDR(item.unitPrice), // Kosong di Kolom D-F
                "", // HARGA di Kolom G
                formatCurrencyIDR(item.unitPrice * item.jumlahBarangMasuk), // Kosong di Kolom H,
                ""
            ]);
        });

        // Tambahkan total tax pada baris setelah data transaksi
        ws_data.push(["", "PPN 11%", "", "", "", "", "", "", formatCurrencyIDR(excelData.reduce((total, item) => total + item.tax, 0))]);

        const ws = XLSX.utils.aoa_to_sheet(ws_data);
        const wb = { Sheets: { 'InvoiceData': ws }, SheetNames: ['InvoiceData'] };

        // Merge cells
        ws['!merges'] = [
            // No merges required for H7 and I7
        ];

        // Center align cells in rows 2-4
        for (let R = 2; R <= 6; ++R) {
            for (let C = 0; C <= 8; ++C) {
                const cell_address = { c: C, r: R };
                const cell_ref = XLSX.utils.encode_cell(cell_address);
                if (!ws[cell_ref]) ws[cell_ref] = { t: 's', v: '' };
                ws[cell_ref].s = {
                    alignment: { horizontal: 'center', vertical: 'center' },
                    font: { bold: true }
                };
            }
        }

        // Set date format for the I3 column
        ws['I3'].z = 'dd-mmm-yy';

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    } catch (error) {
        console.error('Error generating Excel file:', error);
    }
};

export default InvoiceExcelExport;

const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = ('0' + date.getDate()).slice(-2);
    const month = date.toLocaleString('en-GB', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2);
    return `${day}-${month}-${year}`;
};
