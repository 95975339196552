import FormLabel from "../component/FormLabel";
import TextBox from "../component/TextBox";
import { useNavigate, useParams } from 'react-router-dom';

function SalesOrder(){
    let { customerID } = useParams();

    return(
        <div className='Menu'>
            <div className='MainMenu'>
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Venken Systems</div>
                </div>
                <div className='grid-container'>
                    <div className="only-100-width">
                        <FormLabel labelName={"Cutomer Name"}/>
                        <TextBox placeholder={""}/>
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"PO Number"}/>
                        <TextBox/>
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"Date"}/>
                        <TextBox placeholder={"Insert your PO number"}/>
                    </div>
                </div>
                <div className='grid-container'>
                    <div className="only-100-width">
                        <FormLabel labelName={"Description of Good"}/>
                        <TextBox/>
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"Quantity"}/>
                        <TextBox/>
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"Unit Price"}/>
                        <TextBox/>
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"Amount"}/>
                        <TextBox/>
                    </div>
                    <div className="only-100-width">
                        <FormLabel labelName={"Keterangan"}/>
                        <TextBox maxLines={5} />
                    </div>
                </div>
                <div className='grid-container-with-padding-left'>
                    <div className="only-100-width-card-approve">Submit</div>
                </div>
            </div>
        </div>
    );
}

export default SalesOrder;