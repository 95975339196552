import { useNavigate } from "react-router-dom";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseActive from "../../component/menu/PurchaseActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import { useEffect, useState } from "react";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import ExportExcel from "../../services/ExcelExportServices";
import ErrorDialog from "../../component/dialog/ErrorDialog";
import Loader from "../../component/Loader";
import { API_BASE_URL, formatDate } from "../../../App";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormLabel from "../../component/FormLabel";
import TextBox from "../../component/TextBox";

function SeeAllPurchaseInvoice() {
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [isExporting, setIsExporting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [purchaseData, setPurchaseData] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [searchTrigger, setSearchTrigger] = useState(false);
    const [PONumber, setPONumber] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetchPurchaseData();
                setPurchaseData(response);
            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail purchase data:', error.message);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [searchTrigger]);

    const fetchPurchaseData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}purchase/getallpurchaseinvoice.php?startdate=${startDate.toISOString().split('T')[0]}&enddate=${endDate.toISOString().split('T')[0]}&PONumber=${PONumber}`);
            if (!response.ok) {
                throw new Error('Failed to fetch purchase order data');
            }
            const data = await response.json();
            return data.Data || [];
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const handleClick = (PONumber) => {
        navigate(`/purchaseIndex/Detail/invoice/${encodeURIComponent(PONumber)}`);
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleExportClick = async () => {
        setIsExporting(true);
        try {
            await ExportExcel({ excelData: purchaseData, fileName: 'purchase_data' });
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to export purchase order data:', error.message);
            setShowDialog(true);
        }
        setIsExporting(false);
    };

    const handleSearchClick = () => {
        setSearchTrigger(!searchTrigger);
    };

    return (
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive />
                <SalesNonActive />
                <PurchaseActive />
                <FinanceNonActive />
                <WarehouseNonActive />
                <HRNonActive />
                <AnalyticsnonActive />
                <DocumentnonActive />
                <SettingNonActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                <div className='MenuName'>See All Purchase Invoice</div>
                <div className='grid-container-smaller'>
                    <div className='FilterDate'>
                        <div>
                            <FormLabel labelName={"PO Number"}/>
                            <div className='space-between-1vh'></div>
                            <TextBox placeholder={'Insert your PO number'} value={PONumber} onChange={(e) => setPONumber(e.target.value)}/>
                        </div>
                        <div>
                            <FormLabel labelName={"Start Invoice Date"}/>
                            <div className='space-between-1vh'></div>
                            <DatePicker
                                className='date-picker'
                                selected={startDate}
                                dateFormat="dd MMMM yyyy"
                                onChange={(date) => setStartDate(date)}
                                placeholderText='Select sales date'
                            />
                        </div>
                        <div>
                            <FormLabel labelName={"End Invoice Date"}/>
                            <div className='space-between-1vh'></div>
                            <DatePicker
                                className='date-picker'
                                selected={endDate}
                                dateFormat="dd MMMM yyyy"
                                onChange={(date) => setEndDate(date)}
                                placeholderText='Select end date'
                            />
                        </div>
                        <button className='seeAllDetailCard' onClick={handleSearchClick}>Search</button>
                    </div>
                    <button className='FilterExport' onClick={handleExportClick} disabled={isExporting}>
                        {isExporting ? (<Loader/>) : 'Export to Excel'}
                    </button>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? <Loader /> : (
                    purchaseData.map((purchase, index) => (
                        <div key={index} className='seeAllCard' onClick={() => handleClick(purchase.PONumber)}>
                            <div className='grid-container-smaller'>
                                <div>
                                    <div className='seeAllTitle'>{purchase.PONumber}</div>
                                    <div className='seeAllDetail'>Purchase date {formatDate(purchase.invoiceDate)}</div>
                                </div>
                                <div className='seeAllDetailCard'>{purchase.PO_Status_Name}</div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default SeeAllPurchaseInvoice;
