import React, { useState, useEffect } from 'react';
import FormLabel from "../FormLabel";
import TextBox from '../TextBox';
import { FaTrash } from 'react-icons/fa';
import { API_BASE_URL, formatCurrency } from '../../../App';

function PurchaseImportTemplate({ items, setItems, currency }) {
    const [count, setCount] = useState(0);
    const [total, setTotal] = useState(0);
    const [vat, setVat] = useState(0.11);
    const [kodeProduk, setKodeProduk] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    useEffect(() => {
        calculateTotal();
        console.log(currency); // Access currency prop here
    }, [items, currency]); // Include currency in the dependency array

    const calculateTotal = () => {
        let sum = 0;
        items.forEach(item => {
            sum += item.quantity * item.unitPrice;
        });
        setTotal(sum);
    };

    const handleAddItem = () => {
        setCount(prevCount => prevCount + 1);

        const newItem = {
            id: count + 1,
            label: `Item #${count + 1}`,
            productname: '',
            quantity: 0, 
            unitPrice: 0,
            packaging : 0 
        };

        // Update the items list with the new item
        setItems(prevItems => [...prevItems, newItem]);
    };

    const handleProductNameChange = (id, value) => {
        setItems(prevItems =>
            prevItems.map(item =>
                item.id === id ? { ...item, productname: value } : item
            )
        );
    };

    const handleDeleteItem = (id) => {
        const updatedItems = items.filter(item => item.id !== id);
        setItems(updatedItems);
    };

    const handleQuantityChange = (id, value) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setItems(prevItems =>
                prevItems.map(item =>
                    item.id === id ? { ...item, quantity: value } : item
                )
            );
        }
    };

    const handlePackagingSizeChange = (id, value) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setItems(prevItems =>
                prevItems.map(item =>
                    item.id === id ? { ...item, packaging: value } : item
                )
            );
        }
    };

    const handleUnitPriceChange = (id, value) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setItems(prevItems =>
                prevItems.map(item =>
                    item.id === id ? { ...item, unitPrice: value.toLocaleString() } : item
                )
            );
        }
    };

    const handleKodeProdukChange = async (e) => {
        const value = e.target.value;
        setKodeProduk(value);

        if (value.length > 0) {
            try {
                const response = await fetch(`${API_BASE_URL}warehouse/productquery.php?find=${value}`);
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setSuggestions(data.products);
                    setShowSuggestions(true);
                } else {
                    setSuggestions([]);
                    setShowSuggestions(false);
                }
            } catch (error) {
                console.error("Error fetching product suggestions:", error);
                setSuggestions([]);
                setShowSuggestions(false);
            }
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    };

    const handleSuggestionClick = (id, suggestion) => {
        setItems(prevItems =>
            prevItems.map(item =>
                item.id === id ? { ...item, productname: suggestion.productName } : item
            )
        );
        setKodeProduk(suggestion.skuID);
        setShowSuggestions(false);
    };

    return (
        <div>
            {items.map(item => (
                <div key={item.id}>
                    <div className='ItemNameTemplateForm'>
                        <FormLabel labelName={item.label}/>
                        <div onClick={() => handleDeleteItem(item.id)} className='DeleteTemplateForm'>
                            <FaTrash className="icon" />
                            Delete {item.label}
                        </div>
                    </div>
                    <div className='grid-container'>
                        <div className="only-100-width">
                            <FormLabel labelName={"Product Name"}/>
                            <TextBox 
                                value={item.productname} 
                                onChange={e => {
                                    handleProductNameChange(item.id, e.target.value);
                                    handleKodeProdukChange(e);
                                }} 
                                placeholder={"Insert product name"} 
                            />
                            {showSuggestions && (
                                <ul className="suggestions-list">
                                    {suggestions.map((suggestion, index) => (
                                        <li key={index} onClick={() => handleSuggestionClick(item.id, suggestion)}>
                                            {suggestion.skuID} - {suggestion.productName}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className="only-100-width">
                            <FormLabel labelName={"Quantity (Kg)"}/>
                            <TextBox value={item.quantity} onChange={e => handleQuantityChange(item.id, e.target.value)} />
                        </div>
                        <div className="only-100-width">
                            <FormLabel labelName={"Packaging Size (Kg)"}/>
                            <TextBox value={item.packaging} onChange={e => handlePackagingSizeChange(item.id, e.target.value)} />
                        </div>
                        <div className="only-100-width">
                            <FormLabel labelName={`Unit Price (${currency})`} />
                            <TextBox value={item.unitPrice.toLocaleString()} onChange={e => handleUnitPriceChange(item.id, e.target.value)} placeholder="0.00" />
                        </div>
                        <div className="only-100-width">
                            <FormLabel labelName={`Total (${currency})`}/>
                            <TextBox value={formatCurrency((item.quantity * item.unitPrice), currency)} readOnly />
                        </div>
                    </div>
                </div>
            ))}
            <div className='grid-container'>
                <div className="only-100-width">
                </div>
                <div className="only-100-width">
                </div>
                <div className="only-100-width">
                </div>
                <div className="only-100-width">
                    <FormLabel labelName={"Total"}/>
                </div>
                <div className="only-100-width">
                    <TextBox value={formatCurrency(total, currency)} readOnly />
                </div>
            </div>
            <div className='AddItemTemplate' onClick={handleAddItem} style={{ cursor: 'pointer' }} >Click Here To Add Item</div>
        </div>
    );
}

export default PurchaseImportTemplate;
