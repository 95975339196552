import DashboardNonActive from '../../component/menu/DashboardNonActive';
import '../../component/Component.css';
import '../../../App.css';
import SalesNonActive from '../../component/menu/SalesNonActive';
import React, { useState, useEffect } from 'react';
import Logout from '../../component/menu/Logout';
import ProfilSearchBox from '../../component/ProfilSearchBox';
import FourCardGrid from '../../component/card/FourCardGrid';
import TwoCardGrid from '../../component/card/TwoCardGrid';
import OneCardGrid from '../../component/card/OneCardGrid';
import SettingNonActive from '../../component/menu/SettingNonActive';
import { useNavigate } from 'react-router-dom';
import NewPurchaseDialog from '../../component/dialog/NewPurchaseDialogBox';
import useAuthSession from '../../services/Session';
import PurchaseActive from '../../component/menu/PurchaseActive';
import FinanceNonActive from '../../component/menu/FinanceNonActive';
import WarehouseNonActive from '../../component/menu/WarehouseNonActive';
import HRNonActive from '../../component/menu/HRNonActive';
import AnalyticsnonActive from '../../component/menu/AnalyticsNonActive';
import DocumentnonActive from '../../component/menu/DocumentNonActive';
import Loader from '../../component/Loader';
import NotFoundPage from '../../component/maintanance/NotFound';
import {API_BASE_URL, formatDate} from '../../../App';
import ErrorDialog from '../../component/dialog/ErrorDialog';

function PurchaseIndex(){
    const permissionAccess = localStorage.getItem('permissionAccess');
    const [purchaseLocal, setPurchaseLocal] = useState([]);
    const [purchaseImport, setPurchaseImport] = useState([]);
    const [receieveItems, setReceieveItems] = useState([]);
    const [purchaseInvoice, setPurchaseInvoiceItems] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [index, setIndex] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const { username } = useAuthSession();


    useEffect(() => {
        const fetchPurchaseLocal = async () => {
            setLoading(true);
            try {
                const response = await fetch(API_BASE_URL +  'purchase/gettoppurchaselocal.php');
                const data = await response.json();
                if (data.Data.length === 0) {
                    setPurchaseLocal(['-']);
                } else {
                    setPurchaseLocal(data.Data); 
                }
            } catch (error) {
                // setErrorCode('400');
                // setErrorTitle('Error failed to fetch detail purchase local data : ', error);
                // setShowErrorDialog(true);
            } finally {
                setLoading(false);
            }
        };
    
        const fetchPurchaseImport = async () => {
            setLoading(true);
            try {
                const response = await fetch(API_BASE_URL +  'purchase/gettoppurchaseimport.php');
                const data = await response.json();
                if (data.Data.length === 0) {
                    setPurchaseImport(['-']);
                } else {
                    setPurchaseImport(data.Data); 
                }
            } catch (error) {
                // setErrorCode('400');
                // setErrorTitle('Error failed to fetch detail purchase import data : ', error);
                // setShowErrorDialog(true);
            } finally {
                setLoading(false);
            }
        };

        const fetchReceiveItems = async () => {
            setLoading(true);
            try {
                const response = await fetch(API_BASE_URL +  'purchase/receivingitems/gettopreceiveitems.php');
                const data = await response.json();
                if (data.Data.length === 0) {
                    setReceieveItems(['-']);
                } else {
                    setReceieveItems(data.Data); 
                }
            } catch (error) {
                // setErrorCode('400');
                // setErrorTitle('Error failed to fetch detail receiving items data : ', error);
                // setShowErrorDialog(true);
            } finally {
                setLoading(false);
            }
        };

        const fetchInvoiceItems = async () => {
            setLoading(true);
            try {
                const response = await fetch(API_BASE_URL +  'purchase/invoice/gettopinvoice.php');
                const data = await response.json();
                if (data.Data.length === 0) {
                    setPurchaseInvoiceItems(['-']);
                } else {
                    setPurchaseInvoiceItems(data.Data); 
                }
            } catch (error) {
                // setErrorCode('400');
                // setErrorTitle('Error failed to fetch detail purchase invoice data : ', error);
                // setShowErrorDialog(true);
            } finally {
                setLoading(false);
            }
        };

        const fetchStatistic = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}purchase/getoverallpurchase.php`);
                if (!response.ok) {
                    throw new Error('Failed to fetch sales order data');
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setIndex(data.Data);
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail purchase local data');
                    setShowErrorDialog(true);
                }   
            } catch (error) {
                // setErrorCode('400');
                // setErrorTitle('Error failed to fetch detail purchase local data : ', error);
                // setShowErrorDialog(true);
            } finally {
                setLoading(false);
            }
        }
    
        fetchPurchaseLocal();
        fetchPurchaseImport();
        fetchReceiveItems();
        fetchInvoiceItems();
        fetchStatistic();
    }, []);
    
    const handleNewClick = (event) => {
        event.preventDefault(); 
        setShowDialog(true);
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const dialogBox = <NewPurchaseDialog onCloseDialog={handleCloseDialog}/>;

    const handleErrorCloseDialog = () => {
        setShowErrorDialog(false);
    };

    return(
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                {showDialog && dialogBox}
                <ProfilSearchBox username={username}/>
                {showErrorDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleErrorCloseDialog} />}
                {loading ? (<Loader/>) : (
                    <div>
                        {permissionAccess === 'Full access' || permissionAccess === 'Purchase only' ? 
                            (
                                <div>
                                    <div className='grid-container'>
                                        <div className='MenuName'>Purchase Module</div>
                                        <div className='create-new-button' onClick={handleNewClick}>Create New</div>
                                    </div>
                                    <FourCardGrid 
                                        headline={`${index.CurrentMonth} Overview`}
                                        title1="Draft"
                                        content1={index.total_draft}
                                        title2="Approved"
                                        content2={index.total_approved}
                                        title3="Receieved"
                                        content3={index.total_received}
                                        title4="Invoice"
                                        content4={index.total_invoice}
                                    />
                                    <div className='space-between'/>
                                    {/* Purchase Order (Local) */}
                                    <OneCardGrid
                                        headline="Purchase Order (Local)"
                                        data={purchaseLocal.map(order => ({
                                            companyName: order.supplier_name,
                                            orderDate: formatDate(order.PODate),
                                            PONumber: order.PONumber,
                                            POTypeName: order.PO_Type_Name
                                        }))}
                                        type="Local"
                                    />
                                    <div className='space-between'/>
                                    {/* Purchase Order (Import) */}
                                    <OneCardGrid
                                        headline="Purchase Order (Import)"
                                        data={purchaseImport.map(order => ({
                                            companyName: order.supplier_name,
                                            orderDate: formatDate(order.PODate),
                                            PONumber: order.PONumber,
                                            POTypeName: order.PO_Type_Name
                                        }))}
                                        type="Import"
                                    />
                                    <div className='space-between'/>
                                    <OneCardGrid
                                        headline="Receiving Items"
                                        data={receieveItems.map(order => ({
                                            companyName: order.supplier_name,
                                            orderDate: formatDate(order.ReceivingDate),
                                            PONumber: order.PONumber
                                        }))}
                                        type="Receive"
                                    />
                                    <div className='space-between'/>
                                    <OneCardGrid
                                        headline="Purchase Invoice"
                                        data={purchaseInvoice.map(order => ({
                                            companyName: order.supplier_name,
                                            orderDate: formatDate(order.invoiceDate),
                                            PONumber: order.PONumber
                                        }))}
                                        type="Invoice"
                                    />
                                    {/* <div className='space-between'/> */}
                                    {/* <OneCardGrid
                                        headline="Supplier Oustanding"
                                        data={purchaseInvoice.map(order => ({
                                            companyName: order.supplier_name,
                                            orderDate: formatDate(order.invoiceDate),
                                            PONumber: order.PONumber
                                        }))}
                                        type="Invoice"
                                    /> */}
                                </div>
                            ) : 
                            (
                                <div>
                                    <div className='grid-container-smaller'>
                                        <div className='MenuName'>Purchase Module</div>
                                    </div>
                                    <NotFoundPage menuName={'Purchase'}/>
                                </div>
                            )
                        }
                    </div>
                )}
                
            </div>
        </div>
    );
}

export default PurchaseIndex;