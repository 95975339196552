import { useEffect, useState } from "react";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceActive from "../../component/menu/FinanceActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import { API_BASE_URL } from "../../../App";
import Loader from "../../component/Loader";
import FormLabel from "../../component/FormLabel";
import DatePicker from "react-datepicker";
import TextBox from "../../component/TextBox";
import TextArea from "../../component/TextArea";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../../component/dialog/ErrorDialog";

function NewPembayaran() {
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [loading, setLoading] = useState(false);
    const [allAccountCode, setAllAccountCode] = useState([]);
    const [selectedAccountCode, setSelectedAccountCode] = useState('');
    const [selectedAccountCodeName, setSelectedAccountCodeName] = useState('');
    const [allBank, setAllBank] = useState([]);
    const [selectedBank, setSelectedBank] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [voucherNo, setVoucherNo] = useState('');
    const [memo, setMemo] = useState('');
    const [chequeNo, setChequeNo] = useState('');
    const [payee, setPayee] = useState('');
    const [memoAccount, setMemoAccount] = useState('');
    const [amount, setAmount] = useState('');
    const [accountAmount, setAccountAmount] = useState('');

    useEffect(() => {
        fetchAccountCode();
        fetchBankAccount();
    }, []);

    useEffect(() => {
        if (selectedAccountCode) {
            fetchAccountName(selectedAccountCode);
        }
    }, [selectedAccountCode]);

    const fetchAccountCode = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}finance/allaccountcode.php`);
            const data = await response.json();

            if (data.StatusCode === 200) {
                setAllAccountCode(data.Data);
                if (data.Data.length > 0) {
                    setSelectedAccountCode(data.Data[0].code);
                    setSelectedAccountCodeName(data.Data[0].account_name);
                }
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch last PO Number');
                setShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch account code: ' + error.message);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const fetchBankAccount = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}finance/allbankaccount.php`);
            const data = await response.json();

            if (data.StatusCode === 200) {
                setAllBank(data.Data);
                if (data.Data.length > 0) {
                    setSelectedBank(data.Data[0].bank_number);
                }
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch last PO Number');
                setShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch bank account: ' + error.message);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const fetchAccountName = async (accountCode) => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}finance/findaccountcodename.php?account_code=${accountCode}`);
            const data = await response.json();
            if (data.StatusCode === 200) {
                const accountNameFromAPI = data.Data[0]?.account_name || 'No PIC assigned';
                setSelectedAccountCodeName(accountNameFromAPI);
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch PIC Name');
                setShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch account name: ' + error.message);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleBankAccountChange = (event) => {
        const newBankAccount = event.target.value;
        setSelectedBank(newBankAccount);
    };

    const handleAccountCodeChange = (event) => {
        const newAccountCode = event.target.value;
        setSelectedAccountCode(newAccountCode);
    };

    const handleSubmitNew = async () => {
        setLoading(true);
        try {
            const API = `${API_BASE_URL}finance/insertpembayaran.php`;

            const cleanedAmount = parseInt(amount.replace(/\./g, ''), 10);
            const cleanedAccountAmount = parseInt(accountAmount.replace(/\./g, ''), 10);

            let params = new URLSearchParams({
                paid_from : selectedBank,
                voucher_no : voucherNo,
                cheque_no : chequeNo,
                date : startDate,
                memo : memo,
                payee : payee,
                amount : cleanedAmount,
                account_code : selectedAccountCode,
                account_amount : cleanedAccountAmount,
                account_memo : memoAccount,
                username : username
            });

            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params
            });

            if(response.status === 200){
                navigate(`/financeIndex/Pembayaran`);
            }

            if(response.status === 500){
                setErrorCode('400');
                setErrorTitle('Error while update sales order data !!');
                setShowDialog(true);
            }

        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed while inserting pembayaran : ', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmitClose = async () => {
        setLoading(true);
        try {
            const API = `${API_BASE_URL}finance/insertpembayaran.php`;

            const cleanedAmount = parseInt(amount.replace(/\./g, ''), 10);
            const cleanedAccountAmount = parseInt(accountAmount.replace(/\./g, ''), 10);

            let params = new URLSearchParams({
                paid_from : selectedBank,
                voucher_no : voucherNo,
                cheque_no : chequeNo,
                date : startDate,
                memo : memo,
                payee : payee,
                amount : cleanedAmount,
                account_code : selectedAccountCode,
                account_amount : cleanedAccountAmount,
                account_memo : memoAccount,
                username : username
            });

            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params
            });

            if(response.status === 200){
                navigate(`/financeIndex`);
            }

            if(response.status === 500){
                setErrorCode('400');
                setErrorTitle('Error while update sales order data !!');
                setShowDialog(true);
            }

        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed while inserting pembayaran : ', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleVoucherNoChange = (event) => {
        setVoucherNo(event.target.value);
    };

    const handleMemoChange = (event) => {
        setMemo(event.target.value);
    };

    const handleMemoAccountChange = (event) => {
        setMemoAccount(event.target.value);
    };

    const handleChequeNoChange = (event) => {
        setChequeNo(event.target.value);
    };

    const handlePayeeChange = (event) => {
        setPayee(event.target.value);
    };

    const handleAmountChange = (event) => {
        const formattedAmount = event.target.value.replace(/\D/g, '');
        setAmount(new Intl.NumberFormat('id-ID').format(formattedAmount));
    }

    const handleAccountAmountChange = (event) => {
        const formattedAmount = event.target.value.replace(/\D/g, '');
        setAccountAmount(new Intl.NumberFormat('id-ID').format(formattedAmount));
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    return (
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive />
                <SalesNonActive />
                <PurchaseNonActive />
                <FinanceActive />
                <WarehouseNonActive />
                <HRNonActive />
                <AnalyticsnonActive />
                <DocumentnonActive />
                <SettingNonActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Finance Module</div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? (<Loader />) : (
                    <div>
                        <div className='card-for-grid'>
                            <div className='title-for-card'>Pembayaran</div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Paid From"} />
                                    <div className='space-between-1vh'></div>
                                    <select className='new-form-dropdown' value={selectedBank} onChange={handleBankAccountChange}>
                                        {allBank.map((bank) => (
                                            <option key={bank.bank_number} value={bank.bank_number}>
                                                {bank.bank_number} - {bank.bank_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Voucher No"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox 
                                        placeholder={"Input your voucher number"} 
                                        value={voucherNo} 
                                        onChange={handleVoucherNoChange} 
                                    />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Cheque No"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox 
                                        placeholder={"Input your voucher number"} 
                                        value={chequeNo} 
                                        onChange={handleChequeNoChange} 
                                    />
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Date"} />
                                    <div className='space-between-1vh'></div>
                                    <DatePicker
                                        className='date-picker'
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat="dd MMMM yyyy"
                                    />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Memo"} />
                                    <div className='space-between-1vh'></div>
                                    <TextArea 
                                        maxLines={5} 
                                        placeholder={"Insert your memo here"} 
                                        value={memo} 
                                        onChange={handleMemoChange} 
                                    />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Payee"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox 
                                        placeholder={"Input your voucher number"} 
                                        value={payee} 
                                        onChange={handlePayeeChange} 
                                    />
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Amount"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox 
                                        placeholder={"Insert your amount here"}
                                        value={amount}
                                        onChange={handleAmountChange}
                                    />
                                </div>
                                <div className="only-100-width">
                                </div>
                                <div className="only-100-width">
                                </div>
                            </div>
                            <div className='title-for-card'>Detail Pembayaran</div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Account No"} />
                                    <div className='space-between-1vh'></div>
                                    <select className='new-form-dropdown' value={selectedAccountCode} onChange={handleAccountCodeChange}>
                                        {allAccountCode.map((code) => (
                                            <option key={code.code} value={code.code}>
                                                {code.code} {code.account_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Account Name"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={selectedAccountCodeName} readOnly />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Amount"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox 
                                        placeholder={"Insert your amount here"}
                                        value={accountAmount}
                                        onChange={handleAccountAmountChange}
                                    />
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Memo"} />
                                    <div className='space-between-1vh'></div>
                                    <TextArea 
                                        maxLines={5} 
                                        placeholder={"Insert your memo here"} 
                                        value={memoAccount} 
                                        onChange={handleMemoAccountChange} 
                                    />
                                </div>
                                <div className="only-100-width">
                                </div>
                                <div className="only-100-width">
                                </div>
                            </div>
                        </div>
                        <div className='grid-container-with-padding-left'>
                            <div className="only-100-width-card-submit" onClick={handleSubmitNew}>Submit & New</div>
                            <div className="only-100-width-card-approve" onClick={handleSubmitClose}>Submit & Close</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default NewPembayaran;
