import React, { useState, useEffect } from 'react';
import SettingGridCard from './SettingGridCard';
import '../../component/Component.css';
import DashboardNonActive from '../../component/menu/DashboardNonActive';
import SalesNonActive from '../../component/menu/SalesNonActive';
import PurchaseNonActive from '../../component/menu/PurchaseNonActive';
import Logout from '../../component/menu/Logout';
import ProfilSearchBox from '../../component/ProfilSearchBox';
import { API_BASE_URL } from '../../../App';
import useAuthSession from '../../services/Session';
import SettingActive from '../../component/menu/SettingActive';
import FinanceNonActive from '../../component/menu/FinanceNonActive';
import WarehouseNonActive from '../../component/menu/WarehouseNonActive';
import HRNonActive from '../../component/menu/HRNonActive';
import AnalyticsnonActive from '../../component/menu/AnalyticsNonActive';
import DocumentnonActive from '../../component/menu/DocumentNonActive';
import Loader from '../../component/Loader';
import ErrorDialog from '../../component/dialog/ErrorDialog';

function SettingIndex() {
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(true);
    const { username } = useAuthSession();
    const [showDialog, setShowDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');
    
    useEffect(() => {
        fetch(API_BASE_URL + 'master/settings/Setting.php?action=1')
            .then(response => response.json())
            .then(data => {
                if (data.Status === "Success") {
                    setSettings(data.Data);
                } else {
                    setErrorTitle(data.StatusCode);
                    setErrorMessage(data.Status);
                    setShowDialog(true);
                }
            })
            .catch(error => {
                setErrorTitle("Error");
                setErrorMessage(error.message);
                setShowDialog(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const renderSettingGrids = () => {
        if (loading) {
            return <Loader/>;
        } else {
            const numGrids = Math.ceil(settings.length / 4);
            const settingGroups = [];

            for (let i = 0; i < numGrids; i++) {
                const startIndex = i * 4;
                const endIndex = Math.min(startIndex + 4, settings.length);
                const group = settings.slice(startIndex, endIndex);
                settingGroups.push(group);
            }

            return (
                <div>
                    {settingGroups.map((group, index) => (
                        <div className="grid-container" key={index}>
                            {group.map((setting, settingIndex) => (
                                <SettingGridCard
                                    key={setting.settingId} // Ensure each key is unique
                                    images={[setting.settingImage]}
                                    titles={[setting.settingName]}
                                    captions={[setting.settingCaption]}
                                    ids={[setting.settingId]} 
                                />
                            ))}
                        </div>
                    ))}
                </div>
            );
        }
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    return (
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseNonActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div>
                    <div className='grid-container'>
                        <div className='MenuName'>Settings Module</div>
                    </div>
                    {showDialog && <ErrorDialog onCloseDialog={handleCloseDialog} title={errorTitle} message={errorMessage} />}
                    {renderSettingGrids()}
                </div>
            </div>
        </div>
    );
}

export default SettingIndex;
