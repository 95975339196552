import React, { useState, useEffect } from 'react';
import FormLabel from "../../component/FormLabel";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import TextArea from "../../component/TextArea";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseActive from "../../component/menu/PurchaseActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import {fetchPayments, fetchCompanys, fetchImportSuppliers } from '../../services/PurchaseService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PurchaseImportTemplate from '../../component/form/PurchaseImportTemplate';
import { API_BASE_URL } from '../../../App';
import ErrorDialog from '../../component/dialog/ErrorDialog';
import SuccessDialog from '../../component/dialog/SuccessDialog';
import TextBox from '../../component/TextBox';
import Loader from '../../component/Loader';
import { useNavigate } from 'react-router-dom';

function NewPurchaseImport(){
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [selectedOrigin, setSelectedOrigin] = useState('');
    const [filteredSuppliers, setFilteredSuppliers] = useState([]);
    const [selectedSupplier, setselectedSupplier] = useState('');
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [selectedPayment, setselectedPayment] = useState('');
    const [filteredShippings, setFilteredShippings] = useState([]);
    const [selectedShipping, setselectedShipping] = useState('');
    const [selectedCurrency, setselectedCurrency] = useState('');
    const [txtCurrencyName, setselectedCurrencyName] = useState('');
    const [txtOriginName, setselectedOriginName] = useState('');
    const [txtTermname, setselectedTermName] = useState('');
    const [txtRemarks, setTxtRemarks] = useState('');
    const [txtShippingRemarks, setTxtShippingRemarks] = useState('');
    const [selectedTerm, setselectedterm] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [items, setItems] = useState([]);
    const [companyData, setCompanyData] = useState({});
    const [picName, setPicName] = useState('');
    const [loading, setLoading] = useState(false);
    const [PONumber, setPONumber] = useState('');
    const [ErrorShowDialog, setErrorShowDialog] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');

    useEffect(() => {
        fetchShippings();
        fetchSupplierData('1252f67e-bfda-11ee-9dcf-0e799759a249');
        fetchPaymentData();
        fetchCompanyData();
        fetchTerm();
        fetchPONumber();
        setTxtShippingRemarks('VIK - PO - No....\nInv. No....DD......\nJakarta');
    }, []);

    const fetchPONumber = async () => {
        setLoading(true);
        try{
            const response = await fetch(API_BASE_URL + 'purchase/recievelastPOnumber.php');
            const data = await response.json();
            if(data.StatusCode === 200){
                setPONumber(data.Data.PONumber);
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch last PO Number');
                setErrorShowDialog(true);
            }
        } catch (e){
            setErrorCode('400');
            setErrorTitle('Error failed to fetch last PO Number : ', e);
            setErrorShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const fetchTerm = async () => {
        setLoading(true);
        try {
            const response = await fetch(API_BASE_URL + 'master/term/getallterm.php');
            const data = await response.json();
            if (data.StatusCode === 200) {
                setselectedterm(data.Data[0]?.term_id || '');
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch all term data');
                setErrorShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch all term data : ', error);
            setErrorShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const fetchPaymentData = async () => {
        setLoading(true);
        try {
            const paymentData = await fetchPayments();
            setFilteredPayments(paymentData);
            setselectedPayment(paymentData[0]?.Id || '');
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch payment data : ', error);
            setErrorShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const fetchSupplierData = async (companyID) => {
        setLoading(true);
        try {
            const supplierData = await fetchImportSuppliers(companyID);
            setFilteredSuppliers(supplierData);
            setselectedSupplier(supplierData[0]?.supplier_id || '');
            fetchPicName(supplierData[0]?.supplier_id);
            fetchSupplierCurrency(supplierData[0]?.supplier_id);
            fetchSupplierOrigin(supplierData[0]?.supplier_id);
            fetchSupplierTerm(supplierData[0]?.supplier_id);
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch payment data : ', error);
            setErrorShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const fetchPicName = async (supplierId) => {
        if (!supplierId) return;

        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}master/supplier/getpicnamebasedonsupplier.php?supplier=${supplierId}`);
            const data = await response.json();
            if (data.StatusCode === 200) {
                const picNameFromAPI = data.Data[0]?.supplier_pic_name || 'No PIC assigned';
                setPicName(picNameFromAPI);
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail PIC data');
                setErrorShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch detail PIC data : ', error);
            setErrorShowDialog(true);
            setPicName('Failed to load PIC name');
        } finally {
            setLoading(false);
        }
    };

    const fetchSupplierCurrency = async (supplierID) => {
        if (!supplierID) return;
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}master/supplier/getcurrencybasedonsupplier.php?supplier=${supplierID}`);
            const data = await response.json();
            if (data.StatusCode === 200) {
                const currencyFromPIC = data.Data[0]?.currency_name || 'No Currency assigned';
                const currencyIDFromPIC = data.Data[0]?.supplier_currency || 'No Currency assigned';
                setselectedCurrency(currencyIDFromPIC);
                setselectedCurrencyName(currencyFromPIC);
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch currency based on supplier');
                setErrorShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch currency based on supplier : ', error);
            setErrorShowDialog(true);
            setPicName('Failed to load Currency name');
        } finally {
            setLoading(false);
        }
    }

    const fetchSupplierOrigin = async (supplierID) => {
        if (!supplierID) return;
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}master/supplier/getoriginbasedonsupplier.php?supplier=${supplierID}`);
            const data = await response.json();
            if (data.StatusCode === 200) {
                const originFromPIC = data.Data[0]?.origin_name || 'No Currency assigned';
                const originIDFromPIC = data.Data[0]?.supplier_origin || 'No Currency assigned';
                setSelectedOrigin(originIDFromPIC);
                setselectedOriginName(originFromPIC);
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail origin based on supplier');
                setErrorShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch detail origin based on supplier : ', error);
            setErrorShowDialog(true);
            setPicName('Failed to load Currency name');
        } finally {
            setLoading(false);
        }
    }

    const fetchSupplierTerm = async (supplierID) => {
        if (!supplierID) return;
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}master/supplier/gettermbasedonsupplier.php?supplier=${supplierID}`);
            const data = await response.json();
            if (data.StatusCode === 200) {
                const termFromPIC = data.Data[0]?.term_name || 'No Currency assigned';
                const termIDFromPIC = data.Data[0]?.supplier_term || 'No Currency assigned';
                setselectedterm(termIDFromPIC);
                setselectedTermName(termFromPIC);
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch term data based on supplier');
                setErrorShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch term data based on supplier : ', error);
            setErrorShowDialog(true);
            setPicName('Failed to load Currency name');
        } finally {
            setLoading(false);
        }
    }

    const fetchCompanyData = async () => {
        setLoading(true);
        try {
            const companyData = await fetchCompanys();
            if (companyData && companyData.length > 0) { 
                setCompanyData(companyData);
            } else {
                setCompanyData([]);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch detail company data : ', error);
            setErrorShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const fetchShippings = async () => {
        setLoading(true);
        try {
            const response = await fetch(API_BASE_URL + 'master/shipping/getallshipping.php');
            const data = await response.json();
            if (data.StatusCode === 200) {
                setFilteredShippings(data.Data);
                setselectedShipping(data.Data[0]?.shipment_id || '');
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch shipping data');
                setErrorShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch shipping data : ', error);
            setErrorShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleShippingChange = (event) => {
        setselectedShipping(event.target.value);
    };

    const handleSupplierChange = (event) => {
        const newSupplierId = event.target.value;
        setselectedSupplier(newSupplierId);
        fetchPicName(newSupplierId); 
        fetchSupplierCurrency(newSupplierId);
        fetchSupplierOrigin(newSupplierId);
        fetchSupplierTerm(newSupplierId);
    };

    const handlePaymentChange = (event) => {
        setselectedPayment(event.target.value);
    };

    const handleNewClick = async (event) => {
        event.preventDefault(); 
        const productNameNull = items.some(item => !item.productname);
    
        if (productNameNull) {
            setErrorShowDialog(true);
        } else {
            setLoading(true);
            try{
                const API = API_BASE_URL + 'purchase/insertpurchaseimport.php';

                let params = new URLSearchParams({
                    purchase_order_number: PONumber,
                    purchase_order_date: startDate,
                    purchase_order_supplier: selectedSupplier,
                    purchase_order_shipment: selectedShipping,
                    purchase_order_term: selectedTerm,
                    purchase_order_payment: selectedPayment,
                    purchase_order_origin: selectedOrigin,
                    purchase_order_shippingmarks: txtShippingRemarks,
                    purchase_order_remarks: txtRemarks,
                    purchase_order_status: 'd7ab6134-d157-11ee-8',
                    purchase_order_type: '705da1d4-d157-11ee-8',
                    purchase_order_currency: selectedCurrency,
                    insert_by: username,
                    product_length: items.length
                });

                items.forEach((item, index) => {
                    params.append(`purchase_order_product_name_${index + 1}`, item.productname);
                    params.append(`purchase_order_product_quantity_${index + 1}`, item.quantity);
                    params.append(`purchase_order_product_packaging_size_${index + 1}`, item.packaging);
                    params.append(`purchase_order_product_unit_price_${index + 1}`, item.unitPrice);
                });

                const response = await fetch(API, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: params
                });

                if(response.status === 200){
                    navigate(`/purchaseIndex`);
                }

                if(response.status === 500){
                    setErrorCode('400');
                    setErrorTitle('Error failed to insert purchase data');
                    setErrorShowDialog(true);
                }
            } catch (e){
                setErrorCode('400');
                setErrorTitle('Error failed to insert purchase data : ', e);
                setErrorShowDialog(true);
            } finally {
                setLoading(false);
            }
            
        }
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleErrorCloseDialog = () => {
        setErrorShowDialog(false);
    };

    const errorDialogBox = <ErrorDialog onCloseDialog={handleErrorCloseDialog} errorCode={errorCode} errorMessage={errorTitle}/>
    const dialogBox = <SuccessDialog onCloseDialog={handleCloseDialog} errorCode={"403"} errorMessage={"Cannot be null. Please fill the blank before submit"}/>;


    return(
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                {showDialog && dialogBox}
                <ProfilSearchBox username={username}/>
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Purchase Module</div>
                </div>
                {ErrorShowDialog && errorDialogBox}
                {loading ? <Loader/> : (
                    <div>
                        <div className='card-for-grid'>
                            <div className='title-for-card'>Purchase Order Import</div>
                            {/* First Row included order number, date */}
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Order Number"}/>
                                    <TextBox value={PONumber} onChange={(e) => setPONumber(e.target.value)}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"To"}/>
                                    <select className='new-form-dropdown' value={selectedSupplier} onChange={handleSupplierChange}>
                                        {filteredSuppliers.map((supplier) => (
                                            <option key={supplier.supplier_id} value={supplier.supplier_id}>
                                                {supplier['Supplier']}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Date"}/>
                                    <DatePicker
                                        className='date-picker'
                                        selected={startDate}
                                        onChange={(date) => {
                                            setStartDate(date);
                                        }}
                                        dateFormat="dd MMMM YYYY"
                                    />
                                </div>
                            </div>
                            {/* Second Row included Attn, Shipment, and Payment */}
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Attn"}/>
                                    <div>{picName}</div>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Shipment"}/>
                                    <select className='new-form-dropdown' value={selectedShipping} onChange={handleShippingChange}>
                                        {filteredShippings.map((shipping) => (
                                            <option key={shipping.shipment_id} value={shipping.shipment_id}>
                                                {shipping['shipment_name']}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Term"}/>
                                    <TextBox value={txtTermname}/>
                                </div>
                            </div>
                            {/* Third Row included Payment, Origin, and Null */}
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Payment"}/>
                                    <select className='new-form-dropdown' value={selectedPayment} onChange={handlePaymentChange}>
                                        {filteredPayments.map((payment) => (
                                            <option key={payment.Id} value={payment.Id}>
                                                {payment['Payment Method']}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Origin"}/>
                                    <TextBox value={txtOriginName}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Currency"}/>
                                    <TextBox value={txtCurrencyName}/>
                                </div>
                            </div>
                            {txtCurrencyName === null || txtCurrencyName === '' ? (
                                <Loader />
                            ) : (
                                <PurchaseImportTemplate items={items} setItems={setItems} currency={txtCurrencyName} />
                            )}

                            {/* Third Row included Should Mention and Delivery Address */}
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Should mention name : "}/>
                                    <div className='company-data-purchase'>
                                        {companyData.length > 0 ? companyData[0].company_name : 'Loading...'}
                                    </div>

                                    <div className='company-data-purchase'>
                                        {companyData.length > 0 ? companyData[0].company_address : 'Loading...'}
                                    </div>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Shipping Marks"}/>
                                    <TextArea placeholder="Remarks" type="text" maxLines={5} value={txtShippingRemarks} onChange={(e) => setTxtShippingRemarks(e.target.value)}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Remarks"}/>
                                    <TextArea placeholder="Remarks" type="text" maxLines={5} value={txtRemarks} onChange={(e) => setTxtRemarks(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        {/* Action Button Grid */}
                        <div className='grid-container-with-padding-left'>
                            {/* <div className="only-100-width-card-submit" onClick={handleUploadPDF}>Upload PDF</div> */}
                            <div className="only-100-width-card-approve" onClick={handleNewClick}>Submit</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NewPurchaseImport;