import React, { useState } from 'react';
import TextBox from '../TextBox';
import { useNavigate } from 'react-router-dom';
import ErrorBox from '../ErrorBox';
import Loader from '../Loader';
import { API_BASE_URL } from '../../../App';

const NewCurrencyDialog = ({ onCloseDialog }) => {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(true);  
    const [txtCurrency, settxtCurrency] = useState('');
    const [error, setError] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleCloseClick = () => {
        onCloseDialog(); 
    };

    if (!showDialog) {
        return null; 
    }

    const handleSubmit = async (event) => {
        event.preventDefault(); 

        if(
            !txtCurrency
        ) {
            setErrorTitle("Submission Failed");
            setErrorMessage("Please fill in all fields !!");
            setError("Please fill in all fields");
            return;
        }

        setLoading(true);

        try {
            const apiInsertTerm = `${API_BASE_URL}master/currency/insertcurrency.php`;
            const response = await fetch(apiInsertTerm, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    currency_name: txtCurrency
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to insert currency data');
            }

            if (response.status === 500) {
                setErrorTitle("Insert Failed");
                setErrorMessage("Check your field again !!");
                setShowDialog(true);
            }

            const responseData = await response.json();

            if(response.status === 200){
                
                navigate('/settingIndex');
            }
        } catch (e){
            setErrorTitle("Insert Currency Failed");
            setErrorMessage("An error occurred during insert. Please try again later.");
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const dialogBox = <ErrorBox onCloseDialog={handleCloseDialog} title={errorTitle} message={errorMessage} />;
    
    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            New Currency Data
                        </div>
                        {error && dialogBox}
                        {loading ? (<Loader/>) : (
                            <form>
                                <div className='TwoGridForm'>
                                    <div className='TwoGridFormContainer'>
                                        <label className='TwoGridFormLabel'>Currency</label>
                                        <div className='space-between-1vh'></div>
                                        <TextBox 
                                            value={txtCurrency}
                                            onChange={(e) => settxtCurrency(e.target.value)}
                                            placeholder="xxxx xxxx" type="text" maxLines={1}/>
                                    </div>
                                </div>
                            </form>
                        )}
                        <div className='space-between'/>
                        <div className='grid-container-form-submit'>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Close</div>
                            <div className='space-between-width'/>
                            <div className='verification-button' onClick={handleSubmit}>Submit</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewCurrencyDialog;
