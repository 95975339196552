import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import DashboardNonActive from '../../component/menu/DashboardNonActive';
import SalesNonActive from '../../component/menu/SalesNonActive';
import PurchaseActive from '../../component/menu/PurchaseActive';
import FinanceNonActive from '../../component/menu/FinanceNonActive';
import WarehouseNonActive from '../../component/menu/WarehouseNonActive';
import HRNonActive from '../../component/menu/HRNonActive';
import AnalyticsnonActive from '../../component/menu/AnalyticsNonActive';
import DocumentnonActive from '../../component/menu/DocumentNonActive';
import SettingNonActive from '../../component/menu/SettingNonActive';
import Logout from '../../component/menu/Logout';
import ProfilSearchBox from '../../component/ProfilSearchBox';
import FormLabel from '../../component/FormLabel';
import TextArea from '../../component/TextArea';
import useAuthSession from '../../services/Session';
import Loader from '../../component/Loader';
import { fetchCompanys } from '../../services/PurchaseService';
import handleDownloadPDF from '../../services/PDFExportServices';
import TextBox from '../../component/TextBox';
import { API_BASE_URL, formatCurrencyIDR, formatDate, formatDateTime } from '../../../App';
import { useNavigate } from 'react-router-dom';
import ErrorDialog from '../../component/dialog/ErrorDialog';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DetailPurchaseLocal() {
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [searchParams] = useSearchParams();
    const PONumber = searchParams.get('poNumber');
    const [detailPurchaseLocal, setDetailPurchaseLocal] = useState(null);
    const [companyData, setCompanyData] = useState({});
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [purchaseDate, setPurchaseDate] = useState(new Date());
    const [shipmentDate, setShipmentDate] = useState(new Date());
    const [quantities, setQuantities] = useState([]);
    const [hargasatuan, setHargaSatuan] = useState([]);
    const permissionAccess = localStorage.getItem('permissionAccess');

    useEffect(() => {
        const fetchPurchaseDetail = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}purchase/getdetailpurchaselocal.php?PONumber=${PONumber}`);

                const data = await response.json();
                if (data.StatusCode === 200) {
                    setDetailPurchaseLocal(data.Data);
                    setPurchaseDate(new Date(data.Data.Details[0].PODate));
                    setShipmentDate(new Date(data.Data.Details[0].POShipment));

                    const initialQuantities = data.Data.Items.map(item => item.POQuantity);
                    setQuantities(initialQuantities);
                    console.log(quantities);

                    const initialHargaSatuan = data.Data.Items.map(item => item.POUnitPrice);
                    setHargaSatuan(initialHargaSatuan);

                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail purchase local data');
                    setShowDialog(true);
                }
            } catch (error) {
                // setErrorCode('400');
                console.log('Error failed to fetch detail purchase details : ', error);
                // setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        const fetchCompanyData = async () => {
            setLoading(true);
            try{
                const companyData = await fetchCompanys();
                if (companyData && companyData.length > 0) { // Check if data is not empty
                    setCompanyData(companyData);
                } else {
                    setCompanyData([]);
                }
            } catch (error){
                // setErrorCode('400');
                // setErrorTitle('Error failed to fetch detail company details : ', error);
                // setShowDialog(true);
            } finally {
                setLoading(false);
            }
        }

        if (PONumber) {
            fetchPurchaseDetail();
            fetchCompanyData();
        }
    }, [PONumber]);

    const handleExportPDF = () => {
        const pdfContentElement = document.querySelector('.card-for-grid');
    
        // if (!detailPurchaseLocal || !detailPurchaseLocal.Details || detailPurchaseLocal.Details.length === 0) {
        //     return;  
        // }

        // Preparing the items data
        const items = detailPurchaseLocal.Items.map((item, index) => [
            index + 1,
            item.POProductName,
            item.POQuantity,
            item.POPackagingSize,
            formatCurrencyIDR(item.POUnitPrice),
            formatCurrencyIDR(item.POQuantity * item.POUnitPrice)
        ]);

        // Calculating totals
        const total = detailPurchaseLocal.Items.reduce((sum, item) => sum + (item.POQuantity * item.POUnitPrice), 0);
        const vat = total * (detailPurchaseLocal.Details[0].PPNPercentage / 100);
        const grandTotal = total + vat;

        // Adding VAT and Total rows
        items.push(
            ['', '', '', '', `Vat ${detailPurchaseLocal.Details[0].PPNPercentage}%`, formatCurrencyIDR(vat)],
            ['', '', '', '', 'Total', formatCurrencyIDR(grandTotal)]
        );
    
        const data = {
            PONumber: detailPurchaseLocal.Details[0].PONumber,
            Supplier: detailPurchaseLocal.Details[0].POSupplierPIC,
            PODate: formatDate(detailPurchaseLocal.Details[0].PODate),
            Attn: detailPurchaseLocal.Details[0].POSupplierPIC,
            SupplierCompany: detailPurchaseLocal.Details[0].supplierName,
            Payment: detailPurchaseLocal.Details[0].payment_name,
            Delivery: formatDate(detailPurchaseLocal.Details[0].POShipment),
            Items: items
        };
    
        handleDownloadPDF(pdfContentElement, { FileNumber: detailPurchaseLocal.Details[0].PONumber, FileType: 'Purchase Order Local' }, data);
    };

    const hadleApproveAction = async () => {
        setLoading(true);

        try{
            const APIupdate = API_BASE_URL + 'purchase/approvepurchase.php';
            const response = await fetch(APIupdate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    purchase_order_number: detailPurchaseLocal.Details[0].PONumber,
                    purchase_order_status: 'e71e4fc4-d157-11ee-8',
                    update_by: username
                }),
            });

            if (!response.ok) {
                setErrorCode('400');
                setErrorTitle('Error failed to approve purchase local');
                setShowDialog(true);
            }

            if (response.status === 500) {
                setErrorCode('400');
                setErrorTitle('Error failed to approve purchase local');
                setShowDialog(true);
            }
            
            if(response.status === 200){
                navigate('/purchaseIndex');
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to approve purchase local : ', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleRejectAction = async () => {
        setLoading(true);

        try{
            const APIupdate = API_BASE_URL + 'purchase/rejectpurchase.php';
            const response = await fetch(APIupdate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    purchase_order_number: detailPurchaseLocal.Details[0].PONumber,
                    purchase_order_status: 'ee1d682c-d157-11ee-8',
                    update_by: username
                }),
            });

            if (!response.ok) {
                setErrorCode('400');
                setErrorTitle('Error failed to reject purchase local');
                setShowDialog(true);
            }

            if (response.status === 500) {
                setErrorCode('400');
                setErrorTitle('Error failed to reject purchase local');
                setShowDialog(true);
            }
            
            if(response.status === 200){
                navigate('/purchaseIndex');
            }
        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error failed to reject purchase local : ', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleOnDelivery = async (PONumber) => {
        navigate(`/purchaseIndex/New/withParams/receivingItems/${encodeURIComponent(PONumber)}`);
    }

    const handleCreateInvoice = async (PONumber) => {
        navigate(`/purchaseIndex/New/withParams/purchaseInvoice/${encodeURIComponent(PONumber)}`);
    }

    const handleFieldChange = (index, field, value) => {
        const updatedDetails = detailPurchaseLocal.Details.map((item, i) => {
            if (i === index) {
                return { ...item, [field]: value };
            }
            return item;
        });
        setDetailPurchaseLocal({ ...detailPurchaseLocal, Details: updatedDetails });
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleQuantityChange = (e, index) => {
        const value = e.target.value;
        setQuantities(prevQuantities => {
            const newQuantities = [...prevQuantities];
            newQuantities[index] = value;
            return newQuantities;
        });
    };

    const TotalCount = (harga, quantity) => {
        const Total = (harga * quantity);
        return formatCurrencyIDR(Total);
    };

    const handleHargaSatuanChange = (e, index) => {
        const value = e.target.value;
        setHargaSatuan(prevHargaSatuan => {
            const newHargaSatuan = [...prevHargaSatuan];
            newHargaSatuan[index] = value;
            return newHargaSatuan;
        })
    }

    const DPPCountW = (harga, quantity) => {
        const DPPResult = harga * quantity;
        return DPPResult;
    };

    const PPNCountW = (harga, quantity) => {
        const PPNResult = (harga * quantity) * (detailPurchaseLocal.Details[0].PPNPercentage / 100);
        return PPNResult;
    };

    let totalDPP = 0;
    let totalPPN = 0;
    let totalHargaSatuan = 0;
    
    if (detailPurchaseLocal) {
        detailPurchaseLocal.Items.forEach((item, index) => {
            const DPP = DPPCountW(hargasatuan[index], quantities[index]);
            const PPN = PPNCountW(hargasatuan[index], quantities[index]);
            totalDPP += parseFloat(DPP);
            totalPPN += parseFloat(PPN);
            totalHargaSatuan += parseFloat(hargasatuan[index]);
        });
    }

    const handleResubmit = async () => {
        setLoading(true);
        try{
            const API = API_BASE_URL + 'purchase/revisiedpurchaselocal.php';

            let params = new URLSearchParams({
                purchase_order_number : detailPurchaseLocal.Details[0].PONumber,
                purchase_order_date : purchaseDate,
                shipment_date : shipmentDate,
                insert_by : username,
                product_length : detailPurchaseLocal.Items.length
            });

            detailPurchaseLocal.Items.forEach((item, index) => {
                params.append(`purchase_order_product_name_${index + 1}`, item.POProductName);
                params.append(`purchase_order_quantity_before_${index + 1}`, item.POQuantity);
                params.append(`purchase_order_unit_price_before_${index + 1}`, item.POUnitPrice);
                params.append(`purchase_order_quantity_after_${index + 1}`, quantities[index]);
                params.append(`purchase_order_unit_price_after_${index + 1}`, hargasatuan[index]);
            })

            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params
            });

            if(response.status === 200){
                navigate(`/purchaseIndex`);
            }

            if(response.status === 500){
                setErrorCode('400');
                setErrorTitle('Error while update sales order data !!');
                setShowDialog(true);
            }

        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error fetching revised sales order data:', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }
 
    return (
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Purchase Module</div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? 
                    ( <Loader/> ) : detailPurchaseLocal ? (
                        <div>
                            <div className='card-for-grid'>
                                <div className='title-for-card'>Purchase Status</div>
                                <div className='grid-container'>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Status"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailPurchaseLocal.Details[0].POStatusName}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Created By"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailPurchaseLocal.Details[0].InsertBy}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Created At"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{formatDateTime(detailPurchaseLocal.Details[0].InsertDt)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-for-grid'>
                                <div className='title-for-card'>Purchase Order Local</div>
                                <div className='grid-container'>
                                    {/* Displaying basic details */}
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Order Number"}/>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={detailPurchaseLocal.Details[0].PONumber}/>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Supplier Name"}/>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={detailPurchaseLocal.Details[0].supplierName}/>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Date"}/>
                                        <div className='space-between-1vh'></div>
                                        {detailPurchaseLocal.Details[0].POStatusName === 'Rejected' ?<DatePicker
                                            selected={purchaseDate}
                                            onChange={(date) => setPurchaseDate(date)}
                                            dateFormat="dd MMMM yyyy"
                                            className="datepicker"
                                        /> : <TextBox value={formatDate(detailPurchaseLocal.Details[0].PODate)}/>}
                                        {/* <TextBox value={formatDate(detailPurchaseLocal.Details[0].PODate)}/> */}
                                    </div>
                                </div>
                                <div className='grid-container'>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Attn"}/>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={detailPurchaseLocal.Details[0].POSupplierPIC}/>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Shipment"}/>
                                        <div className='space-between-1vh'></div>
                                        {detailPurchaseLocal.Details[0].POStatusName === 'Rejected' ?<DatePicker
                                            selected={shipmentDate}
                                            onChange={(date) => setShipmentDate(date)}
                                            dateFormat="dd MMMM yyyy"
                                            className="datepicker"
                                        /> : <TextBox value={formatDate(detailPurchaseLocal.Details[0].POShipment)}/>}
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Payment"}/>
                                        <div className='space-between-1vh'></div>
                                        <TextBox value={detailPurchaseLocal.Details[0].payment_name}/>
                                    </div>
                                </div>
                                {detailPurchaseLocal.Items.map((item, index) => (
                                    <div key={index}>
                                        <div className='ItemNameTemplateForm'>
                                            <FormLabel labelName={`Item #${index + 1}`} />
                                        </div>
                                        <div className="grid-container">
                                            <div className="only-100-width">
                                                <FormLabel labelName={`Product Name`}/>
                                                <div className='space-between-1vh'></div>
                                                    {detailPurchaseLocal.Details[0].POStatusName === 'Rejected' ? 
                                                        <TextBox value={item.POProductName} onChange={(e) => handleFieldChange(index, 'ProductName', e.target.value)} /> :
                                                        <TextBox value={item.POProductName} readOnly />
                                                    }
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={`Quantity (Kg)`}/>
                                                <div className='space-between-1vh'></div>
                                                {detailPurchaseLocal.Details[0].POStatusName === 'Rejected' ? <TextBox value={quantities[index]} onChange={(e) => handleQuantityChange(e, index)}/> : <TextBox value={item.POQuantity}/>}
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={`Packaging Size (Kg)`}/>
                                                <div className='space-between-1vh'></div>
                                                <TextBox value={item.POPackagingSize}/>
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={`Unit Price (IDR)`}/>
                                                <div className='space-between-1vh'></div>
                                                {detailPurchaseLocal.Details[0].POStatusName === 'Rejected' ? <TextBox value={hargasatuan[index]} onChange={(e) => handleHargaSatuanChange(e, index)}/> : <TextBox value={item.POUnitPrice}/>}
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={`Total (IDR)`}/>
                                                <div className='space-between-1vh'></div>
                                                <TextBox value={TotalCount(hargasatuan[index], quantities[index])} />
                                            </div>
                                        </div>

                                    </div>
                                ))}
                                <div className='grid-container'>
                                    <div className="only-100-width">

                                    </div>
                                    <div className="only-100-width">
                                        
                                    </div>
                                    <div className="only-100-width">
                                        
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"VAT (11%)"}/>
                                    </div>
                                    <div className="only-100-width">
                                        <TextBox value={formatCurrencyIDR(totalPPN)} readOnly />
                                    </div>
                                </div>
                                <div className='grid-container'>
                                    <div className="only-100-width">

                                    </div>
                                    <div className="only-100-width">
                                        
                                    </div>
                                    <div className="only-100-width">
                                        
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Total"}/>
                                    </div>
                                    <div className="only-100-width">
                                        <TextBox value={formatCurrencyIDR(totalDPP + totalPPN)} readOnly />
                                    </div>
                                </div>
                                <div className='grid-container'>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Invoice Under"}/>
                                        <div className='company-data-purchase'>
                                            {companyData.length > 0 ? companyData[0].company_name : 'Loading...'}
                                        </div>

                                        <div className='company-data-purchase'>
                                            {companyData.length > 0 ? companyData[0].company_address : 'Loading...'}
                                        </div>

                                    </div>
                                    <div className="only-100-width">
                                        
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Delivery Address"}/>
                                        <TextArea placeholder="Remarks" type="text" maxLines={5} value={"Pergudangan Taman Tekno\nSektor XI Blok K3 No. 47, BSD - Tangerang\nTelp : 02175880017"}/>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='card-for-grid'>
                                <div className='title-for-card'>Document</div>
                            </div> */}
                            <div className='grid-container-with-padding-left'>
                                {/* <div className="only-100-width-card-reject" onClick={handleUploadPDF}>Upload PDF</div> */}
                                {/* <div className="only-100-width-card-reject" onClick={handleExportPDF}>Export as PDF</div> */}
                                {
                                    detailPurchaseLocal.Details[0].POStatusName === 'Approved' || detailPurchaseLocal.Details[0].POStatusName === 'Received' || detailPurchaseLocal.Details[0].POStatusName === 'Invoice' ? (
                                        <div className="only-100-width-card-reject" onClick={handleExportPDF}>Export as PDF</div>
                                    ) : (
                                        <div></div>
                                    )
                                }
                                {
                                    detailPurchaseLocal.Details[0].POStatusName === 'Draft' && permissionAccess === 'Full access' ? (
                                        <div className="only-100-width-card-approve">
                                            <div onClick={hadleApproveAction}>Approve</div>
                                        </div>
                                    ) :  detailPurchaseLocal.Details[0].POStatusName === 'Approved' ? (
                                        <div className="only-100-width-card-approve">
                                            <div onClick={() => handleOnDelivery(detailPurchaseLocal.Details[0].PONumber)}>Receive Items</div>
                                        </div>
                                    ) :  detailPurchaseLocal.Details[0].POStatusName === 'Received' ? (
                                        <div className="only-100-width-card-approve">
                                            <div onClick={() => handleCreateInvoice(detailPurchaseLocal.Details[0].PONumber)}>Create Invoice</div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )
                                }
                                {
                                    detailPurchaseLocal.Details[0].POStatusName === 'Draft' && permissionAccess === 'Full access' ? (
                                        <div className="only-100-width-card-reject">
                                            <div onClick={handleRejectAction}>Reject</div>
                                        </div>
                                    ) : detailPurchaseLocal.Details[0].POStatusName === 'Rejected' ? (
                                        <div className="only-100-width-card-reject">
                                            <div onClick={handleResubmit}>Resubmit</div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )
                                }
                            </div>
                    </div>
                ) : (<Loader/>)}
            </div>
        </div>
    );
}

export default DetailPurchaseLocal;
