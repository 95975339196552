import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { API_BASE_URL } from '../../App';

const handleDownloadPDF = async (contentElement, { FileNumber, FileType }, data) => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    let currentY = 55; // Initial Y position

    try {
        const response = await fetch(`${API_BASE_URL}master/document/watermark.php?id=2`);
        if (!response.ok) throw new Error('Network response was not ok');
        
        const jsonResponse = await response.json();
        if (jsonResponse.StatusCode !== 200) throw new Error('Error fetching watermark image');

        const watermarkImageBase64 = `data:image/png;base64,${jsonResponse.data.watermark}`;
        const imgWidth = pdf.internal.pageSize.width;
        const imgHeight = pdf.internal.pageSize.height;

        pdf.addImage(watermarkImageBase64, 'PNG', 0, 0, imgWidth, imgHeight, '', 'NONE', 1);
        
        // Set default font and size for the document
        pdf.setFont('Tahoma', 'normal');
        pdf.setFontSize(10);

        switch (FileType) {
            case 'Purchase Order Local':
                pdf.setFont('Tahoma', 'bold');
                pdf.text('Purchase Order', 10, currentY);
                pdf.setFont('Tahoma', 'normal');
                pdf.text(`To: ${data.SupplierCompany}`, 110, currentY);
                currentY += 7;
                pdf.text(`No: ${data.PONumber}`, 10, currentY);
                currentY += 7;
                pdf.text(`Date: ${data.PODate}`, 10, currentY);
                currentY += 15;
                pdf.text(`Attn: ${data.Attn}`, 110, currentY);
                currentY += 7;

                const poLocalTableData = [
                    ['No.', 'Product', 'Quantity (Kg)', 'Packaging Size (Kg)', 'Unit Price (IDR)', 'Total (IDR)'],
                    ...data.Items
                ];

                pdf.autoTable({
                    startY: currentY + 10,
                    head: [['No.', 'Product', 'Quantity (Kg)', 'Packaging Size (Kg)', 'Unit Price (IDR)', 'Total (IDR)']],
                    body: data.Items,
                    theme: 'plain',
                    styles: { font: 'Tahoma', fontSize: 10, textColor: [0, 0, 0], lineColor: [0, 0, 0], lineWidth: 0.3 },
                    columnStyles: { 0: { halign: 'center' }, 4: { halign: 'right' }, 5: { halign: 'right' } },
                    didDrawCell: function (data) {
                        // Check if the row is "Vat 11%" or "Total"
                        if (data.row.index === poLocalTableData.length - 2 || data.row.index === poLocalTableData.length - 1) {
                            if (data.column.index < 4) {
                                // Remove left borders for "Vat 11%" and "Total"
                                data.cell.styles.lineWidth = { top: 0, right: 0, bottom: 0, left: 0 };
                            }
                        }
                    }
                });

                currentY = pdf.autoTable.previous.finalY + 20;
                pdf.text(`Payment: ${data.Payment}`, 10, currentY);
                currentY += 7;
                pdf.text(`Delivery: ${data.Delivery}`, 10, currentY);
                currentY += 7;
                pdf.text(`Delivery Address: `, 10, currentY);
                currentY += 7;
                pdf.text(`Pergudangan Taman Tekno`, 45, currentY);
                currentY += 7;
                pdf.text(`Sektor XI Blok K3 No.47, BSD - Tangerang`, 45, currentY);
                currentY += 7;
                pdf.text(`Telp: 021-75880017`, 45, currentY);
                currentY += 7;
                pdf.text(`Invoice Under: `, 10, currentY);
                currentY += 7;
                pdf.setFont('Tahoma', 'bold');
                pdf.text(`PT. Venken International Kimia`, 45, currentY);
                currentY += 7;
                pdf.text(`Jl. Raya Kelapa Puan Blok AG 11/7`, 45, currentY);
                currentY += 7;
                pdf.text(`Kec. Curug - Kabupaten Tangerang`, 45, currentY);
                currentY += 7;
                pdf.text(`Indonesia`, 45, currentY);
                currentY += 7;
                pdf.setFont('Tahoma', 'normal');
                pdf.text(`Documents: COA send by E-mail to purchasing.vik@gmail.com`, 10, currentY);
                break;

            // Add other cases similarly...
            case 'Purchase Order Import':
                pdf.setFont('Tahoma', 'bold');
                pdf.setFontSize(10);
    
                pdf.text(`Purchase Order`, 10, currentY);
                pdf.setFont('Tahoma', 'normal');
                pdf.text(`To    : ${data.SupplierCompany}`, 110, currentY);
                currentY += 7
                pdf.text(`No     : ${data.PONumber}`, 10, currentY);
                currentY += 7
                pdf.text(`Date  : ${data.PODate}`, 10, currentY);
                currentY += 7
                pdf.text(`Attn  : ${data.Supplier}`, 110, currentY);

                const poImportTableData = [
                    ['No.', 'Product', 'Quantity (Kg)', 'Packaging Size (Kg)', `Unit Price (${data.CurrencyName})`, `Total (${data.CurrencyName})`],
                    ...data.Items
                ];

                pdf.autoTable({
                    startY: currentY + 10,
                    head: [['No.', 'Product', 'Quantity (Kg)', 'Packaging Size (Kg)', `Unit Price (${data.CurrencyName})`, `Total (${data.CurrencyName})`]],
                    body: data.Items,
                    theme: 'plain',
                    styles: { font: 'Tahoma', fontSize: 10, textColor: [0, 0, 0], lineColor: [0, 0, 0], lineWidth: 0.3 },
                    columnStyles: { 0: { halign: 'center' }, 4: { halign: 'right' }, 5: { halign: 'right' } }
                });
                

                currentY = pdf.autoTable.previous.finalY + 20;

                pdf.text(`Term        : ${data.Term}`, 10, currentY);//tambah 7
                currentY += 7;
                pdf.text(`Shipment : ${data.Shipment}`, 10, currentY);//tambah 7
                currentY += 7;
                pdf.text(`Payment  : ${data.Payment}`, 10, currentY);//tambah 7
                currentY += 15;

                pdf.text(`Origin                  : ${data.Origin}`, 10, currentY);
                currentY += 7;
                const shippingMarksParts = data.ShippingMarks.split(/(Inv\. No\..*?)(\s*Jakarta)/);
                pdf.text(`Shipping Marks  : ${shippingMarksParts[0].trim()}`, 10, currentY);
                currentY += 7;
                pdf.text(`${shippingMarksParts[1].trim()}`, 37, currentY);
                currentY += 7;
                pdf.text(`${shippingMarksParts[2].trim()}`, 37, currentY);
                currentY += 15;
                // pdf.text(`Remarks   : ${data.Remarks}`, 10, currentY);
                // currentY += 15;

                pdf.text('Should mention name:', 20, currentY);
                pdf.setFont('Tahoma', 'bold');
                currentY += 5;
                pdf.text(`PT. Venken International Kimia`, 25, currentY);
                currentY += 5;
                pdf.text(`Jl. Raya Kelapa Puan Blok AG 11/7`, 25, currentY);
                currentY += 5;
                pdf.text(`Kec. Curug - Kabupaten Tangerang`, 25, currentY);
                currentY += 5;
                pdf.text(`Indonesia`, 25, currentY);
                currentY += 15;

                pdf.setFont('Tahoma', 'normal');
                pdf.text(`Documents : COA send by E-mail to purchasing.vik@gmail.com`, 10, currentY);
                break;
            default:
                break;
        }

        html2canvas(contentElement).then((canvas) => {
            pdf.save(`${FileType} - ${FileNumber}.pdf`);
        });

    } catch (error) {
        console.error('Error fetching watermark image:', error);
    }
};

export default handleDownloadPDF;
